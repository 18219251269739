import { FilterOptionsState } from '@material-ui/lab'
import { matchSorter } from 'match-sorter'
import { useCallback } from 'react'
import { GroupOptionType, InfoOption, isInfoOption } from './types'

type Options = {
  selectedGroupIds: string[]
}

const useHandleFilterOptions = ({ selectedGroupIds }: Options) =>
  useCallback(
    (options: GroupOptionType[], state: FilterOptionsState<GroupOptionType>) => {
      // Extract the info options real quick before performing the search (we don't want to filter out the info options)
      const infoOptions = options.filter(o => isInfoOption(o))
      const groupOptions = options.filter(o => !isInfoOption(o))

      // If there are only info options and no group options, just return the info options
      if (groupOptions.length === 0) return infoOptions

      const filteredOptions = matchSorter(groupOptions, state.inputValue, { keys: ['title'] })

      // Add the info options back in
      filteredOptions.push(...infoOptions)

      // If there are 10 or fewer options, show the filter results even if there's no input to filter them by (i.e., just normal dropdown list options with no filter)
      if (options.length <= 10) return filteredOptions

      if (state.inputValue) {
        // If the user's search has results add a "matches" info option to tell them to select a math or open the sidebar to view more.
        if (filteredOptions.length > 0) {
          const matches: InfoOption = { status: 'matches', openSidebarWhenSelected: true }
          filteredOptions.push(matches)
        } else {
          // If there are no matches, tell the user, but only allow sidebar opening if the are more than 10 options
          const noMatches: InfoOption = {
            status: 'no-matches',
            openSidebarWhenSelected: options.length > 10,
          }
          filteredOptions.push(noMatches)
        }

        return filteredOptions
      }
      // With more than 10 options "require" input before showing the list
      else {
        const noInput: InfoOption = { status: 'no-input', openSidebarWhenSelected: true }
        return [noInput]
      }
    },
    [selectedGroupIds]
  )

export default useHandleFilterOptions
