import { useEffect, useState } from 'react'
import { LOCAL_STORAGE_ANONYMOUS_ACCOUNT_KEY } from '../constants'
import { useLocalStorage } from './use-local-storage'

export const useIsAnonymous = () => {
  const { getItem } = useLocalStorage()

  const [isAnonymous, setIsAnonymous] = useState<boolean>()

  useEffect(() => {
    ;(async () => {
      const anonymousAccountId = await getItem({ key: LOCAL_STORAGE_ANONYMOUS_ACCOUNT_KEY })
      setIsAnonymous(Boolean(anonymousAccountId))
    })()
  }, [])

  return isAnonymous
}
