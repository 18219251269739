import { RegisterStep } from './types'
import * as yup from 'yup'

const getValidationSchema = (step: RegisterStep) => {
  if (step === RegisterStep.SignIn) {
    return yup.object().shape({
      email: yup.string().email('Invalid email address').required('Required'),
      password: yup.string().required('Required'),
    })
  }

  if (step === RegisterStep.Register) {
    return yup.object().shape({
      email: yup.string().email('Invalid email address').required('Required'),
      password: yup.string().required('Required'),
      confirmPassword: yup
        .string()
        .required('Required')
        .test('passwords-match', 'Passwords must match', function (value) {
          return this.parent.password === value
        }),
      firstName: yup.string().required('Required'),
      lastName: yup.string().required('Required'),
    })
  }

  return yup.object().shape({})
}

export default getValidationSchema
