import { useField } from 'formik'
import { IField } from './interfaces'
import { TextInput } from './index'
import { CommonProps, MUIInputVariants } from '../interfaces'
import { useEffect } from 'react'

export interface IFormikTextInputProps extends CommonProps {
  fieldProps: IField
  variant?: MUIInputVariants
  textFieldOnly?: boolean
  autoFocus?: boolean
  touched?: boolean
  hideHelperTextWhenEmpty?: boolean
  multiline?: boolean
  rows?: number
  noMargin?: boolean
  tooltip?: string
  type?: 'email'
}
export const FormikTextInput = (props: IFormikTextInputProps) => {
  const [field, meta, helpers] = useField(props.fieldProps)

  useEffect(() => {
    if (props.touched !== undefined) helpers.setTouched(props.touched)
  }, [props.touched])

  return (
    <TextInput
      {...props}
      {...meta}
      {...field}
      disabled={props.fieldProps.disabled}
      onChange={helpers.setValue}
    />
  )
}
