import { Paper, Typography, useTheme } from '@material-ui/core'
import { useSchoolContext } from '../../stores/school'
import { ImageDisplay } from '../image-display'

const AccessRevoked = () => {
  const theme = useTheme()

  const {
    state: { school },
  } = useSchoolContext()
  const { schoolSetting } = school

  if (!schoolSetting) throw new Error('Missing school setting.')

  return (
    <Paper style={{ padding: theme.spacing(2), margin: theme.spacing(2) }}>
      {schoolSetting.thumbnailImage?.temporaryDownloadUrl && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: theme.spacing(2),
          }}
        >
          <ImageDisplay
            style={{
              height: 75,
              width: 75,
              borderRadius: theme.shape.borderRadius,
            }}
            src={schoolSetting.thumbnailImage.temporaryDownloadUrl}
          />
        </div>
      )}

      <Typography style={{ marginBottom: theme.spacing(2) }} align="center">
        You currently do not have access to the <b>{school.name}</b> app. Please contact your school
        administrator to restore access.
      </Typography>
    </Paper>
  )
}

export default AccessRevoked
