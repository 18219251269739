import { Button, Typography } from '@material-ui/core'
import { ArrowForward } from '@material-ui/icons'
import { FormikTextInput, PasswordField } from '../../../forms'
import { StepProps } from './types'

const PasswordResetRequired = ({ formik }: StepProps) => (
  <>
    <Typography gutterBottom variant="h4">
      Password Reset Required
    </Typography>

    <Typography gutterBottom color="textSecondary">
      To help ensure the security of your account, we occasionally require you to verify your email
      address and create a new password.
    </Typography>

    <Typography style={{ marginBottom: 16 }} color="textSecondary">
      Please check your email and follow the link to reset your password, then return here to
      continue signing in.
    </Typography>

    <div style={{ width: '100%', display: 'flex' }}>
      <FormikTextInput
        type="email"
        noMargin
        fieldProps={{
          name: 'email',
          label: 'Email',
          disabled: true,
        }}
      />
    </div>

    <div style={{ width: '100%', display: 'flex' }}>
      <PasswordField
        style={{ marginBottom: 16 }}
        fieldProps={{
          name: 'password',
          label: 'Password',
          labelWidth: 70,
        }}
        noMargin
      />
    </div>

    <Button
      disabled={!formik.isValid}
      style={{ width: '100%' }}
      size="large"
      type="submit"
      color="primary"
      variant="contained"
      endIcon={<ArrowForward />}
    >
      Continue
    </Button>
  </>
)

export default PasswordResetRequired
