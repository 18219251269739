import { searchIconTypes } from '../../helpers'
import { FormikDynamicFieldsTouched, FormikValuesOnChange } from '../forms'
import { OnKeyPressEvent } from '../on-key-press-event'
import { FormValues } from './types'

type Props = {
  submitForm: () => void
  values: FormValues
  setFieldValue: (field: string, value: any) => void
  iconAutoSetRef: React.MutableRefObject<boolean>
}

const Effects = ({ submitForm, values, setFieldValue, iconAutoSetRef }: Props) => {
  return (
    <>
      <OnKeyPressEvent keyPress="Enter" action={submitForm} />
      <FormikDynamicFieldsTouched />
      <FormikValuesOnChange
        values={values}
        onChange={(prevValues, nextValues) => {
          if (
            (!nextValues.iconType || iconAutoSetRef.current) &&
            nextValues.name.length &&
            prevValues.name !== nextValues.name
          ) {
            iconAutoSetRef.current = true
            const result = searchIconTypes(nextValues.name)[0]
            if (result !== nextValues.iconType) setFieldValue('iconType', result)
          }
        }}
        debounceMs={200}
      />
    </>
  )
}

export default Effects
