import { Link } from '@material-ui/core'
import { useCallback } from 'react'
import { joinStrings } from '../../helpers/array-helpers'
import { InfoOption } from './types'

const useGetContentForInfoOption = () =>
  useCallback((option: InfoOption) => {
    switch (option.status) {
      case 'matches':
        return (
          <span>
            Select an option above or <Link>view list</Link>.
          </span>
        )
      case 'no-input':
        return (
          <span>
            Start typing to search for a group or <Link>pick from list</Link>.
          </span>
        )
      case 'no-matches':
        return (
          <span>
            No groups match. Try a different search or <Link>pick from a list</Link> instead.
          </span>
        )
      case 'schoolwide-selected':
        return (
          <span>
            You have selected the <b>Schoolwide</b> group. There is no need to include any more
            groups since content available to the <b>Schoolwide</b> group will be visible to all
            users from all groups.
          </span>
        )
      case 'divisions-selected':
        if (!option.selectedDivisionNames)
          throw new Error(
            'Unable to display divisions-selected IFinalOption. Missing selectedDivisionNames.'
          )
        const divisionNames = joinStrings(option.selectedDivisionNames)
        const genericLabel =
          option.selectedDivisionNames && option.selectedDivisionNames.length > 1
            ? 'these divisions'
            : 'this division'
        return (
          <span>
            You have selected <b>{divisionNames}</b>. Groups from {genericLabel} are omitted from
            this list of group options. There is no need to include any more groups from{' '}
            <b>{divisionNames}</b> since content available to {genericLabel} will be visible to
            users in all groups within <b>{divisionNames}</b>.
          </span>
        )
    }
  }, [])

export default useGetContentForInfoOption
