import { useSnackbar } from 'notistack'
import { createContext, FunctionComponent, useCallback, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAccessToken, useAppState, useLocalStorage } from '.'
import {
  ClaimAnonymousAccountMutation,
  ClaimAnonymousAccountMutationVariables,
  RegisterUserMutation,
} from '../graphql/autogenerate/operations'
import {
  useAcceptInvitationMutation,
  useAuthenticateMutation,
  useClaimAnonymousAccountMutation,
  useCurrentUserQuery,
  useForgotPasswordMutation,
  useRefreshTokenQuery,
  useRegisterUserMutation,
  useResetPasswordMutation,
} from '../graphql/autogenerate/react-query'
import { AppActionType } from '../stores/app-state'
import { useHandleReactQuery, useHandleReactQueryMutation } from './use-handle-react-query'
import { LOCAL_STORAGE_ANONYMOUS_ACCOUNT_KEY } from '../constants'

interface IAuthCredentials {
  email: string
  password: string
}

interface ISignup extends IAuthCredentials {
  firstName: string
  lastName: string
}

interface IResetPassword {
  userId: string
  token: string
  password: string
}

interface IAuthContext {
  signup: (args: ISignup, dontNavigate?: boolean) => Promise<RegisterUserMutation>
  login: ReturnType<typeof useAuthenticateMutation>['mutateAsync']
  acceptInvitation: ReturnType<typeof useAcceptInvitationMutation>['mutate']
  forgotPassword: (args: { email: string; redirect?: string | null }) => void
  resetPassword: (args: IResetPassword) => Promise<void>
  logout: () => void
  claimAnonymousAccount: (
    args: ClaimAnonymousAccountMutationVariables
  ) => Promise<ClaimAnonymousAccountMutation>
}
const AuthContext = createContext<IAuthContext | undefined>(undefined)

// Hook for child components to get the auth object and re-render when it changes.
export const useAuth = () => {
  const context = useContext(AuthContext)
  if (!context) throw new Error(`Attempted to use AuthContext before it's provider.`)
  return context
}

// Provider component that wraps your app and makes auth object available to any child component that calls useAuth().
export const AuthProvider: FunctionComponent = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar()
  const {
    dispatch,
    state: { authed },
  } = useAppState()
  const navigate = useNavigate()
  const { setAccessToken } = useAccessToken()

  /* 
    On startup of each session, attempt to refresh the token.
  */
  useRefreshTokenQuery(undefined, {
    onSuccess: async ({ refreshToken }) => await setAccessToken(refreshToken),
  })

  /* 
    Signup
  */
  const { mutateAsync: registerUserMutation } = useHandleReactQueryMutation(
    useRegisterUserMutation({
      onSuccess: async ({ registerUser }) => {
        if (registerUser?.jwtToken) {
          await onAuthSuccess(registerUser.jwtToken)
          enqueueSnackbar('Account created. Welcome!', { variant: 'success' })
        }
      },
    })
  )

  const signup = async (variables: ISignup, dontNavigate?: boolean) => {
    const results = await registerUserMutation({ ...variables, _email: variables.email })
    if (!dontNavigate) navigate('/', { replace: true })
    return results
  }

  const { removeItem } = useLocalStorage()
  const { mutateAsync: claimAnonymousAccount } = useHandleReactQueryMutation(
    useClaimAnonymousAccountMutation({
      onSuccess: async ({ claimAnonymousAccount }) => {
        await removeItem({ key: LOCAL_STORAGE_ANONYMOUS_ACCOUNT_KEY })
        await onAuthSuccess(claimAnonymousAccount?.jwtToken)
      },
    })
  )

  const onAuthSuccess = useCallback(async (jwtToken?: string | null) => {
    /* 
      A null jwtToken means the authentication failed for any reason (wrong password, account doesn't exist)
    */
    if (jwtToken === null) {
      enqueueSnackbar(
        <div>
          Login failed.{' '}
          <span>
            Email and password do not match, or an account does not exist with the provided email
            address.
          </span>
        </div>,
        {
          variant: 'error',
          preventDuplicate: false,
        }
      )
    }

    if (jwtToken) {
      await setAccessToken(jwtToken)
      dispatch({ type: AppActionType.login })
      gtag('set', { user_id: 'USER_ID' })
    }
  }, [])

  /* 
    Login
  */
  const { mutateAsync: login } = useHandleReactQueryMutation(
    useAuthenticateMutation({
      onSuccess: ({ authenticate }) => onAuthSuccess(authenticate?.jwtToken),
    })
  )

  /* 
    Accept invitation

    Don't redirect if the invitation is being accepted on mobile, otherwise the user will be redirected to mobile web and may not set up push notifications.
  */
  const { mutate: acceptInvitation } = useHandleReactQueryMutation(
    useAcceptInvitationMutation({
      onSuccess: ({ acceptInvitation }) => onAuthSuccess(acceptInvitation?.jwtToken),
    })
  )

  /* 
    Fetch the current user if authed. Whenever the current user changes, update it in the app state.
  */
  useHandleReactQuery(
    useCurrentUserQuery(undefined, {
      enabled: authed,
      onSuccess: ({ currentUser }) => {
        gtag('set', { user_id: currentUser?.id })
        dispatch({ type: AppActionType.setCurrentUser, payload: { currentUser } })
      },
    })
  )

  /* 
    Logout
  */
  const logout = () => {
    setAccessToken(null)
    dispatch({ type: AppActionType.logout })
    navigate('/')
  }

  /* 
    Forgot Password
  */
  const { mutateAsync: forgotPasswordQuery } = useHandleReactQueryMutation(
    useForgotPasswordMutation()
  )
  const forgotPassword = async ({
    email,
    redirect,
  }: {
    email: string
    redirect?: string | null
  }) => {
    await forgotPasswordQuery(
      { _email: email, redirect },
      {
        onSuccess: () => {
          enqueueSnackbar('Successfully sent password reset email.', {
            variant: 'success',
            preventDuplicate: false,
          })
        },
      }
    )
  }

  /* 
    Reset Password
  */
  const { mutateAsync: resetPasswordQuery } = useHandleReactQueryMutation(
    useResetPasswordMutation()
  )
  const resetPassword = async ({ token, password, userId }: IResetPassword) => {
    const results = await resetPasswordQuery({ resetToken: token, newPassword: password, userId })
    if (results?.resetPassword)
      enqueueSnackbar('Password successfully reset.', {
        variant: 'success',
        preventDuplicate: false,
      })
  }

  return (
    <AuthContext.Provider
      value={{
        logout,
        login,
        acceptInvitation,
        signup,
        forgotPassword,
        resetPassword,
        claimAnonymousAccount,
      }}
      children={children}
    />
  )
}
