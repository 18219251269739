import { Add, Remove } from '@material-ui/icons'
import { createStyles, makeStyles, useTheme } from '@material-ui/core'
import clsx from 'clsx'
import { memo } from 'react'
import { IconType } from '../../graphql/autogenerate/schemas'
import { AnimationIconWraper } from '../animation-icon-wrapper'
import { IconTypeDisplay } from '../icon-type-display'

const useGroupRowStyles = makeStyles(theme =>
  createStyles({
    row: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      marginBottom: theme.spacing(0.5),
      padding: `0px ${theme.spacing(1)}px`,
      cursor: 'pointer',
    },
    hover: {
      '&:hover': {
        backgroundColor: theme.palette.grey[200],
        '& span:nth-child(3)': {
          display: 'flex!important',
        },
      },
    },
    disabled: {
      color: theme.palette.grey[500],
      cursor: 'default',
    },
    rowTitle: {
      paddingLeft: theme.spacing(0.5),
      flex: 1,
    },
    icon: {
      color: theme.palette.grey[500],
    },
    hideAddRemoveFavoritesIcon: {
      display: 'none!important',
    },
  })
)

type Props = {
  title: string
  subTitle?: string
  iconType: IconType
  color: string
  onClick: () => void
  selected?: boolean
  disabled?: boolean
}

const GroupRow = memo(({ onClick, title, selected, iconType, color, disabled }: Props) => {
  const styles = useGroupRowStyles()
  const theme = useTheme()

  return (
    <div
      className={clsx(styles.row, disabled && styles.disabled, !disabled && styles.hover)}
      onClick={() => !disabled && onClick()}
    >
      <IconTypeDisplay
        type={iconType}
        size={20}
        style={{
          marginRight: 5,
          color: disabled ? theme.palette.grey[500] : color,
        }}
      />
      <div className={styles.rowTitle}>{title}</div>
      {selected !== undefined && (
        <AnimationIconWraper
          className={clsx(styles.icon)}
          children={selected ? <Remove /> : <Add />}
          tooltip={{
            title: selected ? 'Remove from selection' : 'Add to selection',
            placement: 'right',
          }}
        />
      )}
    </div>
  )
})

export default GroupRow
