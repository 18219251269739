import * as Types from './operations';

import { useQuery, UseQueryOptions, useMutation, UseMutationOptions } from 'react-query';
import { useFetchData } from '../network';
export const ButtonFragmentDoc = `
    fragment Button on Button {
  label
  type
  url
  id
  description
}
    `;
export const FileFragmentDoc = `
    fragment File on File {
  temporaryDownloadUrl
  filename
  id
  label
  type
}
    `;
export const EventFieldsFragmentDoc = `
    fragment EventFields on Event {
  allDay
  endDate
  googleId
  id
  information
  locationString
  startDate
  title
  googleCalendarId
  buttons {
    buttons {
      ...Button
    }
  }
  coverImage {
    ...File
  }
}
    ${ButtonFragmentDoc}
${FileFragmentDoc}`;
export const CalendarEventFragmentDoc = `
    fragment CalendarEvent on Event {
  ...EventFields
  calendarEvents {
    nodes {
      calendarId
    }
  }
}
    ${EventFieldsFragmentDoc}`;
export const CalendarGroupFragmentDoc = `
    fragment calendarGroup on CalendarGroup {
  title
  sortOrder
  id
  calendarGroupCalendars {
    nodes {
      calendar {
        group {
          id
        }
        id
      }
    }
  }
}
    `;
export const CurrentUserFragmentDoc = `
    fragment CurrentUser on Person {
  appAdministrator
  schoolSlugs
  firstName
  lastName
  email
  id
  userAccountId
}
    `;
export const GcsStatFragmentDoc = `
    fragment GcsStat on GoogleCalendarSync {
  id
  lastSyncedAt
  syncedUpTo
  syncedEvents {
    totalCount
  }
  status
}
    `;
export const GoogleCalendarFragmentDoc = `
    fragment GoogleCalendar on GoogleCalendar {
  id
  colorId
  timeZone
  summaryOverride
  summary
  primary
  description
  backgroundColor
  accessRole
}
    `;
export const GoogleAccountFragmentDoc = `
    fragment GoogleAccount on GoogleAccount {
  accountId
  email
  errorState
  id
  pictureUrl
  createdAt
  googleCalendarSyncs {
    totalCount
    nodes {
      syncedEvents {
        totalCount
      }
    }
  }
  googleCalendars {
    ...GoogleCalendar
  }
}
    ${GoogleCalendarFragmentDoc}`;
export const GoogleCalendarSyncFragmentDoc = `
    fragment GoogleCalendarSync on GoogleCalendarSync {
  createdAt
  googleCalendarColor
  googleCalendarId
  googleCalendarSummary
  id
  lastSyncedAt
  syncedUpTo
  syncedEvents {
    totalCount
  }
  status
  googleCalendarSyncCalendars {
    nodes {
      calendar {
        title
        group {
          id
          groupName
          iconType
          isSchoolwideGroup
          groupCategory {
            iconType
            iconBackgroundColor
          }
        }
      }
    }
    totalCount
  }
}
    `;
export const GroupLabelFragmentDoc = `
    fragment GroupLabel on Group {
  groupCategoryId
  iconType
  isDivisionGroup
  isSchoolwideGroup
  id
  name
}
    `;
export const NotificationFileFragmentDoc = `
    fragment NotificationFile on NotificationFile {
  id
  sortOrder
  file {
    temporaryDownloadUrl
    filename
    id
    label
    type
  }
}
    `;
export const NotificationFragmentFragmentDoc = `
    fragment NotificationFragment on Notification {
  body
  createdAt
  delivery
  draft
  id
  message
  embedUrl
  nextOccurrence
  recipientCount
  recurringCron
  recurringEnabled
  sendDate
  updatedAt
  userTypes
  logs {
    logs {
      error
      info
      timestamp
    }
  }
  personByCreatedBy {
    email
  }
  personByUpdatedBy {
    email
  }
  groupsByNotificationGroupNotificationIdAndGroupId(orderBy: SORT_ORDER_ASC) {
    nodes {
      ...GroupLabel
    }
  }
  buttons {
    buttons {
      ...Button
    }
  }
  newslettersByNotificationNewsletterNotificationIdAndNewsletterId {
    nodes {
      id
      title
      status
      publishDate
    }
    totalCount
  }
  notificationFiles(orderBy: SORT_ORDER_ASC) {
    nodes {
      ...NotificationFile
    }
  }
}
    ${GroupLabelFragmentDoc}
${ButtonFragmentDoc}
${NotificationFileFragmentDoc}`;
export const ResourceFileFragmentDoc = `
    fragment ResourceFile on ResourceFile {
  id
  sortOrder
  file {
    temporaryDownloadUrl
    filename
    id
    label
    type
  }
}
    `;
export const ResourceFragmentFragmentDoc = `
    fragment ResourceFragment on Resource {
  body
  id
  parentResource
  groupId
  published
  sortOrder
  title
  type
  url
  header
  embedUrl
  userTypes
  disableTileOverlay
  resourceByParentResource {
    id
    title
    defaultCoverImage {
      ...File
    }
    defaultThumbnailImage {
      ...File
    }
  }
  resourcesByParentResource {
    totalCount
  }
  coverImage {
    ...File
  }
  defaultCoverImage {
    ...File
  }
  thumbnailImage {
    ...File
  }
  defaultThumbnailImage {
    ...File
  }
  tileImage {
    ...File
  }
  buttons {
    buttons {
      ...Button
    }
  }
  resourceFiles(orderBy: SORT_ORDER_ASC) {
    nodes {
      ...ResourceFile
    }
  }
}
    ${FileFragmentDoc}
${ButtonFragmentDoc}
${ResourceFileFragmentDoc}`;
export const SchoolSettingFragmentDoc = `
    fragment SchoolSetting on SchoolSetting {
  coverImage {
    ...File
  }
  downloadUrl
  feedbackUrl
  launchScreenEnabled
  launchScreenPoweredByImage {
    ...File
  }
  launchScreenPoweredByLabel
  logoImage {
    ...File
  }
  mascotImage {
    ...File
  }
  schoolImage {
    ...File
  }
  sponsored
  sponsorsIncludeSilversOnHome
  thumbnailImage {
    ...File
  }
  timeZone
  parentPasscode
  parentPasscodeHint
  studentPasscode
  studentPasscodeHint
  enableGuestAccess
  enableParentFamilyAccess
  enableStudentAccess
  googleAnalyticsId
  scheduledFacultyReset
  scheduledGuestReset
  scheduledParentReset
  scheduledStudentReset
  resourceTileOpacity
  goldSponsorLabel
  silverSponsorLabel
  bronzeSponsorLabel
  registrationMode
}
    ${FileFragmentDoc}`;
export const GroupListFieldsFragmentDoc = `
    fragment GroupListFields on Group {
  groupName
  iconType
  id
  information
  groupCategoryId
  divisionId
  isDivisionGroup
  isSchoolwideGroup
  icon
  calendars {
    nodes {
      id
      title
    }
  }
  passcode
  passcodeHint
  autoEnrollUserTypes
  slug
  registrationMode
}
    `;
export const GroupCategoryListFieldsFragmentDoc = `
    fragment GroupCategoryListFields on GroupCategory {
  name
  id
  groups(orderBy: SORT_ORDER_ASC) {
    nodes {
      ...GroupListFields
    }
    totalCount
  }
  iconBackgroundColor
  iconType
}
    ${GroupListFieldsFragmentDoc}`;
export const SchoolDivisionsFragmentDoc = `
    fragment SchoolDivisions on Division {
  name
  id
  schoolwide
  group {
    ...GroupListFields
  }
  groupCategories(orderBy: SORT_ORDER_ASC) {
    nodes {
      ...GroupCategoryListFields
    }
    totalCount
  }
}
    ${GroupListFieldsFragmentDoc}
${GroupCategoryListFieldsFragmentDoc}`;
export const SchoolDetailFragmentDoc = `
    fragment SchoolDetail on School {
  theme {
    primaryColor
    secondaryColor
  }
  appleDeveloperAccountExpiry
  appIsLatest
  iosAppIsLatest
  androidAppIsLatest
  androidAppDownloadUrl
  iosAppDownloadUrl
  isLaunched
  isMigrated
  name
  schoolwideLabel
  id
  slug
  archived
  schoolSetting {
    ...SchoolSetting
  }
  divisions(orderBy: SORT_ORDER_ASC) {
    nodes {
      ...SchoolDivisions
    }
    totalCount
  }
  sponsors(orderBy: SORT_ORDER_ASC, filter: {published: {equalTo: true}}) {
    totalCount
    nodes {
      id
      logoImage {
        ...File
      }
      type
    }
  }
}
    ${SchoolSettingFragmentDoc}
${SchoolDivisionsFragmentDoc}
${FileFragmentDoc}`;
export const SchoolListFieldsFragmentDoc = `
    fragment SchoolListFields on School {
  id
  appleDeveloperAccountExpiry
  iosPushCertExpiry
  appIsLatest
  iosAppIsLatest
  androidAppIsLatest
  androidAppDownloadUrl
  iosAppDownloadUrl
  isLaunched
  isMigrated
  slug
  createdAt
  name
  city
  state
  theme {
    primaryColor
  }
  personByCreatedBy {
    email
  }
  schoolSetting {
    thumbnailImage {
      ...File
    }
  }
}
    ${FileFragmentDoc}`;
export const PersonFieldsFragmentDoc = `
    fragment PersonFields on Person {
  createdAt
  firstName
  lastName
  updatedAt
  id
  userAccountId
}
    `;
export const EventFeedFragmentDoc = `
    fragment EventFeed on Event {
  allDay
  endDate
  id
  information
  locationString
  slug
  startDate
  title
  thumbnailImage {
    ...File
  }
  coverImage {
    ...File
  }
  calendarDisplay {
    icon
    iconBackgroundColor
    calendars {
      icon
      iconBackgroundColor
      title
    }
  }
  buttons {
    buttons {
      ...Button
    }
  }
}
    ${FileFragmentDoc}
${ButtonFragmentDoc}`;
export const GlobalGroupListFragmentDoc = `
    fragment GlobalGroupList on Group {
  globalSortOrder
  icon
  hasContent
  hasResources
  id
  name
  isSchoolwideGroup
  isDivisionGroup
  slug
  divisionId
  groupCategory {
    iconBackgroundColor
  }
}
    `;
export const GroupDetailFragmentDoc = `
    fragment GroupDetail on Group {
  id
  information
  hasContent
  hasResources
  name
  icon
  groupCategory {
    iconBackgroundColor
  }
  calendars {
    nodes {
      id
    }
  }
}
    `;
export const MarqueeFragmentDoc = `
    fragment Marquee on Marquee {
  body
  buttons {
    buttons {
      ...Button
    }
  }
  countdownDate
  countdownLabel
  countdownHideWhenCompleted
  enabled
  id
  marqueeImage {
    ...File
  }
  embedUrl
}
    ${ButtonFragmentDoc}
${FileFragmentDoc}`;
export const NewsletterFragmentDoc = `
    fragment Newsletter on Newsletter {
  createdAt
  updatedAt
  title
  publishDate
  status
  id
  slug
  url
  coverImage {
    ...File
  }
  userTypes
  groupsByGroupNewsletterNewsletterIdAndGroupId {
    nodes {
      ...GroupLabel
    }
  }
  pageId
  notificationsByNotificationNewsletterNewsletterIdAndNotificationId {
    totalCount
    nodes {
      recipientCount
      delivery
    }
  }
}
    ${FileFragmentDoc}
${GroupLabelFragmentDoc}`;
export const PageSectionFileFragmentDoc = `
    fragment PageSectionFile on PageSectionFile {
  id
  cover
  description
  sortOrder
  title
  fileRelationType
  file {
    ...File
  }
}
    ${FileFragmentDoc}`;
export const PageSectionFragmentDoc = `
    fragment PageSection on PageSection {
  id
  body
  bodyImageAlignment
  buttons {
    buttons {
      ...Button
    }
  }
  sortOrder
  subtitle
  title
  titleAlignment
  type
  url
  pageSectionFiles(orderBy: SORT_ORDER_ASC) {
    totalCount
    nodes {
      ...PageSectionFile
    }
  }
}
    ${ButtonFragmentDoc}
${PageSectionFileFragmentDoc}`;
export const SchoolPersonFragmentDoc = `
    fragment SchoolPerson on SchoolPerson {
  id
  includeInDirectory
  userTypes
  accessRevoked
  schoolPersonInvitation {
    accepted
    slug
    createdAt
    createdBy
  }
  person {
    id
    bio
    email
    firstName
    lastName
    phone
    profileImage {
      ...File
    }
    title
    userAccountId
    userSession {
      lastOnline
    }
    adminGroups: groupPeople(
      filter: {schoolId: {equalTo: $schoolId}, admin: {equalTo: true}}
    ) {
      nodes {
        admin
        id
        groupId
        member
        personId
      }
    }
    memberGroups: groupPeople(
      filter: {schoolId: {equalTo: $schoolId}, member: {equalTo: true}}
    ) {
      nodes {
        admin
        id
        groupId
        member
        personId
      }
    }
  }
}
    ${FileFragmentDoc}`;
export const CurrentUserSchoolPermissionsFragmentDoc = `
    fragment CurrentUserSchoolPermissions on Person {
  id
  groups: groupPeopleForSchool(schoolId: $schoolId) {
    nodes {
      groupId
      admin
      member
    }
  }
  school: schoolPerson(_schoolId: $schoolId) {
    id
    completedOnboarding
    includeInDirectory
    userTypes
    schoolId
    accessRevoked
    schoolPersonInvitation {
      accepted
      slug
    }
    schoolPersonInstallations(filter: $installationFilter) {
      nodes {
        id
        pushEnabled
      }
    }
  }
}
    `;
export const SponsorFragmentDoc = `
    fragment Sponsor on Sponsor {
  body
  buttons {
    buttons {
      ...Button
    }
  }
  id
  published
  schoolId
  slug
  sortOrder
  title
  type
  coverImage {
    ...File
  }
  logoImage {
    ...File
  }
  promoImage {
    ...File
  }
  thumbnailImage {
    ...File
  }
  primaryColor
}
    ${ButtonFragmentDoc}
${FileFragmentDoc}`;
export const PasswordResetRequiredDocument = `
    query PasswordResetRequired($email: Email!) {
  passwordResetRequired(email: $email)
}
    `;
export const usePasswordResetRequiredQuery = <
      TData = Types.PasswordResetRequiredQuery,
      TError = unknown
    >(
      variables: Types.PasswordResetRequiredQueryVariables, 
      options?: UseQueryOptions<Types.PasswordResetRequiredQuery, TError, TData>
    ) => 
    useQuery<Types.PasswordResetRequiredQuery, TError, TData>(
      ['PasswordResetRequired', variables],
      useFetchData<Types.PasswordResetRequiredQuery, Types.PasswordResetRequiredQueryVariables>(PasswordResetRequiredDocument).bind(null, variables),
      options
    );
export const AuthenticateDocument = `
    mutation Authenticate($email: Email!, $password: String!) {
  authenticate(input: {email: $email, password: $password}) {
    clientMutationId
    jwtToken
  }
}
    `;
export const useAuthenticateMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.AuthenticateMutation, TError, Types.AuthenticateMutationVariables, TContext>) => 
    useMutation<Types.AuthenticateMutation, TError, Types.AuthenticateMutationVariables, TContext>(
      useFetchData<Types.AuthenticateMutation, Types.AuthenticateMutationVariables>(AuthenticateDocument),
      options
    );
export const CheckEmailForFacultyStaffJoinSchoolDocument = `
    query CheckEmailForFacultyStaffJoinSchool($schoolId: UUID!, $email: Email!) {
  passwordResetRequired(email: $email)
  checkPersonEmailForSchool(email: $email, schoolId: $schoolId) {
    id
    schoolPeople(
      filter: {schoolId: {equalTo: $schoolId}, userTypes: {contains: FACULTY_STAFF}}
    ) {
      totalCount
      nodes {
        id
        person {
          userAccountId
        }
        schoolPersonInvitation {
          accepted
        }
      }
    }
  }
}
    `;
export const useCheckEmailForFacultyStaffJoinSchoolQuery = <
      TData = Types.CheckEmailForFacultyStaffJoinSchoolQuery,
      TError = unknown
    >(
      variables: Types.CheckEmailForFacultyStaffJoinSchoolQueryVariables, 
      options?: UseQueryOptions<Types.CheckEmailForFacultyStaffJoinSchoolQuery, TError, TData>
    ) => 
    useQuery<Types.CheckEmailForFacultyStaffJoinSchoolQuery, TError, TData>(
      ['CheckEmailForFacultyStaffJoinSchool', variables],
      useFetchData<Types.CheckEmailForFacultyStaffJoinSchoolQuery, Types.CheckEmailForFacultyStaffJoinSchoolQueryVariables>(CheckEmailForFacultyStaffJoinSchoolDocument).bind(null, variables),
      options
    );
export const ClaimAnonymousAccountDocument = `
    mutation ClaimAnonymousAccount($anonymousAccountEmail: Email!, $anonymousAccountPassword: String!, $claimingAccountEmail: Email, $claimingAccountPassword: String!, $claimingPersonFirstName: String, $claimingPersonLastName: String, $schoolId: UUID) {
  claimAnonymousAccount(
    input: {claimingAccountPassword: $claimingAccountPassword, anonymousAccountEmail: $anonymousAccountEmail, anonymousAccountPassword: $anonymousAccountPassword, claimingAccountEmail: $claimingAccountEmail, claimingPersonFirstName: $claimingPersonFirstName, claimingPersonLastName: $claimingPersonLastName, schoolId: $schoolId}
  ) {
    jwtToken
  }
}
    `;
export const useClaimAnonymousAccountMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.ClaimAnonymousAccountMutation, TError, Types.ClaimAnonymousAccountMutationVariables, TContext>) => 
    useMutation<Types.ClaimAnonymousAccountMutation, TError, Types.ClaimAnonymousAccountMutationVariables, TContext>(
      useFetchData<Types.ClaimAnonymousAccountMutation, Types.ClaimAnonymousAccountMutationVariables>(ClaimAnonymousAccountDocument),
      options
    );
export const CreateDivisionDocument = `
    mutation CreateDivision($name: String!, $schoolId: UUID!) {
  createDivision(
    input: {division: {name: $name, groupCategories: {create: [{name: "Grades"}, {name: "Athletics"}, {name: "Clubs"}, {name: "General"}]}, group: {create: {groupName: $name, iconType: IOS_FILLED_SCHOOL, calendars: {create: {}}}}, school: {connectById: {id: $schoolId}}}}
  ) {
    division {
      id
    }
  }
}
    `;
export const useCreateDivisionMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.CreateDivisionMutation, TError, Types.CreateDivisionMutationVariables, TContext>) => 
    useMutation<Types.CreateDivisionMutation, TError, Types.CreateDivisionMutationVariables, TContext>(
      useFetchData<Types.CreateDivisionMutation, Types.CreateDivisionMutationVariables>(CreateDivisionDocument),
      options
    );
export const CreateGoogleCalendarSyncDocument = `
    mutation CreateGoogleCalendarSync($googleCalendarSummary: String!, $googleCalendarId: String!, $googleCalendarColor: String!, $googleAccountId: UUID!, $calendarIds: [UUID]!) {
  createGoogleCalendarSyncWithCalendars(
    input: {calendarIds: $calendarIds, googleAccountId: $googleAccountId, googleCalendarColor: $googleCalendarColor, googleCalendarId: $googleCalendarId, googleCalendarSummary: $googleCalendarSummary}
  ) {
    clientMutationId
  }
}
    `;
export const useCreateGoogleCalendarSyncMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.CreateGoogleCalendarSyncMutation, TError, Types.CreateGoogleCalendarSyncMutationVariables, TContext>) => 
    useMutation<Types.CreateGoogleCalendarSyncMutation, TError, Types.CreateGoogleCalendarSyncMutationVariables, TContext>(
      useFetchData<Types.CreateGoogleCalendarSyncMutation, Types.CreateGoogleCalendarSyncMutationVariables>(CreateGoogleCalendarSyncDocument),
      options
    );
export const CreateGroupDocument = `
    mutation CreateGroup($groupName: String!, $groupCategoryId: UUID!, $iconType: IconType, $information: String, $passcode: String, $passcodeHint: String, $autoEnrollUserTypes: [UserType], $registrationMode: RegistrationMode) {
  createGroup(
    input: {group: {groupName: $groupName, groupCategory: {connectById: {id: $groupCategoryId}}, iconType: $iconType, information: $information, calendars: {create: {}}, passcode: $passcode, passcodeHint: $passcodeHint, autoEnrollUserTypes: $autoEnrollUserTypes, registrationMode: $registrationMode}}
  ) {
    group {
      id
      groupName
    }
  }
}
    `;
export const useCreateGroupMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.CreateGroupMutation, TError, Types.CreateGroupMutationVariables, TContext>) => 
    useMutation<Types.CreateGroupMutation, TError, Types.CreateGroupMutationVariables, TContext>(
      useFetchData<Types.CreateGroupMutation, Types.CreateGroupMutationVariables>(CreateGroupDocument),
      options
    );
export const CreateGroupCategoryDocument = `
    mutation CreateGroupCategory($id: UUID!, $name: String!, $divisionId: UUID!, $sortOrder: Int!, $iconType: IconType!, $color: String) {
  createGroupCategory(
    input: {groupCategory: {name: $name, division: {connectById: {id: $divisionId}}, iconBackgroundColor: $color, iconType: $iconType, id: $id, sortOrder: $sortOrder}}
  ) {
    groupCategory {
      id
    }
  }
}
    `;
export const useCreateGroupCategoryMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.CreateGroupCategoryMutation, TError, Types.CreateGroupCategoryMutationVariables, TContext>) => 
    useMutation<Types.CreateGroupCategoryMutation, TError, Types.CreateGroupCategoryMutationVariables, TContext>(
      useFetchData<Types.CreateGroupCategoryMutation, Types.CreateGroupCategoryMutationVariables>(CreateGroupCategoryDocument),
      options
    );
export const CreateOrUpdateCalendarGroupDocument = `
    mutation CreateOrUpdateCalendarGroup($id: UUID!, $sortOrder: Int!, $title: String!, $calendarIds: [UUID]!, $schoolId: UUID!) {
  createOrUpdateCalendarGroup(
    input: {calendarGroupId: $id, calendarGroupSortOrder: $sortOrder, calendarGroupTitle: $title, calendarIds: $calendarIds, calendarGroupSchoolId: $schoolId}
  ) {
    clientMutationId
  }
}
    `;
export const useCreateOrUpdateCalendarGroupMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.CreateOrUpdateCalendarGroupMutation, TError, Types.CreateOrUpdateCalendarGroupMutationVariables, TContext>) => 
    useMutation<Types.CreateOrUpdateCalendarGroupMutation, TError, Types.CreateOrUpdateCalendarGroupMutationVariables, TContext>(
      useFetchData<Types.CreateOrUpdateCalendarGroupMutation, Types.CreateOrUpdateCalendarGroupMutationVariables>(CreateOrUpdateCalendarGroupDocument),
      options
    );
export const CreateOrUpdateEventDocument = `
    mutation CreateOrUpdateEvent($title: String!, $startDate: Datetime!, $endDate: Datetime!, $calendarIds: [UUID]!, $locationString: String, $information: String, $eventId: UUID, $buttons: [ButtonInput!]) {
  createOrUpdateEventForCalendars(
    input: {calendarIds: $calendarIds, eventTitle: $title, eventStartDate: $startDate, eventEndDate: $endDate, eventLocationString: $locationString, eventInformation: $information, eventId: $eventId, eventButtons: {buttons: $buttons}}
  ) {
    event {
      id
    }
  }
}
    `;
export const useCreateOrUpdateEventMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.CreateOrUpdateEventMutation, TError, Types.CreateOrUpdateEventMutationVariables, TContext>) => 
    useMutation<Types.CreateOrUpdateEventMutation, TError, Types.CreateOrUpdateEventMutationVariables, TContext>(
      useFetchData<Types.CreateOrUpdateEventMutation, Types.CreateOrUpdateEventMutationVariables>(CreateOrUpdateEventDocument),
      options
    );
export const CreateOrUpdateNotificationDocument = `
    mutation CreateOrUpdateNotification($userTypes: [UserType]!, $sendDate: Datetime, $recurringEnabled: Boolean, $recurringCron: String, $message: String!, $id: UUID, $groupIds: [UUID]!, $delivery: NotificationDelivery!, $body: String, $draft: Boolean, $buttons: [ButtonInput!], $embedUrl: String) {
  createOrUpdateNotification(
    input: {notificationBody: $body, notificationDelivery: $delivery, notificationGroupIds: $groupIds, notificationId: $id, notificationMessage: $message, notificationRecurringCron: $recurringCron, notificationRecurringEnabled: $recurringEnabled, notificationSendDate: $sendDate, notificationUserTypes: $userTypes, notificationDraft: $draft, notificationButtons: {buttons: $buttons}, notificationEmbedUrl: $embedUrl}
  ) {
    notification {
      id
      message
      nextOccurrence
      delivery
      body
      recurringCron
      recurringEnabled
      sendDate
      userTypes
      draft
      groupsByNotificationGroupNotificationIdAndGroupId {
        nodes {
          id
        }
      }
      buttons {
        buttons {
          ...Button
        }
      }
    }
  }
}
    ${ButtonFragmentDoc}`;
export const useCreateOrUpdateNotificationMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.CreateOrUpdateNotificationMutation, TError, Types.CreateOrUpdateNotificationMutationVariables, TContext>) => 
    useMutation<Types.CreateOrUpdateNotificationMutation, TError, Types.CreateOrUpdateNotificationMutationVariables, TContext>(
      useFetchData<Types.CreateOrUpdateNotificationMutation, Types.CreateOrUpdateNotificationMutationVariables>(CreateOrUpdateNotificationDocument),
      options
    );
export const CreateNotificationFileDocument = `
    mutation CreateNotificationFile($notificationId: UUID!, $filename: String!, $type: FileType!, $label: String, $sortOrder: Int!) {
  createNotificationFile(
    input: {notificationFile: {notificationId: $notificationId, sortOrder: $sortOrder, file: {create: {filename: $filename, type: $type, label: $label}}}}
  ) {
    clientMutationId
  }
}
    `;
export const useCreateNotificationFileMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.CreateNotificationFileMutation, TError, Types.CreateNotificationFileMutationVariables, TContext>) => 
    useMutation<Types.CreateNotificationFileMutation, TError, Types.CreateNotificationFileMutationVariables, TContext>(
      useFetchData<Types.CreateNotificationFileMutation, Types.CreateNotificationFileMutationVariables>(CreateNotificationFileDocument),
      options
    );
export const CreateResourceDocument = `
    mutation CreateResource($body: String, $url: String, $groupId: UUID!, $parentResource: UUID, $published: Boolean = false, $title: String, $type: ResourceType!, $header: Boolean, $disableTileOverlay: Boolean, $thumbnailImage: ResourceThumbnailImageFileIdFkeyInput, $coverImage: ResourceCoverImageIdFkeyInput, $tileImage: ResourceTileImageFileIdFkeyInput, $buttons: [ButtonInput!], $userTypes: [UserType]!, $attachments: ResourceFileResourceIdFkeyInverseInput, $embedUrl: String) {
  createResource(
    input: {resource: {title: $title, body: $body, url: $url, groupId: $groupId, parentResource: $parentResource, published: $published, type: $type, header: $header, thumbnailImage: $thumbnailImage, coverImage: $coverImage, tileImage: $tileImage, buttons: {buttons: $buttons}, userTypes: $userTypes, disableTileOverlay: $disableTileOverlay, resourceFiles: $attachments, embedUrl: $embedUrl}}
  ) {
    clientMutationId
  }
}
    `;
export const useCreateResourceMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.CreateResourceMutation, TError, Types.CreateResourceMutationVariables, TContext>) => 
    useMutation<Types.CreateResourceMutation, TError, Types.CreateResourceMutationVariables, TContext>(
      useFetchData<Types.CreateResourceMutation, Types.CreateResourceMutationVariables>(CreateResourceDocument),
      options
    );
export const CreateSchoolDocument = `
    mutation CreateSchool($name: String!, $city: String!, $state: String!, $primaryColor: String, $secondaryColor: String, $timeZone: Timezone!) {
  createSchool(
    input: {school: {name: $name, city: $city, state: $state, theme: {create: {primaryColor: $primaryColor, secondaryColor: $secondaryColor}}, schoolSetting: {create: {timeZone: $timeZone}}}}
  ) {
    school {
      id
      name
      slug
      theme {
        primaryColor
        secondaryColor
      }
    }
  }
}
    `;
export const useCreateSchoolMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.CreateSchoolMutation, TError, Types.CreateSchoolMutationVariables, TContext>) => 
    useMutation<Types.CreateSchoolMutation, TError, Types.CreateSchoolMutationVariables, TContext>(
      useFetchData<Types.CreateSchoolMutation, Types.CreateSchoolMutationVariables>(CreateSchoolDocument),
      options
    );
export const DeleteCalendarGroupDocument = `
    mutation DeleteCalendarGroup($id: UUID!) {
  deleteCalendarGroup(input: {id: $id}) {
    deletedCalendarGroupNodeId
  }
}
    `;
export const useDeleteCalendarGroupMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.DeleteCalendarGroupMutation, TError, Types.DeleteCalendarGroupMutationVariables, TContext>) => 
    useMutation<Types.DeleteCalendarGroupMutation, TError, Types.DeleteCalendarGroupMutationVariables, TContext>(
      useFetchData<Types.DeleteCalendarGroupMutation, Types.DeleteCalendarGroupMutationVariables>(DeleteCalendarGroupDocument),
      options
    );
export const DeleteDivisionDocument = `
    mutation DeleteDivision($divisionId: UUID!) {
  deleteDivision(input: {id: $divisionId}) {
    clientMutationId
  }
}
    `;
export const useDeleteDivisionMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.DeleteDivisionMutation, TError, Types.DeleteDivisionMutationVariables, TContext>) => 
    useMutation<Types.DeleteDivisionMutation, TError, Types.DeleteDivisionMutationVariables, TContext>(
      useFetchData<Types.DeleteDivisionMutation, Types.DeleteDivisionMutationVariables>(DeleteDivisionDocument),
      options
    );
export const DeleteEventDocument = `
    mutation DeleteEvent($id: UUID!) {
  deleteEvent(input: {id: $id}) {
    deletedEventNodeId
  }
}
    `;
export const useDeleteEventMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.DeleteEventMutation, TError, Types.DeleteEventMutationVariables, TContext>) => 
    useMutation<Types.DeleteEventMutation, TError, Types.DeleteEventMutationVariables, TContext>(
      useFetchData<Types.DeleteEventMutation, Types.DeleteEventMutationVariables>(DeleteEventDocument),
      options
    );
export const DeleteFileDocument = `
    mutation DeleteFile($id: UUID!) {
  deleteFileIfPossible(input: {fileId: $id}) {
    clientMutationId
  }
}
    `;
export const useDeleteFileMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.DeleteFileMutation, TError, Types.DeleteFileMutationVariables, TContext>) => 
    useMutation<Types.DeleteFileMutation, TError, Types.DeleteFileMutationVariables, TContext>(
      useFetchData<Types.DeleteFileMutation, Types.DeleteFileMutationVariables>(DeleteFileDocument),
      options
    );
export const DeleteGcsDocument = `
    mutation DeleteGcs($deleteEvents: Boolean = false, $gcsId: UUID!) {
  deleteGcs(input: {deleteEvents: $deleteEvents, gcsId: $gcsId}) {
    clientMutationId
  }
}
    `;
export const useDeleteGcsMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.DeleteGcsMutation, TError, Types.DeleteGcsMutationVariables, TContext>) => 
    useMutation<Types.DeleteGcsMutation, TError, Types.DeleteGcsMutationVariables, TContext>(
      useFetchData<Types.DeleteGcsMutation, Types.DeleteGcsMutationVariables>(DeleteGcsDocument),
      options
    );
export const DeleteGoogleAccountDocument = `
    mutation DeleteGoogleAccount($deleteEvents: Boolean = false, $googleAccountId: UUID!) {
  removeGoogleAccount(
    input: {deleteEvents: $deleteEvents, googleAccountId: $googleAccountId}
  ) {
    clientMutationId
  }
}
    `;
export const useDeleteGoogleAccountMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.DeleteGoogleAccountMutation, TError, Types.DeleteGoogleAccountMutationVariables, TContext>) => 
    useMutation<Types.DeleteGoogleAccountMutation, TError, Types.DeleteGoogleAccountMutationVariables, TContext>(
      useFetchData<Types.DeleteGoogleAccountMutation, Types.DeleteGoogleAccountMutationVariables>(DeleteGoogleAccountDocument),
      options
    );
export const DeleteGroupDocument = `
    mutation DeleteGroup($groupId: UUID!) {
  deleteGroup(input: {id: $groupId}) {
    deletedGroupNodeId
  }
}
    `;
export const useDeleteGroupMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.DeleteGroupMutation, TError, Types.DeleteGroupMutationVariables, TContext>) => 
    useMutation<Types.DeleteGroupMutation, TError, Types.DeleteGroupMutationVariables, TContext>(
      useFetchData<Types.DeleteGroupMutation, Types.DeleteGroupMutationVariables>(DeleteGroupDocument),
      options
    );
export const DeleteGroupCategoryDocument = `
    mutation DeleteGroupCategory($id: UUID!) {
  deleteGroupCategory(input: {id: $id}) {
    deletedGroupCategoryNodeId
  }
}
    `;
export const useDeleteGroupCategoryMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.DeleteGroupCategoryMutation, TError, Types.DeleteGroupCategoryMutationVariables, TContext>) => 
    useMutation<Types.DeleteGroupCategoryMutation, TError, Types.DeleteGroupCategoryMutationVariables, TContext>(
      useFetchData<Types.DeleteGroupCategoryMutation, Types.DeleteGroupCategoryMutationVariables>(DeleteGroupCategoryDocument),
      options
    );
export const DeleteNotificationDocument = `
    mutation DeleteNotification($id: UUID!) {
  deleteNotification(input: {id: $id}) {
    deletedNotificationNodeId
  }
}
    `;
export const useDeleteNotificationMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.DeleteNotificationMutation, TError, Types.DeleteNotificationMutationVariables, TContext>) => 
    useMutation<Types.DeleteNotificationMutation, TError, Types.DeleteNotificationMutationVariables, TContext>(
      useFetchData<Types.DeleteNotificationMutation, Types.DeleteNotificationMutationVariables>(DeleteNotificationDocument),
      options
    );
export const DeleteResourceDocument = `
    mutation DeleteResource($id: UUID!) {
  deleteResource(input: {id: $id}) {
    deletedResourceNodeId
  }
}
    `;
export const useDeleteResourceMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.DeleteResourceMutation, TError, Types.DeleteResourceMutationVariables, TContext>) => 
    useMutation<Types.DeleteResourceMutation, TError, Types.DeleteResourceMutationVariables, TContext>(
      useFetchData<Types.DeleteResourceMutation, Types.DeleteResourceMutationVariables>(DeleteResourceDocument),
      options
    );
export const ForgotPasswordDocument = `
    mutation ForgotPassword($_email: Email!, $redirect: String) {
  sendPasswordResetEmail(input: {_email: $_email, redirect: $redirect}) {
    clientMutationId
  }
}
    `;
export const useForgotPasswordMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.ForgotPasswordMutation, TError, Types.ForgotPasswordMutationVariables, TContext>) => 
    useMutation<Types.ForgotPasswordMutation, TError, Types.ForgotPasswordMutationVariables, TContext>(
      useFetchData<Types.ForgotPasswordMutation, Types.ForgotPasswordMutationVariables>(ForgotPasswordDocument),
      options
    );
export const MakeDivisionSchoolwideDocument = `
    mutation MakeDivisionSchoolwide($divisionId: UUID!) {
  makeDivisionSchoolwide(input: {divisionId: $divisionId}) {
    clientMutationId
  }
}
    `;
export const useMakeDivisionSchoolwideMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.MakeDivisionSchoolwideMutation, TError, Types.MakeDivisionSchoolwideMutationVariables, TContext>) => 
    useMutation<Types.MakeDivisionSchoolwideMutation, TError, Types.MakeDivisionSchoolwideMutationVariables, TContext>(
      useFetchData<Types.MakeDivisionSchoolwideMutation, Types.MakeDivisionSchoolwideMutationVariables>(MakeDivisionSchoolwideDocument),
      options
    );
export const UpdateMarqueeDocument = `
    mutation UpdateMarquee($id: UUID!, $body: String, $countdownDate: Datetime, $countdownLabel: String, $countdownHideWhenCompleted: Boolean, $enabled: Boolean!, $buttons: [ButtonInput!], $marqueeImage: MarqueeMarqueeImageFileIdFkeyInput, $marqueeImageFileId: UUID, $embedUrl: String) {
  updateMarquee(
    input: {patch: {body: $body, countdownDate: $countdownDate, countdownLabel: $countdownLabel, enabled: $enabled, buttons: {buttons: $buttons}, marqueeImage: $marqueeImage, marqueeImageFileId: $marqueeImageFileId, countdownHideWhenCompleted: $countdownHideWhenCompleted, embedUrl: $embedUrl}, id: $id}
  ) {
    clientMutationId
  }
}
    `;
export const useUpdateMarqueeMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.UpdateMarqueeMutation, TError, Types.UpdateMarqueeMutationVariables, TContext>) => 
    useMutation<Types.UpdateMarqueeMutation, TError, Types.UpdateMarqueeMutationVariables, TContext>(
      useFetchData<Types.UpdateMarqueeMutation, Types.UpdateMarqueeMutationVariables>(UpdateMarqueeDocument),
      options
    );
export const StartNewsletterDocument = `
    mutation StartNewsletter($title: String!, $url: String, $userTypes: [UserType]!, $coverImage: NewsletterCoverImageFileIdFkeyInput, $groups: GroupNewsletterNewsletterIdFkeyInverseInput) {
  createNewsletter(
    input: {newsletter: {status: DRAFT, groupNewsletters: $groups, title: $title, url: $url, userTypes: $userTypes, coverImage: $coverImage, page: {create: {}}}}
  ) {
    newsletter {
      id
    }
  }
}
    `;
export const useStartNewsletterMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.StartNewsletterMutation, TError, Types.StartNewsletterMutationVariables, TContext>) => 
    useMutation<Types.StartNewsletterMutation, TError, Types.StartNewsletterMutationVariables, TContext>(
      useFetchData<Types.StartNewsletterMutation, Types.StartNewsletterMutationVariables>(StartNewsletterDocument),
      options
    );
export const UpdateNewsletterHeaderDocument = `
    mutation UpdateNewsletterHeader($id: UUID!, $title: String!, $coverImage: NewsletterCoverImageFileIdFkeyInput, $coverImageFileId: UUID) {
  updateNewsletter(
    input: {patch: {coverImage: $coverImage, title: $title, coverImageFileId: $coverImageFileId}, id: $id}
  ) {
    newsletter {
      id
    }
  }
}
    `;
export const useUpdateNewsletterHeaderMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.UpdateNewsletterHeaderMutation, TError, Types.UpdateNewsletterHeaderMutationVariables, TContext>) => 
    useMutation<Types.UpdateNewsletterHeaderMutation, TError, Types.UpdateNewsletterHeaderMutationVariables, TContext>(
      useFetchData<Types.UpdateNewsletterHeaderMutation, Types.UpdateNewsletterHeaderMutationVariables>(UpdateNewsletterHeaderDocument),
      options
    );
export const UpdateNewsletterPublishDateDocument = `
    mutation UpdateNewsletterPublishDate($id: UUID!, $publishDate: Datetime!) {
  updateNewsletter(input: {patch: {publishDate: $publishDate}, id: $id}) {
    newsletter {
      id
    }
  }
}
    `;
export const useUpdateNewsletterPublishDateMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.UpdateNewsletterPublishDateMutation, TError, Types.UpdateNewsletterPublishDateMutationVariables, TContext>) => 
    useMutation<Types.UpdateNewsletterPublishDateMutation, TError, Types.UpdateNewsletterPublishDateMutationVariables, TContext>(
      useFetchData<Types.UpdateNewsletterPublishDateMutation, Types.UpdateNewsletterPublishDateMutationVariables>(UpdateNewsletterPublishDateDocument),
      options
    );
export const UpdateNewsletterRecipientsDocument = `
    mutation UpdateNewsletterRecipients($newsletterGroupIds: [UUID]!, $newsletterId: UUID!, $newsletterUserTypes: [UserType]!) {
  updateNewsletterRecipients(
    input: {newsletterGroupIds: $newsletterGroupIds, newsletterId: $newsletterId, newsletterUserTypes: $newsletterUserTypes}
  ) {
    newsletter {
      id
    }
  }
}
    `;
export const useUpdateNewsletterRecipientsMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.UpdateNewsletterRecipientsMutation, TError, Types.UpdateNewsletterRecipientsMutationVariables, TContext>) => 
    useMutation<Types.UpdateNewsletterRecipientsMutation, TError, Types.UpdateNewsletterRecipientsMutationVariables, TContext>(
      useFetchData<Types.UpdateNewsletterRecipientsMutation, Types.UpdateNewsletterRecipientsMutationVariables>(UpdateNewsletterRecipientsDocument),
      options
    );
export const CancelScheduledNewsletterDocument = `
    mutation CancelScheduledNewsletter($id: UUID!) {
  updateNewsletter(input: {patch: {status: DRAFT}, id: $id}) {
    newsletter {
      id
    }
  }
}
    `;
export const useCancelScheduledNewsletterMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.CancelScheduledNewsletterMutation, TError, Types.CancelScheduledNewsletterMutationVariables, TContext>) => 
    useMutation<Types.CancelScheduledNewsletterMutation, TError, Types.CancelScheduledNewsletterMutationVariables, TContext>(
      useFetchData<Types.CancelScheduledNewsletterMutation, Types.CancelScheduledNewsletterMutationVariables>(CancelScheduledNewsletterDocument),
      options
    );
export const UnpublishNewsletterDocument = `
    mutation UnpublishNewsletter($id: UUID!) {
  updateNewsletter(input: {patch: {status: DRAFT}, id: $id}) {
    newsletter {
      id
    }
  }
}
    `;
export const useUnpublishNewsletterMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.UnpublishNewsletterMutation, TError, Types.UnpublishNewsletterMutationVariables, TContext>) => 
    useMutation<Types.UnpublishNewsletterMutation, TError, Types.UnpublishNewsletterMutationVariables, TContext>(
      useFetchData<Types.UnpublishNewsletterMutation, Types.UnpublishNewsletterMutationVariables>(UnpublishNewsletterDocument),
      options
    );
export const PublishNewsletterDocument = `
    mutation PublishNewsletter($newsletterId: UUID!, $publishDate: Datetime, $sendNotification: Boolean!) {
  publishNewsletter(
    input: {newsletterId: $newsletterId, publishDate: $publishDate, sendNotification: $sendNotification}
  ) {
    clientMutationId
  }
}
    `;
export const usePublishNewsletterMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.PublishNewsletterMutation, TError, Types.PublishNewsletterMutationVariables, TContext>) => 
    useMutation<Types.PublishNewsletterMutation, TError, Types.PublishNewsletterMutationVariables, TContext>(
      useFetchData<Types.PublishNewsletterMutation, Types.PublishNewsletterMutationVariables>(PublishNewsletterDocument),
      options
    );
export const DeleteNewsletterDocument = `
    mutation DeleteNewsletter($id: UUID!) {
  deleteNewsletter(input: {id: $id}) {
    newsletter {
      id
    }
  }
}
    `;
export const useDeleteNewsletterMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.DeleteNewsletterMutation, TError, Types.DeleteNewsletterMutationVariables, TContext>) => 
    useMutation<Types.DeleteNewsletterMutation, TError, Types.DeleteNewsletterMutationVariables, TContext>(
      useFetchData<Types.DeleteNewsletterMutation, Types.DeleteNewsletterMutationVariables>(DeleteNewsletterDocument),
      options
    );
export const DuplicateNewsletterDocument = `
    mutation DuplicateNewsletter($id: UUID!) {
  duplicateNewsletter(input: {newsletterId: $id}) {
    newsletter {
      id
    }
  }
}
    `;
export const useDuplicateNewsletterMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.DuplicateNewsletterMutation, TError, Types.DuplicateNewsletterMutationVariables, TContext>) => 
    useMutation<Types.DuplicateNewsletterMutation, TError, Types.DuplicateNewsletterMutationVariables, TContext>(
      useFetchData<Types.DuplicateNewsletterMutation, Types.DuplicateNewsletterMutationVariables>(DuplicateNewsletterDocument),
      options
    );
export const DeletePageSectionDocument = `
    mutation DeletePageSection($id: UUID!) {
  deletePageSection(input: {id: $id}) {
    pageSection {
      id
    }
  }
}
    `;
export const useDeletePageSectionMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.DeletePageSectionMutation, TError, Types.DeletePageSectionMutationVariables, TContext>) => 
    useMutation<Types.DeletePageSectionMutation, TError, Types.DeletePageSectionMutationVariables, TContext>(
      useFetchData<Types.DeletePageSectionMutation, Types.DeletePageSectionMutationVariables>(DeletePageSectionDocument),
      options
    );
export const DeletePageSectionFileDocument = `
    mutation DeletePageSectionFile($id: UUID!) {
  deletePageSectionFile(input: {id: $id}) {
    clientMutationId
  }
}
    `;
export const useDeletePageSectionFileMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.DeletePageSectionFileMutation, TError, Types.DeletePageSectionFileMutationVariables, TContext>) => 
    useMutation<Types.DeletePageSectionFileMutation, TError, Types.DeletePageSectionFileMutationVariables, TContext>(
      useFetchData<Types.DeletePageSectionFileMutation, Types.DeletePageSectionFileMutationVariables>(DeletePageSectionFileDocument),
      options
    );
export const CreateTitlePageSectionDocument = `
    mutation CreateTitlePageSection($pageId: UUID!, $title: String!, $subtitle: String) {
  createPageSection(
    input: {pageSection: {type: TITLE, pageId: $pageId, title: $title, subtitle: $subtitle}}
  ) {
    pageSection {
      id
    }
  }
}
    `;
export const useCreateTitlePageSectionMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.CreateTitlePageSectionMutation, TError, Types.CreateTitlePageSectionMutationVariables, TContext>) => 
    useMutation<Types.CreateTitlePageSectionMutation, TError, Types.CreateTitlePageSectionMutationVariables, TContext>(
      useFetchData<Types.CreateTitlePageSectionMutation, Types.CreateTitlePageSectionMutationVariables>(CreateTitlePageSectionDocument),
      options
    );
export const UpdateTitlePageSectionDocument = `
    mutation UpdateTitlePageSection($title: String!, $subtitle: String, $id: UUID!) {
  updatePageSection(input: {patch: {title: $title, subtitle: $subtitle}, id: $id}) {
    pageSection {
      id
    }
  }
}
    `;
export const useUpdateTitlePageSectionMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.UpdateTitlePageSectionMutation, TError, Types.UpdateTitlePageSectionMutationVariables, TContext>) => 
    useMutation<Types.UpdateTitlePageSectionMutation, TError, Types.UpdateTitlePageSectionMutationVariables, TContext>(
      useFetchData<Types.UpdateTitlePageSectionMutation, Types.UpdateTitlePageSectionMutationVariables>(UpdateTitlePageSectionDocument),
      options
    );
export const CreateTextPageSectionDocument = `
    mutation CreateTextPageSection($pageId: UUID!, $title: String, $body: String!) {
  createPageSection(
    input: {pageSection: {type: TEXT, pageId: $pageId, title: $title, body: $body}}
  ) {
    pageSection {
      id
    }
  }
}
    `;
export const useCreateTextPageSectionMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.CreateTextPageSectionMutation, TError, Types.CreateTextPageSectionMutationVariables, TContext>) => 
    useMutation<Types.CreateTextPageSectionMutation, TError, Types.CreateTextPageSectionMutationVariables, TContext>(
      useFetchData<Types.CreateTextPageSectionMutation, Types.CreateTextPageSectionMutationVariables>(CreateTextPageSectionDocument),
      options
    );
export const UpdateTextPageSectionDocument = `
    mutation UpdateTextPageSection($title: String, $body: String!, $id: UUID!) {
  updatePageSection(input: {patch: {title: $title, body: $body}, id: $id}) {
    pageSection {
      id
    }
  }
}
    `;
export const useUpdateTextPageSectionMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.UpdateTextPageSectionMutation, TError, Types.UpdateTextPageSectionMutationVariables, TContext>) => 
    useMutation<Types.UpdateTextPageSectionMutation, TError, Types.UpdateTextPageSectionMutationVariables, TContext>(
      useFetchData<Types.UpdateTextPageSectionMutation, Types.UpdateTextPageSectionMutationVariables>(UpdateTextPageSectionDocument),
      options
    );
export const CreatePicturePageSectionDocument = `
    mutation CreatePicturePageSection($pageId: UUID!, $pageSectionFiles: PageSectionFilePageSectionIdFkeyInverseInput!) {
  createPageSection(
    input: {pageSection: {pageId: $pageId, type: PICTURE, pageSectionFiles: $pageSectionFiles}}
  ) {
    pageSection {
      id
    }
  }
}
    `;
export const useCreatePicturePageSectionMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.CreatePicturePageSectionMutation, TError, Types.CreatePicturePageSectionMutationVariables, TContext>) => 
    useMutation<Types.CreatePicturePageSectionMutation, TError, Types.CreatePicturePageSectionMutationVariables, TContext>(
      useFetchData<Types.CreatePicturePageSectionMutation, Types.CreatePicturePageSectionMutationVariables>(CreatePicturePageSectionDocument),
      options
    );
export const UpdatePicturePageSectionDocument = `
    mutation UpdatePicturePageSection($id: UUID!, $pageSectionFiles: PageSectionFilePageSectionIdFkeyInverseInput!) {
  updatePageSection(
    input: {patch: {pageSectionFiles: $pageSectionFiles}, id: $id}
  ) {
    pageSection {
      id
    }
  }
}
    `;
export const useUpdatePicturePageSectionMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.UpdatePicturePageSectionMutation, TError, Types.UpdatePicturePageSectionMutationVariables, TContext>) => 
    useMutation<Types.UpdatePicturePageSectionMutation, TError, Types.UpdatePicturePageSectionMutationVariables, TContext>(
      useFetchData<Types.UpdatePicturePageSectionMutation, Types.UpdatePicturePageSectionMutationVariables>(UpdatePicturePageSectionDocument),
      options
    );
export const CreateAttachmentPageSectionDocument = `
    mutation CreateAttachmentPageSection($pageId: UUID!, $attachments: PageSectionFilePageSectionIdFkeyInverseInput!) {
  createPageSection(
    input: {pageSection: {pageId: $pageId, type: ATTACHMENT, pageSectionFiles: $attachments}}
  ) {
    pageSection {
      id
    }
  }
}
    `;
export const useCreateAttachmentPageSectionMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.CreateAttachmentPageSectionMutation, TError, Types.CreateAttachmentPageSectionMutationVariables, TContext>) => 
    useMutation<Types.CreateAttachmentPageSectionMutation, TError, Types.CreateAttachmentPageSectionMutationVariables, TContext>(
      useFetchData<Types.CreateAttachmentPageSectionMutation, Types.CreateAttachmentPageSectionMutationVariables>(CreateAttachmentPageSectionDocument),
      options
    );
export const UpdateAttachmentPageSectionDocument = `
    mutation UpdateAttachmentPageSection($id: UUID!, $attachments: PageSectionFilePageSectionIdFkeyInverseInput!) {
  updatePageSection(input: {patch: {pageSectionFiles: $attachments}, id: $id}) {
    pageSection {
      id
    }
  }
}
    `;
export const useUpdateAttachmentPageSectionMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.UpdateAttachmentPageSectionMutation, TError, Types.UpdateAttachmentPageSectionMutationVariables, TContext>) => 
    useMutation<Types.UpdateAttachmentPageSectionMutation, TError, Types.UpdateAttachmentPageSectionMutationVariables, TContext>(
      useFetchData<Types.UpdateAttachmentPageSectionMutation, Types.UpdateAttachmentPageSectionMutationVariables>(UpdateAttachmentPageSectionDocument),
      options
    );
export const CreateButtonPageSectionDocument = `
    mutation CreateButtonPageSection($pageId: UUID!, $buttons: [ButtonInput!]) {
  createPageSection(
    input: {pageSection: {pageId: $pageId, type: BUTTON, buttons: {buttons: $buttons}}}
  ) {
    pageSection {
      id
    }
  }
}
    `;
export const useCreateButtonPageSectionMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.CreateButtonPageSectionMutation, TError, Types.CreateButtonPageSectionMutationVariables, TContext>) => 
    useMutation<Types.CreateButtonPageSectionMutation, TError, Types.CreateButtonPageSectionMutationVariables, TContext>(
      useFetchData<Types.CreateButtonPageSectionMutation, Types.CreateButtonPageSectionMutationVariables>(CreateButtonPageSectionDocument),
      options
    );
export const UpdateButtonPageSectionDocument = `
    mutation UpdateButtonPageSection($id: UUID!, $buttons: [ButtonInput!]) {
  updatePageSection(input: {patch: {buttons: {buttons: $buttons}}, id: $id}) {
    pageSection {
      id
    }
  }
}
    `;
export const useUpdateButtonPageSectionMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.UpdateButtonPageSectionMutation, TError, Types.UpdateButtonPageSectionMutationVariables, TContext>) => 
    useMutation<Types.UpdateButtonPageSectionMutation, TError, Types.UpdateButtonPageSectionMutationVariables, TContext>(
      useFetchData<Types.UpdateButtonPageSectionMutation, Types.UpdateButtonPageSectionMutationVariables>(UpdateButtonPageSectionDocument),
      options
    );
export const CreateVideoPageSectionDocument = `
    mutation CreateVideoPageSection($pageId: UUID!, $url: String!) {
  createPageSection(
    input: {pageSection: {pageId: $pageId, type: VIDEO, url: $url}}
  ) {
    pageSection {
      id
    }
  }
}
    `;
export const useCreateVideoPageSectionMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.CreateVideoPageSectionMutation, TError, Types.CreateVideoPageSectionMutationVariables, TContext>) => 
    useMutation<Types.CreateVideoPageSectionMutation, TError, Types.CreateVideoPageSectionMutationVariables, TContext>(
      useFetchData<Types.CreateVideoPageSectionMutation, Types.CreateVideoPageSectionMutationVariables>(CreateVideoPageSectionDocument),
      options
    );
export const UpdateVideoPageSectionDocument = `
    mutation UpdateVideoPageSection($id: UUID!, $url: String!) {
  updatePageSection(input: {patch: {url: $url}, id: $id}) {
    pageSection {
      id
    }
  }
}
    `;
export const useUpdateVideoPageSectionMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.UpdateVideoPageSectionMutation, TError, Types.UpdateVideoPageSectionMutationVariables, TContext>) => 
    useMutation<Types.UpdateVideoPageSectionMutation, TError, Types.UpdateVideoPageSectionMutationVariables, TContext>(
      useFetchData<Types.UpdateVideoPageSectionMutation, Types.UpdateVideoPageSectionMutationVariables>(UpdateVideoPageSectionDocument),
      options
    );
export const UpdatePageSectionSortOrderDocument = `
    mutation UpdatePageSectionSortOrder($newSortOrder: Int!, $pageSectionId: UUID!) {
  updatePageSectionSortOrder(
    input: {newSortOrder: $newSortOrder, pageSectionId: $pageSectionId}
  ) {
    clientMutationId
  }
}
    `;
export const useUpdatePageSectionSortOrderMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.UpdatePageSectionSortOrderMutation, TError, Types.UpdatePageSectionSortOrderMutationVariables, TContext>) => 
    useMutation<Types.UpdatePageSectionSortOrderMutation, TError, Types.UpdatePageSectionSortOrderMutationVariables, TContext>(
      useFetchData<Types.UpdatePageSectionSortOrderMutation, Types.UpdatePageSectionSortOrderMutationVariables>(UpdatePageSectionSortOrderDocument),
      options
    );
export const CreatePersonForSchoolDocument = `
    mutation CreatePersonForSchool($bio: String, $email: Email!, $firstName: String!, $lastName: String!, $phone: String, $title: String, $schoolId: UUID!, $includeInDirectory: Boolean = false, $userTypes: [UserType]!, $profileImage: ProfileImageFileIdFkeyInput, $profileImageFileId: UUID) {
  createPerson(
    input: {person: {firstName: $firstName, lastName: $lastName, bio: $bio, email: $email, phone: $phone, title: $title, profileImage: $profileImage, profileImageFileId: $profileImageFileId, schoolPeopleToPersonIdUsingId: {create: {schoolId: $schoolId, includeInDirectory: $includeInDirectory, userTypes: $userTypes}}}}
  ) {
    person {
      id
      schoolPeople {
        nodes {
          id
          schoolId
        }
      }
    }
  }
}
    `;
export const useCreatePersonForSchoolMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.CreatePersonForSchoolMutation, TError, Types.CreatePersonForSchoolMutationVariables, TContext>) => 
    useMutation<Types.CreatePersonForSchoolMutation, TError, Types.CreatePersonForSchoolMutationVariables, TContext>(
      useFetchData<Types.CreatePersonForSchoolMutation, Types.CreatePersonForSchoolMutationVariables>(CreatePersonForSchoolDocument),
      options
    );
export const AddExternalPersonToSchoolDocument = `
    mutation AddExternalPersonToSchool($includeInDirectory: Boolean = false, $personId: UUID!, $schoolId: UUID!, $userTypes: [UserType]!) {
  createSchoolPerson(
    input: {schoolPerson: {userTypes: $userTypes, schoolId: $schoolId, includeInDirectory: $includeInDirectory, personId: $personId}}
  ) {
    schoolPerson {
      id
    }
  }
}
    `;
export const useAddExternalPersonToSchoolMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.AddExternalPersonToSchoolMutation, TError, Types.AddExternalPersonToSchoolMutationVariables, TContext>) => 
    useMutation<Types.AddExternalPersonToSchoolMutation, TError, Types.AddExternalPersonToSchoolMutationVariables, TContext>(
      useFetchData<Types.AddExternalPersonToSchoolMutation, Types.AddExternalPersonToSchoolMutationVariables>(AddExternalPersonToSchoolDocument),
      options
    );
export const UpdateSchoolPersonProfileAndSchoolPropertiesDocument = `
    mutation UpdateSchoolPersonProfileAndSchoolProperties($id: UUID!, $bio: String, $firstName: String!, $lastName: String!, $phone: String, $title: String, $schoolPersonId: UUID!, $userTypes: [UserType]!, $includeInDirectory: Boolean!, $profileImage: ProfileImageFileIdFkeyInput, $profileImageFileId: UUID) {
  updatePerson(
    input: {patch: {bio: $bio, firstName: $firstName, lastName: $lastName, phone: $phone, title: $title, profileImage: $profileImage, profileImageFileId: $profileImageFileId, schoolPeopleToPersonIdUsingId: {updateById: {patch: {userTypes: $userTypes, includeInDirectory: $includeInDirectory}, id: $schoolPersonId}}}, id: $id}
  ) {
    person {
      id
      schoolPeople {
        nodes {
          id
          schoolId
        }
      }
    }
  }
}
    `;
export const useUpdateSchoolPersonProfileAndSchoolPropertiesMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.UpdateSchoolPersonProfileAndSchoolPropertiesMutation, TError, Types.UpdateSchoolPersonProfileAndSchoolPropertiesMutationVariables, TContext>) => 
    useMutation<Types.UpdateSchoolPersonProfileAndSchoolPropertiesMutation, TError, Types.UpdateSchoolPersonProfileAndSchoolPropertiesMutationVariables, TContext>(
      useFetchData<Types.UpdateSchoolPersonProfileAndSchoolPropertiesMutation, Types.UpdateSchoolPersonProfileAndSchoolPropertiesMutationVariables>(UpdateSchoolPersonProfileAndSchoolPropertiesDocument),
      options
    );
export const InvitePersonToSchoolDocument = `
    mutation InvitePersonToSchool($schoolPersonId: UUID!) {
  createSchoolPersonInvitation(
    input: {schoolPersonInvitation: {schoolPersonId: $schoolPersonId}}
  ) {
    schoolPersonInvitation {
      slug
    }
  }
}
    `;
export const useInvitePersonToSchoolMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.InvitePersonToSchoolMutation, TError, Types.InvitePersonToSchoolMutationVariables, TContext>) => 
    useMutation<Types.InvitePersonToSchoolMutation, TError, Types.InvitePersonToSchoolMutationVariables, TContext>(
      useFetchData<Types.InvitePersonToSchoolMutation, Types.InvitePersonToSchoolMutationVariables>(InvitePersonToSchoolDocument),
      options
    );
export const SendSchoolPersonInvitationEmailDocument = `
    mutation SendSchoolPersonInvitationEmail($schoolPersonInvitationId: UUID!) {
  schoolPersonInvitationSend(
    input: {schoolPersonInvitationId: $schoolPersonInvitationId}
  ) {
    clientMutationId
  }
}
    `;
export const useSendSchoolPersonInvitationEmailMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.SendSchoolPersonInvitationEmailMutation, TError, Types.SendSchoolPersonInvitationEmailMutationVariables, TContext>) => 
    useMutation<Types.SendSchoolPersonInvitationEmailMutation, TError, Types.SendSchoolPersonInvitationEmailMutationVariables, TContext>(
      useFetchData<Types.SendSchoolPersonInvitationEmailMutation, Types.SendSchoolPersonInvitationEmailMutationVariables>(SendSchoolPersonInvitationEmailDocument),
      options
    );
export const DeleteSchoolPersonInvitationDocument = `
    mutation DeleteSchoolPersonInvitation($schoolPersonId: UUID!) {
  deleteSchoolPersonInvitation(input: {schoolPersonId: $schoolPersonId}) {
    schoolPersonInvitation {
      schoolPersonId
    }
  }
}
    `;
export const useDeleteSchoolPersonInvitationMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.DeleteSchoolPersonInvitationMutation, TError, Types.DeleteSchoolPersonInvitationMutationVariables, TContext>) => 
    useMutation<Types.DeleteSchoolPersonInvitationMutation, TError, Types.DeleteSchoolPersonInvitationMutationVariables, TContext>(
      useFetchData<Types.DeleteSchoolPersonInvitationMutation, Types.DeleteSchoolPersonInvitationMutationVariables>(DeleteSchoolPersonInvitationDocument),
      options
    );
export const RemoveFacultyStaffFromSchoolDocument = `
    mutation RemoveFacultyStaffFromSchool($personId: UUID!, $schoolId: UUID!) {
  removeFacultyStaffPersonFromSchool(
    input: {personId: $personId, schoolId: $schoolId}
  ) {
    clientMutationId
  }
}
    `;
export const useRemoveFacultyStaffFromSchoolMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.RemoveFacultyStaffFromSchoolMutation, TError, Types.RemoveFacultyStaffFromSchoolMutationVariables, TContext>) => 
    useMutation<Types.RemoveFacultyStaffFromSchoolMutation, TError, Types.RemoveFacultyStaffFromSchoolMutationVariables, TContext>(
      useFetchData<Types.RemoveFacultyStaffFromSchoolMutation, Types.RemoveFacultyStaffFromSchoolMutationVariables>(RemoveFacultyStaffFromSchoolDocument),
      options
    );
export const SetSchoolAdminGroupsForPersonDocument = `
    mutation SetSchoolAdminGroupsForPerson($groupIds: [UUID]!, $personId: UUID!, $schoolId: UUID!) {
  setSchoolAdminGroupsForPerson(
    input: {personId: $personId, schoolId: $schoolId, groupIds: $groupIds}
  ) {
    clientMutationId
  }
}
    `;
export const useSetSchoolAdminGroupsForPersonMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.SetSchoolAdminGroupsForPersonMutation, TError, Types.SetSchoolAdminGroupsForPersonMutationVariables, TContext>) => 
    useMutation<Types.SetSchoolAdminGroupsForPersonMutation, TError, Types.SetSchoolAdminGroupsForPersonMutationVariables, TContext>(
      useFetchData<Types.SetSchoolAdminGroupsForPersonMutation, Types.SetSchoolAdminGroupsForPersonMutationVariables>(SetSchoolAdminGroupsForPersonDocument),
      options
    );
export const SubmitOnboardingAnswersDocument = `
    mutation SubmitOnboardingAnswers($selectedGroupIds: [UUID]!, $schoolId: UUID!, $userTypes: [UserType]!, $installationFilter: SchoolPersonInstallationFilter) {
  submitCurrentPersonSchoolOnboarding(
    input: {schoolId: $schoolId, userTypes: $userTypes, selectedGroupIds: $selectedGroupIds}
  ) {
    schoolPerson {
      id
      completedOnboarding
      schoolPersonInstallations(filter: $installationFilter) {
        nodes {
          id
          bundleId
          deviceName
          oneSignalPlayerId
          oneSignalToken
          osVersion
          platform
        }
      }
    }
  }
}
    `;
export const useSubmitOnboardingAnswersMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.SubmitOnboardingAnswersMutation, TError, Types.SubmitOnboardingAnswersMutationVariables, TContext>) => 
    useMutation<Types.SubmitOnboardingAnswersMutation, TError, Types.SubmitOnboardingAnswersMutationVariables, TContext>(
      useFetchData<Types.SubmitOnboardingAnswersMutation, Types.SubmitOnboardingAnswersMutationVariables>(SubmitOnboardingAnswersDocument),
      options
    );
export const CreateSchoolPersonInstallationDocument = `
    mutation CreateSchoolPersonInstallation($bundleId: String!, $deviceName: String!, $osVersion: String!, $platform: String!, $schoolPersonId: UUID!, $appVersion: String!) {
  createSchoolPersonInstallation(
    input: {schoolPersonInstallation: {platform: $platform, bundleId: $bundleId, deviceName: $deviceName, osVersion: $osVersion, schoolPersonId: $schoolPersonId, appVersion: $appVersion}}
  ) {
    schoolPersonInstallation {
      id
      schoolPersonId
    }
  }
}
    `;
export const useCreateSchoolPersonInstallationMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.CreateSchoolPersonInstallationMutation, TError, Types.CreateSchoolPersonInstallationMutationVariables, TContext>) => 
    useMutation<Types.CreateSchoolPersonInstallationMutation, TError, Types.CreateSchoolPersonInstallationMutationVariables, TContext>(
      useFetchData<Types.CreateSchoolPersonInstallationMutation, Types.CreateSchoolPersonInstallationMutationVariables>(CreateSchoolPersonInstallationDocument),
      options
    );
export const UpdateSchoolPersonInstallationDocument = `
    mutation UpdateSchoolPersonInstallation($id: UUID!, $patch: SchoolPersonInstallationPatch!) {
  updateSchoolPersonInstallation(input: {patch: $patch, id: $id}) {
    clientMutationId
  }
}
    `;
export const useUpdateSchoolPersonInstallationMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.UpdateSchoolPersonInstallationMutation, TError, Types.UpdateSchoolPersonInstallationMutationVariables, TContext>) => 
    useMutation<Types.UpdateSchoolPersonInstallationMutation, TError, Types.UpdateSchoolPersonInstallationMutationVariables, TContext>(
      useFetchData<Types.UpdateSchoolPersonInstallationMutation, Types.UpdateSchoolPersonInstallationMutationVariables>(UpdateSchoolPersonInstallationDocument),
      options
    );
export const AdminUpdatePersonGroupMembershipAndUserTypesDocument = `
    mutation AdminUpdatePersonGroupMembershipAndUserTypes($adminMode: Boolean = false, $personId: UUID!, $schoolId: UUID!, $selectedGroupIds: [UUID]!, $userTypes: [UserType]!) {
  updatePersonGroupMembershipForSchool(
    input: {personId: $personId, schoolId: $schoolId, adminMode: $adminMode, selectedGroupIds: $selectedGroupIds}
  ) {
    clientMutationId
  }
  updateSchoolPersonByPersonIdAndSchoolId(
    input: {patch: {userTypes: $userTypes}, personId: $personId, schoolId: $schoolId}
  ) {
    clientMutationId
  }
}
    `;
export const useAdminUpdatePersonGroupMembershipAndUserTypesMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.AdminUpdatePersonGroupMembershipAndUserTypesMutation, TError, Types.AdminUpdatePersonGroupMembershipAndUserTypesMutationVariables, TContext>) => 
    useMutation<Types.AdminUpdatePersonGroupMembershipAndUserTypesMutation, TError, Types.AdminUpdatePersonGroupMembershipAndUserTypesMutationVariables, TContext>(
      useFetchData<Types.AdminUpdatePersonGroupMembershipAndUserTypesMutation, Types.AdminUpdatePersonGroupMembershipAndUserTypesMutationVariables>(AdminUpdatePersonGroupMembershipAndUserTypesDocument),
      options
    );
export const SetSchoolPersonAccessDocument = `
    mutation SetSchoolPersonAccess($id: UUID!, $accessRevoked: Boolean!) {
  updateSchoolPerson(input: {patch: {accessRevoked: $accessRevoked}, id: $id}) {
    clientMutationId
  }
}
    `;
export const useSetSchoolPersonAccessMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.SetSchoolPersonAccessMutation, TError, Types.SetSchoolPersonAccessMutationVariables, TContext>) => 
    useMutation<Types.SetSchoolPersonAccessMutation, TError, Types.SetSchoolPersonAccessMutationVariables, TContext>(
      useFetchData<Types.SetSchoolPersonAccessMutation, Types.SetSchoolPersonAccessMutationVariables>(SetSchoolPersonAccessDocument),
      options
    );
export const RegisterUserDocument = `
    mutation RegisterUser($firstName: String!, $lastName: String!, $_email: Email!, $password: String!) {
  registerUser(
    input: {firstName: $firstName, lastName: $lastName, _email: $_email, password: $password}
  ) {
    jwtToken
  }
}
    `;
export const useRegisterUserMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.RegisterUserMutation, TError, Types.RegisterUserMutationVariables, TContext>) => 
    useMutation<Types.RegisterUserMutation, TError, Types.RegisterUserMutationVariables, TContext>(
      useFetchData<Types.RegisterUserMutation, Types.RegisterUserMutationVariables>(RegisterUserDocument),
      options
    );
export const RegisterAnonymousUserDocument = `
    mutation RegisterAnonymousUser($_email: Email!, $password: String!) {
  registerAnonymousUser(input: {_email: $_email, password: $password}) {
    jwtToken
  }
}
    `;
export const useRegisterAnonymousUserMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.RegisterAnonymousUserMutation, TError, Types.RegisterAnonymousUserMutationVariables, TContext>) => 
    useMutation<Types.RegisterAnonymousUserMutation, TError, Types.RegisterAnonymousUserMutationVariables, TContext>(
      useFetchData<Types.RegisterAnonymousUserMutation, Types.RegisterAnonymousUserMutationVariables>(RegisterAnonymousUserDocument),
      options
    );
export const ResetPasswordDocument = `
    mutation ResetPassword($userId: UUID!, $resetToken: String!, $newPassword: String!) {
  resetPassword(
    input: {userId: $userId, resetToken: $resetToken, newPassword: $newPassword}
  ) {
    boolean
  }
}
    `;
export const useResetPasswordMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.ResetPasswordMutation, TError, Types.ResetPasswordMutationVariables, TContext>) => 
    useMutation<Types.ResetPasswordMutation, TError, Types.ResetPasswordMutationVariables, TContext>(
      useFetchData<Types.ResetPasswordMutation, Types.ResetPasswordMutationVariables>(ResetPasswordDocument),
      options
    );
export const UpdateOneSignalSchoolSettingsDocument = `
    mutation UpdateOneSignalSchoolSettings($appId: UUID!, $onesignalAppAuthToken: String!, $onesignalAppId: String!) {
  updateApp(
    input: {patch: {onesignalAppAuthToken: $onesignalAppAuthToken, onesignalAppId: $onesignalAppId}, id: $appId}
  ) {
    clientMutationId
  }
}
    `;
export const useUpdateOneSignalSchoolSettingsMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.UpdateOneSignalSchoolSettingsMutation, TError, Types.UpdateOneSignalSchoolSettingsMutationVariables, TContext>) => 
    useMutation<Types.UpdateOneSignalSchoolSettingsMutation, TError, Types.UpdateOneSignalSchoolSettingsMutationVariables, TContext>(
      useFetchData<Types.UpdateOneSignalSchoolSettingsMutation, Types.UpdateOneSignalSchoolSettingsMutationVariables>(UpdateOneSignalSchoolSettingsDocument),
      options
    );
export const ToggleSchoolArchivedDocument = `
    mutation ToggleSchoolArchived($schoolId: UUID!, $archived: Boolean!) {
  updateSchool(input: {patch: {archived: $archived}, id: $schoolId}) {
    clientMutationId
  }
}
    `;
export const useToggleSchoolArchivedMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.ToggleSchoolArchivedMutation, TError, Types.ToggleSchoolArchivedMutationVariables, TContext>) => 
    useMutation<Types.ToggleSchoolArchivedMutation, TError, Types.ToggleSchoolArchivedMutationVariables, TContext>(
      useFetchData<Types.ToggleSchoolArchivedMutation, Types.ToggleSchoolArchivedMutationVariables>(ToggleSchoolArchivedDocument),
      options
    );
export const UpdateGeneralSchoolSettingsDocument = `
    mutation UpdateGeneralSchoolSettings($schoolId: UUID!, $downloadUrl: String, $feedbackUrl: String, $googleAnalyticsId: String) {
  updateSchoolSetting(
    input: {patch: {downloadUrl: $downloadUrl, feedbackUrl: $feedbackUrl, googleAnalyticsId: $googleAnalyticsId}, schoolId: $schoolId}
  ) {
    clientMutationId
  }
}
    `;
export const useUpdateGeneralSchoolSettingsMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.UpdateGeneralSchoolSettingsMutation, TError, Types.UpdateGeneralSchoolSettingsMutationVariables, TContext>) => 
    useMutation<Types.UpdateGeneralSchoolSettingsMutation, TError, Types.UpdateGeneralSchoolSettingsMutationVariables, TContext>(
      useFetchData<Types.UpdateGeneralSchoolSettingsMutation, Types.UpdateGeneralSchoolSettingsMutationVariables>(UpdateGeneralSchoolSettingsDocument),
      options
    );
export const UpdateSponsorSchoolSettingsDocument = `
    mutation UpdateSponsorSchoolSettings($schoolId: UUID!, $sponsored: Boolean!, $sponsorsIncludeSilversOnHome: Boolean!, $goldSponsorLabel: String, $silverSponsorLabel: String, $bronzeSponsorLabel: String) {
  updateSchoolSetting(
    input: {patch: {sponsored: $sponsored, sponsorsIncludeSilversOnHome: $sponsorsIncludeSilversOnHome, goldSponsorLabel: $goldSponsorLabel, silverSponsorLabel: $silverSponsorLabel, bronzeSponsorLabel: $bronzeSponsorLabel}, schoolId: $schoolId}
  ) {
    clientMutationId
  }
}
    `;
export const useUpdateSponsorSchoolSettingsMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.UpdateSponsorSchoolSettingsMutation, TError, Types.UpdateSponsorSchoolSettingsMutationVariables, TContext>) => 
    useMutation<Types.UpdateSponsorSchoolSettingsMutation, TError, Types.UpdateSponsorSchoolSettingsMutationVariables, TContext>(
      useFetchData<Types.UpdateSponsorSchoolSettingsMutation, Types.UpdateSponsorSchoolSettingsMutationVariables>(UpdateSponsorSchoolSettingsDocument),
      options
    );
export const UpdateLaunchScreenSchoolSettingsDocument = `
    mutation UpdateLaunchScreenSchoolSettings($schoolId: UUID!, $launchScreenEnabled: Boolean!, $launchScreenPoweredByLabel: String, $launchScreenPoweredByImage: SchoolSettingsLaunchScreenPoweredByImageIdFkeyInput, $launchScreenPoweredByImageFileId: UUID, $logoImage: SchoolSettingsLogoImageFileIdFkeyInput, $logoImageFileId: UUID, $schoolImage: SchoolSettingsSchoolImageFileIdFkeyInput, $schoolImageFileId: UUID) {
  updateSchoolSetting(
    input: {patch: {launchScreenEnabled: $launchScreenEnabled, launchScreenPoweredByLabel: $launchScreenPoweredByLabel, launchScreenPoweredByImage: $launchScreenPoweredByImage, launchScreenPoweredByImageId: $launchScreenPoweredByImageFileId, logoImage: $logoImage, logoImageFileId: $logoImageFileId, schoolImage: $schoolImage, schoolImageFileId: $schoolImageFileId}, schoolId: $schoolId}
  ) {
    clientMutationId
  }
}
    `;
export const useUpdateLaunchScreenSchoolSettingsMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.UpdateLaunchScreenSchoolSettingsMutation, TError, Types.UpdateLaunchScreenSchoolSettingsMutationVariables, TContext>) => 
    useMutation<Types.UpdateLaunchScreenSchoolSettingsMutation, TError, Types.UpdateLaunchScreenSchoolSettingsMutationVariables, TContext>(
      useFetchData<Types.UpdateLaunchScreenSchoolSettingsMutation, Types.UpdateLaunchScreenSchoolSettingsMutationVariables>(UpdateLaunchScreenSchoolSettingsDocument),
      options
    );
export const UpdateCreativeSchoolSettingsDocument = `
    mutation UpdateCreativeSchoolSettings($schoolId: UUID!, $thumbnailImage: SchoolSettingsThumbnailImageFileIdFkeyInput, $thumbnailImageFileId: UUID, $resourceTileOpacity: Int) {
  updateSchoolSetting(
    input: {patch: {thumbnailImage: $thumbnailImage, thumbnailImageFileId: $thumbnailImageFileId, resourceTileOpacity: $resourceTileOpacity}, schoolId: $schoolId}
  ) {
    clientMutationId
  }
}
    `;
export const useUpdateCreativeSchoolSettingsMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.UpdateCreativeSchoolSettingsMutation, TError, Types.UpdateCreativeSchoolSettingsMutationVariables, TContext>) => 
    useMutation<Types.UpdateCreativeSchoolSettingsMutation, TError, Types.UpdateCreativeSchoolSettingsMutationVariables, TContext>(
      useFetchData<Types.UpdateCreativeSchoolSettingsMutation, Types.UpdateCreativeSchoolSettingsMutationVariables>(UpdateCreativeSchoolSettingsDocument),
      options
    );
export const UpdateSchoolSettingUserAccessDocument = `
    mutation UpdateSchoolSettingUserAccess($schoolId: UUID!, $parentPasscode: String, $parentPasscodeHint: String, $studentPasscode: String, $studentPasscodeHint: String, $enableGuestAccess: Boolean!, $enableParentFamilyAccess: Boolean!, $enableStudentAccess: Boolean!, $registrationMode: RegistrationMode) {
  updateSchoolSetting(
    input: {patch: {parentPasscode: $parentPasscode, parentPasscodeHint: $parentPasscodeHint, studentPasscode: $studentPasscode, studentPasscodeHint: $studentPasscodeHint, enableGuestAccess: $enableGuestAccess, enableParentFamilyAccess: $enableParentFamilyAccess, enableStudentAccess: $enableStudentAccess, registrationMode: $registrationMode}, schoolId: $schoolId}
  ) {
    clientMutationId
  }
}
    `;
export const useUpdateSchoolSettingUserAccessMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.UpdateSchoolSettingUserAccessMutation, TError, Types.UpdateSchoolSettingUserAccessMutationVariables, TContext>) => 
    useMutation<Types.UpdateSchoolSettingUserAccessMutation, TError, Types.UpdateSchoolSettingUserAccessMutationVariables, TContext>(
      useFetchData<Types.UpdateSchoolSettingUserAccessMutation, Types.UpdateSchoolSettingUserAccessMutationVariables>(UpdateSchoolSettingUserAccessDocument),
      options
    );
export const ScheduleSettingsResetDocument = `
    mutation ScheduleSettingsReset($scheduleDate: Datetime!, $schoolId: UUID!, $userType: UserType!) {
  scheduleSettingsReset(
    input: {schoolId: $schoolId, userType: $userType, scheduleDate: $scheduleDate}
  )
}
    `;
export const useScheduleSettingsResetMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.ScheduleSettingsResetMutation, TError, Types.ScheduleSettingsResetMutationVariables, TContext>) => 
    useMutation<Types.ScheduleSettingsResetMutation, TError, Types.ScheduleSettingsResetMutationVariables, TContext>(
      useFetchData<Types.ScheduleSettingsResetMutation, Types.ScheduleSettingsResetMutationVariables>(ScheduleSettingsResetDocument),
      options
    );
export const CancelScheduleSettingsResetDocument = `
    mutation CancelScheduleSettingsReset($schoolId: UUID!, $userType: UserType!) {
  cancelScheduleSettingsResetForSchoolUserType(
    input: {schoolId: $schoolId, userType: $userType}
  ) {
    clientMutationId
  }
}
    `;
export const useCancelScheduleSettingsResetMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.CancelScheduleSettingsResetMutation, TError, Types.CancelScheduleSettingsResetMutationVariables, TContext>) => 
    useMutation<Types.CancelScheduleSettingsResetMutation, TError, Types.CancelScheduleSettingsResetMutationVariables, TContext>(
      useFetchData<Types.CancelScheduleSettingsResetMutation, Types.CancelScheduleSettingsResetMutationVariables>(CancelScheduleSettingsResetDocument),
      options
    );
export const CreateSponsorDocument = `
    mutation CreateSponsor($buttons: [ButtonInput!], $primaryColor: String, $published: Boolean = false, $schoolId: UUID!, $type: SponsorType!, $title: String!, $body: String, $coverImage: SponsorCoverImageFileIdFkeyInput, $thumbnailImage: SponsorThumbnailImageFileIdFkeyInput, $logoImage: SponsorLogoImageFileIdFkeyInput, $promoImage: SponsorPromoImageFileIdFkeyInput) {
  createSponsor(
    input: {sponsor: {title: $title, body: $body, buttons: {buttons: $buttons}, primaryColor: $primaryColor, published: $published, schoolId: $schoolId, type: $type, coverImage: $coverImage, thumbnailImage: $thumbnailImage, logoImage: $logoImage, promoImage: $promoImage}}
  ) {
    sponsor {
      id
    }
  }
}
    `;
export const useCreateSponsorMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.CreateSponsorMutation, TError, Types.CreateSponsorMutationVariables, TContext>) => 
    useMutation<Types.CreateSponsorMutation, TError, Types.CreateSponsorMutationVariables, TContext>(
      useFetchData<Types.CreateSponsorMutation, Types.CreateSponsorMutationVariables>(CreateSponsorDocument),
      options
    );
export const UpdateSponsorDocument = `
    mutation UpdateSponsor($id: UUID!, $buttons: [ButtonInput!], $primaryColor: String, $published: Boolean = false, $type: SponsorType!, $title: String!, $body: String, $coverImage: SponsorCoverImageFileIdFkeyInput, $coverImageFileId: UUID, $thumbnailImage: SponsorThumbnailImageFileIdFkeyInput, $thumbnailImageFileId: UUID, $logoImage: SponsorLogoImageFileIdFkeyInput, $logoImageFileId: UUID, $promoImage: SponsorPromoImageFileIdFkeyInput, $promoImageFileId: UUID) {
  updateSponsor(
    input: {patch: {title: $title, body: $body, buttons: {buttons: $buttons}, primaryColor: $primaryColor, published: $published, type: $type, coverImage: $coverImage, coverImageFileId: $coverImageFileId, thumbnailImage: $thumbnailImage, thumbnailImageFileId: $thumbnailImageFileId, logoImage: $logoImage, logoImageFileId: $logoImageFileId, promoImage: $promoImage, promoImageFileId: $promoImageFileId}, id: $id}
  ) {
    sponsor {
      id
    }
  }
}
    `;
export const useUpdateSponsorMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.UpdateSponsorMutation, TError, Types.UpdateSponsorMutationVariables, TContext>) => 
    useMutation<Types.UpdateSponsorMutation, TError, Types.UpdateSponsorMutationVariables, TContext>(
      useFetchData<Types.UpdateSponsorMutation, Types.UpdateSponsorMutationVariables>(UpdateSponsorDocument),
      options
    );
export const UpdateSponsorSortOrderDocument = `
    mutation UpdateSponsorSortOrder($newSortOrder: Int!, $sponsorId: UUID!) {
  updateSponsorSortOrder(
    input: {newSortOrder: $newSortOrder, sponsorId: $sponsorId}
  ) {
    clientMutationId
  }
}
    `;
export const useUpdateSponsorSortOrderMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.UpdateSponsorSortOrderMutation, TError, Types.UpdateSponsorSortOrderMutationVariables, TContext>) => 
    useMutation<Types.UpdateSponsorSortOrderMutation, TError, Types.UpdateSponsorSortOrderMutationVariables, TContext>(
      useFetchData<Types.UpdateSponsorSortOrderMutation, Types.UpdateSponsorSortOrderMutationVariables>(UpdateSponsorSortOrderDocument),
      options
    );
export const UpdateDivisionNameDocument = `
    mutation UpdateDivisionName($name: String!, $divisionId: UUID!) {
  updateDivision(input: {patch: {name: $name}, id: $divisionId}) {
    division {
      name
    }
  }
}
    `;
export const useUpdateDivisionNameMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.UpdateDivisionNameMutation, TError, Types.UpdateDivisionNameMutationVariables, TContext>) => 
    useMutation<Types.UpdateDivisionNameMutation, TError, Types.UpdateDivisionNameMutationVariables, TContext>(
      useFetchData<Types.UpdateDivisionNameMutation, Types.UpdateDivisionNameMutationVariables>(UpdateDivisionNameDocument),
      options
    );
export const UpdateDivisionSortOrderDocument = `
    mutation UpdateDivisionSortOrder($divisionId: UUID!, $sortOrder: Int!) {
  updateDivision(input: {patch: {sortOrder: $sortOrder}, id: $divisionId}) {
    clientMutationId
  }
}
    `;
export const useUpdateDivisionSortOrderMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.UpdateDivisionSortOrderMutation, TError, Types.UpdateDivisionSortOrderMutationVariables, TContext>) => 
    useMutation<Types.UpdateDivisionSortOrderMutation, TError, Types.UpdateDivisionSortOrderMutationVariables, TContext>(
      useFetchData<Types.UpdateDivisionSortOrderMutation, Types.UpdateDivisionSortOrderMutationVariables>(UpdateDivisionSortOrderDocument),
      options
    );
export const UpdateGroupDocument = `
    mutation UpdateGroup($groupId: UUID!, $groupName: String!, $groupCategoryId: UUID!, $iconType: IconType, $information: String, $passcode: String, $passcodeHint: String, $autoEnrollUserTypes: [UserType], $registrationMode: RegistrationMode) {
  updateGroup(
    input: {patch: {iconType: $iconType, groupName: $groupName, information: $information, groupCategoryId: $groupCategoryId, passcode: $passcode, passcodeHint: $passcodeHint, autoEnrollUserTypes: $autoEnrollUserTypes, registrationMode: $registrationMode}, id: $groupId}
  ) {
    clientMutationId
  }
}
    `;
export const useUpdateGroupMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.UpdateGroupMutation, TError, Types.UpdateGroupMutationVariables, TContext>) => 
    useMutation<Types.UpdateGroupMutation, TError, Types.UpdateGroupMutationVariables, TContext>(
      useFetchData<Types.UpdateGroupMutation, Types.UpdateGroupMutationVariables>(UpdateGroupDocument),
      options
    );
export const UpdateGroupCategoryDocument = `
    mutation UpdateGroupCategory($id: UUID!, $name: String!, $sortOrder: Int!, $iconType: IconType!, $color: String) {
  updateGroupCategory(
    input: {patch: {iconBackgroundColor: $color, iconType: $iconType, name: $name, sortOrder: $sortOrder}, id: $id}
  ) {
    groupCategory {
      id
      name
      sortOrder
      iconBackgroundColor
      iconType
    }
  }
}
    `;
export const useUpdateGroupCategoryMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.UpdateGroupCategoryMutation, TError, Types.UpdateGroupCategoryMutationVariables, TContext>) => 
    useMutation<Types.UpdateGroupCategoryMutation, TError, Types.UpdateGroupCategoryMutationVariables, TContext>(
      useFetchData<Types.UpdateGroupCategoryMutation, Types.UpdateGroupCategoryMutationVariables>(UpdateGroupCategoryDocument),
      options
    );
export const UpdateGroupSortOrderDocument = `
    mutation UpdateGroupSortOrder($groupId: UUID!, $sortOrder: Int!) {
  updateGroup(input: {patch: {sortOrder: $sortOrder}, id: $groupId}) {
    clientMutationId
  }
}
    `;
export const useUpdateGroupSortOrderMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.UpdateGroupSortOrderMutation, TError, Types.UpdateGroupSortOrderMutationVariables, TContext>) => 
    useMutation<Types.UpdateGroupSortOrderMutation, TError, Types.UpdateGroupSortOrderMutationVariables, TContext>(
      useFetchData<Types.UpdateGroupSortOrderMutation, Types.UpdateGroupSortOrderMutationVariables>(UpdateGroupSortOrderDocument),
      options
    );
export const UpdateResourceDocument = `
    mutation UpdateResource($body: String, $id: UUID!, $published: Boolean!, $title: String, $url: String, $header: Boolean, $disableTileOverlay: Boolean, $thumbnailImage: ResourceThumbnailImageFileIdFkeyInput, $thumbnailImageFileId: UUID, $coverImage: ResourceCoverImageIdFkeyInput, $coverImageFileId: UUID, $tileImage: ResourceTileImageFileIdFkeyInput, $tileImageFileId: UUID, $buttons: [ButtonInput!], $userTypes: [UserType]!, $attachments: ResourceFileResourceIdFkeyInverseInput, $embedUrl: String) {
  updateResource(
    input: {patch: {body: $body, published: $published, title: $title, url: $url, header: $header, thumbnailImage: $thumbnailImage, thumbnailImageFileId: $thumbnailImageFileId, coverImage: $coverImage, coverImageFileId: $coverImageFileId, tileImage: $tileImage, tileImageFileId: $tileImageFileId, buttons: {buttons: $buttons}, userTypes: $userTypes, disableTileOverlay: $disableTileOverlay, resourceFiles: $attachments, embedUrl: $embedUrl}, id: $id}
  ) {
    clientMutationId
  }
}
    `;
export const useUpdateResourceMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.UpdateResourceMutation, TError, Types.UpdateResourceMutationVariables, TContext>) => 
    useMutation<Types.UpdateResourceMutation, TError, Types.UpdateResourceMutationVariables, TContext>(
      useFetchData<Types.UpdateResourceMutation, Types.UpdateResourceMutationVariables>(UpdateResourceDocument),
      options
    );
export const UpdateResourceSortOrderDocument = `
    mutation UpdateResourceSortOrder($resourceId: UUID!, $newSortOrder: Int!) {
  updateResourceSortOrder(
    input: {newSortOrder: $newSortOrder, resourceId: $resourceId}
  ) {
    clientMutationId
  }
}
    `;
export const useUpdateResourceSortOrderMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.UpdateResourceSortOrderMutation, TError, Types.UpdateResourceSortOrderMutationVariables, TContext>) => 
    useMutation<Types.UpdateResourceSortOrderMutation, TError, Types.UpdateResourceSortOrderMutationVariables, TContext>(
      useFetchData<Types.UpdateResourceSortOrderMutation, Types.UpdateResourceSortOrderMutationVariables>(UpdateResourceSortOrderDocument),
      options
    );
export const UpdateCurrentUserSessionDataDocument = `
    mutation UpdateCurrentUserSessionData($sessionDataPartial: JSON!) {
  updateCurrentUserSessionData(input: {sessionDataPartial: $sessionDataPartial}) {
    clientMutationId
  }
}
    `;
export const useUpdateCurrentUserSessionDataMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.UpdateCurrentUserSessionDataMutation, TError, Types.UpdateCurrentUserSessionDataMutationVariables, TContext>) => 
    useMutation<Types.UpdateCurrentUserSessionDataMutation, TError, Types.UpdateCurrentUserSessionDataMutationVariables, TContext>(
      useFetchData<Types.UpdateCurrentUserSessionDataMutation, Types.UpdateCurrentUserSessionDataMutationVariables>(UpdateCurrentUserSessionDataDocument),
      options
    );
export const SchoolAppDocument = `
    query SchoolApp($schoolId: UUID!) {
  apps(condition: {schoolId: $schoolId}) {
    nodes {
      id
      onesignalAppAuthToken
      onesignalAppId
    }
  }
}
    `;
export const useSchoolAppQuery = <
      TData = Types.SchoolAppQuery,
      TError = unknown
    >(
      variables: Types.SchoolAppQueryVariables, 
      options?: UseQueryOptions<Types.SchoolAppQuery, TError, TData>
    ) => 
    useQuery<Types.SchoolAppQuery, TError, TData>(
      ['SchoolApp', variables],
      useFetchData<Types.SchoolAppQuery, Types.SchoolAppQueryVariables>(SchoolAppDocument).bind(null, variables),
      options
    );
export const RefreshTokenDocument = `
    query RefreshToken {
  refreshToken
}
    `;
export const useRefreshTokenQuery = <
      TData = Types.RefreshTokenQuery,
      TError = unknown
    >(
      variables?: Types.RefreshTokenQueryVariables, 
      options?: UseQueryOptions<Types.RefreshTokenQuery, TError, TData>
    ) => 
    useQuery<Types.RefreshTokenQuery, TError, TData>(
      ['RefreshToken', variables],
      useFetchData<Types.RefreshTokenQuery, Types.RefreshTokenQueryVariables>(RefreshTokenDocument).bind(null, variables),
      options
    );
export const PresignedUploadUrlDocument = `
    mutation PresignedUploadUrl($filetype: FileType!) {
  presignedUploadUrl(input: {filetype: $filetype}) {
    url
    filename
  }
}
    `;
export const usePresignedUploadUrlMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.PresignedUploadUrlMutation, TError, Types.PresignedUploadUrlMutationVariables, TContext>) => 
    useMutation<Types.PresignedUploadUrlMutation, TError, Types.PresignedUploadUrlMutationVariables, TContext>(
      useFetchData<Types.PresignedUploadUrlMutation, Types.PresignedUploadUrlMutationVariables>(PresignedUploadUrlDocument),
      options
    );
export const DeleteTemporaryAwsS3UploadDocument = `
    mutation DeleteTemporaryAwsS3Upload($filename: String!) {
  deleteTemporaryAwsS3Upload(input: {filename: $filename})
}
    `;
export const useDeleteTemporaryAwsS3UploadMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.DeleteTemporaryAwsS3UploadMutation, TError, Types.DeleteTemporaryAwsS3UploadMutationVariables, TContext>) => 
    useMutation<Types.DeleteTemporaryAwsS3UploadMutation, TError, Types.DeleteTemporaryAwsS3UploadMutationVariables, TContext>(
      useFetchData<Types.DeleteTemporaryAwsS3UploadMutation, Types.DeleteTemporaryAwsS3UploadMutationVariables>(DeleteTemporaryAwsS3UploadDocument),
      options
    );
export const CalendarGroupsDocument = `
    query CalendarGroups($schoolId: UUID!) {
  calendarGroups(condition: {schoolId: $schoolId}, orderBy: SORT_ORDER_ASC) {
    nodes {
      ...calendarGroup
    }
  }
}
    ${CalendarGroupFragmentDoc}`;
export const useCalendarGroupsQuery = <
      TData = Types.CalendarGroupsQuery,
      TError = unknown
    >(
      variables: Types.CalendarGroupsQueryVariables, 
      options?: UseQueryOptions<Types.CalendarGroupsQuery, TError, TData>
    ) => 
    useQuery<Types.CalendarGroupsQuery, TError, TData>(
      ['CalendarGroups', variables],
      useFetchData<Types.CalendarGroupsQuery, Types.CalendarGroupsQueryVariables>(CalendarGroupsDocument).bind(null, variables),
      options
    );
export const SchoolCalendarGroupListDocument = `
    query SchoolCalendarGroupList($schoolId: UUID!) {
  school(id: $schoolId) {
    calendarGroups {
      totalCount
      nodes {
        id
        title
        sortOrder
      }
    }
  }
}
    `;
export const useSchoolCalendarGroupListQuery = <
      TData = Types.SchoolCalendarGroupListQuery,
      TError = unknown
    >(
      variables: Types.SchoolCalendarGroupListQueryVariables, 
      options?: UseQueryOptions<Types.SchoolCalendarGroupListQuery, TError, TData>
    ) => 
    useQuery<Types.SchoolCalendarGroupListQuery, TError, TData>(
      ['SchoolCalendarGroupList', variables],
      useFetchData<Types.SchoolCalendarGroupListQuery, Types.SchoolCalendarGroupListQueryVariables>(SchoolCalendarGroupListDocument).bind(null, variables),
      options
    );
export const CurrentUserDocument = `
    query currentUser {
  currentUser {
    ...CurrentUser
  }
}
    ${CurrentUserFragmentDoc}`;
export const useCurrentUserQuery = <
      TData = Types.CurrentUserQuery,
      TError = unknown
    >(
      variables?: Types.CurrentUserQueryVariables, 
      options?: UseQueryOptions<Types.CurrentUserQuery, TError, TData>
    ) => 
    useQuery<Types.CurrentUserQuery, TError, TData>(
      ['currentUser', variables],
      useFetchData<Types.CurrentUserQuery, Types.CurrentUserQueryVariables>(CurrentUserDocument).bind(null, variables),
      options
    );
export const CurrentUserSchoolPersonDocument = `
    query CurrentUserSchoolPerson($schoolId: UUID!) {
  currentUser {
    schoolPerson(_schoolId: $schoolId) {
      userTypes
    }
  }
}
    `;
export const useCurrentUserSchoolPersonQuery = <
      TData = Types.CurrentUserSchoolPersonQuery,
      TError = unknown
    >(
      variables: Types.CurrentUserSchoolPersonQueryVariables, 
      options?: UseQueryOptions<Types.CurrentUserSchoolPersonQuery, TError, TData>
    ) => 
    useQuery<Types.CurrentUserSchoolPersonQuery, TError, TData>(
      ['CurrentUserSchoolPerson', variables],
      useFetchData<Types.CurrentUserSchoolPersonQuery, Types.CurrentUserSchoolPersonQueryVariables>(CurrentUserSchoolPersonDocument).bind(null, variables),
      options
    );
export const VerifyAuthDocument = `
    query VerifyAuth {
  verifyAuth
}
    `;
export const useVerifyAuthQuery = <
      TData = Types.VerifyAuthQuery,
      TError = unknown
    >(
      variables?: Types.VerifyAuthQueryVariables, 
      options?: UseQueryOptions<Types.VerifyAuthQuery, TError, TData>
    ) => 
    useQuery<Types.VerifyAuthQuery, TError, TData>(
      ['VerifyAuth', variables],
      useFetchData<Types.VerifyAuthQuery, Types.VerifyAuthQueryVariables>(VerifyAuthDocument).bind(null, variables),
      options
    );
export const CurrentUserSessionDocument = `
    query CurrentUserSession {
  currentUser {
    userSession {
      sessionData
      updatedAt
    }
  }
}
    `;
export const useCurrentUserSessionQuery = <
      TData = Types.CurrentUserSessionQuery,
      TError = unknown
    >(
      variables?: Types.CurrentUserSessionQueryVariables, 
      options?: UseQueryOptions<Types.CurrentUserSessionQuery, TError, TData>
    ) => 
    useQuery<Types.CurrentUserSessionQuery, TError, TData>(
      ['CurrentUserSession', variables],
      useFetchData<Types.CurrentUserSessionQuery, Types.CurrentUserSessionQueryVariables>(CurrentUserSessionDocument).bind(null, variables),
      options
    );
export const EventsForWeekDocument = `
    query EventsForWeek($date: Datetime!, $calendarIds: [UUID]!) {
  getCalendarEventsForWeek(calendarIds: $calendarIds, date: $date) {
    nodes {
      ...CalendarEvent
    }
  }
}
    ${CalendarEventFragmentDoc}`;
export const useEventsForWeekQuery = <
      TData = Types.EventsForWeekQuery,
      TError = unknown
    >(
      variables: Types.EventsForWeekQueryVariables, 
      options?: UseQueryOptions<Types.EventsForWeekQuery, TError, TData>
    ) => 
    useQuery<Types.EventsForWeekQuery, TError, TData>(
      ['EventsForWeek', variables],
      useFetchData<Types.EventsForWeekQuery, Types.EventsForWeekQueryVariables>(EventsForWeekDocument).bind(null, variables),
      options
    );
export const MemberGroupsEventsForWeekDocument = `
    query MemberGroupsEventsForWeek($date: Datetime!, $schoolId: UUID!) {
  currentPersonSchoolCalendarEventsInWeek(date: $date, schoolId: $schoolId) {
    totalCount
    nodes {
      ...EventFeed
    }
  }
}
    ${EventFeedFragmentDoc}`;
export const useMemberGroupsEventsForWeekQuery = <
      TData = Types.MemberGroupsEventsForWeekQuery,
      TError = unknown
    >(
      variables: Types.MemberGroupsEventsForWeekQueryVariables, 
      options?: UseQueryOptions<Types.MemberGroupsEventsForWeekQuery, TError, TData>
    ) => 
    useQuery<Types.MemberGroupsEventsForWeekQuery, TError, TData>(
      ['MemberGroupsEventsForWeek', variables],
      useFetchData<Types.MemberGroupsEventsForWeekQuery, Types.MemberGroupsEventsForWeekQueryVariables>(MemberGroupsEventsForWeekDocument).bind(null, variables),
      options
    );
export const CalendarEventsForWeekDocument = `
    query CalendarEventsForWeek($date: Datetime!, $calendarIds: [UUID]!) {
  getCalendarEventsForWeek(calendarIds: $calendarIds, date: $date) {
    totalCount
    nodes {
      ...EventFeed
    }
  }
}
    ${EventFeedFragmentDoc}`;
export const useCalendarEventsForWeekQuery = <
      TData = Types.CalendarEventsForWeekQuery,
      TError = unknown
    >(
      variables: Types.CalendarEventsForWeekQueryVariables, 
      options?: UseQueryOptions<Types.CalendarEventsForWeekQuery, TError, TData>
    ) => 
    useQuery<Types.CalendarEventsForWeekQuery, TError, TData>(
      ['CalendarEventsForWeek', variables],
      useFetchData<Types.CalendarEventsForWeekQuery, Types.CalendarEventsForWeekQueryVariables>(CalendarEventsForWeekDocument).bind(null, variables),
      options
    );
export const GoogleAccountStatsDocument = `
    query GoogleAccountStats {
  googleAccounts {
    nodes {
      id
      googleCalendarSyncs {
        totalCount
        nodes {
          ...GcsStat
        }
      }
    }
  }
}
    ${GcsStatFragmentDoc}`;
export const useGoogleAccountStatsQuery = <
      TData = Types.GoogleAccountStatsQuery,
      TError = unknown
    >(
      variables?: Types.GoogleAccountStatsQueryVariables, 
      options?: UseQueryOptions<Types.GoogleAccountStatsQuery, TError, TData>
    ) => 
    useQuery<Types.GoogleAccountStatsQuery, TError, TData>(
      ['GoogleAccountStats', variables],
      useFetchData<Types.GoogleAccountStatsQuery, Types.GoogleAccountStatsQueryVariables>(GoogleAccountStatsDocument).bind(null, variables),
      options
    );
export const GoogleAccountsDocument = `
    query GoogleAccounts($schoolId: UUID!) {
  googleOauthUrl
  currentPersonGoogleAccounts {
    nodes {
      ...GoogleAccount
    }
  }
  googleCalendarSyncForSchool(schoolId: $schoolId) {
    totalCount
    nodes {
      ...GoogleCalendarSync
    }
  }
}
    ${GoogleAccountFragmentDoc}
${GoogleCalendarSyncFragmentDoc}`;
export const useGoogleAccountsQuery = <
      TData = Types.GoogleAccountsQuery,
      TError = unknown
    >(
      variables: Types.GoogleAccountsQueryVariables, 
      options?: UseQueryOptions<Types.GoogleAccountsQuery, TError, TData>
    ) => 
    useQuery<Types.GoogleAccountsQuery, TError, TData>(
      ['GoogleAccounts', variables],
      useFetchData<Types.GoogleAccountsQuery, Types.GoogleAccountsQueryVariables>(GoogleAccountsDocument).bind(null, variables),
      options
    );
export const GoogleOauthUrlDocument = `
    query GoogleOauthUrl {
  googleOauthUrl
}
    `;
export const useGoogleOauthUrlQuery = <
      TData = Types.GoogleOauthUrlQuery,
      TError = unknown
    >(
      variables?: Types.GoogleOauthUrlQueryVariables, 
      options?: UseQueryOptions<Types.GoogleOauthUrlQuery, TError, TData>
    ) => 
    useQuery<Types.GoogleOauthUrlQuery, TError, TData>(
      ['GoogleOauthUrl', variables],
      useFetchData<Types.GoogleOauthUrlQuery, Types.GoogleOauthUrlQueryVariables>(GoogleOauthUrlDocument).bind(null, variables),
      options
    );
export const GroupResourcesDocument = `
    query GroupResources($groupId: UUID!) {
  resources(
    condition: {groupId: $groupId}
    orderBy: SORT_ORDER_ASC
    filter: {parentResource: {isNull: true}}
  ) {
    nodes {
      ...ResourceFragment
    }
    totalCount
  }
}
    ${ResourceFragmentFragmentDoc}`;
export const useGroupResourcesQuery = <
      TData = Types.GroupResourcesQuery,
      TError = unknown
    >(
      variables: Types.GroupResourcesQueryVariables, 
      options?: UseQueryOptions<Types.GroupResourcesQuery, TError, TData>
    ) => 
    useQuery<Types.GroupResourcesQuery, TError, TData>(
      ['GroupResources', variables],
      useFetchData<Types.GroupResourcesQuery, Types.GroupResourcesQueryVariables>(GroupResourcesDocument).bind(null, variables),
      options
    );
export const PublishedGroupResourcesDocument = `
    query PublishedGroupResources($groupId: UUID!) {
  resources: groupResourcesForCurrentPerson(
    groupId: $groupId
    filter: {published: {equalTo: true}, parentResource: {isNull: true}}
  ) {
    nodes {
      ...ResourceFragment
    }
    totalCount
  }
  group(id: $groupId) {
    groupCategory {
      iconBackgroundColor
    }
  }
}
    ${ResourceFragmentFragmentDoc}`;
export const usePublishedGroupResourcesQuery = <
      TData = Types.PublishedGroupResourcesQuery,
      TError = unknown
    >(
      variables: Types.PublishedGroupResourcesQueryVariables, 
      options?: UseQueryOptions<Types.PublishedGroupResourcesQuery, TError, TData>
    ) => 
    useQuery<Types.PublishedGroupResourcesQuery, TError, TData>(
      ['PublishedGroupResources', variables],
      useFetchData<Types.PublishedGroupResourcesQuery, Types.PublishedGroupResourcesQueryVariables>(PublishedGroupResourcesDocument).bind(null, variables),
      options
    );
export const CurrentUserSchoolGroupsDocument = `
    query CurrentUserSchoolGroups($schoolId: UUID!) {
  currentUser {
    groupPeopleForSchool(schoolId: $schoolId) {
      nodes {
        group {
          ...GlobalGroupList
        }
      }
    }
  }
}
    ${GlobalGroupListFragmentDoc}`;
export const useCurrentUserSchoolGroupsQuery = <
      TData = Types.CurrentUserSchoolGroupsQuery,
      TError = unknown
    >(
      variables: Types.CurrentUserSchoolGroupsQueryVariables, 
      options?: UseQueryOptions<Types.CurrentUserSchoolGroupsQuery, TError, TData>
    ) => 
    useQuery<Types.CurrentUserSchoolGroupsQuery, TError, TData>(
      ['CurrentUserSchoolGroups', variables],
      useFetchData<Types.CurrentUserSchoolGroupsQuery, Types.CurrentUserSchoolGroupsQueryVariables>(CurrentUserSchoolGroupsDocument).bind(null, variables),
      options
    );
export const GroupDetailBySlugDocument = `
    query GroupDetailBySlug($slug: String!) {
  groupBySlug(slug: $slug) {
    ...GroupDetail
  }
}
    ${GroupDetailFragmentDoc}`;
export const useGroupDetailBySlugQuery = <
      TData = Types.GroupDetailBySlugQuery,
      TError = unknown
    >(
      variables: Types.GroupDetailBySlugQueryVariables, 
      options?: UseQueryOptions<Types.GroupDetailBySlugQuery, TError, TData>
    ) => 
    useQuery<Types.GroupDetailBySlugQuery, TError, TData>(
      ['GroupDetailBySlug', variables],
      useFetchData<Types.GroupDetailBySlugQuery, Types.GroupDetailBySlugQueryVariables>(GroupDetailBySlugDocument).bind(null, variables),
      options
    );
export const CurrentUserSchoolGroupResourcesDocument = `
    query CurrentUserSchoolGroupResources($schoolId: UUID!, $userType: [UserType]!) {
  currentUser {
    groupPeopleForSchool(schoolId: $schoolId) {
      nodes {
        group {
          resources(
            filter: {published: {equalTo: true}, and: {userTypes: {equalTo: []}, or: {userTypes: {contains: $userType}}}}
            orderBy: SORT_ORDER_ASC
          ) {
            nodes {
              body
              id
              parentResource
              tileImage {
                temporaryDownloadUrl
              }
              title
              type
              url
              slug
            }
            totalCount
          }
          icon
          iconType
          id
          name
          isSchoolwideGroup
          isDivisionGroup
          globalSortOrder
          slug
          groupCategory {
            iconBackgroundColor
          }
        }
      }
      totalCount
    }
  }
}
    `;
export const useCurrentUserSchoolGroupResourcesQuery = <
      TData = Types.CurrentUserSchoolGroupResourcesQuery,
      TError = unknown
    >(
      variables: Types.CurrentUserSchoolGroupResourcesQueryVariables, 
      options?: UseQueryOptions<Types.CurrentUserSchoolGroupResourcesQuery, TError, TData>
    ) => 
    useQuery<Types.CurrentUserSchoolGroupResourcesQuery, TError, TData>(
      ['CurrentUserSchoolGroupResources', variables],
      useFetchData<Types.CurrentUserSchoolGroupResourcesQuery, Types.CurrentUserSchoolGroupResourcesQueryVariables>(CurrentUserSchoolGroupResourcesDocument).bind(null, variables),
      options
    );
export const InvitationDetailDocument = `
    query InvitationDetail($slug: String!) {
  invitationDetail(slug: $slug) {
    invitationAccepted
    inviterEmail
    inviterFirstName
    inviterLastName
    inviteeAccountEmail
    inviteeHasAccount
    schoolName
    schoolPrimaryColor
    schoolThumbnail {
      ...File
    }
  }
}
    ${FileFragmentDoc}`;
export const useInvitationDetailQuery = <
      TData = Types.InvitationDetailQuery,
      TError = unknown
    >(
      variables: Types.InvitationDetailQueryVariables, 
      options?: UseQueryOptions<Types.InvitationDetailQuery, TError, TData>
    ) => 
    useQuery<Types.InvitationDetailQuery, TError, TData>(
      ['InvitationDetail', variables],
      useFetchData<Types.InvitationDetailQuery, Types.InvitationDetailQueryVariables>(InvitationDetailDocument).bind(null, variables),
      options
    );
export const AcceptInvitationDocument = `
    mutation AcceptInvitation($invitationSlug: String!, $password: String!) {
  acceptInvitation(input: {invitationSlug: $invitationSlug, password: $password}) {
    jwtToken
  }
}
    `;
export const useAcceptInvitationMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.AcceptInvitationMutation, TError, Types.AcceptInvitationMutationVariables, TContext>) => 
    useMutation<Types.AcceptInvitationMutation, TError, Types.AcceptInvitationMutationVariables, TContext>(
      useFetchData<Types.AcceptInvitationMutation, Types.AcceptInvitationMutationVariables>(AcceptInvitationDocument),
      options
    );
export const SchoolMarqueeDocument = `
    query SchoolMarquee($schoolId: UUID!) {
  marquees(condition: {schoolId: $schoolId}) {
    nodes {
      ...Marquee
    }
  }
}
    ${MarqueeFragmentDoc}`;
export const useSchoolMarqueeQuery = <
      TData = Types.SchoolMarqueeQuery,
      TError = unknown
    >(
      variables: Types.SchoolMarqueeQueryVariables, 
      options?: UseQueryOptions<Types.SchoolMarqueeQuery, TError, TData>
    ) => 
    useQuery<Types.SchoolMarqueeQuery, TError, TData>(
      ['SchoolMarquee', variables],
      useFetchData<Types.SchoolMarqueeQuery, Types.SchoolMarqueeQueryVariables>(SchoolMarqueeDocument).bind(null, variables),
      options
    );
export const AdminNewslettersDocument = `
    query AdminNewsletters($publishDateAfter: Datetime!, $publishDateBefore: Datetime!, $schoolId: UUID!) {
  newslettersForSchoolForCurrentPerson(
    publishedAfter: $publishDateAfter
    publishedBefore: $publishDateBefore
    schoolId: $schoolId
    asAdmin: true
  ) {
    totalCount
    nodes {
      ...Newsletter
    }
  }
}
    ${NewsletterFragmentDoc}`;
export const useAdminNewslettersQuery = <
      TData = Types.AdminNewslettersQuery,
      TError = unknown
    >(
      variables: Types.AdminNewslettersQueryVariables, 
      options?: UseQueryOptions<Types.AdminNewslettersQuery, TError, TData>
    ) => 
    useQuery<Types.AdminNewslettersQuery, TError, TData>(
      ['AdminNewsletters', variables],
      useFetchData<Types.AdminNewslettersQuery, Types.AdminNewslettersQueryVariables>(AdminNewslettersDocument).bind(null, variables),
      options
    );
export const GetNewsletterByIdDocument = `
    query GetNewsletterById($id: UUID!) {
  newsletter(id: $id) {
    ...Newsletter
  }
}
    ${NewsletterFragmentDoc}`;
export const useGetNewsletterByIdQuery = <
      TData = Types.GetNewsletterByIdQuery,
      TError = unknown
    >(
      variables: Types.GetNewsletterByIdQueryVariables, 
      options?: UseQueryOptions<Types.GetNewsletterByIdQuery, TError, TData>
    ) => 
    useQuery<Types.GetNewsletterByIdQuery, TError, TData>(
      ['GetNewsletterById', variables],
      useFetchData<Types.GetNewsletterByIdQuery, Types.GetNewsletterByIdQueryVariables>(GetNewsletterByIdDocument).bind(null, variables),
      options
    );
export const GetNewsletterBySlugDocument = `
    query GetNewsletterBySlug($slug: String!) {
  getNewsletterBySlug(newsletterSlug: $slug) {
    ...Newsletter
  }
}
    ${NewsletterFragmentDoc}`;
export const useGetNewsletterBySlugQuery = <
      TData = Types.GetNewsletterBySlugQuery,
      TError = unknown
    >(
      variables: Types.GetNewsletterBySlugQueryVariables, 
      options?: UseQueryOptions<Types.GetNewsletterBySlugQuery, TError, TData>
    ) => 
    useQuery<Types.GetNewsletterBySlugQuery, TError, TData>(
      ['GetNewsletterBySlug', variables],
      useFetchData<Types.GetNewsletterBySlugQuery, Types.GetNewsletterBySlugQueryVariables>(GetNewsletterBySlugDocument).bind(null, variables),
      options
    );
export const GroupNewslettersDocument = `
    query GroupNewsletters($groupId: UUID!) {
  group(id: $groupId) {
    newsletters: currentUserNewsletters(
      orderBy: PUBLISH_DATE_DESC
      filter: {status: {equalTo: PUBLISHED}}
    ) {
      totalCount
      nodes {
        url
        id
        publishDate
        title
        slug
        coverImage {
          ...File
        }
      }
    }
  }
}
    ${FileFragmentDoc}`;
export const useGroupNewslettersQuery = <
      TData = Types.GroupNewslettersQuery,
      TError = unknown
    >(
      variables: Types.GroupNewslettersQueryVariables, 
      options?: UseQueryOptions<Types.GroupNewslettersQuery, TError, TData>
    ) => 
    useQuery<Types.GroupNewslettersQuery, TError, TData>(
      ['GroupNewsletters', variables],
      useFetchData<Types.GroupNewslettersQuery, Types.GroupNewslettersQueryVariables>(GroupNewslettersDocument).bind(null, variables),
      options
    );
export const SchoolAdminNotificationsCountDocument = `
    query SchoolAdminNotificationsCount($schoolId: UUID!) {
  getNotificationsForSchoolForCurrentPerson(schoolId: $schoolId, asAdmin: true) {
    totalCount
  }
}
    `;
export const useSchoolAdminNotificationsCountQuery = <
      TData = Types.SchoolAdminNotificationsCountQuery,
      TError = unknown
    >(
      variables: Types.SchoolAdminNotificationsCountQueryVariables, 
      options?: UseQueryOptions<Types.SchoolAdminNotificationsCountQuery, TError, TData>
    ) => 
    useQuery<Types.SchoolAdminNotificationsCountQuery, TError, TData>(
      ['SchoolAdminNotificationsCount', variables],
      useFetchData<Types.SchoolAdminNotificationsCountQuery, Types.SchoolAdminNotificationsCountQueryVariables>(SchoolAdminNotificationsCountDocument).bind(null, variables),
      options
    );
export const SchoolAdminNotificationsDocument = `
    query SchoolAdminNotifications($schoolId: UUID!, $draft: Boolean!, $delivery: [NotificationDelivery!]!, $limit: Int, $offset: Int, $includeTargetRecipientCount: Boolean = false, $orderBy: NotificationsOrderByType) {
  getNotificationsForSchoolForCurrentPerson(
    schoolId: $schoolId
    offset: $offset
    first: $limit
    orderBy: $orderBy
    asAdmin: true
    filter: {draft: {equalTo: $draft}, delivery: {in: $delivery}}
  ) {
    totalCount
    nodes {
      ...NotificationFragment
      targetRecipientCount @include(if: $includeTargetRecipientCount)
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      endCursor
      startCursor
    }
  }
}
    ${NotificationFragmentFragmentDoc}`;
export const useSchoolAdminNotificationsQuery = <
      TData = Types.SchoolAdminNotificationsQuery,
      TError = unknown
    >(
      variables: Types.SchoolAdminNotificationsQueryVariables, 
      options?: UseQueryOptions<Types.SchoolAdminNotificationsQuery, TError, TData>
    ) => 
    useQuery<Types.SchoolAdminNotificationsQuery, TError, TData>(
      ['SchoolAdminNotifications', variables],
      useFetchData<Types.SchoolAdminNotificationsQuery, Types.SchoolAdminNotificationsQueryVariables>(SchoolAdminNotificationsDocument).bind(null, variables),
      options
    );
export const CurrentPersonScholNotificationsDocument = `
    query CurrentPersonScholNotifications($schoolId: UUID!) {
  schoolNotificationsForCurrentPerson(
    schoolId: $schoolId
    filter: {delivery: {equalTo: SENT}}
  ) {
    nodes {
      id
      notificationGroups {
        nodes {
          group {
            id
          }
        }
      }
      notificationNewsletters {
        nodes {
          newsletter {
            url
          }
        }
      }
      message
      sendDate
      slug
      userTypes
    }
    totalCount
  }
}
    `;
export const useCurrentPersonScholNotificationsQuery = <
      TData = Types.CurrentPersonScholNotificationsQuery,
      TError = unknown
    >(
      variables: Types.CurrentPersonScholNotificationsQueryVariables, 
      options?: UseQueryOptions<Types.CurrentPersonScholNotificationsQuery, TError, TData>
    ) => 
    useQuery<Types.CurrentPersonScholNotificationsQuery, TError, TData>(
      ['CurrentPersonScholNotifications', variables],
      useFetchData<Types.CurrentPersonScholNotificationsQuery, Types.CurrentPersonScholNotificationsQueryVariables>(CurrentPersonScholNotificationsDocument).bind(null, variables),
      options
    );
export const CurrentUserGroupNotificationsDocument = `
    query CurrentUserGroupNotifications($groupId: UUID!) {
  group(id: $groupId) {
    notifications: currentUserNotifications(
      orderBy: SEND_DATE_DESC
      filter: {delivery: {equalTo: SENT}}
    ) {
      nodes {
        id
        notificationGroups {
          nodes {
            group {
              id
            }
          }
        }
        message
        sendDate
        slug
        userTypes
      }
      totalCount
    }
  }
}
    `;
export const useCurrentUserGroupNotificationsQuery = <
      TData = Types.CurrentUserGroupNotificationsQuery,
      TError = unknown
    >(
      variables: Types.CurrentUserGroupNotificationsQueryVariables, 
      options?: UseQueryOptions<Types.CurrentUserGroupNotificationsQuery, TError, TData>
    ) => 
    useQuery<Types.CurrentUserGroupNotificationsQuery, TError, TData>(
      ['CurrentUserGroupNotifications', variables],
      useFetchData<Types.CurrentUserGroupNotificationsQuery, Types.CurrentUserGroupNotificationsQueryVariables>(CurrentUserGroupNotificationsDocument).bind(null, variables),
      options
    );
export const NotificationDetailDocument = `
    query NotificationDetail($id: UUID!) {
  notification(id: $id) {
    body
    buttons {
      buttons {
        ...Button
      }
    }
    notificationGroups {
      nodes {
        group {
          id
        }
      }
    }
    id
    message
    sendDate
    slug
    userTypes
    embedUrl
    newslettersByNotificationNewsletterNotificationIdAndNewsletterId {
      nodes {
        ...Newsletter
      }
    }
    notificationFiles(orderBy: SORT_ORDER_ASC) {
      nodes {
        ...NotificationFile
      }
    }
  }
}
    ${ButtonFragmentDoc}
${NewsletterFragmentDoc}
${NotificationFileFragmentDoc}`;
export const useNotificationDetailQuery = <
      TData = Types.NotificationDetailQuery,
      TError = unknown
    >(
      variables: Types.NotificationDetailQueryVariables, 
      options?: UseQueryOptions<Types.NotificationDetailQuery, TError, TData>
    ) => 
    useQuery<Types.NotificationDetailQuery, TError, TData>(
      ['NotificationDetail', variables],
      useFetchData<Types.NotificationDetailQuery, Types.NotificationDetailQueryVariables>(NotificationDetailDocument).bind(null, variables),
      options
    );
export const PageDocument = `
    query Page($id: UUID!) {
  page(id: $id) {
    id
    pageSections(orderBy: SORT_ORDER_ASC) {
      nodes {
        ...PageSection
      }
      totalCount
    }
  }
}
    ${PageSectionFragmentDoc}`;
export const usePageQuery = <
      TData = Types.PageQuery,
      TError = unknown
    >(
      variables: Types.PageQueryVariables, 
      options?: UseQueryOptions<Types.PageQuery, TError, TData>
    ) => 
    useQuery<Types.PageQuery, TError, TData>(
      ['Page', variables],
      useFetchData<Types.PageQuery, Types.PageQueryVariables>(PageDocument).bind(null, variables),
      options
    );
export const FacultyStaffDocument = `
    query FacultyStaff($schoolId: UUID!) {
  schoolPeople(
    filter: {userTypes: {contains: FACULTY_STAFF}, schoolId: {equalTo: $schoolId}}
  ) {
    nodes {
      ...SchoolPerson
    }
    totalCount
  }
}
    ${SchoolPersonFragmentDoc}`;
export const useFacultyStaffQuery = <
      TData = Types.FacultyStaffQuery,
      TError = unknown
    >(
      variables: Types.FacultyStaffQueryVariables, 
      options?: UseQueryOptions<Types.FacultyStaffQuery, TError, TData>
    ) => 
    useQuery<Types.FacultyStaffQuery, TError, TData>(
      ['FacultyStaff', variables],
      useFetchData<Types.FacultyStaffQuery, Types.FacultyStaffQueryVariables>(FacultyStaffDocument).bind(null, variables),
      options
    );
export const SchoolUsersDocument = `
    query SchoolUsers($schoolId: UUID!, $userTypes: [UserType!]) {
  schoolPeople(
    filter: {userTypes: {containedBy: $userTypes}, schoolId: {equalTo: $schoolId}, isAnonymous: {equalTo: false}}
  ) {
    nodes {
      ...SchoolPerson
    }
    totalCount
  }
}
    ${SchoolPersonFragmentDoc}`;
export const useSchoolUsersQuery = <
      TData = Types.SchoolUsersQuery,
      TError = unknown
    >(
      variables: Types.SchoolUsersQueryVariables, 
      options?: UseQueryOptions<Types.SchoolUsersQuery, TError, TData>
    ) => 
    useQuery<Types.SchoolUsersQuery, TError, TData>(
      ['SchoolUsers', variables],
      useFetchData<Types.SchoolUsersQuery, Types.SchoolUsersQueryVariables>(SchoolUsersDocument).bind(null, variables),
      options
    );
export const DoesPersonExistDocument = `
    query DoesPersonExist($email: Email!, $schoolId: UUID!) {
  personWithAccount: checkAccountEmail(email: $email) {
    id
    email
    firstName
    lastName
  }
  schoolPerson: checkPersonEmailForSchool(email: $email, schoolId: $schoolId) {
    id
    email
    firstName
    lastName
    phone
    bio
    profileImage {
      ...File
    }
    title
    userAccountId
    adminGroups: groupPeople(condition: {admin: true}) {
      nodes {
        admin
        id
        groupId
        member
        personId
      }
    }
    schoolInfo: schoolPeople {
      nodes {
        id
        includeInDirectory
        userTypes
        schoolId
        schoolPersonInvitation {
          accepted
          slug
        }
      }
    }
  }
}
    ${FileFragmentDoc}`;
export const useDoesPersonExistQuery = <
      TData = Types.DoesPersonExistQuery,
      TError = unknown
    >(
      variables: Types.DoesPersonExistQueryVariables, 
      options?: UseQueryOptions<Types.DoesPersonExistQuery, TError, TData>
    ) => 
    useQuery<Types.DoesPersonExistQuery, TError, TData>(
      ['DoesPersonExist', variables],
      useFetchData<Types.DoesPersonExistQuery, Types.DoesPersonExistQueryVariables>(DoesPersonExistDocument).bind(null, variables),
      options
    );
export const RecipientCountDocument = `
    query RecipientCount($groupIds: [UUID]!, $userTypes: [UserType]) {
  recipientCount(groupIds: $groupIds, userTypes: $userTypes)
}
    `;
export const useRecipientCountQuery = <
      TData = Types.RecipientCountQuery,
      TError = unknown
    >(
      variables: Types.RecipientCountQueryVariables, 
      options?: UseQueryOptions<Types.RecipientCountQuery, TError, TData>
    ) => 
    useQuery<Types.RecipientCountQuery, TError, TData>(
      ['RecipientCount', variables],
      useFetchData<Types.RecipientCountQuery, Types.RecipientCountQueryVariables>(RecipientCountDocument).bind(null, variables),
      options
    );
export const ResourceChildrenDocument = `
    query ResourceChildren($parentResource: UUID!) {
  resources(condition: {parentResource: $parentResource}, orderBy: SORT_ORDER_ASC) {
    nodes {
      ...ResourceFragment
    }
    totalCount
  }
}
    ${ResourceFragmentFragmentDoc}`;
export const useResourceChildrenQuery = <
      TData = Types.ResourceChildrenQuery,
      TError = unknown
    >(
      variables: Types.ResourceChildrenQueryVariables, 
      options?: UseQueryOptions<Types.ResourceChildrenQuery, TError, TData>
    ) => 
    useQuery<Types.ResourceChildrenQuery, TError, TData>(
      ['ResourceChildren', variables],
      useFetchData<Types.ResourceChildrenQuery, Types.ResourceChildrenQueryVariables>(ResourceChildrenDocument).bind(null, variables),
      options
    );
export const AdminResourceDocument = `
    query AdminResource($id: UUID!) {
  resource(id: $id) {
    ...ResourceFragment
  }
  resources(condition: {parentResource: $id}, orderBy: SORT_ORDER_ASC) {
    nodes {
      ...ResourceFragment
    }
    totalCount
  }
}
    ${ResourceFragmentFragmentDoc}`;
export const useAdminResourceQuery = <
      TData = Types.AdminResourceQuery,
      TError = unknown
    >(
      variables: Types.AdminResourceQueryVariables, 
      options?: UseQueryOptions<Types.AdminResourceQuery, TError, TData>
    ) => 
    useQuery<Types.AdminResourceQuery, TError, TData>(
      ['AdminResource', variables],
      useFetchData<Types.AdminResourceQuery, Types.AdminResourceQueryVariables>(AdminResourceDocument).bind(null, variables),
      options
    );
export const ResourceBySlugDocument = `
    query ResourceBySlug($slug: String!) {
  resourceBySlug(slug: $slug) {
    ...ResourceFragment
  }
}
    ${ResourceFragmentFragmentDoc}`;
export const useResourceBySlugQuery = <
      TData = Types.ResourceBySlugQuery,
      TError = unknown
    >(
      variables: Types.ResourceBySlugQueryVariables, 
      options?: UseQueryOptions<Types.ResourceBySlugQuery, TError, TData>
    ) => 
    useQuery<Types.ResourceBySlugQuery, TError, TData>(
      ['ResourceBySlug', variables],
      useFetchData<Types.ResourceBySlugQuery, Types.ResourceBySlugQueryVariables>(ResourceBySlugDocument).bind(null, variables),
      options
    );
export const ResourceDocument = `
    query Resource($id: UUID!) {
  resource(id: $id) {
    ...ResourceFragment
  }
  resources(
    condition: {parentResource: $id, published: true}
    orderBy: SORT_ORDER_ASC
  ) {
    nodes {
      ...ResourceFragment
    }
    totalCount
  }
}
    ${ResourceFragmentFragmentDoc}`;
export const useResourceQuery = <
      TData = Types.ResourceQuery,
      TError = unknown
    >(
      variables: Types.ResourceQueryVariables, 
      options?: UseQueryOptions<Types.ResourceQuery, TError, TData>
    ) => 
    useQuery<Types.ResourceQuery, TError, TData>(
      ['Resource', variables],
      useFetchData<Types.ResourceQuery, Types.ResourceQueryVariables>(ResourceDocument).bind(null, variables),
      options
    );
export const SchoolPersonResourcesDocument = `
    query SchoolPersonResources($schoolId: UUID!, $personId: UUID!) {
  schoolResourcesForPerson(personId: $personId, schoolId: $schoolId) {
    totalCount
    nodes {
      body
      id
      parentResource
      tileImage {
        temporaryDownloadUrl
      }
      title
      type
      url
      slug
      group {
        globalSortOrder
        icon
        iconType
        name
        slug
        id
        groupCategory {
          iconBackgroundColor
        }
      }
    }
  }
}
    `;
export const useSchoolPersonResourcesQuery = <
      TData = Types.SchoolPersonResourcesQuery,
      TError = unknown
    >(
      variables: Types.SchoolPersonResourcesQueryVariables, 
      options?: UseQueryOptions<Types.SchoolPersonResourcesQuery, TError, TData>
    ) => 
    useQuery<Types.SchoolPersonResourcesQuery, TError, TData>(
      ['SchoolPersonResources', variables],
      useFetchData<Types.SchoolPersonResourcesQuery, Types.SchoolPersonResourcesQueryVariables>(SchoolPersonResourcesDocument).bind(null, variables),
      options
    );
export const SchoolCalendarsQueryDocument = `
    query SchoolCalendarsQuery($schoolId: UUID!) {
  divisions(condition: {schoolId: $schoolId}, orderBy: SORT_ORDER_ASC) {
    nodes {
      id
      name
      schoolwide
      group {
        id
        groupName
      }
      groupCategories(orderBy: SORT_ORDER_ASC) {
        nodes {
          id
          name
          iconType
          iconBackgroundColor
          groups(orderBy: SORT_ORDER_ASC) {
            nodes {
              groupName
              iconType
              id
              name
              calendars {
                nodes {
                  id
                  title
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;
export const useSchoolCalendarsQueryQuery = <
      TData = Types.SchoolCalendarsQueryQuery,
      TError = unknown
    >(
      variables: Types.SchoolCalendarsQueryQueryVariables, 
      options?: UseQueryOptions<Types.SchoolCalendarsQueryQuery, TError, TData>
    ) => 
    useQuery<Types.SchoolCalendarsQueryQuery, TError, TData>(
      ['SchoolCalendarsQuery', variables],
      useFetchData<Types.SchoolCalendarsQueryQuery, Types.SchoolCalendarsQueryQueryVariables>(SchoolCalendarsQueryDocument).bind(null, variables),
      options
    );
export const SchoolDetailDocument = `
    query SchoolDetail($slug: String!) {
  schoolBySlug(slug: $slug) {
    ...SchoolDetail
  }
}
    ${SchoolDetailFragmentDoc}`;
export const useSchoolDetailQuery = <
      TData = Types.SchoolDetailQuery,
      TError = unknown
    >(
      variables: Types.SchoolDetailQueryVariables, 
      options?: UseQueryOptions<Types.SchoolDetailQuery, TError, TData>
    ) => 
    useQuery<Types.SchoolDetailQuery, TError, TData>(
      ['SchoolDetail', variables],
      useFetchData<Types.SchoolDetailQuery, Types.SchoolDetailQueryVariables>(SchoolDetailDocument).bind(null, variables),
      options
    );
export const CurrentPersonSchoolPermisionsDocument = `
    query CurrentPersonSchoolPermisions($schoolId: UUID!, $installationFilter: SchoolPersonInstallationFilter) {
  currentUserPermissions: currentUser {
    ...CurrentUserSchoolPermissions
  }
}
    ${CurrentUserSchoolPermissionsFragmentDoc}`;
export const useCurrentPersonSchoolPermisionsQuery = <
      TData = Types.CurrentPersonSchoolPermisionsQuery,
      TError = unknown
    >(
      variables: Types.CurrentPersonSchoolPermisionsQueryVariables, 
      options?: UseQueryOptions<Types.CurrentPersonSchoolPermisionsQuery, TError, TData>
    ) => 
    useQuery<Types.CurrentPersonSchoolPermisionsQuery, TError, TData>(
      ['CurrentPersonSchoolPermisions', variables],
      useFetchData<Types.CurrentPersonSchoolPermisionsQuery, Types.CurrentPersonSchoolPermisionsQueryVariables>(CurrentPersonSchoolPermisionsDocument).bind(null, variables),
      options
    );
export const GetSchoolThemeDocument = `
    query GetSchoolTheme($slug: String!) {
  schoolBySlug(slug: $slug) {
    theme {
      primaryColor
      secondaryColor
    }
  }
}
    `;
export const useGetSchoolThemeQuery = <
      TData = Types.GetSchoolThemeQuery,
      TError = unknown
    >(
      variables: Types.GetSchoolThemeQueryVariables, 
      options?: UseQueryOptions<Types.GetSchoolThemeQuery, TError, TData>
    ) => 
    useQuery<Types.GetSchoolThemeQuery, TError, TData>(
      ['GetSchoolTheme', variables],
      useFetchData<Types.GetSchoolThemeQuery, Types.GetSchoolThemeQueryVariables>(GetSchoolThemeDocument).bind(null, variables),
      options
    );
export const SchoolListDocument = `
    query SchoolList($archived: Boolean!, $filter: SchoolFilter) {
  schools(condition: {archived: $archived}, filter: $filter, orderBy: NAME_ASC) {
    nodes {
      ...SchoolListFields
    }
    totalCount
  }
}
    ${SchoolListFieldsFragmentDoc}`;
export const useSchoolListQuery = <
      TData = Types.SchoolListQuery,
      TError = unknown
    >(
      variables: Types.SchoolListQueryVariables, 
      options?: UseQueryOptions<Types.SchoolListQuery, TError, TData>
    ) => 
    useQuery<Types.SchoolListQuery, TError, TData>(
      ['SchoolList', variables],
      useFetchData<Types.SchoolListQuery, Types.SchoolListQueryVariables>(SchoolListDocument).bind(null, variables),
      options
    );
export const SearchSchoolsDocument = `
    query SearchSchools($searchTerm: String!) {
  schools(orderBy: NAME_ASC, filter: {name: {includesInsensitive: $searchTerm}}) {
    nodes {
      ...SchoolListFields
    }
  }
}
    ${SchoolListFieldsFragmentDoc}`;
export const useSearchSchoolsQuery = <
      TData = Types.SearchSchoolsQuery,
      TError = unknown
    >(
      variables: Types.SearchSchoolsQueryVariables, 
      options?: UseQueryOptions<Types.SearchSchoolsQuery, TError, TData>
    ) => 
    useQuery<Types.SearchSchoolsQuery, TError, TData>(
      ['SearchSchools', variables],
      useFetchData<Types.SearchSchoolsQuery, Types.SearchSchoolsQueryVariables>(SearchSchoolsDocument).bind(null, variables),
      options
    );
export const SchoolSponsorsDocument = `
    query SchoolSponsors($schoolId: UUID!) {
  gold: sponsors(
    condition: {schoolId: $schoolId, type: GOLD}
    orderBy: SORT_ORDER_ASC
  ) {
    totalCount
    nodes {
      ...Sponsor
    }
  }
  silver: sponsors(
    condition: {schoolId: $schoolId, type: SILVER}
    orderBy: SORT_ORDER_ASC
  ) {
    totalCount
    nodes {
      ...Sponsor
    }
  }
  bronze: sponsors(
    condition: {schoolId: $schoolId, type: BRONZE}
    orderBy: SORT_ORDER_ASC
  ) {
    totalCount
    nodes {
      ...Sponsor
    }
  }
}
    ${SponsorFragmentDoc}`;
export const useSchoolSponsorsQuery = <
      TData = Types.SchoolSponsorsQuery,
      TError = unknown
    >(
      variables: Types.SchoolSponsorsQueryVariables, 
      options?: UseQueryOptions<Types.SchoolSponsorsQuery, TError, TData>
    ) => 
    useQuery<Types.SchoolSponsorsQuery, TError, TData>(
      ['SchoolSponsors', variables],
      useFetchData<Types.SchoolSponsorsQuery, Types.SchoolSponsorsQueryVariables>(SchoolSponsorsDocument).bind(null, variables),
      options
    );
export const SponsorBySlugDocument = `
    query SponsorBySlug($slug: String!) {
  sponsorBySlug(slug: $slug) {
    ...Sponsor
  }
}
    ${SponsorFragmentDoc}`;
export const useSponsorBySlugQuery = <
      TData = Types.SponsorBySlugQuery,
      TError = unknown
    >(
      variables: Types.SponsorBySlugQueryVariables, 
      options?: UseQueryOptions<Types.SponsorBySlugQuery, TError, TData>
    ) => 
    useQuery<Types.SponsorBySlugQuery, TError, TData>(
      ['SponsorBySlug', variables],
      useFetchData<Types.SponsorBySlugQuery, Types.SponsorBySlugQueryVariables>(SponsorBySlugDocument).bind(null, variables),
      options
    );
export const DeleteSponsorDocument = `
    mutation DeleteSponsor($id: UUID!) {
  deleteSponsor(input: {id: $id}) {
    clientMutationId
  }
}
    `;
export const useDeleteSponsorMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<Types.DeleteSponsorMutation, TError, Types.DeleteSponsorMutationVariables, TContext>) => 
    useMutation<Types.DeleteSponsorMutation, TError, Types.DeleteSponsorMutationVariables, TContext>(
      useFetchData<Types.DeleteSponsorMutation, Types.DeleteSponsorMutationVariables>(DeleteSponsorDocument),
      options
    );