import { useSchoolOnboardingContext } from '..'
import { useSchoolContext } from '../../../stores/school'
import SelectionCheckbox from '../checkbox'

const SelectDivisions = () => {
  const {
    state: {
      school: {
        divisions: { nodes: divisions },
      },
    },
  } = useSchoolContext()

  const { setSchoolOnboardingState, selectedDivisions } = useSchoolOnboardingContext()

  return (
    <>
      {divisions
        .filter(o => !o.schoolwide)
        .map(division => (
          <SelectionCheckbox
            key={division.id}
            checked={Boolean(selectedDivisions?.some(o => o.id === division.id))}
            label={division.name}
            onChange={checked => {
              if (checked) {
                if (!selectedDivisions?.some(o => o.id === division.id))
                  setSchoolOnboardingState(_state => ({
                    ..._state,
                    selectedDivisions: divisions.filter(
                      o =>
                        o.id === division.id ||
                        _state.selectedDivisions?.some(selected => selected.id === o.id)
                    ),
                  }))
              } else {
                setSchoolOnboardingState(_state => ({
                  ..._state,
                  selectedDivisions: _state.selectedDivisions?.filter(o => o.id !== division.id),
                }))
              }
            }}
          />
        ))}
    </>
  )
}

export default SelectDivisions
