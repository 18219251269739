import { useCallback } from 'react'
import { Row } from 'react-table'
import { SchoolPersonFragment } from '../../graphql/autogenerate/operations'

const useEmailSort = () =>
  useCallback((rowA: Row<SchoolPersonFragment>, rowB: Row<SchoolPersonFragment>) => {
    if (!rowA.original.person?.email && !rowB.original.person?.email) return 0
    if (!rowA.original.person?.email) return -1
    if (!rowB.original.person?.email) return 1
    return rowA.original.person?.email > rowB.original.person?.email ? 1 : -1
  }, [])

export default useEmailSort
