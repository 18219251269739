import { FormikContextType } from 'formik'
import { FormikSwitchField, FormikTextInput } from '../../forms'
import { FormikRadio } from '../../forms/formik-radio'
import { FormValues } from './types'
import { SchoolSettingFragment } from '../../../graphql/autogenerate/operations'
import { Callout } from '../../callout'

interface AppAccessProps {
  formikProps: FormikContextType<FormValues>
  schoolSetting: SchoolSettingFragment
}

const AppAccess = ({ formikProps: { values }, schoolSetting }: AppAccessProps) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
      <h3>App Access Restrictions</h3>

      <FormikRadio
        field={{
          name: 'appAccessMode',
          label: 'App Access Mode',
          disabled: Boolean(schoolSetting.registrationMode),
        }}
        options={[
          {
            label: 'Require registration',
            value: 'registration',
          },
          {
            label: 'Require passcode',
            value: 'passcode',
          },
          {
            label: 'None',
            value: 'none',
          },
        ]}
      />

      {values.appAccessMode === 'registration' && (
        <Callout type="warning" style={{ maxWidth: 500 }}>
          {!schoolSetting.registrationMode
            ? 'Setting your access mode to registration will require users to sign up for an account before they can access the app.'
            : 'Contact support if you would like to remove the registration requirement from your app.'}
        </Callout>
      )}

      {values.appAccessMode === 'passcode' && (
        <>
          <FormikSwitchField
            field={{
              name: 'parentPasscodeEnabled',
              label: 'Require passcode for Parents',
              disabled: !values.enableParentFamilyAccess,
            }}
            tooltip="Enable to require a passcode when someone tries to join the app as a parent. Note: the passcode is case sensitive."
          />
          {values.parentPasscodeEnabled && (
            <div style={{ display: 'flex', maxWidth: 500 }}>
              <FormikTextInput
                fieldProps={{
                  name: 'parentPasscode',
                  label: 'Passcode',
                  disabled: !values.enableParentFamilyAccess,
                }}
              />
              <FormikTextInput
                fieldProps={{
                  name: 'parentPasscodeHint',
                  label: 'Passcode Hint (optional)',
                  disabled: !values.enableParentFamilyAccess,
                }}
              />
            </div>
          )}

          <FormikSwitchField
            field={{
              name: 'studentPasscodeEnabled',
              label: 'Require passcode for Students',
              disabled: !values.enableStudentAccess,
            }}
            tooltip="Enable to require a passcode when someone tries to join the app as a student. Note: the passcode is case sensitive."
          />
          {values.studentPasscodeEnabled && (
            <div style={{ display: 'flex', maxWidth: 500 }}>
              <FormikTextInput
                fieldProps={{
                  name: 'studentPasscode',
                  label: 'Passcode',
                  disabled: !values.enableStudentAccess,
                }}
              />
              <FormikTextInput
                fieldProps={{
                  name: 'studentPasscodeHint',
                  label: 'Passcode Hint (optional)',
                  disabled: !values.enableStudentAccess,
                }}
              />
            </div>
          )}
        </>
      )}

      {/* {values.appAccessMode === "registration" && (
        <FormikSelectField
          style={{ maxWidth: 500 }}
          fieldProps={{
            name: "registrationMode",
            label: "Registration Approval",
          }}
          getKeyFromOptionValue={(value) => value}
          options={[
            {
              label: "Invitation Only",
              value: RegistrationMode.Invitation,
              key: RegistrationMode.Invitation,
            },
            {
              label: "Approval",
              value: RegistrationMode.Approval,
              key: RegistrationMode.Approval,
            },
            {
              label: "Open",
              value: RegistrationMode.Open,
              key: RegistrationMode.Open,
            },
          ]}
        />
      )} */}
    </div>
  )
}

export default AppAccess
