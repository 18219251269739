import { useTheme } from "@material-ui/core";
import { FormikSwitchField } from "../../forms";

const UserTypes = () => {
  const theme = useTheme();

  return (
    <>
      <h3>Enabled User Types</h3>
      <div style={{ display: "flex", marginBottom: theme.spacing(2) }}>
        <FormikSwitchField
          style={{ marginRight: theme.spacing(3) }}
          field={{
            name: "enableParentFamilyAccess",
            label: "Parent/Family",
          }}
          tooltip="Allow parent/family users to join the app."
        />
        <FormikSwitchField
          style={{ marginRight: theme.spacing(3) }}
          field={{ name: "enableStudentAccess", label: "Student" }}
          tooltip="Allow student users to join the app."
        />
        <FormikSwitchField
          style={{ marginRight: theme.spacing(3) }}
          field={{ name: "enableGuestAccess", label: "Guest" }}
          tooltip="Allow guest users to join the app."
        />
      </div>
    </>
  );
};

export default UserTypes;
