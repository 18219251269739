import { Button, Typography, useTheme } from "@material-ui/core";
import {
  ArrowForward,
  Check,
  CheckCircleOutline,
  HighlightOff,
} from "@material-ui/icons";
import { useEffect, useState } from "react";
import { useUpdateSchoolPersonInstallationMutation } from "../../graphql/autogenerate/react-query";
import { useHandleReactQueryMutation } from "../../hooks";
import {
  INativeBridgeContext,
  SourceVersionNumber,
  SourceVersionTest,
  TestDeviceSourceVersion,
  openAppSettings,
  pollForNotificationIsSubscribed,
  pollIOSForNotDeterminedNotificationPermissionStatus,
  registerForPushNotifications,
  useNativeBridge,
} from "../../hooks/use-native-bridge";
import { useSchoolContext } from "../../stores/school";
import { useSchoolOnboardingContext, useSchoolOnboardingStyles } from ".";

const EnableNotifications = () => {
  const theme = useTheme();
  const onboardingStyles = useSchoolOnboardingStyles();
  const { setSchoolOnboardingState } = useSchoolOnboardingContext();
  const {
    state: { currentUserPermissions },
    userHasOnboarded,
  } = useSchoolContext();
  const schoolPerson = currentUserPermissions.school;
  const schoolPersonInstallation =
    currentUserPermissions.school?.schoolPersonInstallations.nodes[0];
  const notificationsEnabled = schoolPersonInstallation?.pushEnabled;

  const nativeBridge = useNativeBridge();

  const { mutateAsync: updateSchoolPersonInstallation } =
    useHandleReactQueryMutation(useUpdateSchoolPersonInstallationMutation());

  const [registered, setRegistered] = useState<boolean | undefined>();
  const [
    initialNotificationStatusCheckCompleted,
    setInitialNotificationStatusCheckCompleted,
  ] = useState(false);

  // Android requires that we poll for their initial notificaiton status. This has us skip Yes/No page.
  // If this ever gets super annoying, we can always yank this out and do something more like
  // onOSPersmissionChanged in the native code that we call at the end of setOneSignalUserId
  // in the native Android code (something like promptlessOneSignalInit() or something).
  useEffect(() => {
    async function PollForSubStatusAndUpdate(
      schoolPersonInstallationId: string
    ) {
      if (
        nativeBridge !== undefined &&
        nativeBridge.device.sourceVersion !== undefined &&
        TestDeviceSourceVersion(
          SourceVersionNumber["10.0.2"],
          nativeBridge.device.sourceVersion,
          SourceVersionTest.GreaterThanOrEqualTo
        )
      ) {
        let pushStatus = await pollForNotificationIsSubscribed();
        if (pushStatus) {
          await updateSchoolPersonInstallation({
            id: schoolPersonInstallationId,
            patch: {
              oneSignalToken: pushStatus.pushToken,
              oneSignalPlayerId: pushStatus.playerId,
              pushEnabled: pushStatus.pushEnabled,
            },
          });
          setRegistered(pushStatus.pushEnabled);
          setInitialNotificationStatusCheckCompleted(true);
        }
      } else {
        setRegistered(true);
        setInitialNotificationStatusCheckCompleted(true);
      }
    }

    if (!schoolPersonInstallation || initialNotificationStatusCheckCompleted)
      return;

    // I only need to poll and set Push Notification Subscription Status if its an undefined android device.
    const needToPollForSubscriptionStatus =
      registered === undefined && nativeBridge?.device.platform === "android";

    if (needToPollForSubscriptionStatus) {
      PollForSubStatusAndUpdate(schoolPersonInstallation?.id);
    } else {
      setInitialNotificationStatusCheckCompleted(true);
    }
  }, [schoolPerson, schoolPersonInstallation]);

  if (
    !nativeBridge ||
    !schoolPerson ||
    !initialNotificationStatusCheckCompleted
  )
    return null;

  async function nativelyChangePushSubStatus(
    nativeBridge: INativeBridgeContext,
    schoolPersonInstallationId?: string
  ) {
    //ask the device to change its notificaiton sub status depending on the sub status.
    console.log(
      "sourceVersion is 10.0.2+ pollIOSForNotDeterminedNotificationPermissionStatus()"
    );
    if (nativeBridge.device.platform === "ios" && schoolPersonInstallationId) {
      const isUndefinedPoll =
        await pollIOSForNotDeterminedNotificationPermissionStatus();
      if (isUndefinedPoll) {
        if (isUndefinedPoll.ispushStatusIsUndefined) {
          //Prompt
          console.log(
            "iOS push status undefined. Prompting for push notificaitons."
          );
          const registrationResults = await registerForPushNotifications();
          if (registrationResults) {
            await updateSchoolPersonInstallation({
              id: schoolPersonInstallationId,
              patch: {
                oneSignalToken: registrationResults.pushToken,
                oneSignalPlayerId: registrationResults.playerId,
                pushEnabled: registrationResults.pushEnabled,
              },
            });
            setRegistered(registrationResults.pushEnabled);
          } /* TODO MAYBE NIX THIS ELSE? */ else {
            setRegistered(false);
          }
        } else {
          openAppSettings();
        }
      }
    } else {
      //is android
      //Go to app settings
      openAppSettings();
    }
    const completed = true;
    return completed;
  }

  return (
    <div className={onboardingStyles.stepContainer}>
      {!userHasOnboarded && registered === undefined ? (
        //* Yes, please/No, thank you.
        <>
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              minHeight: 0,
              overflowY: "auto",
            }}
          >
            <Typography gutterBottom variant="h4">
              Enable notifications?
            </Typography>
            <Typography gutterBottom>
              You're in full control of what notifications you receive.
            </Typography>
            <Typography gutterBottom>
              Would you like to receive alerts for schoolwide notifications
              along with any additional groups you subscribe to?
            </Typography>
          </div>

          <div style={{ display: "flex" }}>
            <Button
              style={{ height: 125, marginRight: theme.spacing(1), flex: 1 }}
              size="large"
              color="primary"
              variant="contained"
              onClick={async () => {
                if (
                  nativeBridge.device.sourceVersion !== undefined &&
                  TestDeviceSourceVersion(
                    SourceVersionNumber["10.0.2"],
                    nativeBridge.device.sourceVersion,
                    SourceVersionTest.GreaterThanOrEqualTo
                  )
                ) {
                  const completed = nativelyChangePushSubStatus(
                    nativeBridge,
                    schoolPersonInstallation?.id
                  );
                } else if (schoolPersonInstallation) {
                  //otherwise, assume (potentially incorrectly), that going to the app settings is how to change the notificaiton sub.
                  console.log(
                    "sourceVersion IS NOT 10.0.2+ registerForPushNotifications()"
                  );
                  const registrationResults =
                    await registerForPushNotifications();

                  if (registrationResults) {
                    await updateSchoolPersonInstallation({
                      id: schoolPersonInstallation?.id,
                      patch: {
                        oneSignalToken: registrationResults.pushToken,
                        oneSignalPlayerId: registrationResults.playerId,
                        pushEnabled: registrationResults.pushEnabled,
                      },
                    });
                    setRegistered(registrationResults.pushEnabled);
                  } else {
                    setRegistered(false);
                  }
                }
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <CheckCircleOutline
                  style={{
                    marginBottom: theme.spacing(1),
                    height: 30,
                    width: 30,
                  }}
                />
                Yes please
              </div>
            </Button>

            <Button
              style={{
                height: 125,
                marginLeft: theme.spacing(1),
                flex: 1,
                backgroundColor: theme.palette.grey[700],
              }}
              size="large"
              variant="contained"
              onClick={() => {
                setRegistered(false);
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  color: "white",
                }}
              >
                <HighlightOff
                  style={{
                    marginBottom: theme.spacing(1),
                    height: 30,
                    width: 30,
                  }}
                />
                No thank you
              </div>
            </Button>
          </div>
        </>
      ) : //* 🎉 Notifications Enabled
      registered || Boolean(notificationsEnabled) ? (
        <>
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              minHeight: 0,
              overflowY: "auto",
            }}
          >
            <Typography gutterBottom variant="h5">
              🎉 Notifications Enabled{" "}
            </Typography>
            <Typography gutterBottom>
              You're in full control of what notifications you receive.
            </Typography>
            <Typography gutterBottom>
              You can adjust your subscriptions any time by selecting "Manage
              Groups" in the Notifications tab.
            </Typography>
          </div>
          <Button
            style={{ width: "100%" }}
            size="large"
            color="primary"
            variant="contained"
            endIcon={<ArrowForward />}
            onClick={() =>
              setSchoolOnboardingState((_state) => ({
                ..._state,
                step: "summary",
              }))
            }
          >
            Continue
          </Button>
        </>
      ) : (
        //* 🙁 Notifications Disabled
        <>
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              minHeight: 0,
              overflowY: "auto",
            }}
          >
            <Typography gutterBottom variant="h5">
              🙁 Notifications Disabled
            </Typography>
            <Typography gutterBottom>
              With notifications disabled you will not receive an alert for any
              school messages.
            </Typography>
            <Typography gutterBottom>
              Are you sure you want to proceed with notifications disabled?
            </Typography>
          </div>
          <Button
            style={{ width: "100%", marginBottom: theme.spacing(1) }}
            size="large"
            color="primary"
            variant="contained"
            endIcon={<Check />}
            onClick={async () => {
              if (
                nativeBridge.device.sourceVersion !== undefined &&
                TestDeviceSourceVersion(
                  SourceVersionNumber["10.0.2"],
                  nativeBridge.device.sourceVersion,
                  SourceVersionTest.GreaterThanOrEqualTo
                )
              ) {
                //ask the device to change its notificaiton sub status depending on the sub status.
                console.log(
                  "sourceVersion is 10.0.2+ nativelyChangePushSubStatus()"
                );
                const completed = await nativelyChangePushSubStatus(
                  nativeBridge,
                  schoolPersonInstallation?.id
                );
              } else {
                //otherwise, assume (potentially incorrectly), that going to the app settings is how to change the notificaiton sub.
                console.log("sourceVersion IS NOT 10.0.2+ openAppSettings()");
                openAppSettings();
              }
            }}
          >
            Enable Notifications
          </Button>
          <Button
            style={{ width: "100%" }}
            size="large"
            color="primary"
            variant="outlined"
            endIcon={<ArrowForward />}
            onClick={() =>
              setSchoolOnboardingState((_state) => ({
                ..._state,
                step: "summary",
              }))
            }
          >
            Continue without Notifications
          </Button>
        </>
      )}
    </div>
  );
};

export default EnableNotifications;