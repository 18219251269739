import { Button } from '@material-ui/core'
import { ArrowForward } from '@material-ui/icons'
import { FormikProps } from 'formik'
import { Dispatch, SetStateAction } from 'react'
import { FormikTextInput, PasswordField } from '../../forms'
import { FormValues, RegisterStep, State } from './types'

type Props = {
  formik: FormikProps<FormValues>
  setState: Dispatch<SetStateAction<State>>
}

const CreateAccount = ({ formik, setState }: Props) => {
  return (
    <>
      <div style={{ width: '100%', display: 'flex' }}>
        <FormikTextInput
          style={{ marginBottom: 8 }}
          fieldProps={{
            name: 'firstName',
            label: 'First Name',
          }}
          hideHelperTextWhenEmpty
          noMargin
        />
      </div>

      <div style={{ width: '100%', display: 'flex' }}>
        <FormikTextInput
          style={{ marginBottom: 8 }}
          noMargin
          fieldProps={{
            name: 'lastName',
            label: 'Last Name',
          }}
          hideHelperTextWhenEmpty
        />
      </div>

      <div style={{ width: '100%', display: 'flex' }}>
        <FormikTextInput
          style={{ marginBottom: 8 }}
          type="email"
          noMargin
          fieldProps={{
            name: 'email',
            label: 'Email',
          }}
          hideHelperTextWhenEmpty
        />
      </div>

      <div style={{ width: '100%', display: 'flex' }}>
        <PasswordField
          style={{ marginBottom: 8 }}
          fieldProps={{
            name: 'password',
            label: 'Password',
            labelWidth: 70,
          }}
          noMargin
        />
      </div>

      <div style={{ width: '100%', display: 'flex' }}>
        <PasswordField
          style={{ marginBottom: 8 }}
          fieldProps={{
            name: 'confirmPassword',
            label: 'Confirm Password',
            labelWidth: 140,
          }}
          noMargin
        />
      </div>

      <Button
        disabled={!formik.isValid}
        style={{ width: '100%', marginTop: 16 }}
        size="large"
        type="submit"
        color="primary"
        variant="contained"
        endIcon={<ArrowForward />}
      >
        Continue
      </Button>

      <Button
        onClick={() => {
          setState(prev => ({ ...prev, step: RegisterStep.SignIn }))
        }}
        type="button"
        variant="text"
        color="primary"
        disableElevation
        style={{ width: '100%', marginTop: 8 }}
      >
        Sign In
      </Button>
    </>
  )
}

export default CreateAccount
