import { createStyles, makeStyles, useTheme } from '@material-ui/core'
import { createContext, Dispatch, SetStateAction, useContext, useState } from 'react'
import {
  GroupListFieldsFragment,
  SchoolDivisionsFragment,
} from '../../graphql/autogenerate/operations'
import { UserType } from '../../graphql/autogenerate/schemas'
import { gatherAllGroupsForSchoolFragment } from '../../helpers'
import { useSchoolContext } from '../../stores/school'
import AccessRevoked from './access-revoked'
import Divisions from './divisions'
import FacultyStaffAuth from './faculty-staff-auth'
import EnableNotifications from './notifications'
import Register from './register'
import Summary from './summary'
import UserTypeSelection from './user-type-selection'
import Welcome from './welcome'
import { useNavigate } from 'react-router-dom'

export const useSchoolOnboardingStyles = makeStyles(theme =>
  createStyles({
    stepContainer: {
      backgroundColor: 'white',
      borderTop: `${theme.spacing(1)}px solid ${theme.palette.primary.main}`,
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      minHeight: 0,
      overflowY: 'hidden',
      padding: `${theme.spacing(5)}px ${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(
        1
      )}px`,
      width: '100%',
    },
  })
)

type OnboardingStep =
  | 'welcome'
  | 'register'
  | 'user-type'
  | 'faculty-staff-auth'
  | 'divisions'
  | 'enable-notifications'
  | 'summary'

interface ISchoolOnboardingState {
  step: OnboardingStep
  userTypes?: UserType[]
  selectedDivisions?: SchoolDivisionsFragment[]
  selectedGroups?: GroupListFieldsFragment[]
  notificationsEnabled?: boolean
  onClose?: () => void
}

interface ISchoolOnboardingContext extends ISchoolOnboardingState {
  setSchoolOnboardingState: Dispatch<SetStateAction<ISchoolOnboardingState>>
}

const SchoolOnboardingContext = createContext<ISchoolOnboardingContext | undefined>(undefined)

export const useSchoolOnboardingContext = () => {
  const context = useContext(SchoolOnboardingContext)
  if (!context) throw new Error('Attempted to use SchoolOnboardingContext before it was provided.')
  return context
}

type Props = {
  initialStep?: OnboardingStep
  onClose?: () => void
}

const SchoolOnboarding = ({ initialStep, onClose }: Props) => {
  const theme = useTheme()
  const navigate = useNavigate()

  const {
    state: { currentUserPermissions, school },
  } = useSchoolContext()

  const { divisions } = school

  const schoolwideDivision = divisions.nodes.find(o => o.schoolwide)
  if (!schoolwideDivision) throw new Error('Missing schoolwide division.') // Check to make Typescript happy...there's no way this can happen (prevented at the DB level)

  const [schoolOnboardingState, setSchoolOnboardingState] = useState<ISchoolOnboardingState>(
    (() => {
      const initialState: ISchoolOnboardingState = {
        step: initialStep || 'welcome',
        onClose,
        // Always include the schoolwide division in the user's subscription selections.
        selectedDivisions: [schoolwideDivision],
      }

      // If the user's onboarding is being reset, return the default initial state without preserving previous selections
      if (!currentUserPermissions.school?.completedOnboarding) return initialState

      const allGroups = gatherAllGroupsForSchoolFragment(school)
      const previouslySelectedGroups = currentUserPermissions.groups.nodes.filter(o => o.member)

      initialState.selectedDivisions = [
        schoolwideDivision,
        ...divisions.nodes.filter(
          o => !o.schoolwide && previouslySelectedGroups.find(pg => pg.groupId === o.group?.id)
        ),
      ]

      initialState.selectedGroups = allGroups.filter(
        o =>
          !o.isDivisionGroup &&
          !o.isSchoolwideGroup &&
          previouslySelectedGroups.find(pg => pg.groupId === o.id)
      )

      if (currentUserPermissions.school?.userTypes) {
        initialState.userTypes = currentUserPermissions.school.userTypes as UserType[]
      }

      return initialState
    })()
  )
  const { step } = schoolOnboardingState

  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: theme.palette.primary.main,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        zIndex: 10,
      }}
    >
      <SchoolOnboardingContext.Provider
        value={{ ...schoolOnboardingState, setSchoolOnboardingState }}
      >
        {currentUserPermissions.school?.accessRevoked ? (
          <AccessRevoked />
        ) : (
          <>
            {step === 'welcome' && <Welcome />}
            {step === 'register' && (
              <Register
                afterSubmit={() => {
                  if (initialStep === 'register') {
                    window.location.reload()
                  } else {
                    setSchoolOnboardingState(_state => ({
                      ..._state,
                      step: 'user-type',
                    }))
                  }
                }}
              />
            )}
            {step === 'user-type' && <UserTypeSelection />}
            {step === 'faculty-staff-auth' && <FacultyStaffAuth />}
            {step === 'divisions' && <Divisions />}
            {step === 'enable-notifications' && <EnableNotifications />}
            {step === 'summary' && <Summary />}
          </>
        )}
      </SchoolOnboardingContext.Provider>
    </div>
  )
}

export default SchoolOnboarding
