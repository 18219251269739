import { IconType } from '../../graphql/autogenerate/schemas'

export type GroupOption = {
  id: string
  title: string
  divisionName?: string
  iconType: IconType
  color: string
  isDivision?: boolean
  isSchoolwide?: boolean
  divisionId?: string
  divisionGroupId?: string
}

export type GroupSelectorState = {
  selectionsAutomaticallyRemoved?: {
    schoolwide?: boolean
    divisions?: {
      id: string
      name: string
    }[]
  }
}

export type InfoOption = {
  status: 'no-input' | 'no-matches' | 'matches' | 'schoolwide-selected' | 'divisions-selected'
  selectedDivisionNames?: string[]
  openSidebarWhenSelected?: boolean
}

export type GroupOptionType = GroupOption | InfoOption

export const isInfoOption = (option?: GroupOption | InfoOption): option is InfoOption =>
  (option as InfoOption)?.status !== undefined
