import { useMemo } from 'react'
import { GroupOption, GroupOptionType, InfoOption, isInfoOption } from './types'
import { GroupSelectorMode } from '.'

type Options = {
  groupOptionsRaw: GroupOptionType[]
  selectedGroupIds: string[]
  mode?: GroupSelectorMode
}

/** 
     Performs validations on the group selections to assemble the final list of group options to present the user. 
    Adds InfoOptions to the list for use in displaying information to the user about available options.

    - If Schoolwide is selected then show no options.
    - If any Divisions are selected then filter out 
*/
const useAvailableOptions = ({ groupOptionsRaw, selectedGroupIds, mode }: Options) =>
  useMemo<GroupOptionType[]>(() => {
    let options: GroupOptionType[] = groupOptionsRaw

    /* 
      If we're running in "tiered" mode we need to check if schoolwide or any divisions are selected
      and clear out any groups within schoolwide or divisions if so.
    */
    if (mode === GroupSelectorMode.tiered) {
      /*  
        First and foremost, we need to check validation states...

        - Is schoolwide selected?
      - Which divisions are selected?
    */
      const schoolwideOptionSelected = options.find(
        o => !isInfoOption(o) && o.isSchoolwide && selectedGroupIds.includes(o.id)
      )
      if (schoolwideOptionSelected) {
        // If schoolwide is selected we don't want to offer any more selections.
        const schoolwideSelected: InfoOption = { status: 'schoolwide-selected' }
        return [schoolwideSelected]
      }

      const divisionOptionsSelected = options.filter(
        o => !isInfoOption(o) && o.isDivision && selectedGroupIds.includes(o.id)
      )
      if (divisionOptionsSelected.length > 0) {
        const selectedDivisionIds = (divisionOptionsSelected as GroupOption[]).map(
          o => o.divisionId
        )

        // For any selected divisions, remove groups for those divisions from the option set.
        options = options.filter(
          o => isInfoOption(o) || !o.divisionId || !selectedDivisionIds.includes(o.divisionId)
        )

        // Add a "final option" that lists which divisions groups have been removed for.
        const selectedDivisions: InfoOption = {
          status: 'divisions-selected',
          selectedDivisionNames: (divisionOptionsSelected as GroupOption[]).map(o => {
            if (o.divisionName) return o.divisionName
            throw new Error(
              'Encountered a group option marked as "isDivision" but without a value for the divisionName.'
            )
          }),
        }
        options.push(selectedDivisions)
      }
    }

    return options
  }, [groupOptionsRaw, selectedGroupIds])

export default useAvailableOptions
