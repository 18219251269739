import { useMemo } from 'react'
import {
  CurrentUserFragment,
  GroupListFieldsFragment,
  SchoolDivisionsFragment,
} from '../../graphql/autogenerate/operations'
import { ICurrentUserPermssions } from '../../stores/school'

type GroupOption = GroupListFieldsFragment & {
  divisionGroupId?: string
}

type Options = {
  divisions: SchoolDivisionsFragment[]
  currentUser?: CurrentUserFragment | null
  currentUserPermissions: ICurrentUserPermssions
}

// What groups is this user allowed to manage?
const useDisplayGroups = ({ divisions, currentUser, currentUserPermissions }: Options) =>
  useMemo(() => {
    const groups: GroupOption[] = []

    divisions.forEach(division => {
      if (division.group) groups.push(division.group)
      division.groupCategories.nodes.forEach(category =>
        groups.push(
          ...category.groups.nodes.map(o => ({
            ...o,
            divisionGroupId: division.group?.id,
          }))
        )
      )
    })

    if (currentUserPermissions.schoolwideAdmin || currentUser?.appAdministrator) {
      return groups
    } else {
      return groups.filter(group =>
        currentUserPermissions.groups.nodes.some(
          o => (o.groupId === group.id || o.groupId === group.divisionGroupId) && o.admin
        )
      )
    }
  }, [divisions, currentUser, divisions, currentUserPermissions])

export default useDisplayGroups
