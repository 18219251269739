import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Button,
  Divider,
  Typography,
  useTheme,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import { Form, Formik } from "formik";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useUpdateLaunchScreenSchoolSettingsMutation } from "../../graphql/autogenerate/react-query";
import { useHandleReactQueryMutation } from "../../hooks";
import { useSchoolContext } from "../../stores/school";
import {
  FileFieldValue,
  FormikImageField,
  FormikSwitchField,
  FormikTextInput,
  useImageFieldChangeHandler,
} from "../forms";

export const LaunchScreenSettings = () => {
  const {
    state: {
      school: { schoolSetting, id: schoolId },
    },
    actions: {
      refetch: { schoolDetail: refetchSchoolDetails },
    },
  } = useSchoolContext();

  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [isOpen, setIsOpen] = useState(false);
  const fileChangeHandler = useImageFieldChangeHandler();

  const { mutate: updateLaunchScreenSettings } = useHandleReactQueryMutation(
    useUpdateLaunchScreenSchoolSettingsMutation({
      onSuccess: () => {
        enqueueSnackbar("Launch screen settings updated", {
          variant: "success",
        });
        refetchSchoolDetails();
      },
    })
  );

  if (!schoolSetting) return null;

  return (
    <Formik
      initialValues={{
        launchScreenEnabled: Boolean(schoolSetting.launchScreenEnabled),
        launchScreenPoweredByLabel:
          schoolSetting.launchScreenPoweredByLabel || "",
        launchScreenPoweredByImage:
          schoolSetting.launchScreenPoweredByImage as unknown as FileFieldValue,
        logoImage: schoolSetting.logoImage as unknown as FileFieldValue,
        schoolImage: schoolSetting.schoolImage as unknown as FileFieldValue,
      }}
      onSubmit={async ({
        launchScreenPoweredByImage,
        logoImage,
        schoolImage,
        ...values
      }) => {
        updateLaunchScreenSettings({
          ...values,
          schoolId,
          ...(await fileChangeHandler({
            oldValue: schoolSetting.launchScreenPoweredByImage,
            newValue: launchScreenPoweredByImage,
            filePropertyName: "launchScreenPoweredByImage",
          })),
          ...(await fileChangeHandler({
            oldValue: schoolSetting.logoImage,
            newValue: logoImage,
            filePropertyName: "logoImage",
          })),
          ...(await fileChangeHandler({
            oldValue: schoolSetting.schoolImage,
            newValue: schoolImage,
            filePropertyName: "schoolImage",
          })),
        });
      }}
    >
      {(formikProps) => (
        <Accordion
          expanded={isOpen}
          onChange={(_, expanded) => setIsOpen(expanded)}
        >
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography variant="h5">🚀 Launch Screen</Typography>
          </AccordionSummary>
          <AccordionDetails
            style={{ padding: `${theme.spacing(1)}px ${theme.spacing(2)}px` }}
          >
            <Form style={{ flex: 1, minWidth: 0 }}>
              <FormikSwitchField
                style={{ marginBottom: theme.spacing(2) }}
                field={{ name: "launchScreenEnabled", label: "Enabled" }}
              />
              {formikProps.values.launchScreenEnabled && (
                <div>
                  <FormikTextInput
                    fieldProps={{
                      name: "launchScreenPoweredByLabel",
                      label: '"Powered by" label',
                    }}
                  />
                  <FormikImageField
                    style={{ margin: theme.spacing(0.5), flex: 1 }}
                    aspectRatioHelper="Recommended dimensions: 500px by 500px or 900px by 300px"
                    field={{
                      name: "launchScreenPoweredByImage",
                      label: '"Powered by" Image',
                    }}
                  />
                </div>
              )}

              <div style={{ display: "flex" }}>
                <FormikImageField
                  style={{
                    flex: 1,
                    marginRight: theme.spacing(1),
                    marginLeft: theme.spacing(1),
                  }}
                  field={{ name: "logoImage", label: "Logo" }}
                  aspectRatio={1}
                  aspectRatioHelper="1:1 aspect ratio (e.g. 500px by 500px)"
                />
                <FormikImageField
                  style={{ flex: 1, marginLeft: theme.spacing(1) }}
                  field={{ name: "schoolImage", label: "School" }}
                  imageCropLimitToImage={false}
                  aspectRatioHelper="Recommended dimensions: 3000px by 1000px"
                />
              </div>
            </Form>
          </AccordionDetails>
          <Divider />
          <AccordionActions>
            <Button size="small" onClick={() => setIsOpen(false)}>
              Cancel
            </Button>
            <Button
              onClick={formikProps.submitForm}
              type="submit"
              size="small"
              color="primary"
            >
              Save
            </Button>
          </AccordionActions>
        </Accordion>
      )}
    </Formik>
  );
};
