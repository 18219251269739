import { Button, Typography } from '@material-ui/core'
import { ArrowBack } from '@material-ui/icons'
import { FormikProps } from 'formik'
import { useSchoolOnboardingContext } from '../..'
import { UserType } from '../../../../graphql/autogenerate/schemas'
import { useSchoolContext } from '../../../../stores/school'
import { FormValues, State } from '../types'

type Props = {
  formik: FormikProps<FormValues>
  state: State
}

const NotAdded = ({ formik: { setFieldValue }, state }: Props) => {
  const {
    state: {
      school: { name: schoolName, schoolSetting },
    },
  } = useSchoolContext()

  const { setSchoolOnboardingState } = useSchoolOnboardingContext()

  if (!schoolSetting) throw new Error('School setting not found')

  return (
    <>
      <Typography color="textSecondary" gutterBottom>
        This email address ({state.email}) is not listed in the {schoolName} Faculty/Staff list.
      </Typography>

      <Typography color="textSecondary">
        Please contact your administrator to request to be added, or to confirm which email address
        you should use to join the app.
      </Typography>

      <Button
        style={{ width: '100%', marginTop: 16 }}
        size="large"
        type="button"
        color="primary"
        variant="outlined"
        startIcon={<ArrowBack />}
        onClick={() => {
          setFieldValue('email', '')

          setSchoolOnboardingState(_state => ({
            ..._state,
            step: 'welcome',
            userTypes: _state.userTypes?.filter(o => o !== UserType.FacultyStaff),
          }))
        }}
      >
        Back
      </Button>
    </>
  )
}
export default NotAdded
