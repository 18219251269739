import { Button, Paper, Typography, useTheme } from '@material-ui/core'
import { useSchoolOnboardingContext } from '.'
import { useSchoolContext } from '../../stores/school'
import { ImageDisplay } from '../image-display'
import { useIsAnonymous } from '../../hooks'

const Welcome = () => {
  const theme = useTheme()

  const {
    state: { school },
  } = useSchoolContext()
  const { schoolSetting } = school

  const { setSchoolOnboardingState } = useSchoolOnboardingContext()

  const isAnonymous = useIsAnonymous()

  if (!schoolSetting) throw new Error('Missing school setting.')

  if (isAnonymous === undefined) return null

  return (
    <Paper style={{ padding: theme.spacing(2), margin: theme.spacing(2) }}>
      {schoolSetting.thumbnailImage?.temporaryDownloadUrl && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: theme.spacing(2),
          }}
        >
          <ImageDisplay
            style={{
              height: 75,
              width: 75,
              borderRadius: theme.shape.borderRadius,
            }}
            src={schoolSetting.thumbnailImage.temporaryDownloadUrl}
          />
        </div>
      )}

      <Typography variant="h5" style={{ marginBottom: theme.spacing(2) }} align="center">
        Welcome to the <b>{school.name}</b> app!
      </Typography>

      <Typography style={{ marginBottom: theme.spacing(2) }} align="center">
        Customize your app experience and receive the most up to date calendars, resources,
        notifications, and newsletters tailored for you.
      </Typography>

      <Button
        onClick={() =>
          setSchoolOnboardingState(_state => ({
            ..._state,
            step: schoolSetting.registrationMode && isAnonymous ? 'register' : 'user-type',
          }))
        }
        size="large"
        variant="contained"
        color="secondary"
        style={{ width: '100%' }}
      >
        Get Started
      </Button>
    </Paper>
  )
}

export default Welcome
