import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Button,
  Divider,
  Typography,
  useTheme,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import { Form, Formik } from "formik";
import { useSnackbar } from "notistack";
import { useState } from "react";
import * as yup from "yup";
import {
  useToggleSchoolArchivedMutation,
  useUpdateGeneralSchoolSettingsMutation,
} from "../../graphql/autogenerate/react-query";
import { useHandleReactQueryMutation } from "../../hooks";
import { useSchoolContext } from "../../stores/school";
import { FormikSwitchField, FormikTextInput } from "../forms";

export const GeneralSettings = () => {
  const {
    state: {
      school: { schoolSetting, id: schoolId, archived },
    },
    actions: {
      refetch: { schoolDetail: refetchSchoolDetails },
    },
  } = useSchoolContext();

  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [isOpen, setIsOpen] = useState(false);

  const { mutate: updateGeneralSettings } = useHandleReactQueryMutation(
    useUpdateGeneralSchoolSettingsMutation({
      onSuccess: () => {
        enqueueSnackbar("General settings updated", { variant: "success" });
        refetchSchoolDetails();
      },
    })
  );

  const { mutate: toggleSchoolArchived } = useHandleReactQueryMutation(
    useToggleSchoolArchivedMutation()
  );

  if (!schoolSetting) return null;

  return (
    <Formik
      initialValues={{
        downloadUrl: schoolSetting.downloadUrl || "",
        feedbackUrl: schoolSetting.feedbackUrl || "",
        googleAnalyticsId: schoolSetting.googleAnalyticsId || "",
        archived,
      }}
      validationSchema={yup.object({
        downloadUrl: yup.string().url("Must be a valid URL"),
        feedbackUrl: yup.string().url("Must be a valid URL"),
      })}
      onSubmit={(values) => {
        updateGeneralSettings({ ...values, schoolId });
        toggleSchoolArchived({ ...values, schoolId });
      }}
    >
      {(formikProps) => (
        <Accordion
          expanded={isOpen}
          onChange={(_, expanded) => setIsOpen(expanded)}
        >
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography variant="h5">⚙ General</Typography>
          </AccordionSummary>
          <AccordionDetails
            style={{
              padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
            }}
          >
            <Form style={{ flex: 1 }}>
              <div style={{ display: "flex" }}>
                <FormikTextInput
                  fieldProps={{
                    name: "downloadUrl",
                    label: "Download URL",
                  }}
                />
                <FormikTextInput
                  fieldProps={{
                    name: "feedbackUrl",
                    label: "Feedback URL",
                  }}
                />
              </div>
              <FormikTextInput
                fieldProps={{
                  name: "googleAnalyticsId",
                  label: "Google Analytics ID",
                }}
              />
              <FormikSwitchField
                field={{ name: "archived", label: "Archived" }}
              />
            </Form>
          </AccordionDetails>
          <Divider />
          <AccordionActions>
            <Button size="small" onClick={() => setIsOpen(false)}>
              Cancel
            </Button>
            <Button
              onClick={formikProps.submitForm}
              type="submit"
              size="small"
              color="primary"
            >
              Save
            </Button>
          </AccordionActions>
        </Accordion>
      )}
    </Formik>
  );
};
