import { xor } from 'lodash'
import { useCallback, useEffect, useRef, useState } from 'react'
import { joinStrings } from '../../helpers/array-helpers'
import { ICalloutClosable, ICalloutProps, ICalloutWhy } from '../callout'
import { GroupSelectorState } from './types'

type Options = {
  groupSelectorState: GroupSelectorState
}

type CalloutState = {
  schoolwideCallout: Pick<ICalloutClosable, 'isOpen'>
  divisionCallout: Pick<ICalloutProps, 'children'> &
    Pick<ICalloutClosable, 'isOpen'> &
    Pick<ICalloutWhy, 'tooltip'>
}

export type CalloutControls = {
  calloutsState: CalloutState
  closeSchoolwideCallout: () => void
  closeDivisionCallout: () => void
}

const useCalloutControls = ({
  groupSelectorState: { selectionsAutomaticallyRemoved },
}: Options): CalloutControls => {
  const [calloutsState, setCalloutsState] = useState<CalloutState>({
    schoolwideCallout: {
      isOpen: false,
    },
    divisionCallout: {
      isOpen: false,
      tooltip: '',
      children: '',
    },
  })

  const closeSchoolwideCallout = useCallback(() => {
    setCalloutsState(_state => ({
      ..._state,
      schoolwideCallout: {
        isOpen: false,
      },
    }))
  }, [])

  const closeDivisionCallout = useCallback(() => {
    setCalloutsState(_state => ({
      ..._state,
      divisionCallout: {
        ..._state.divisionCallout,
        isOpen: false,
      },
    }))
  }, [])

  const prevSelectionsAutomaticallyRemoved = useRef(selectionsAutomaticallyRemoved)
  useEffect(() => {
    // We only need to show the alerts if previous selections were automatically removed for the first time (i.e. we don't reshow the callout if we aren't moving from )

    // Toggle on the Schoolwide callout if it was just added for the first time (we don't want to reopen the callout on every subsequent change if the user has dismissed it)
    if (
      !prevSelectionsAutomaticallyRemoved.current?.schoolwide &&
      selectionsAutomaticallyRemoved?.schoolwide
    ) {
      setCalloutsState(_state => ({
        schoolwideCallout: {
          isOpen: true,
        },
        divisionCallout: {
          ..._state.divisionCallout,
          isOpen: false,
        },
      }))
    } else if (
      /* 
        Toggling the Divisions callout takes a bit more checking:
        - Toggle on if a Division was selected for the first time
        - Toggle on if a different mixture of Divisions were selected
      */
      selectionsAutomaticallyRemoved?.divisions &&
      xor(
        prevSelectionsAutomaticallyRemoved.current?.divisions?.map(o => o.id) || [],
        selectionsAutomaticallyRemoved.divisions.map(o => o.id)
      ).length > 0
    ) {
      const divisionNames = joinStrings(selectionsAutomaticallyRemoved.divisions.map(o => o.name))
      setCalloutsState(_state => ({
        schoolwideCallout: {
          isOpen: false,
        },
        divisionCallout: {
          ..._state.divisionCallout,
          isOpen: true,
          children: `${divisionNames} selected. All group selections from ${divisionNames} cleared.`,
          tooltip: `There is no need to include any more groups from ${divisionNames} since content available to ${divisionNames} will be visible to users in all groups within ${divisionNames}.`,
        },
      }))
    }

    // If Schoolwide isn't selected and no Divisions were selected, hide all callouts
    // ! Not sure if this is good...we're automatically hiding these callouts but maybe they should just persist until the user hides them...disabling for now.
    // else if (!selectionsAutomaticallyRemoved || (!selectionsAutomaticallyRemoved.schoolwide && !selectionsAutomaticallyRemoved.divisions)) {
    //     setCalloutsState(_state => ({ ..._state, schoolwideCalloutOpen: false, divisionCallout: { ..._state.divisionCallout, isOpen: false } }))
    // }

    prevSelectionsAutomaticallyRemoved.current = selectionsAutomaticallyRemoved
  }, [selectionsAutomaticallyRemoved])

  return {
    calloutsState,
    closeSchoolwideCallout,
    closeDivisionCallout,
  }
}

export default useCalloutControls
