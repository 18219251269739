import { Dispatch, SetStateAction, useCallback } from 'react'
import { GroupSelectorMode } from '.'
import { GroupOption, GroupSelectorState } from './types'

type Options = {
  setSelectedGroupIds: (selectedGroupIds: string[]) => void
  setGroupSelectorState: Dispatch<SetStateAction<GroupSelectorState>>
  mode?: GroupSelectorMode
}

const useHandleSelectedGroupsChange = ({
  setSelectedGroupIds,
  setGroupSelectorState,
  mode,
}: Options) =>
  useCallback((selectedOptions: GroupOption[]) => {
    /* 
      Validation checks:

      If we're running in "tiered" mode we need to check if schoolwide or any divisions are selected
      and clear out any groups within those selections if so.

      - Is Schoolwide selected?
      If so, check if there were any other selections.
      If there were, don't include them, just set Schoolwide, and show the Schoolwide callout to notify the user what just happened.

      - Are any Divisions selected?
      If so, check if there were any other selections.
      If there were, gather the selections for any selected Divisions.
      If there were any group selections from the selected Divisions, don't inlcude them and show the Divisions callout to notify the user.
    */

    if (mode === GroupSelectorMode.tiered) {
      const schoolwideSelected = selectedOptions.find(o => o.isSchoolwide)
      if (schoolwideSelected) {
        setSelectedGroupIds([schoolwideSelected.id])
        setGroupSelectorState(_state => ({
          ..._state,
          selectionsAutomaticallyRemoved: {
            schoolwide: selectedOptions.length > 1,
          },
        }))
        return
      }

      const divisionsSelected = selectedOptions.filter(o => o.isDivision)
      if (divisionsSelected.length > 0) {
        // Check to see if any of the other selections were from these divisions
        const selectedDivisionIds = divisionsSelected.map(o => o.divisionId)
        const groupSelectionIdsFromSelectedDivisions = selectedOptions
          .filter(
            o =>
              !o.isDivision &&
              !o.isSchoolwide &&
              o.divisionId &&
              selectedDivisionIds.includes(o.divisionId)
          )
          .map(o => o.id)

        // Set the selection to only schoolwide, divisions, and groups not included in the selected divisions
        setSelectedGroupIds(
          selectedOptions
            .filter(o => !groupSelectionIdsFromSelectedDivisions.includes(o.id))
            .map(o => o.id)
        )
        setGroupSelectorState(_state => ({
          ..._state,
          selectionsAutomaticallyRemoved: {
            divisions:
              groupSelectionIdsFromSelectedDivisions.length > 0
                ? divisionsSelected.map(o => {
                    if (o.divisionName) return { id: o.id, name: o.divisionName }
                    throw new Error(
                      'Encountered a group option marked as "isDivision" but without a value for the divisionName.'
                    )
                  })
                : undefined,
          },
        }))

        return
      }
    }

    setSelectedGroupIds(selectedOptions.map(o => o.id))
    setGroupSelectorState(_state => ({
      ..._state,
      schoolwideSelected: undefined,
      divisionsSelected: undefined,
      selectionsAutomaticallyRemoved: undefined,
    }))
  }, [])

export default useHandleSelectedGroupsChange
