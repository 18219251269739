import { LockOutlined } from "@material-ui/icons";
import { useTheme } from "@material-ui/core";
import { Callout } from "../../callout";

interface WarningsProps {
  values: {
    enableParentFamilyAccess: boolean;
    enableStudentAccess: boolean;
    enableGuestAccess: boolean;
    parentPasscodeEnabled: boolean;
    studentPasscodeEnabled: boolean;
  };
}

const Warnings = ({ values }: WarningsProps) => {
  const theme = useTheme();

  const allUsersDisabled =
    !values.enableParentFamilyAccess &&
    !values.enableStudentAccess &&
    !values.enableGuestAccess;

  if (allUsersDisabled) {
    return (
      <Callout
        type="error"
        style={{ display: "flex", marginBottom: theme.spacing(1) }}
      >
        WARNING: you have disabled all user types. Only Faculty/Staff users will
        be able to access your app.
      </Callout>
    );
  }

  const passcodeRequired =
    !values.enableGuestAccess &&
    (!values.enableParentFamilyAccess || values.parentPasscodeEnabled) &&
    (!values.enableStudentAccess || values.studentPasscodeEnabled);

  if (passcodeRequired) {
    return (
      <Callout
        type="warning"
        style={{ marginBottom: theme.spacing(2), display: "flex" }}
      >
        <LockOutlined style={{ marginRight: theme.spacing(1) }} /> With your
        current settings no user will be able to join the app without a
        passcode.
      </Callout>
    );
  }

  return null;
};

export default Warnings;