import { Dispatch, SetStateAction } from 'react'
import { APPLE_REVIEWER_EMAIL } from '../../../constants'
import {
  useCheckEmailForFacultyStaffJoinSchoolQuery,
  useInvitePersonToSchoolMutation,
} from '../../../graphql/autogenerate/react-query'
import { useHandleReactQuery, useHandleReactQueryMutation } from '../../../hooks'
import { State } from './types'
import { ICurrentUserPermssions } from '../../../stores/school'
import { CurrentUserQuery } from '../../../graphql/autogenerate/operations'

type Options = {
  state: State
  setState: Dispatch<SetStateAction<State>>
  schoolId: string
  currentUserPermissions: ICurrentUserPermssions
  currentUser?: CurrentUserQuery
}

const useVerifyEmail = ({ state: { step, email }, setState, schoolId }: Options) => {
  const { mutate: invitePersonToSchool } = useHandleReactQueryMutation(
    useInvitePersonToSchoolMutation({
      onSuccess: () => {
        setState(_state => ({ ..._state, step: 'invitation-not-accepted' }))
      },
    })
  )

  return useHandleReactQuery(
    useCheckEmailForFacultyStaffJoinSchoolQuery(
      { schoolId, email: email! },
      {
        enabled: Boolean(email && email.length) && (!step || step === 'check-account'),
        onSuccess: ({ passwordResetRequired, checkPersonEmailForSchool }) => {
          // We allow Apple reviewers to bypass the auth
          if (email === APPLE_REVIEWER_EMAIL) {
            setState(_state => ({ ..._state, step: 'sign-in' }))
          } else {
            const schoolPerson = checkPersonEmailForSchool?.schoolPeople.nodes[0]

            // This person is in the Faculty/Staff list for this school
            if (schoolPerson) {
              // They have an accepted invitation, let them do password auth
              if (schoolPerson?.schoolPersonInvitation?.accepted) {
                if (passwordResetRequired) {
                  setState(_state => ({
                    ..._state,
                    step: 'password-reset-required',
                  }))
                } else {
                  setState(_state => ({ ..._state, step: 'sign-in' }))
                }
              } else if (schoolPerson.schoolPersonInvitation) {
                // They have an invitation they just haven't accepted it
                setState(_state => ({
                  ..._state,
                  step: 'invitation-not-accepted',
                  schoolPersonId: schoolPerson.id,
                }))
              } else {
                // They don't have an invitation, but they are authorized to join the school. Let them just send their own invite
                setState(_state => ({
                  ..._state,
                  schoolPersonId: schoolPerson.id,
                }))
                invitePersonToSchool({ schoolPersonId: schoolPerson.id })
              }
            } else {
              // This Person is not yet listed in the Faculty/Staff list for the school. Show a message suggesting they request to be added.
              setState(_state => ({ ..._state, step: 'not-added' }))
            }
          }
        },
        refetchOnMount: false,
        refetchOnWindowFocus: true,
        refetchOnReconnect: true,
      }
    )
  )
}

export default useVerifyEmail
