import { Paper, Typography, useTheme } from '@material-ui/core'
import { Form, Formik } from 'formik'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import {
  useCurrentUserQuery,
  useSendSchoolPersonInvitationEmailMutation,
} from '../../../graphql/autogenerate/react-query'
import { useHandleReactQueryMutation, useIsAnonymous } from '../../../hooks'
import { useSchoolContext } from '../../../stores/school'
import { ImageDisplay } from '../../image-display'
import getValidationSchema from './get-validation-schema'
import RestartButton from '../restart-button'
import {
  InvitationNotAccepted,
  NotAdded,
  PasswordResetRequired,
  SignIn,
  VerifyEmail,
} from './steps'
import { defaultState, FormValues, State } from './types'
import useOnSubmit from './use-on-submit'
import useVerifyEmail from './use-verify-email'

const FacultyStaffAuth = () => {
  const theme = useTheme()

  const {
    state: {
      currentUserPermissions,
      school: { id: schoolId, schoolSetting, name },
    },
  } = useSchoolContext()

  const { data: currentUser } = useCurrentUserQuery()
  const isAnonymous = useIsAnonymous()

  useEffect(() => {
    const email = currentUser?.currentUser?.email

    if (isAnonymous !== undefined) {
      if (!isAnonymous && email) {
        setState(_state => ({
          ..._state,
          email,
        }))
      } else {
        setState(_state => ({
          ..._state,
          step: 'check-account',
        }))
      }
    }
  }, [isAnonymous, currentUser])

  const { enqueueSnackbar } = useSnackbar()

  const [state, setState] = useState<State>(defaultState)
  const { step } = state

  const initialValues: FormValues = {
    email: '',
    password: '',
  }

  const onSubmit = useOnSubmit({ state, setState })

  const { refetch: reCheckEmailForFacultyStaffJoinSchoolQuery } = useVerifyEmail({
    state,
    setState,
    schoolId,
    currentUserPermissions,
    currentUser,
  })

  const { mutate: resendInvitation } = useHandleReactQueryMutation(
    useSendSchoolPersonInvitationEmailMutation({
      onSuccess: () => {
        enqueueSnackbar('Invitation email sent.', { variant: 'success' })
      },
    })
  )

  const handleResendInvitation = () =>
    state.schoolPersonId &&
    resendInvitation({
      schoolPersonInvitationId: state.schoolPersonId,
    })

  const handleRecheck = async () => {
    const results = await reCheckEmailForFacultyStaffJoinSchoolQuery()
    if (
      !Boolean(
        results.data?.checkPersonEmailForSchool?.schoolPeople.nodes[0]?.schoolPersonInvitation
          ?.accepted
      )
    ) {
      enqueueSnackbar(
        `Invitation not yet accepted - please accept the invitation sent to ${state.email}.`,
        { variant: 'error' }
      )
    }
  }

  if (!schoolSetting || !step) return null

  return (
    <Paper style={{ padding: theme.spacing(2), margin: theme.spacing(2), position: 'relative' }}>
      <RestartButton />

      {schoolSetting.thumbnailImage?.temporaryDownloadUrl && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: theme.spacing(2),
          }}
        >
          <ImageDisplay
            style={{
              height: 75,
              width: 75,
              borderRadius: theme.shape.borderRadius,
            }}
            src={schoolSetting.thumbnailImage.temporaryDownloadUrl}
          />
        </div>
      )}

      <Typography style={{ marginBottom: theme.spacing(2) }} align="center">
        You must sign in as an authorized user to access the <b>{name}</b> app as a faculty/staff
        member.
      </Typography>

      <Formik
        initialValues={initialValues}
        validationSchema={getValidationSchema(step)}
        onSubmit={onSubmit}
      >
        {formikProps => (
          <Form>
            {step === 'check-account' && <VerifyEmail formik={formikProps} />}

            {step === 'not-added' && <NotAdded formik={formikProps} state={state} />}

            {step === 'password-reset-required' && <PasswordResetRequired formik={formikProps} />}

            {step === 'sign-in' && <SignIn formik={formikProps} />}

            {step === 'invitation-not-accepted' && (
              <InvitationNotAccepted
                state={state}
                formik={formikProps}
                onResendInvitation={handleResendInvitation}
                onRecheck={handleRecheck}
              />
            )}
          </Form>
        )}
      </Formik>
    </Paper>
  )
}

export default FacultyStaffAuth
