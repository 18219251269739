import { Grid } from '@material-ui/core'
import { Form, Formik } from 'formik'
import { useRef, useState } from 'react'
import { useSchoolContext } from '../../stores/school'
import { Modal, useModal } from '../modal'
import Access from './access'
import Actions from './actions'
import ConfirmDeleteDialog from './confirm-delete-dialog'
import Details from './details'
import Effects from './effect'
import getInitialValues from './get-initial-values'
import { IGroupFormModalProps } from './types'
import { useGroupCategoryOptions } from './use-group-category-options'
import useOnSubmit from './use-on-submit'
import validationSchema from './validation-schema'

const GroupFormModal = ({
  modalControl,
  divisions,
  afterSave,
  groupToEdit,
}: IGroupFormModalProps) => {
  const {
    state: {
      enabledUserTypes,
      school: { schoolSetting },
    },
  } = useSchoolContext()

  const groupCategoryOptions = useGroupCategoryOptions({ divisions })

  const confirmGroupDelete = useModal()

  const [createAnother, setCreateAnother] = useState(false)

  const iconAutoSetRef = useRef(false)

  const onSubmit = useOnSubmit({
    groupToEdit,
    modalControl,
    afterSave,
    createAnother,
  })

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={getInitialValues({
          groupToEdit,
          groupCategoryOptions,
        })}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        children={({ submitForm, resetForm, values, setFieldValue }) => (
          <Modal
            {...modalControl.props}
            title={
              groupToEdit
                ? `Edit ${groupToEdit.groupName}`
                : `Add Group${
                    values.groupCategory
                      ? ` to ${values.groupCategory.divisionName} ${values.groupCategory.categoryName}`
                      : ''
                  }`
            }
            size="sm"
            dividers
            dismissible
            actions={
              <Actions
                groupToEdit={groupToEdit}
                modalControl={modalControl}
                confirmGroupDelete={confirmGroupDelete}
                createAnother={createAnother}
                setCreateAnother={setCreateAnother}
                submitForm={submitForm}
              />
            }
            afterClose={() => {
              resetForm()
              setCreateAnother(false)
            }}
          >
            <Effects
              submitForm={submitForm}
              values={values}
              setFieldValue={setFieldValue}
              iconAutoSetRef={iconAutoSetRef}
            />

            <Grid component={Form} container>
              <Details
                values={values}
                groupCategoryOptions={groupCategoryOptions}
                iconAutoSetRef={iconAutoSetRef}
              />

              <Access
                values={values}
                enabledUserTypes={enabledUserTypes}
                registrationMode={schoolSetting?.registrationMode}
              />
            </Grid>
          </Modal>
        )}
      />

      {groupToEdit && (
        <ConfirmDeleteDialog
          confirmGroupDelete={confirmGroupDelete}
          groupToEdit={groupToEdit}
          afterSave={afterSave}
          modalControl={modalControl}
        />
      )}
    </>
  )
}

export default GroupFormModal
