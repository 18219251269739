import { SetStateAction, Dispatch, useCallback } from 'react'
import { GroupOption, GroupSelectorState } from './types'
import useHandleSelectedGroupsChange from './use-handle-selected-groups-change'
import { GroupSelectorMode } from '.'

type Options = {
  selectedGroupIds: string[]
  groupOptionsRaw: GroupOption[]
  setGroupSelectorState: Dispatch<SetStateAction<GroupSelectorState>>
  setSelectedGroupIds: (selectedGroupIds: string[]) => void
  selectedGroupOptions: GroupOption[]
  mode?: GroupSelectorMode
}

const useGroupSelectionHelpers = ({
  selectedGroupIds,
  setSelectedGroupIds,
  groupOptionsRaw,
  setGroupSelectorState,
  selectedGroupOptions,
  mode,
}: Options) => {
  const handleSelectedGroupsChange = useHandleSelectedGroupsChange({
    setSelectedGroupIds,
    setGroupSelectorState,
    mode,
  })

  const removeGroupFromSelection = useCallback(
    (groupId: string) => {
      const _ids = selectedGroupIds.filter(id => id !== groupId)

      handleSelectedGroupsChange(groupOptionsRaw.filter(o => _ids.includes(o.id)))
    },
    [selectedGroupIds]
  )

  /** 
    Adds a single group to the selection if it is not already there.

    Thin wrapper for `handleSelectedGroupsChange`.
  */
  const addGroupToSelection = useCallback(
    (groupOption: GroupOption | string) => {
      if (typeof groupOption === 'string') {
        const addOption = groupOptionsRaw.find(o => o.id === groupOption)
        if (addOption) handleSelectedGroupsChange([...selectedGroupOptions, addOption])
      } else {
        handleSelectedGroupsChange([...selectedGroupOptions, groupOption])
      }
    },
    [selectedGroupOptions]
  )

  /** 
    Toggles the selected state of a group.

    Thin wrapper for `handleSelectedGroupsChange`.
  */
  const toggleGroupSelected = useCallback(
    (groupId: string) => {
      if (selectedGroupIds.includes(groupId)) {
        removeGroupFromSelection(groupId)
      } else {
        addGroupToSelection(groupId)
      }
    },
    [selectedGroupIds]
  )

  return {
    removeGroupFromSelection,
    addGroupToSelection,
    toggleGroupSelected,
    handleSelectedGroupsChange,
  }
}

export default useGroupSelectionHelpers
