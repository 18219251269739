import { Button, Typography, useTheme } from '@material-ui/core'
import { ArrowForward, Check } from '@material-ui/icons'
import { StepProps } from './types'

const InvitationNotAccepted = ({ onResendInvitation, onRecheck }: StepProps) => {
  const theme = useTheme()

  return (
    <>
      <Typography
        gutterBottom
        color="textSecondary"
        variant="h5"
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <Check
          style={{
            color: theme.palette.success.main,
            marginRight: theme.spacing(0.5),
          }}
        />{' '}
        Almost there!
      </Typography>

      <Typography gutterBottom color="textSecondary">
        Please check your email for an invitation to setup your account (be sure to check your spam
        folder).
      </Typography>

      <Typography style={{ marginBottom: 16 }} color="textSecondary">
        Once you've setup your account, you can return here to continue signing in.
      </Typography>

      <div style={{ marginTop: 16 }}>
        <Button
          onClick={onResendInvitation}
          type="button"
          variant="text"
          color="primary"
          disableElevation
        >
          Resend Account Invitation
        </Button>
      </div>

      <Button
        style={{ width: '100%' }}
        size="large"
        type="button"
        color="primary"
        variant="contained"
        endIcon={<ArrowForward />}
        onClick={onRecheck}
      >
        Sign In
      </Button>
    </>
  )
}

export default InvitationNotAccepted
