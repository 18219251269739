import { FormikHelpers } from 'formik'
import { useSnackbar } from 'notistack'
import { GroupListFieldsFragment } from '../../graphql/autogenerate/operations'
import {
  useCreateGroupMutation,
  useUpdateGroupMutation,
} from '../../graphql/autogenerate/react-query'
import { useHandleReactQueryMutation } from '../../hooks'
import { ModalControls } from '../modal'
import { FormValues } from './types'
import { RegistrationMode } from '../../graphql/autogenerate/schemas'

type Options = {
  groupToEdit?: GroupListFieldsFragment
  modalControl: ModalControls
  afterSave: () => void
  createAnother: boolean
}

const useOnSubmit = ({ groupToEdit, modalControl, afterSave, createAnother }: Options) => {
  const { enqueueSnackbar } = useSnackbar()

  const { mutateAsync: createGroup } = useHandleReactQueryMutation(
    useCreateGroupMutation({
      onSuccess: ({ createGroup }) => {
        enqueueSnackbar(
          <span>
            Successfully created <b>{createGroup?.group?.groupName}</b>
          </span>,
          { variant: 'success' }
        )
      },
    })
  )

  const { mutateAsync: updateGroup } = useHandleReactQueryMutation(useUpdateGroupMutation())

  const onSubmit = async (values: FormValues, actions: FormikHelpers<FormValues>) => {
    if (values.groupCategory) {
      if (groupToEdit) {
        await updateGroup({
          groupName: values.name,
          groupCategoryId: values.groupCategory.id,
          iconType: values.iconType || null,
          information: values.description,
          groupId: groupToEdit.id,
          passcode: values.registrationMode === 'passcode' ? values.passcode : null,
          passcodeHint: values.registrationMode === 'passcode' ? values.passcodeHint : null,
          autoEnrollUserTypes:
            values.registrationMode === 'auto-enroll' ? values.autoEnrollUserTypes : null,
          registrationMode:
            values.registrationMode === 'admin'
              ? RegistrationMode.Invitation
              : RegistrationMode.Open,
        })
      } else {
        await createGroup({
          groupName: values.name,
          groupCategoryId: values.groupCategory.id,
          iconType: values.iconType || null,
          information: values.description,
          passcode: values.registrationMode === 'passcode' ? values.passcode : null,
          passcodeHint: values.registrationMode === 'passcode' ? values.passcodeHint : null,
          autoEnrollUserTypes:
            values.registrationMode === 'auto-enroll' ? values.autoEnrollUserTypes : null,
          registrationMode:
            values.registrationMode === 'admin'
              ? RegistrationMode.Invitation
              : RegistrationMode.Open,
        })
      }

      afterSave()

      if (!createAnother) {
        modalControl.close()
      } else {
        actions.setValues(values => ({
          ...values,
          iconType: undefined,
          name: '',
          description: '',
        }))
        actions.setFieldTouched('name', false)
      }
    }
  }

  return onSubmit
}

export default useOnSubmit
