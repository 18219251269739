import * as yup from 'yup'

const validationSchema = yup.object({
  parentPasscode: yup
    .string()
    .nullable()
    .test('parentPasscode', 'Required', function (value) {
      if (this.parent.parentPasscodeEnabled) return Boolean(value)
      return true
    }),
  studentPasscode: yup
    .string()
    .nullable()
    .test('studentPasscode', 'Required', function (value) {
      if (this.parent.studentPasscodeEnabled) return Boolean(value)
      return true
    }),
  // registrationMode: yup
  //   .string()
  //   .nullable()
  //   .test(
  //     "registrationMode",
  //     "Please specify what level of approval is required for new users to join the app.",
  //     function (value) {
  //       if (this.parent.appAccessMode === "registration") return Boolean(value);
  //       return true;
  //     }
  //   ),
})

export default validationSchema
