import { FormControlLabel, useTheme } from '@material-ui/core'
import { useCallback, useRef } from 'react'
import { Checkbox } from '.'
import { UserType } from '../../graphql/autogenerate/schemas'
import { getLabelForUserType } from '../../helpers'

interface IUserTypeSelectorProps {
  /* 
    Value states:
    - null -> nothing checked
    - empty array -> all checked
    - filled array -> all unchecked, invididual user types checked
  */
  selectedUserTypes: string[] | null
  setSelectedUserTypes: (userTypes: string[] | null) => void
  onTouched?: () => void
  userTypes: UserType[]
  error?: boolean
  disableAll?: boolean
  /* 
    UserTypeSelector can be used to select a collection of user types (e.g. for targeting a push notification)
    or for a single user (e.g. for editing user types on a user). In the latter case we apply restrictions
    (e.g. a user cannot be both a student and a faculty member).
  */
  forSingleUser?: boolean
}

export const UserTypeSelector = ({
  selectedUserTypes,
  setSelectedUserTypes,
  onTouched,
  userTypes,
  error,
  disableAll,
  forSingleUser,
}: IUserTypeSelectorProps) => {
  const theme = useTheme()

  const userTypeOptions = useRef(userTypes)

  const handleAllChange = useCallback((checked: boolean) => {
    if (checked) {
      setSelectedUserTypes([])
    } else {
      setSelectedUserTypes(null)
    }

    onTouched && onTouched()
  }, [])

  const handleCheckboxChange = useCallback(
    (checked: boolean, userType: UserType) => {
      if (checked) {
        // If the current selectedUserTypes value is null, then we can go straight to this one UserType being selected.
        if (!selectedUserTypes) {
          setSelectedUserTypes([userType])
          return
        }

        // If the "all" option is enabled and this item being checked makes all checked, go ahead and set all checked.
        if (
          !disableAll &&
          !selectedUserTypes.includes(userType) &&
          selectedUserTypes.length + 1 === userTypeOptions.current.length
        ) {
          setSelectedUserTypes([])
        } else {
          if (forSingleUser) {
            // The only validation combination of two user types is a parent and faculty/staff member.
            if (
              [UserType.FacultyStaff, UserType.Parent].includes(userType) &&
              selectedUserTypes.every(o =>
                [UserType.FacultyStaff, UserType.Parent].includes(o as UserType)
              )
            ) {
              setSelectedUserTypes(
                selectedUserTypes.includes(userType)
                  ? selectedUserTypes
                  : [...selectedUserTypes, userType]
              )
            } else {
              setSelectedUserTypes([userType])
            }
          } else {
            setSelectedUserTypes(
              selectedUserTypes.includes(userType)
                ? selectedUserTypes
                : [...selectedUserTypes, userType]
            )
          }
        }
      } else {
        // If we're moving from "all checked", then someone is deselecting this checkbox expecting the others to stay checked.
        if (selectedUserTypes && selectedUserTypes.length === 0) {
          setSelectedUserTypes(userTypeOptions.current.filter(o => o !== userType.toString()))
        }
        // If unchecking this user type is removing the last one, we need to set to null
        else if (selectedUserTypes?.length === 1 && selectedUserTypes.includes(userType)) {
          setSelectedUserTypes(null)
        } else {
          setSelectedUserTypes((selectedUserTypes || []).filter(o => o !== userType.toString()))
        }
      }

      onTouched && onTouched()
    },
    [selectedUserTypes, disableAll]
  )

  const allChecked = Boolean(selectedUserTypes && selectedUserTypes.length === 0 && !disableAll)

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        marginLeft: theme.spacing(1),
        flexWrap: 'wrap',
      }}
    >
      {!disableAll && (
        <FormControlLabel
          control={
            <Checkbox checked={allChecked} onChange={handleAllChange} name="All" error={error} />
          }
          label="All"
          style={{ marginBottom: theme.spacing(0.5) }}
        />
      )}
      {userTypeOptions.current.map(userType => (
        <FormControlLabel
          key={userType}
          control={
            <Checkbox
              checked={(selectedUserTypes || []).includes(userType.toString()) || allChecked}
              onChange={checked => handleCheckboxChange(checked, userType)}
              name={userType}
              error={error}
            />
          }
          label={getLabelForUserType(userType, forSingleUser)}
          style={{ marginBottom: theme.spacing(0.5) }}
        />
      ))}
    </div>
  )
}
