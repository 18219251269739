import { Grid } from '@material-ui/core'
import { FormikIconSelector, FormikSelectField, FormikTextInput, ISelectOption } from '../forms'
import { FormValues, IGroupCategoryOptionValue } from './types'

type Props = {
  values: FormValues
  groupCategoryOptions: ISelectOption<IGroupCategoryOptionValue>[]
  iconAutoSetRef: React.MutableRefObject<boolean>
}

const Details = ({ values, groupCategoryOptions, iconAutoSetRef }: Props) => {
  return (
    <>
      <Grid item container xs={12}>
        <FormikSelectField
          style={{ margin: 0 }}
          fieldProps={{ name: 'groupCategory', label: 'Group Category' }}
          options={groupCategoryOptions}
          getKeyFromOptionValue={value => value?.id}
        />
      </Grid>
      <Grid item xs={12} container>
        <FormikIconSelector
          fieldProps={{ name: 'iconType', label: 'Group Icon' }}
          color={values.groupCategory?.color}
          onOpened={() => (iconAutoSetRef.current = false)}
        />
        <FormikTextInput
          fieldProps={{ name: 'name', label: 'Group Name' }}
          style={{ marginRight: 0, marginBottom: 0 }}
        />
      </Grid>
      <Grid item container xs={12}>
        <FormikTextInput
          fieldProps={{ name: 'description', label: 'Description' }}
          multiline
          rows={5}
          style={{ margin: 0 }}
        />
      </Grid>
    </>
  )
}

export default Details
