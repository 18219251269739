import { Delete, Edit, RestoreFromTrash } from '@material-ui/icons'
import { useMemo } from 'react'
import { Column } from 'react-table'
import { SchoolPersonFragment } from '../../graphql/autogenerate/operations'
import { AnimatedIconButton } from '../animated-icon-button'
import useEmailSort from './use-email-sort'
import useNameSort from './use-name-sort'

type Options = {
  onEdit: (person: SchoolPersonFragment) => void
  onDelete: (person: SchoolPersonFragment) => void
  onRestoreAccess: (person: SchoolPersonFragment) => void
}

const useColumns = (options: Options) => {
  const nameSort = useNameSort()
  const emailSort = useEmailSort()

  return useMemo(() => {
    const _columns: Column<SchoolPersonFragment>[] = [
      {
        id: 'name',
        accessor: 'person',
        Header: 'Name',
        Cell: ({ value }) => `${value?.firstName} ${value?.lastName}`,
        width: '20%',
        defaultCanSort: true,
        sortType: nameSort,
      },
      {
        id: 'email',
        accessor: 'person',
        Header: 'Email',
        Cell: ({ value }) => value?.email,
        width: '80%',
        defaultCanSort: true,
        sortType: emailSort,
      },
      {
        id: 'actions',
        accessor: 'person',
        Cell: ({ value, row }) => (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <AnimatedIconButton
              icon={<Edit />}
              tooltipProps={{ title: 'Edit access, user type, and group membership' }}
              onClick={() => {
                options.onEdit(row.original)
              }}
            />

            {row.original.accessRevoked ? (
              <AnimatedIconButton
                icon={<RestoreFromTrash />}
                tooltipProps={{ title: 'Restore access' }}
                onClick={() => {
                  options.onRestoreAccess(row.original)
                }}
              />
            ) : (
              <AnimatedIconButton
                icon={<Delete />}
                tooltipProps={{ title: 'Remove from school' }}
                onClick={() => {
                  options.onDelete(row.original)
                }}
              />
            )}
          </div>
        ),
        defaultCanSort: false,
      },
    ]

    return _columns
  }, [])
}

export default useColumns
