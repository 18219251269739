import { useField } from 'formik'
import React, { useCallback } from 'react'
import { SchoolDivisionsFragment } from '../../graphql/autogenerate/operations'
import GroupSelector, { GroupSelectorMode } from '../group-selector'
import { IField } from './interfaces'

interface IFormikGroupSelector {
  fieldProps: IField
  searchPlaceholder?: string
  helperText?: string
  divisions?: SchoolDivisionsFragment[]
  excludeSchoolwide?: boolean
  mode?: GroupSelectorMode
}

export const FormikGroupSelector = ({
  fieldProps,
  divisions,
  searchPlaceholder,
  helperText,
  excludeSchoolwide,
  mode,
}: IFormikGroupSelector) => {
  const [field, meta, helpers] = useField<string[] | undefined>(fieldProps)

  const handleOnBlur = useCallback(
    (ev: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      field.onBlur(ev)
      helpers.setTouched(true)
    },
    []
  )

  return (
    <GroupSelector
      divisions={divisions}
      selectedGroupIds={field.value || []}
      setSelectedGroupIds={groupIds => helpers.setValue(groupIds)}
      label={fieldProps.label}
      helperText={helperText}
      searchPlaceholder={searchPlaceholder}
      error={meta.error}
      initialError={meta.initialError}
      touched={meta.touched}
      onBlur={handleOnBlur}
      excludeSchoolwide={excludeSchoolwide}
      mode={mode}
    />
  )
}
