import { GroupListFieldsFragment } from '../../graphql/autogenerate/operations'
import { UserType, RegistrationMode } from '../../graphql/autogenerate/schemas'
import { ISelectOption } from '../forms'
import { FormValues, IGroupCategoryOptionValue } from './types'

type Options = {
  groupToEdit?: GroupListFieldsFragment
  groupCategoryOptions: ISelectOption<IGroupCategoryOptionValue>[]
}

const getInitialValues = ({ groupToEdit, groupCategoryOptions }: Options): FormValues => ({
  name: groupToEdit?.groupName || '',
  iconType: groupToEdit?.iconType || null,
  description: groupToEdit?.information || '',
  groupCategory: groupCategoryOptions.find(g => g.key === groupToEdit?.groupCategoryId)?.value,
  passcode: groupToEdit?.passcode || '',
  passcodeHint: groupToEdit?.passcodeHint || '',
  autoEnrollUserTypes: (groupToEdit?.autoEnrollUserTypes as UserType[]) || ([] as UserType[]),
  registrationMode: (() => {
    if (groupToEdit?.registrationMode === RegistrationMode.Invitation) return 'admin'
    if (groupToEdit?.passcode) return 'passcode'
    if (groupToEdit?.autoEnrollUserTypes?.length) return 'auto-enroll'
    return 'open'
  })(),
})

export default getInitialValues
