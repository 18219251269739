import dayjs, { Dayjs } from 'dayjs'
import { useSnackbar } from 'notistack'
import { useCallback } from 'react'
import { SchoolSettingFragment } from '../../../graphql/autogenerate/operations'
import {
  useCancelScheduleSettingsResetMutation,
  useScheduleSettingsResetMutation,
  useUpdateSchoolSettingUserAccessMutation,
} from '../../../graphql/autogenerate/react-query'
import { RegistrationMode, UserType } from '../../../graphql/autogenerate/schemas'
import { useHandleReactQueryMutation } from '../../../hooks'
import { useSchoolContext } from '../../../stores/school'
import { FormValues } from './types'

const useHandleSubmit = () => {
  const {
    state: {
      school: { id: schoolId },
    },
    actions: {
      refetch: { schoolDetail: refetchSchoolDetails },
    },
  } = useSchoolContext()

  const { enqueueSnackbar } = useSnackbar()

  const { mutateAsync: updateUserAccessSettings } = useHandleReactQueryMutation(
    useUpdateSchoolSettingUserAccessMutation({
      onSuccess: () => {
        enqueueSnackbar('User access settings updated', { variant: 'success' })
        refetchSchoolDetails()
      },
    })
  )

  const { mutateAsync: scheduleSettingsReset } = useHandleReactQueryMutation(
    useScheduleSettingsResetMutation()
  )
  const { mutateAsync: cancelScheduleSettingsReset } = useHandleReactQueryMutation(
    useCancelScheduleSettingsResetMutation()
  )

  const handleSettingsResetValues = useCallback(
    async ({
      userType,
      previousValue,
      newValue,
    }: {
      userType: UserType
      previousValue?: string | null
      newValue: Dayjs | null
    }) => {
      if (!previousValue && newValue)
        await scheduleSettingsReset({
          scheduleDate: newValue.toISOString(),
          schoolId,
          userType,
        })
      if (previousValue && newValue && !dayjs(previousValue).isSame(newValue)) {
        await cancelScheduleSettingsReset({ schoolId, userType })
        await scheduleSettingsReset({
          scheduleDate: newValue.toISOString(),
          schoolId,
          userType,
        })
      }
    },
    [schoolId, scheduleSettingsReset, cancelScheduleSettingsReset]
  )

  return async ({
    values: {
      scheduledFacultyReset,
      scheduledGuestReset,
      scheduledParentReset,
      scheduledStudentReset,
      registrationMode,
      appAccessMode,
      ...values
    },
    schoolSetting,
  }: {
    values: FormValues
    schoolSetting: SchoolSettingFragment
  }) => {
    await updateUserAccessSettings({
      schoolId,
      ...values,
      parentPasscode:
        appAccessMode === 'passcode' && values.parentPasscodeEnabled ? values.parentPasscode : null,
      parentPasscodeHint:
        appAccessMode === 'passcode' && values.parentPasscodeEnabled
          ? values.parentPasscodeHint
          : null,
      studentPasscode:
        appAccessMode === 'passcode' && values.studentPasscodeEnabled
          ? values.studentPasscode
          : null,
      studentPasscodeHint:
        appAccessMode === 'passcode' && values.studentPasscodeEnabled
          ? values.studentPasscodeHint
          : null,
      registrationMode: appAccessMode === 'registration' ? RegistrationMode.Open : null,
    })

    await handleSettingsResetValues({
      userType: UserType.Parent,
      previousValue: schoolSetting.scheduledParentReset,
      newValue: scheduledParentReset,
    })
    await handleSettingsResetValues({
      userType: UserType.FacultyStaff,
      previousValue: schoolSetting.scheduledFacultyReset,
      newValue: scheduledFacultyReset,
    })
    await handleSettingsResetValues({
      userType: UserType.Student,
      previousValue: schoolSetting.scheduledStudentReset,
      newValue: scheduledStudentReset,
    })
    await handleSettingsResetValues({
      userType: UserType.Guest,
      previousValue: schoolSetting.scheduledGuestReset,
      newValue: scheduledGuestReset,
    })
  }
}

export default useHandleSubmit
