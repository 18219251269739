import { Breadcrumbs, Button, Grid, Typography } from "@material-ui/core";
import { createStyles, makeStyles, useTheme } from "@material-ui/core/styles";
import {
  AccountBox,
  Announcement,
  ArtTrack,
  CalendarToday,
  Check,
  ChromeReaderMode,
  FeaturedVideo,
  Home,
  MonetizationOn,
  Settings,
  SvgIconComponent,
} from "@material-ui/icons";
import clsx from "clsx";
import { ReactNode } from "react";
import {
  Link,
  Navigate,
  Outlet,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { mappedXyzTransitionClasses } from "../anim-xyz/xyz-transition-classes";
import {
  useAppState,
  useAuth,
  useBreadcrumbs,
  useRegisterBreadcrumb,
  useSetPageTitle,
} from "../hooks";
import { useSchoolContext } from "../stores/school";
import { AdminManagePeople } from "./admin-manage-people";
import { AdminMarquee } from "./admin-marquee";
import { AdminNewsletters } from "./admin-newsletters";
import { AdminNotifications } from "./admin-notifications";
import { AdminResources } from "./admin-resources";
import { AdminSchoolButtonNavButtons } from "./admin-school-button-nav-buttons";
import { AdminSchoolDivisions } from "./admin-school-divisions";
import AdminSchoolSettings from "./admin-school-settings";
import { AdminSponsors } from "./admin-sponsors";
import { Calendar } from "./calendar";
import { GuardedRoute } from "./guarded-route";

const useStyles = makeStyles((theme) =>
  createStyles({
    toolbar: {
      backgroundColor: theme.palette.primary.main,
      color: "white",
      transition: theme.transitions.create(["height"]),
      display: "flex",
      flexDirection: "column",
      flexBasis: "unset",
    },
    shrinkingBottom: {
      flex: 1,
      transition: theme.transitions.create(["flex"]),
    },
    shrunkBottom: {
      flex: 0,
    },
    largeToolbar: {
      [theme.breakpoints.up("sm")]: {
        height: 200,
      },
      [theme.breakpoints.down("sm")]: {
        height: 75,
      },
    },
    smallToolbar: {
      height: 50,
      minHeight: 50,
    },
    breadcrumbs: {
      color: "white",
      "& > ol": {},
      padding: `0px ${theme.spacing(2)}px`,
    },
    smallBreadcrumbs: {
      "& > ol": {},
    },
    breadcrumbLink: {
      color: "white",
      display: "flex",
      "& > svg": {
        marginRight: theme.spacing(0.5),
        transition: theme.transitions.create(["font-size"]),
      },
      "& > span": {
        paddingTop: 2,
      },
      transition: theme.transitions.create(["font-size", "padding"]),
      alignItems: "center",
    },
    largeBreadcrumbLink: {
      fontSize: 48,
      padding: `0 0 ${theme.spacing(3)}px ${theme.spacing(1)}px`,
      "& > svg": {
        fontSize: 48,
        marginRight: theme.spacing(1),
      },
    },
  })
);

export const AdminSchoolNav = () => {
  const {
    state: { currentUser },
  } = useAppState();
  const {
    state: { currentUserPermissions },
  } = useSchoolContext();
  const {
    state: {
      school: { name },
    },
  } = useSchoolContext();

  const { logout } = useAuth();

  const styles = useStyles();
  const theme = useTheme();
  const navigate = useNavigate();

  const { id: crumbId } = useRegisterBreadcrumb({
    relativePath: `admin`,
    label: name,
    Icon: Home,
  });
  const { breadcrumbs, composeCrumbLink } = useBreadcrumbs();

  const largeNav =
    breadcrumbs.findIndex((c) => c.id === crumbId) === breadcrumbs.length - 1;

  return (
    <Grid
      container
      direction="column"
      style={{ height: "100vh", flexWrap: "unset" }}
    >
      <Grid
        item
        xs={12}
        className={clsx(
          largeNav ? styles.largeToolbar : styles.smallToolbar,
          styles.toolbar
        )}
        component="div"
      >
        {largeNav && (
          <div style={{ padding: theme.spacing(1) }}>
            <Button
              onClick={logout}
              variant="outlined"
              style={{ color: "white", borderColor: "white" }}
            >
              Logout
            </Button>

            {(currentUser?.appAdministrator ||
              currentUserPermissions.groups.nodes.length > 1) && (
              <Button
                onClick={() => navigate("/schools")}
                variant="outlined"
                style={{
                  color: "white",
                  borderColor: "white",
                  marginLeft: theme.spacing(1),
                }}
              >
                School List
              </Button>
            )}
          </div>
        )}

        <div style={{ flex: 1 }} />
        <TransitionGroup
          component={Breadcrumbs}
          xyz="fade left-4"
          aria-label="breadcrumb"
          className={clsx(
            styles.breadcrumbs,
            !largeNav && styles.smallBreadcrumbs
          )}
        >
          {breadcrumbs.map((crumb) => (
            <CSSTransition
              key={crumb.id}
              timeout={250}
              classNames={mappedXyzTransitionClasses}
              xyx="in-delay-2"
            >
              <Link
                to={largeNav ? "" : composeCrumbLink(crumb)}
                className={clsx(
                  styles.breadcrumbLink,
                  largeNav && styles.largeBreadcrumbLink
                )}
              >
                {crumb.Icon && <crumb.Icon />}
                <span>{crumb.label}</span>
              </Link>
            </CSSTransition>
          ))}
        </TransitionGroup>
        <div
          className={clsx(
            styles.shrinkingBottom,
            largeNav && styles.shrunkBottom
          )}
        />
      </Grid>

      {currentUser?.appAdministrator ||
      currentUserPermissions.schoolwideAdmin ||
      currentUserPermissions.groups.nodes.some((o) => o.admin) ? (
        <>
          {/* They have admin access to at least one group within the school. */}
          <Routes>
            <Route path="" element={<AdminSchoolButtonNavButtons />} />

            <RouteWithBreadcrumb
              path="calendars"
              label="Calendars"
              Icon={CalendarToday}
              element={<Calendar />}
            />
            <RouteWithBreadcrumb
              path="notifications"
              label="Notifications"
              Icon={Announcement}
              element={<AdminNotifications />}
            />
            <RouteWithBreadcrumb
              path="resources"
              label="Resources"
              Icon={ChromeReaderMode}
              element={<AdminResources />}
            />
            <RouteWithBreadcrumb
              path="newsletters"
              label="Newsletters"
              Icon={ArtTrack}
              element={<AdminNewsletters />}
            />

            <GuardedRoute
              guard={
                currentUser?.appAdministrator ||
                currentUserPermissions.schoolwideAdmin
              }
              path="groups"
              element={<AdminSchoolDivisions />}
            />

            <GuardedRouteWithBreadcrumb
              guard={
                currentUser?.appAdministrator ||
                currentUserPermissions.schoolwideAdmin
              }
              path="marquee"
              label="Marquee"
              Icon={FeaturedVideo}
              element={<AdminMarquee />}
            />
            <GuardedRouteWithBreadcrumb
              guard={
                currentUser?.appAdministrator ||
                currentUserPermissions.schoolwideAdmin
              }
              path="people"
              label="People"
              Icon={AccountBox}
              element={<AdminManagePeople />}
            />
            <GuardedRouteWithBreadcrumb
              guard={
                currentUser?.appAdministrator ||
                currentUserPermissions.schoolwideAdmin
              }
              path="sponsors"
              label="Sponsors"
              Icon={MonetizationOn}
              element={<AdminSponsors />}
            />
            <GuardedRouteWithBreadcrumb
              guard={
                currentUser?.appAdministrator ||
                currentUserPermissions.schoolwideAdmin
              }
              path="settings"
              label="Settings"
              Icon={Settings}
              element={<AdminSchoolSettings />}
            />

            <Route path="*" element={<Navigate to="admin" replace />} />
          </Routes>

          <Outlet />
        </>
      ) : (
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ maxWidth: 600 }}>
            {/* This user does not have any admin access. */}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: theme.spacing(2),
              }}
            >
              <Check
                style={{ color: theme.palette.success.main, fontSize: 48 }}
              />
            </div>
            <Typography
              align="center"
              gutterBottom
              variant="h5"
              style={{ display: "flex", alignItems: "center" }}
            >
              {" "}
              You've successfully joined {name} as a Faculty/Staff member!
            </Typography>
            <Typography gutterBottom>
              You have full access to all app resources for Faculty/Staff and
              will receive Faculty/Staff push notifications.{" "}
            </Typography>
            <Typography gutterBottom>
              However, it looks like your school administrator has not granted
              you admin access for any groups. If you believe you should also
              have admin access to manage groups within the school, please
              contact your school administrator.
            </Typography>
          </div>
        </div>
      )}
    </Grid>
  );
};

interface IRouteWithBreadcrumbProps {
  path: string;
  label: string;
  Icon?: SvgIconComponent;
  element: React.ReactElement | null;
  children?: ReactNode;
}

const RouteWithBreadcrumb = ({
  path,
  label,
  Icon,
  element,
  children,
}: IRouteWithBreadcrumbProps) => {
  useSetPageTitle(label);
  useRegisterBreadcrumb({ relativePath: path, label, Icon });

  return (
    <Route path={path} element={element}>
      {children}
    </Route>
  );
};

interface IGuardedRouteWithBreadcrumbProps extends IRouteWithBreadcrumbProps {
  guard?: boolean;
}

const GuardedRouteWithBreadcrumb = ({
  path,
  label,
  Icon,
  element,
  children,
  guard,
}: IGuardedRouteWithBreadcrumbProps) => {
  useSetPageTitle(label);
  useRegisterBreadcrumb({ relativePath: path, label, Icon });

  return (
    <GuardedRoute path={path} element={element} guard={guard}>
      {children}
    </GuardedRoute>
  );
};
