import dayjs from "dayjs";
import { SchoolSettingFragment } from "../../../graphql/autogenerate/operations";

const getInitialValues = (schoolSetting: SchoolSettingFragment) => ({
  parentPasscodeEnabled: Boolean(schoolSetting.parentPasscode),
  parentPasscode: schoolSetting.parentPasscode || "",
  parentPasscodeHint: schoolSetting.parentPasscodeHint || "",
  studentPasscodeEnabled: Boolean(schoolSetting.studentPasscode),
  studentPasscode: schoolSetting.studentPasscode || "",
  studentPasscodeHint: schoolSetting.studentPasscodeHint || "",
  enableGuestAccess: Boolean(schoolSetting.enableGuestAccess),
  enableParentFamilyAccess: Boolean(schoolSetting.enableParentFamilyAccess),
  enableStudentAccess: Boolean(schoolSetting.enableStudentAccess),
  scheduledFacultyReset: schoolSetting.scheduledFacultyReset
    ? dayjs(schoolSetting.scheduledFacultyReset)
    : null,
  scheduledGuestReset: schoolSetting.scheduledGuestReset
    ? dayjs(schoolSetting.scheduledGuestReset)
    : null,
  scheduledParentReset: schoolSetting.scheduledParentReset
    ? dayjs(schoolSetting.scheduledParentReset)
    : null,
  scheduledStudentReset: schoolSetting.scheduledStudentReset
    ? dayjs(schoolSetting.scheduledStudentReset)
    : null,
  appAccessMode: ((): "registration" | "passcode" | "none" => {
    if (schoolSetting.registrationMode) return "registration";
    if (schoolSetting.parentPasscode || schoolSetting.studentPasscode)
      return "passcode";
    return "none";
  })(),
  registrationMode: schoolSetting.registrationMode,
});

export default getInitialValues;
