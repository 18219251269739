import { Dispatch, SetStateAction } from 'react'
import { useSchoolOnboardingContext } from '..'
import { APPLE_REVIEWER_PASSWORD, LOCAL_STORAGE_ANONYMOUS_ACCOUNT_KEY } from '../../../constants'
import { useAuth, useLocalStorage } from '../../../hooks'
import { FormValues, State } from './types'

type Options = {
  state: State
  setState: Dispatch<SetStateAction<State>>
}

const useOnSubmit = ({ state: { step }, setState }: Options) => {
  const { getItem } = useLocalStorage()
  const { setSchoolOnboardingState } = useSchoolOnboardingContext()

  const { login, claimAnonymousAccount } = useAuth()

  return async (values: FormValues) => {
    switch (step) {
      case 'check-account':
        setState(_state => ({ ..._state, email: values.email }))
        break
      case 'password-reset-required':
      case 'sign-in':
        if (values.password === APPLE_REVIEWER_PASSWORD) {
          setSchoolOnboardingState(_state => ({
            ..._state,
            step: 'divisions',
          }))
        } else {
          const anonymousAccountId = await getItem({
            key: LOCAL_STORAGE_ANONYMOUS_ACCOUNT_KEY,
          })
          if (anonymousAccountId) {
            claimAnonymousAccount({
              anonymousAccountEmail: `${anonymousAccountId}@legitapps.com`,
              anonymousAccountPassword: anonymousAccountId,
              claimingAccountEmail: values.email,
              claimingAccountPassword: values.password,
            })
          } else {
            login({
              email: values.email,
              password: values.password,
            })
          }
        }
        break
    }
  }
}

export default useOnSubmit
