import { Typography, useTheme } from '@material-ui/core'
import { useEffect, useMemo, useState } from 'react'
import { useSchoolOnboardingContext, useSchoolOnboardingStyles } from '..'
import { RegistrationMode } from '../../../graphql/autogenerate/schemas'
import { useSchoolContext } from '../../../stores/school'
import BackButton from './back-button'
import ContinueButton from './continue-button'
import ProgressBar from './progress-bar'
import SelectDivisionGroups from './select-division-groups'
import SelectDivisions from './select-divisions'
import { State } from './types'
import useSubmitSelections from './use-submit-selections'

const Divisions = () => {
  const theme = useTheme()
  const onboardingStyles = useSchoolOnboardingStyles()
  const {
    state: {
      school: {
        divisions: { nodes: divisions },
      },
    },
  } = useSchoolContext()

  const { selectedDivisions, selectedGroups, userTypes } = useSchoolOnboardingContext()

  const [state, setState] = useState<State>({
    currentDivision: divisions.length === 1 ? divisions[0] : undefined,
  })
  const { currentDivision, selectionsAvailable } = state

  const selectedDivisionsWithGroups = useMemo(() => {
    return selectedDivisions?.filter(division =>
      division.groupCategories.nodes.some(category =>
        category.groups.nodes.some(
          group =>
            group.registrationMode === RegistrationMode.Open && !group.autoEnrollUserTypes?.length
        )
      )
    )
  }, [selectedDivisions])

  const submitSelections = useSubmitSelections({
    setState,
    selectedGroups,
    selectedDivisions,
    userTypes,
    nextDivisionToSelectGroupsFor: null,
  })

  useEffect(() => {
    // If no divisions have any selectable groups, submit the selections automatically
    if (
      !divisions.some(division =>
        division.groupCategories.nodes.some(category =>
          category.groups.nodes.some(
            group =>
              group.registrationMode === RegistrationMode.Open && !group.autoEnrollUserTypes?.length
          )
        )
      )
    ) {
      submitSelections()
    } else {
      setState(prevState => ({
        ...prevState,
        selectionsAvailable: true,
      }))
    }
  }, [])

  if (!selectionsAvailable) return null

  return (
    <>
      <div className={onboardingStyles.stepContainer}>
        <BackButton
          divisions={divisions}
          selectedDivisionsWithGroups={selectedDivisionsWithGroups}
          currentDivision={currentDivision}
          setState={setState}
        />

        <div
          style={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            minHeight: 0,
            overflowY: 'auto',
          }}
        >
          <Typography gutterBottom variant="h4">
            {currentDivision ? currentDivision.name : 'Divisions'}
          </Typography>

          <Typography style={{ marginBottom: theme.spacing(4) }}>
            Select the {currentDivision ? `${currentDivision.name} groups` : 'divisions'} for which
            you'd like to receive updates.
          </Typography>

          {currentDivision ? (
            <SelectDivisionGroups currentDivision={currentDivision} />
          ) : (
            <SelectDivisions />
          )}
        </div>

        <ContinueButton
          currentDivision={currentDivision}
          selectedDivisions={selectedDivisions}
          selectedGroups={selectedGroups}
          userTypes={userTypes}
          setState={setState}
          selectedDivisionsWithGroups={selectedDivisionsWithGroups}
        />

        {/* Progress bar */}
        {currentDivision &&
          selectedDivisionsWithGroups &&
          selectedDivisionsWithGroups.length > 1 && (
            <ProgressBar
              selectedDivisionsWithGroups={selectedDivisionsWithGroups}
              currentDivision={currentDivision}
            />
          )}
      </div>
    </>
  )
}

export default Divisions
