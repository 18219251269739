import { useHandleReactQuery, useHandleReactQueryMutation } from '../hooks'
import {
  useDeleteSchoolPersonInvitationMutation,
  useFacultyStaffQuery,
  useInvitePersonToSchoolMutation,
  useSendSchoolPersonInvitationEmailMutation,
} from '../graphql/autogenerate/react-query'
import { useSchoolContext } from '../stores/school'
import {
  Avatar,
  Button,
  createStyles,
  Fab,
  makeStyles,
  Typography,
  useTheme,
} from '@material-ui/core'
import { Add, CheckCircleOutline, Delete, Edit, LockOpenOutlined } from '@material-ui/icons'
import { UserType } from '../graphql/autogenerate/schemas'
import { AddPersonModal } from './add-person-modal'
import { Modal, useModal } from './modal'
import { useCallback, useMemo, useState } from 'react'
import { SchoolPersonFragment } from '../graphql/autogenerate/operations'
import dayjs from 'dayjs'
import { Column, Row } from 'react-table'
import { Table } from './table'
import { useButtonStyles, useCommonStyles } from '../styles'
import { CopyTextButton } from './copy-text-button'
import { AnimatedIconButton } from './animated-icon-button'
import { SchoolPersonProfileEditModal } from './school-person-profile-edit-modal'
import { SchoolPersonPermissionsEditModal } from './school-person-permissions-edit-modal'
import { SchoolPersonRemoveModal } from './school-person-remove-modal'

const useStyles = makeStyles(theme =>
  createStyles({
    linkButton: {
      textDecoration: `underline dashed ${theme.palette.grey[500]}`,
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '&:hover': {
        textDecorationColor: theme.palette.primary.main,
        color: theme.palette.primary.main,
      },
    },
  })
)

interface IState {
  schoolPersonToEdit?: SchoolPersonFragment
}

export const AdminManagePeopleFacultyStaff = () => {
  const theme = useTheme()
  const styles = useStyles()

  const {
    state: {
      school: { id: schoolId, name: schoolName },
    },
  } = useSchoolContext()
  const { data, refetch } = useHandleReactQuery(useFacultyStaffQuery({ schoolId }))

  const [state, setState] = useState<IState>({})
  const { schoolPersonToEdit } = state

  const personFormModal = useModal()
  const invitationModal = useModal()
  const profileModal = useModal()
  const permissionsModal = useModal()
  const removeModal = useModal()

  const openInvitationModalForPerson = useCallback((person: SchoolPersonFragment) => {
    setState(_state => ({ ..._state, schoolPersonToEdit: person }))
    invitationModal.open()
  }, [])

  const editPersonProfile = useCallback((person: SchoolPersonFragment) => {
    setState(_state => ({ ..._state, schoolPersonToEdit: person }))
    profileModal.open()
  }, [])

  const editPersonPermissions = useCallback((person: SchoolPersonFragment) => {
    setState(_state => ({ ..._state, schoolPersonToEdit: person }))
    permissionsModal.open()
  }, [])

  const deletePerson = useCallback((person: SchoolPersonFragment) => {
    setState(_state => ({ ..._state, schoolPersonToEdit: person }))
    removeModal.open()
  }, [])

  const memoizedPeople = useMemo(() => {
    if (!data?.schoolPeople?.nodes) return []
    return data?.schoolPeople?.nodes
  }, [data?.schoolPeople?.nodes])

  const nameSort = useCallback(
    (rowA: Row<SchoolPersonFragment>, rowB: Row<SchoolPersonFragment>) => {
      // Sort by first name
      if ((rowA.original.person?.firstName || '') > (rowB.original.person?.firstName || ''))
        return 1

      // If first names are the same, fall back to sorting by last name
      if (
        rowA.original.person?.firstName === rowB.original.person?.firstName &&
        (rowA.original.person?.lastName || '') > (rowB.original.person?.lastName || '')
      )
        return 1

      return -1
    },
    []
  )

  const emailSort = useCallback(
    (rowA: Row<SchoolPersonFragment>, rowB: Row<SchoolPersonFragment>) => {
      if (!rowA.original.person?.email && !rowB.original.person?.email) return 0
      if (!rowA.original.person?.email) return -1
      if (!rowB.original.person?.email) return 1
      return rowA.original.person?.email > rowB.original.person?.email ? 1 : -1
    },
    []
  )

  const statusSort = useCallback(
    (rowA: Row<SchoolPersonFragment>, rowB: Row<SchoolPersonFragment>) => {
      let aStatus = 'n'
      if (rowA.original.schoolPersonInvitation?.accepted) {
        aStatus = 'a'
      } else if (rowA.original.schoolPersonInvitation) {
        aStatus = 'i'
      }

      let bStatus = 'n'
      if (rowB.original.schoolPersonInvitation?.accepted) {
        bStatus = 'a'
      } else if (rowB.original.schoolPersonInvitation) {
        bStatus = 'i'
      }
      return aStatus > bStatus ? 1 : -1
    },
    []
  )

  const lastActiveSort = useCallback(
    (rowA: Row<SchoolPersonFragment>, rowB: Row<SchoolPersonFragment>) => {
      const aOnline = rowA.original.person?.userSession?.lastOnline
      const bOnline = rowB.original.person?.userSession?.lastOnline
      if (aOnline && !bOnline) return 1
      if (bOnline && !aOnline) return -1
      if (aOnline && bOnline) return dayjs(aOnline).isAfter(dayjs(bOnline)) ? 1 : -1
      return 1
    },
    []
  )

  const columns: Column<SchoolPersonFragment>[] = useMemo(() => {
    const _columns: Column<SchoolPersonFragment>[] = [
      {
        id: 'profilePic',
        accessor: 'person',
        Cell: ({ value }) => (
          <Avatar
            alt={`${value?.firstName} ${value?.lastName}`}
            src={value?.profileImage?.temporaryDownloadUrl || ''}
            style={{ width: 50, height: 50 }}
          >
            {value?.firstName?.slice(0, 1)}
            {value?.lastName?.slice(0, 1)}
          </Avatar>
        ),
        width: 50,
        defaultCanSort: false,
      },
      {
        id: 'name',
        accessor: 'person',
        Header: 'Name',
        Cell: ({ value }) => `${value?.firstName} ${value?.lastName}`,
        width: '20%',
        defaultCanSort: true,
        sortType: nameSort,
      },
      {
        id: 'email',
        accessor: 'person',
        Header: 'Email',
        Cell: ({ value }) => value?.email,
        width: '40%',
        defaultCanSort: true,
        sortType: emailSort,
      },
      {
        id: 'status',
        accessor: 'schoolPersonInvitation',
        Header: () => <div style={{ textAlign: 'center', flex: 1, marginLeft: 18 }}>Status</div>,
        Cell: ({ value, row }) => {
          if (value?.accepted) {
            return (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
                onClick={() => openInvitationModalForPerson(row.original)}
              >
                <CheckCircleOutline
                  style={{ color: theme.palette.success.main, marginRight: theme.spacing(1) }}
                />
              </div>
            )
          } else {
            return (
              <div
                className={styles.linkButton}
                onClick={() => openInvitationModalForPerson(row.original)}
              >
                {value && 'Invited'}
                {!value && 'Not Invited'}
              </div>
            )
          }
        },
        width: '10%',
        defaultCanSort: true,
        sortType: statusSort,
      },
      // {
      //     id: 'lastActive',
      //     accessor: 'person',
      //     Header: () => <div style={{ textAlign: 'center', flex: 1, marginLeft: 18 }}>Last Active</div>,
      //     Cell: ({ value }) => value?.userSession?.lastOnline ? dayjs(value.userSession.lastOnline).fromNow() : <div style={{ textAlign: 'center', color: theme.palette.grey[ 700 ] }}>&mdash;</div>,
      //     width: '10%',
      //     defaultCanSort: true,
      //     sortType: lastActiveSort,
      // },
      {
        id: 'actions',
        accessor: 'person',
        Cell: ({ value, row }) => (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <AnimatedIconButton
              icon={<Edit />}
              tooltipProps={{ title: 'Edit profile' }}
              onClick={() => editPersonProfile(row.original)}
            />
            <AnimatedIconButton
              icon={<LockOpenOutlined />}
              tooltipProps={{ title: 'Edit permissions' }}
              onClick={() => editPersonPermissions(row.original)}
            />
            <AnimatedIconButton
              icon={<Delete />}
              tooltipProps={{ title: 'Remove from school' }}
              onClick={() => deletePerson(row.original)}
            />
          </div>
        ),
        defaultCanSort: false,
      },
    ]

    return _columns
  }, [])

  return (
    <>
      <Table
        data={memoizedPeople}
        columns={columns}
        tableSize="small"
        hover
        initialTableState={{ sortBy: [{ id: 'name' }] }}
      />

      <Fab
        onClick={personFormModal.open}
        variant="extended"
        color="primary"
        style={{ position: 'absolute', bottom: theme.spacing(4), right: theme.spacing(4) }}
      >
        <Add />
      </Fab>

      <AddPersonModal
        modal={personFormModal}
        userType={UserType.FacultyStaff}
        afterClose={personUpdated => {
          // If a person was saved (either added to the school or updated), refetch list
          if (personUpdated) refetch()
        }}
      />

      <Modal
        {...invitationModal.props}
        title={(() => {
          if (schoolPersonToEdit?.schoolPersonInvitation?.accepted)
            return `${schoolPersonToEdit.person?.firstName} has accepted their invitation`
          return 'Account Status'
        })()}
        size="sm"
        dismissible
        closeButton
        afterClose={() => {
          setState(_state => ({ ..._state, schoolPersonToEdit: undefined }))
          refetch()
        }}
      >
        {schoolPersonToEdit && !schoolPersonToEdit.schoolPersonInvitation && (
          <SendInvitation
            schoolPersonToEdit={schoolPersonToEdit}
            modal={invitationModal}
            schoolName={schoolName}
          />
        )}
        {schoolPersonToEdit?.schoolPersonInvitation &&
          !schoolPersonToEdit.schoolPersonInvitation.accepted && (
            <SentInvitation
              schoolPersonToEdit={schoolPersonToEdit}
              modal={invitationModal}
              schoolName={schoolName}
            />
          )}
        {schoolPersonToEdit?.schoolPersonInvitation?.accepted && (
          <ActiveAccount
            schoolPersonToEdit={schoolPersonToEdit}
            modal={invitationModal}
            schoolName={schoolName}
          />
        )}
      </Modal>

      <SchoolPersonProfileEditModal
        modal={profileModal}
        afterClose={() => {
          setState(_state => ({ ..._state, schoolPersonToEdit: undefined }))
          refetch()
        }}
        schoolPersonToEdit={state.schoolPersonToEdit}
      />

      <SchoolPersonPermissionsEditModal
        modal={permissionsModal}
        afterClose={() => {
          setState(_state => ({ ..._state, schoolPersonToEdit: undefined }))
          refetch()
        }}
        schoolPersonToEdit={state.schoolPersonToEdit}
      />

      <SchoolPersonRemoveModal
        modal={removeModal}
        afterClose={() => {
          setState(_state => ({ ..._state, schoolPersonToEdit: undefined }))
          refetch()
        }}
        schoolPersonToEdit={state.schoolPersonToEdit}
      />
    </>
  )
}

interface ISendInvitationProps {
  modal: ReturnType<typeof useModal>
  schoolPersonToEdit: SchoolPersonFragment
  schoolName: string
}
const SendInvitation = ({ schoolPersonToEdit, schoolName, modal }: ISendInvitationProps) => {
  const theme = useTheme()

  const [state, setState] = useState({
    sent: false,
  })

  const { mutate: invitePersonToSchool } = useHandleReactQueryMutation(
    useInvitePersonToSchoolMutation({
      onSuccess: () => setState({ sent: true }),
    })
  )

  if (state.sent) {
    return (
      <div>
        <Typography gutterBottom variant="h6">
          Invitation sent to {schoolPersonToEdit.person?.firstName} at{' '}
          {schoolPersonToEdit.person?.email}.
        </Typography>

        <Typography>
          Tip: it may help to let {schoolPersonToEdit.person?.firstName} know to expect this
          invitation. Consider{' '}
          <a href={`mailto:${schoolPersonToEdit.person?.email}`}>
            sending {schoolPersonToEdit.person?.firstName} an email
          </a>{' '}
          to give them a heads up in case the invitation goes to their junk/spam folder.
        </Typography>

        <Button
          style={{ minWidth: 100, margin: `${theme.spacing(2)}px 0px` }}
          variant="outlined"
          color="primary"
          onClick={modal.close}
        >
          Done
        </Button>
      </div>
    )
  } else {
    return (
      <div>
        <Typography gutterBottom variant="h6">
          No access.
        </Typography>

        <Typography>
          Would you like to send <b>{schoolPersonToEdit.person?.firstName}</b> (
          {schoolPersonToEdit.person?.email}) an invitation to set up their account with{' '}
          {schoolName}?
        </Typography>

        <Button
          variant="contained"
          color="primary"
          style={{ minWidth: 100, margin: `${theme.spacing(2)}px 0px` }}
          onClick={() => {
            invitePersonToSchool({ schoolPersonId: schoolPersonToEdit.id })
          }}
        >
          Send Invitation
        </Button>
      </div>
    )
  }
}

interface ISentInvitationProps {
  modal: ReturnType<typeof useModal>
  schoolPersonToEdit: SchoolPersonFragment
  schoolName: string
}
const SentInvitation = ({ schoolPersonToEdit, schoolName, modal }: ISentInvitationProps) => {
  const theme = useTheme()
  const buttonStyles = useButtonStyles()

  const [state, setState] = useState({
    status: 'summary' as 'summary' | 'resent' | 'canceled',
  })

  const { mutate: resendInvitation } = useHandleReactQueryMutation(
    useSendSchoolPersonInvitationEmailMutation({ onSuccess: () => setState({ status: 'resent' }) })
  )
  const { mutate: deleteInvitation } = useHandleReactQueryMutation(
    useDeleteSchoolPersonInvitationMutation({
      onSuccess: () => setState(_state => ({ ..._state, status: 'canceled' })),
    })
  )

  if (state.status === 'resent') {
    return (
      <div>
        <Typography gutterBottom variant="h6">
          Invitation resent to {schoolPersonToEdit.person?.firstName} at{' '}
          {schoolPersonToEdit.person?.email}.
        </Typography>

        <Typography>
          Tip: it may help to let {schoolPersonToEdit.person?.firstName} know to expect this
          invitation. Consider{' '}
          <a href={`mailto:${schoolPersonToEdit.person?.email}`}>
            sending {schoolPersonToEdit.person?.firstName} an email
          </a>{' '}
          to give them a heads up in case the invitation goes to their junk/spam folder.
        </Typography>

        <Button
          style={{ minWidth: 100, margin: `${theme.spacing(2)}px 0px` }}
          variant="outlined"
          color="primary"
          onClick={modal.close}
        >
          Done
        </Button>
      </div>
    )
  }

  if (state.status === 'canceled') {
    return (
      <div>
        <Typography gutterBottom variant="h6">
          Invitation removed for {schoolPersonToEdit.person?.firstName}.
        </Typography>

        <Button
          style={{ minWidth: 100, margin: `${theme.spacing(2)}px 0px` }}
          variant="outlined"
          color="primary"
          onClick={modal.close}
        >
          Done
        </Button>
      </div>
    )
  }

  if (state.status === 'summary') {
    return (
      <div>
        <Typography gutterBottom variant="h6">
          {schoolPersonToEdit.person?.firstName} was invited{' '}
          {dayjs(schoolPersonToEdit.schoolPersonInvitation?.createdAt).fromNow()} (
          {dayjs(schoolPersonToEdit.schoolPersonInvitation?.createdAt).format('M/D/YY')}).
        </Typography>

        <div style={{ display: 'flex', margin: `${theme.spacing(2)}px 0px` }}>
          <CopyTextButton
            variant="outlined"
            textToCopy={`${process.env.REACT_APP_ROOT_URL}/invitation/${schoolPersonToEdit.schoolPersonInvitation?.slug}`}
            style={{ minWidth: 100, marginRight: theme.spacing(1) }}
          >
            Copy Invitation Link
          </CopyTextButton>

          <Button
            variant="outlined"
            color="primary"
            style={{ minWidth: 100, marginRight: theme.spacing(1) }}
            onClick={() => resendInvitation({ schoolPersonInvitationId: schoolPersonToEdit.id })}
          >
            Resend Invitation
          </Button>
          <Button
            variant="contained"
            className={buttonStyles.error}
            style={{ minWidth: 100, marginRight: theme.spacing(1) }}
            onClick={() => deleteInvitation({ schoolPersonId: schoolPersonToEdit.id })}
          >
            Cancel Invitation
          </Button>
        </div>
      </div>
    )
  }

  return null
}

interface IActiveAccountProps {
  modal: ReturnType<typeof useModal>
  schoolPersonToEdit: SchoolPersonFragment
  schoolName: string
}
const ActiveAccount = ({ schoolPersonToEdit, schoolName, modal }: IActiveAccountProps) => {
  const commonStyles = useCommonStyles()
  const theme = useTheme()

  return (
    <div>
      <Typography gutterBottom variant="h6">
        Their account is set up and good to go!
      </Typography>

      <Typography style={{ marginBottom: theme.spacing(2), display: 'flex', alignItems: 'center' }}>
        You can manage {schoolPersonToEdit.person?.firstName}'s permissions by pressing the{' '}
        <LockOpenOutlined
          style={{ margin: `0px ${theme.spacing(0.5)}px ${theme.spacing(0.5)}` }}
          className={commonStyles.baseIconColor}
        />{' '}
        icon.
      </Typography>
    </div>
  )
}
