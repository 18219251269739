import { Box, Button } from '@material-ui/core'
import { GroupListFieldsFragment } from '../../graphql/autogenerate/operations'
import { useButtonStyles } from '../../styles'
import { Checkbox } from '../forms'
import { ModalControls } from '../modal'
import { Dispatch, SetStateAction } from 'react'

type Props = {
  groupToEdit?: GroupListFieldsFragment
  modalControl: ModalControls
  confirmGroupDelete: ModalControls
  createAnother: boolean
  setCreateAnother: Dispatch<SetStateAction<boolean>>
  submitForm: () => void
}

const Actions = ({
  groupToEdit,
  modalControl,
  confirmGroupDelete,
  createAnother,
  setCreateAnother,
  submitForm,
}: Props) => {
  const { warningOutlined } = useButtonStyles()

  return (
    <Box flex={1} display="flex" justifyContent="space-between">
      {!!groupToEdit ? (
        <Button
          type="button"
          variant="outlined"
          className={warningOutlined}
          onClick={confirmGroupDelete.open}
        >
          Delete
        </Button>
      ) : (
        <Button
          style={{ minWidth: 100 }}
          type="button"
          onClick={modalControl.close}
          color="inherit"
        >
          Cancel
        </Button>
      )}

      <Box display="flex" justifyContent="flex-end" alignItems="center" flex={1}>
        {!!groupToEdit ? (
          <Button
            style={{ minWidth: 100 }}
            type="button"
            onClick={modalControl.close}
            color="inherit"
          >
            Cancel
          </Button>
        ) : (
          <Checkbox
            checked={createAnother}
            label="Save and create another"
            onChange={setCreateAnother}
            name="create-another-group"
          />
        )}
        <Box ml={1}>
          <Button
            style={{ minWidth: 100 }}
            type="submit"
            variant="outlined"
            color="primary"
            onClick={submitForm}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default Actions
