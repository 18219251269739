import { Button, Typography, useTheme } from '@material-ui/core'
import { useSnackbar } from 'notistack'
import { useState } from 'react'
import { useSetSchoolPersonAccessMutation } from '../../graphql/autogenerate/react-query'
import { Modal, useModal } from '../modal'
import { useHandleReactQueryMutation } from '../../hooks'
import { SchoolPersonFragment } from '../../graphql/autogenerate/operations'
import { useSchoolContext } from '../../stores/school'
import { useButtonStyles } from '../../styles'

type Props = {
  modal: ReturnType<typeof useModal>
  onModalClose: () => void
  schoolPersonToEdit?: SchoolPersonFragment
}

const RemoveAccessModal = ({ modal, onModalClose, schoolPersonToEdit }: Props) => {
  const theme = useTheme()
  const buttonStyles = useButtonStyles()

  const {
    state: {
      school: { name: schoolName },
    },
  } = useSchoolContext()
  const { enqueueSnackbar } = useSnackbar()
  const [state, setState] = useState({ removed: false })

  const { mutate: setAccessRevoked } = useHandleReactQueryMutation(
    useSetSchoolPersonAccessMutation({
      onSuccess: () => {
        enqueueSnackbar('Access revoked', { variant: 'warning' })
        setState({ removed: true })
      },
    })
  )

  const person = schoolPersonToEdit?.person
  if (!schoolPersonToEdit || !person) return null

  return (
    <Modal
      {...modal.props}
      title="Remove Access"
      size="sm"
      dismissible
      closeButton
      afterClose={() => {
        onModalClose()
        setState({ removed: false })
      }}
    >
      {state.removed ? (
        <div>
          <Typography gutterBottom variant="h6">
            {person.firstName} has been removed from {schoolName}.
          </Typography>

          <Typography gutterBottom>
            {person.firstName} will not be able to view any school content.
          </Typography>

          <Button
            style={{ minWidth: 100, margin: `${theme.spacing(2)}px 0px` }}
            variant="outlined"
            color="primary"
            onClick={modal.close}
          >
            Done
          </Button>
        </div>
      ) : (
        <div>
          <Typography gutterBottom variant="h6">
            Are you sure you want to remove{' '}
            <b>
              {person.firstName} {person.lastName} ({person.email})
            </b>{' '}
            from {schoolName}?
          </Typography>

          <Typography>{person.firstName} will not be able to view any school content.</Typography>

          <Button
            variant="contained"
            className={buttonStyles.error}
            style={{ minWidth: 100, margin: `${theme.spacing(2)}px 0px` }}
            onClick={() => {
              setAccessRevoked({ id: schoolPersonToEdit.id, accessRevoked: true })
            }}
          >
            Remove
          </Button>
        </div>
      )}
    </Modal>
  )
}

export default RemoveAccessModal
