import { LOCAL_STORAGE_ANONYMOUS_ACCOUNT_KEY } from '../../../constants'
import { useAuth, useLocalStorage } from '../../../hooks'
import { useSchoolContext } from '../../../stores/school'
import { FormValues, RegisterStep, State } from './types'

type Options = {
  state: State
  schoolId: string
  afterSubmit: () => void
}

const useOnSubmit = ({ state: { step }, schoolId, afterSubmit }: Options) => {
  const { signup, login, claimAnonymousAccount } = useAuth()

  const {
    actions: { refetch },
  } = useSchoolContext()

  const { getItem } = useLocalStorage()

  return async (values: FormValues) => {
    const anonymousAccountId = await getItem({
      key: LOCAL_STORAGE_ANONYMOUS_ACCOUNT_KEY,
    })

    let success = false

    if (step === RegisterStep.SignIn) {
      if (anonymousAccountId) {
        const results = await claimAnonymousAccount({
          anonymousAccountEmail: `${anonymousAccountId}@legitapps.com`,
          anonymousAccountPassword: anonymousAccountId,
          claimingAccountEmail: values.email,
          claimingAccountPassword: values.password,
        })

        if (results.claimAnonymousAccount?.jwtToken) {
          success = true
        }
      } else {
        const result = await login(values)
        if (result.authenticate?.jwtToken) {
          success = true
        }
      }
    } else if (step === RegisterStep.Register) {
      if (anonymousAccountId) {
        // Claim the anonymous account
        const result = await claimAnonymousAccount({
          anonymousAccountEmail: `${anonymousAccountId}@legitapps.com`,
          anonymousAccountPassword: anonymousAccountId,
          claimingAccountEmail: values.email,
          claimingAccountPassword: values.password,
          claimingPersonFirstName: values.firstName,
          claimingPersonLastName: values.lastName,
          schoolId,
        })

        if (result.claimAnonymousAccount?.jwtToken) {
          success = true
        }
      } else {
        const result = await signup(values, true)
        if (result.registerUser?.jwtToken) {
          success = true
        }
      }
    }

    if (success) {
      await refetch.schoolDetail()
      afterSubmit()
    }
  }
}

export default useOnSubmit
