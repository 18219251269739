import { Box, Divider, Drawer, Typography } from '@material-ui/core'
import React, { useMemo } from 'react'
import { Callout } from '../callout'
import GroupsTree from '../groups-tree/index'
import { GroupOptionType, InfoOption, isInfoOption } from './types'
import useGetContentForInfoOption from './use-get-content-for-info-option'
import { GroupSelectorMode } from '.'

type Props = {
  open: boolean
  close: () => void
  availableOptions: GroupOptionType[]
  selectedGroupIds: string[]
  onGroupSelected: (groupId: string) => void
  mode?: GroupSelectorMode
  excludeSchoolwide?: boolean
}

const Sidebar = React.memo(
  ({
    open,
    close,
    availableOptions,
    selectedGroupIds,
    onGroupSelected,
    mode,
    excludeSchoolwide,
  }: Props) => {
    const getContentForInfoOption = useGetContentForInfoOption()

    const infoOptions = useMemo(() => {
      return availableOptions.filter(
        o =>
          isInfoOption(o) &&
          (o.status === 'divisions-selected' || o.status === 'schoolwide-selected')
      ) as InfoOption[]
    }, [availableOptions])

    return (
      <Drawer anchor="left" open={open} onClose={close}>
        <Box width={250}>
          <Box p={1}>
            <Typography variant="h6">Select Group</Typography>
          </Box>

          <Divider />

          <Box
            m={1}
            children={infoOptions.map(o => (
              <Callout key={o.status} children={getContentForInfoOption(o)} type="warning" />
            ))}
          />

          <GroupsTree
            onGroupSelected={group => onGroupSelected(group.id)}
            selectedGroupIds={selectedGroupIds}
            mode={mode}
            excludeSchoolwide={excludeSchoolwide}
          />
        </Box>
      </Drawer>
    )
  }
)

export default Sidebar
