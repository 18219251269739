import { useMemo } from 'react'
import { SchoolDivisionsFragment } from '../../../graphql/autogenerate/operations'

type Options = {
  divisions: SchoolDivisionsFragment[]
  currentDivision?: SchoolDivisionsFragment
  selectedDivisionsWithGroups?: SchoolDivisionsFragment[]
}

const usePreviousDivision = ({
  divisions,
  currentDivision,
  selectedDivisionsWithGroups,
}: Options) =>
  useMemo(() => {
    if (divisions.length === 1 || !currentDivision || !selectedDivisionsWithGroups) return null
    const currentDivisionIdx = selectedDivisionsWithGroups.findIndex(
      o => o.id === currentDivision.id
    )
    if (currentDivisionIdx === -1 || currentDivisionIdx === 0) return null
    return selectedDivisionsWithGroups[currentDivisionIdx - 1]
  }, [selectedDivisionsWithGroups, currentDivision, divisions])

export default usePreviousDivision
