import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Button,
  Divider,
  Typography,
  useTheme,
} from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'
import { Form, Formik } from 'formik'
import { useState } from 'react'
import { useSchoolContext } from '../../../stores/school'
import AppAccess from './app-access'
import getInitialValues from './get-initial-values'
import GroupMembershipReset from './group-membership-reset'
import useHandleSubmit from './use-handle-submit'
import UserTypes from './user-types'
import validationSchema from './validation-schema'
import Warnings from './warnings'

const UserTypeSettings = () => {
  const {
    state: {
      school: { schoolSetting, id: schoolId },
    },
  } = useSchoolContext()

  const theme = useTheme()
  const [isOpen, setIsOpen] = useState(false)
  const onSubmit = useHandleSubmit()

  if (!schoolSetting) return null

  return (
    <Formik
      initialValues={getInitialValues(schoolSetting)}
      validationSchema={validationSchema}
      onSubmit={async values => {
        await onSubmit({ values, schoolSetting })
      }}
    >
      {formikProps => (
        <Accordion expanded={isOpen} onChange={(_, expanded) => setIsOpen(expanded)}>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography variant="h5">🧑‍🤝‍🧑 User Account Settings</Typography>
          </AccordionSummary>

          <AccordionDetails
            style={{
              padding: `0px ${theme.spacing(2)}px ${theme.spacing(2)}px `,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Warnings values={formikProps.values} />

            <Form style={{ flex: 1 }}>
              <UserTypes />

              <AppAccess formikProps={formikProps} schoolSetting={schoolSetting} />

              <GroupMembershipReset
                formikProps={formikProps}
                schoolSetting={schoolSetting}
                schoolId={schoolId}
              />
            </Form>
          </AccordionDetails>

          <Divider />

          <AccordionActions>
            <Button size="small" onClick={() => setIsOpen(false)}>
              Cancel
            </Button>

            <Button onClick={formikProps.submitForm} type="submit" size="small" color="primary">
              Save
            </Button>
          </AccordionActions>
        </Accordion>
      )}
    </Formik>
  )
}

export default UserTypeSettings
