import { GroupListFieldsFragment } from '../../graphql/autogenerate/operations'
import { useDeleteGroupMutation } from '../../graphql/autogenerate/react-query'
import { useHandleReactQueryMutation } from '../../hooks'
import { ConfirmDialog } from '../confirm-dialog'
import { ModalControls } from '../modal'

type Props = {
  confirmGroupDelete: ModalControls
  groupToEdit: GroupListFieldsFragment
  afterSave: () => void
  modalControl: ModalControls
}

const ConfirmDeleteDialog = ({
  confirmGroupDelete,
  groupToEdit,
  afterSave,
  modalControl,
}: Props) => {
  const { mutate: deleteGroup } = useHandleReactQueryMutation(useDeleteGroupMutation())

  return (
    <ConfirmDialog
      {...confirmGroupDelete.props}
      title={`Confirm Delete: ${groupToEdit?.groupName}`}
      confirmButton={{
        label: 'Delete',
        type: 'error',
      }}
      body={
        <div>
          <p>Are you sure you want to delete this Group?</p>
          <p>All content within this Group will be permanently deleted:</p>
          <ul>
            <li>Events</li>
            <li>Resources</li>
            <li>Newsletters</li>
            <li>Notification history</li>
          </ul>
          <p>
            Once the Group is deleted, it will be removed from all users' subscription lists and
            will no longer show up as a subscription option.
          </p>
        </div>
      }
      confirm={() => {
        deleteGroup(
          { groupId: groupToEdit.id },
          {
            onSettled: () => {
              afterSave()
              confirmGroupDelete.close()
              modalControl.close()
            },
          }
        )
      }}
    />
  )
}

export default ConfirmDeleteDialog
