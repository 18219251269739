import { Paper, Typography } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import { Form, Formik } from 'formik'
import { useEffect, useState } from 'react'
import { useSchoolContext } from '../../../stores/school'
import { ImageDisplay } from '../../image-display'
import RestartButton from '../restart-button'
import CreateAccount from './create-account'
import getValidationSchema from './get-validation-schema'
import SignIn from './sign-in'
import { defaultState, FormValues, RegisterStep, State } from './types'
import useOnSubmit from './use-on-submit'
import { useIsAnonymous } from '../../../hooks'

type Props = {
  afterSubmit: () => void
}

const Register = ({ afterSubmit }: Props) => {
  const theme = useTheme()

  const {
    state: {
      school: { id: schoolId, schoolSetting, name },
    },
  } = useSchoolContext()

  const isAnonymous = useIsAnonymous()

  const [state, setState] = useState<State>(defaultState)

  const onSubmit = useOnSubmit({ state, schoolId, afterSubmit })

  useEffect(() => {
    setState(prev => ({
      ...prev,
      step: isAnonymous ? RegisterStep.Register : RegisterStep.SignIn,
    }))
  }, [isAnonymous])

  if (!schoolSetting || state.step === undefined) return null

  const initialValues: FormValues = {
    email: '',
    password: '',
    confirmPassword: '',
    firstName: '',
    lastName: '',
  }

  return (
    <Paper style={{ padding: theme.spacing(2), margin: theme.spacing(2), position: 'relative' }}>
      <RestartButton />

      {schoolSetting.thumbnailImage?.temporaryDownloadUrl && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: theme.spacing(2),
          }}
        >
          <ImageDisplay
            style={{
              height: 75,
              width: 75,
              borderRadius: theme.shape.borderRadius,
            }}
            src={schoolSetting.thumbnailImage.temporaryDownloadUrl}
          />
        </div>
      )}

      <Typography style={{ marginBottom: theme.spacing(2) }} align="center">
        Create an account or sign to access the <b>{name}</b> app.
      </Typography>

      <Formik
        initialValues={initialValues}
        validationSchema={getValidationSchema(state.step)}
        onSubmit={onSubmit}
      >
        {formikProps => (
          <Form>
            {state.step === RegisterStep.SignIn && (
              <SignIn formik={formikProps} setState={setState} />
            )}
            {state.step === RegisterStep.Register && (
              <CreateAccount formik={formikProps} setState={setState} />
            )}
          </Form>
        )}
      </Formik>
    </Paper>
  )
}

export default Register
