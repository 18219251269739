import { Grid } from '@material-ui/core'
import { UserType, RegistrationMode } from '../../graphql/autogenerate/schemas'
import { Callout } from '../callout'
import { FormikSelectField, FormikTextInput, FormikUserTypeSelector } from '../forms'
import { FormValues } from './types'

type Props = {
  values: FormValues
  enabledUserTypes: UserType[]
  registrationMode?: RegistrationMode | null
}

const Access = ({ values, enabledUserTypes, registrationMode }: Props) => {
  let accessDescription = 'Users can join without a passcode or admin approval'

  if (values.registrationMode === 'passcode') {
    accessDescription = 'Requires a passcode to join (note: the passcode is case sensitive)'
  } else if (values.registrationMode === 'auto-enroll') {
    accessDescription =
      'The group will be hidden and users cannot choose to join, but will be automatically added based on their UserType(s)'
  } else if (values.registrationMode === 'admin') {
    accessDescription = 'The group will be hidden and admins must add users to the group'
  }

  const options = [
    { label: 'Open', value: 'open', key: 'open' },
    {
      label: 'Passcode',
      value: 'passcode',
      key: 'passcode',
    },
    {
      label: 'Auto-enroll',
      value: 'auto-enroll',
      key: 'auto-enroll',
    },
  ]

  if (registrationMode) {
    options.push({
      label: 'Admin',
      value: 'admin',
      key: 'admin',
    })
  }

  return (
    <>
      <Grid item container xs={12}>
        <FormikSelectField
          hideHelperTextWhenEmpty
          fieldProps={{ name: 'registrationMode', label: 'Access Type' }}
          options={options}
          getKeyFromOptionValue={value => value}
        />
      </Grid>

      {values.registrationMode && (
        <Grid item container xs={12} justify="center" style={{ marginBottom: 16 }}>
          <Callout style={{ flex: 1 }}>{accessDescription}</Callout>
        </Grid>
      )}

      <Grid item container xs={12}>
        {values.registrationMode === 'passcode' && (
          <div style={{ display: 'flex', width: '100%' }}>
            <FormikTextInput
              style={{ flex: 1 }}
              fieldProps={{ name: 'passcode', label: 'Passcode' }}
            />
            <FormikTextInput
              style={{ flex: 1 }}
              fieldProps={{
                name: 'passcodeHint',
                label: 'Passcode Hint (optional)',
              }}
            />
          </div>
        )}

        {values.registrationMode === 'auto-enroll' && (
          <div style={{ display: 'flex', width: '100%' }}>
            <FormikUserTypeSelector
              disableAll
              field={{ name: 'autoEnrollUserTypes' }}
              userTypes={enabledUserTypes}
            />
          </div>
        )}
      </Grid>
    </>
  )
}

export default Access
