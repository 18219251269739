export type State = {
  step?:
    | 'check-account'
    | 'password-reset-required'
    | 'sign-in'
    | 'invitation-not-accepted'
    | 'not-added'
  email: string | null
  schoolPersonId: string | null
}

export const defaultState: State = {
  email: null,
  schoolPersonId: null,
}

export type FormValues = {
  email: string
  password: string
}
