import * as Yup from 'yup'

const validationSchema = Yup.object({
  groupCategory: Yup.object({}).required('Required'),
  name: Yup.string().required('Required'),
  requirePasscode: Yup.bool(),
  passcode: Yup.string()
    .nullable()
    .test('passcode', 'Required', function (value) {
      if (this.parent.requirePasscode) return Boolean(value)
      return true
    }),
  passcodeHint: Yup.string().nullable(),
  autoEnrollUserTypes: Yup.array().nullable().required('Required'),
})

export default validationSchema
