import { useMemo, useState } from 'react'
import { useSchoolUsersQuery } from '../../graphql/autogenerate/react-query'
import { UserType } from '../../graphql/autogenerate/schemas'
import { useHandleReactQuery } from '../../hooks/use-handle-react-query'
import { useSchoolContext } from '../../stores/school'
import { Table } from '../table'
import useColumns from './use-columns'
import { useModal } from '../modal'
import EditPersonModal from './edit-person-modal'
import { SchoolPersonFragment } from '../../graphql/autogenerate/operations'
import RemoveAccessModal from './remove-access-modal'
import RestoreAccessModal from './restore-access-modal'

type Props = {
  userTypes: UserType[]
}

const ManagePeopleTable = ({ userTypes }: Props) => {
  const {
    state: {
      school: { id: schoolId },
    },
  } = useSchoolContext()

  const [personToEdit, setPersonToEdit] = useState<SchoolPersonFragment>()
  const editPersonModal = useModal()
  const deletePersonModal = useModal()
  const restoreAccessModal = useModal()

  const { data, refetch } = useHandleReactQuery(useSchoolUsersQuery({ schoolId, userTypes }))

  const memoizedPeople = useMemo(() => {
    if (!data?.schoolPeople?.nodes) return []
    return data?.schoolPeople?.nodes
  }, [data?.schoolPeople?.nodes])

  const columns = useColumns({
    onEdit: person => {
      setPersonToEdit(person)
      editPersonModal.open()
    },
    onDelete: person => {
      setPersonToEdit(person)
      deletePersonModal.open()
    },
    onRestoreAccess: person => {
      setPersonToEdit(person)
      restoreAccessModal.open()
    },
  })

  return (
    <>
      <Table
        data={memoizedPeople}
        columns={columns}
        tableSize="small"
        hover
        initialTableState={{ sortBy: [{ id: 'name' }] }}
      />

      <EditPersonModal
        modal={editPersonModal}
        schoolPersonToEdit={personToEdit}
        onModalClose={() => {
          setPersonToEdit(undefined)
          refetch()
        }}
      />

      <RemoveAccessModal
        modal={deletePersonModal}
        schoolPersonToEdit={personToEdit}
        onModalClose={() => {
          setPersonToEdit(undefined)
          refetch()
        }}
      />

      <RestoreAccessModal
        modal={restoreAccessModal}
        schoolPersonToEdit={personToEdit}
        onModalClose={() => {
          setPersonToEdit(undefined)
          refetch()
        }}
      />
    </>
  )
}

export default ManagePeopleTable
