import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Button,
  Divider,
  Typography,
  useTheme,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import { Form, Formik } from "formik";
import { useSnackbar } from "notistack";
import { useState } from "react";
import {
  useSchoolAppQuery,
  useUpdateOneSignalSchoolSettingsMutation,
} from "../../graphql/autogenerate/react-query";
import { useHandleReactQuery, useHandleReactQueryMutation } from "../../hooks";
import { useSchoolContext } from "../../stores/school";
import { FormikTextInput } from "../forms";

export const PushNotificationsSettings = () => {
  const {
    state: {
      school: { schoolSetting, id: schoolId },
    },
    actions: {
      refetch: { schoolDetail: refetchSchoolDetails },
    },
  } = useSchoolContext();

  const { data } = useHandleReactQuery(useSchoolAppQuery({ schoolId }));
  const app = data?.apps?.nodes[0];

  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [isOpen, setIsOpen] = useState(false);

  const { mutate: updateOneSignalSettings } = useHandleReactQueryMutation(
    useUpdateOneSignalSchoolSettingsMutation({
      onSuccess: () => {
        enqueueSnackbar("Push notification settings updated", {
          variant: "success",
        });
        refetchSchoolDetails();
      },
    })
  );

  if (!schoolSetting || !app) return null;

  return (
    <Formik
      initialValues={{
        onesignalAppId: app.onesignalAppId || "",
        onesignalAppAuthToken: app.onesignalAppAuthToken || "",
      }}
      onSubmit={(values) =>
        updateOneSignalSettings({ ...values, appId: app.id })
      }
    >
      {(formikProps) => (
        <Accordion
          expanded={isOpen}
          onChange={(_, expanded) => setIsOpen(expanded)}
        >
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography variant="h5">📲 Push Notifications</Typography>
          </AccordionSummary>
          <AccordionDetails
            style={{
              padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
            }}
          >
            <Form style={{ flex: 1 }}>
              <div style={{ display: "flex" }}>
                <FormikTextInput
                  hideHelperTextWhenEmpty
                  fieldProps={{ name: "onesignalAppId", label: "App ID" }}
                />
                <FormikTextInput
                  hideHelperTextWhenEmpty
                  fieldProps={{
                    name: "onesignalAppAuthToken",
                    label: "Key",
                  }}
                />
              </div>
            </Form>
          </AccordionDetails>
          <Divider />
          <AccordionActions>
            <Button size="small" onClick={() => setIsOpen(false)}>
              Cancel
            </Button>
            <Button
              onClick={formikProps.submitForm}
              type="submit"
              size="small"
              color="primary"
            >
              Save
            </Button>
          </AccordionActions>
        </Accordion>
      )}
    </Formik>
  );
};
