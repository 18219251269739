import { Button, Typography, useTheme } from '@material-ui/core'
import { useMemo, useState } from 'react'
import { useNavigate } from 'react-router'
import { REFETCH_INTERVAL } from '../constants'
import {
  GlobalGroupListFragment,
  SchoolDivisionsFragment,
} from '../graphql/autogenerate/operations'
import { useCurrentUserSchoolGroupsQuery } from '../graphql/autogenerate/react-query'
import { IconType } from '../graphql/autogenerate/schemas'
import { useHandleReactQuery } from '../hooks'
import { useSchoolContext } from '../stores/school'
import { useButtonStyles } from '../styles'
import { Callout } from './callout'
import { IconTypeDisplay } from './icon-type-display'
import SchoolOnboarding from './school-onboarding'

export const SchoolGroupsList = () => {
  const theme = useTheme()
  const {
    state: {
      school: {
        id: schoolId,
        divisions: { totalCount: divisionsCount, nodes: divisions },
      },
    },
  } = useSchoolContext()
  const { data, refetch } = useHandleReactQuery(
    useCurrentUserSchoolGroupsQuery({ schoolId }, { refetchInterval: REFETCH_INTERVAL })
  )
  const navigate = useNavigate()
  const [showManage, setShowManage] = useState(false)
  const buttonStyles = useButtonStyles()

  const groupsByDivision = useMemo(() => {
    const groups = data?.currentUser?.groupPeopleForSchool?.nodes
      .map(o => o.group)
      .sort((a, b) => {
        if (a?.globalSortOrder && !b?.globalSortOrder) return 1
        if (!a?.globalSortOrder && b?.globalSortOrder) return -1
        if (a?.globalSortOrder && b?.globalSortOrder) return a.globalSortOrder - b.globalSortOrder
        return 0
      })

    return divisions.reduce((_groupsByDivision, division) => {
      const divisionGroups =
        (groups?.filter(o => !!o && o?.divisionId === division.id) as GlobalGroupListFragment[]) ||
        []
      if (divisionGroups.length) {
        _groupsByDivision.push({
          division,
          groups:
            (groups?.filter(
              o => !!o && o?.divisionId === division.id
            ) as GlobalGroupListFragment[]) || [],
        })
      }

      return _groupsByDivision
    }, [] as { division: SchoolDivisionsFragment; groups: GlobalGroupListFragment[] }[])
  }, [data])

  return (
    <>
      <div style={{ padding: theme.spacing(1), paddingBottom: 63 }}>
        {groupsByDivision.map(({ division, groups }) => (
          <div key={division.id}>
            {divisionsCount > 1 && <Typography variant="h5">{division.name}</Typography>}
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {groups?.map(group => (
                <div
                  key={group?.id}
                  style={{ margin: theme.spacing(1) }}
                  onClick={() => navigate(`${group.slug}/resources`)}
                >
                  <div
                    style={{
                      opacity: group.hasContent ? 1 : 0.5,
                      height: 100,
                      width: 100,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      borderRadius: '50%',
                      backgroundColor:
                        group.groupCategory?.iconBackgroundColor || theme.palette.primary.main,
                      color: 'white',
                    }}
                  >
                    <IconTypeDisplay type={group.icon as IconType} size="50%" />
                  </div>
                  <Typography
                    color={group.hasContent ? 'textPrimary' : 'textSecondary'}
                    style={{
                      maxWidth: 100,
                      marginTop: theme.spacing(0.5),
                      wordBreak: 'break-word',
                    }}
                    align="center"
                  >
                    {group.name}
                  </Typography>
                </div>
              ))}
            </div>
          </div>
        ))}

        <Callout type="info" style={{ marginTop: theme.spacing(2) }}>
          <div>
            <Typography gutterBottom>
              Being a part of a group allows you to see its latest resources, newsletters, events,
              and notifications.
            </Typography>
            <div style={{ marginBottom: theme.spacing(1), marginTop: theme.spacing(2) }}>
              <Button
                onClick={() => setShowManage(true)}
                size="small"
                className={buttonStyles.white}
                variant="outlined"
              >
                Manage Groups
              </Button>
            </div>
          </div>
        </Callout>
      </div>
      {showManage && (
        <SchoolOnboarding
          initialStep="user-type"
          onClose={() => {
            refetch()
            setShowManage(false)
          }}
        />
      )}
    </>
  )
}
