import { useTheme } from '@material-ui/core'
import { memo } from 'react'
import {
  GroupCategoryListFieldsFragment,
  GroupListFieldsFragment,
} from '../../graphql/autogenerate/operations'
import { MemoizedExpandableDropdown } from '../expandable-dropdown'
import { IconTypeDisplay } from '../icon-type-display'
import GroupRow from './group-row'

const GroupCategory = memo(
  ({
    category,
    groupSelected,
    displayGroups,
    disabled,
  }: {
    category: GroupCategoryListFieldsFragment
    displayGroups: GroupListFieldsFragment[]
    disabled?: boolean
    selectedGroupIds?: string[]
    groupSelected: (group: GroupListFieldsFragment) => void
  }) => {
    const theme = useTheme()
    return (
      <MemoizedExpandableDropdown
        sectionTitle={
          <>
            <IconTypeDisplay
              type={category.iconType}
              size={20}
              style={{
                marginRight: 5,
                color: category.iconBackgroundColor || theme.palette.grey[500],
              }}
            />
            {category.name}
          </>
        }
      >
        <div style={{ paddingLeft: theme.spacing(3.5) }}>
          {category.groups.nodes
            .filter(o => displayGroups.some(g => g.id === o.id))
            .map(group => (
              <GroupRow
                key={group.id}
                title={group.groupName}
                iconType={group.iconType || category.iconType}
                color={category.iconBackgroundColor || theme.palette.grey[500]}
                onClick={() => groupSelected(group)}
                disabled={disabled}
              />
            ))}
        </div>
      </MemoizedExpandableDropdown>
    )
  }
)

export default GroupCategory
