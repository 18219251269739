import { Button, useTheme } from "@material-ui/core";
import { FormikContextType } from "formik";
import { SchoolSettingFragment } from "../../../graphql/autogenerate/operations";
import { useCancelScheduleSettingsResetMutation } from "../../../graphql/autogenerate/react-query";
import { UserType } from "../../../graphql/autogenerate/schemas";
import { useHandleReactQueryMutation } from "../../../hooks";
import { Callout } from "../../callout";
import { FormikDatePicker } from "../../forms";
import { FormValues } from "./types";

interface Props {
  formikProps: FormikContextType<FormValues>;
  schoolSetting: SchoolSettingFragment;
  schoolId: string;
}

const GroupMembershipReset = ({
  formikProps,
  schoolSetting,
  schoolId,
}: Props) => {
  const theme = useTheme();

  const { mutateAsync: cancelScheduleSettingsReset } =
    useHandleReactQueryMutation(useCancelScheduleSettingsResetMutation());

  return (
    <>
      <h3>Group Membership Reset</h3>
      <Callout
        type="info"
        style={{ display: "flex", marginBottom: theme.spacing(1) }}
      >
        Choose dates to (optionally) require users of each type to re-do their
        app onboarding the next time they open the app.
      </Callout>

      <div style={{ display: "inline-flex", flexDirection: "column" }}>
        <FormikDatePicker
          fieldProps={{
            name: "scheduledParentReset",
            label: "Parent/Family",
            disabled: !formikProps.values.enableParentFamilyAccess,
          }}
        />
        {schoolSetting.scheduledParentReset && (
          <Button
            onClick={() => {
              cancelScheduleSettingsReset({
                schoolId,
                userType: UserType.Parent,
              });
              formikProps.setFieldValue("scheduledParentReset", null);
            }}
            variant="text"
          >
            Cancel
          </Button>
        )}
      </div>

      <div
        style={{
          display: "inline-flex",
          flexDirection: "column",
          marginLeft: theme.spacing(2),
        }}
      >
        <FormikDatePicker
          fieldProps={{
            name: "scheduledStudentReset",
            label: "Student",
            disabled: !formikProps.values.enableStudentAccess,
          }}
        />
        {schoolSetting.scheduledStudentReset && (
          <Button
            onClick={() => {
              cancelScheduleSettingsReset({
                schoolId,
                userType: UserType.Student,
              });
              formikProps.setFieldValue("scheduledStudentReset", null);
            }}
            variant="text"
          >
            Cancel
          </Button>
        )}
      </div>

      <div
        style={{
          display: "inline-flex",
          flexDirection: "column",
          marginLeft: theme.spacing(2),
        }}
      >
        <FormikDatePicker
          fieldProps={{
            name: "scheduledGuestReset",
            label: "Guest",
            disabled: !formikProps.values.enableGuestAccess,
          }}
        />
        {schoolSetting.scheduledGuestReset && (
          <Button
            onClick={() => {
              cancelScheduleSettingsReset({
                schoolId,
                userType: UserType.Guest,
              });
              formikProps.setFieldValue("scheduledGuestReset", null);
            }}
            variant="text"
          >
            Cancel
          </Button>
        )}
      </div>

      <div
        style={{
          display: "inline-flex",
          flexDirection: "column",
          marginLeft: theme.spacing(2),
        }}
      >
        <FormikDatePicker
          fieldProps={{
            name: "scheduledFacultyReset",
            label: "Faculty/Staff",
          }}
        />
        {schoolSetting.scheduledFacultyReset && (
          <Button
            onClick={() => {
              cancelScheduleSettingsReset({
                schoolId,
                userType: UserType.FacultyStaff,
              });
              formikProps.setFieldValue("scheduledFacultyReset", null);
            }}
            variant="text"
          >
            Cancel
          </Button>
        )}
      </div>
    </>
  );
};

export default GroupMembershipReset;