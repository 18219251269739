import { Box, Button, Typography } from '@material-ui/core'
import { Formik } from 'formik'
import { useSnackbar } from 'notistack'
import { SchoolPersonFragment } from '../../graphql/autogenerate/operations'
import { useAdminUpdatePersonGroupMembershipAndUserTypesMutation } from '../../graphql/autogenerate/react-query'
import { useHandleReactQueryMutation } from '../../hooks'
import { useSchoolContext } from '../../stores/school'
import { FormikGroupSelector } from '../forms/formik-group-selector'
import { FormikUserTypeSelector } from '../forms/formik-user-type-selector'
import { GroupSelectorMode } from '../group-selector'
import { Modal, ModalControls } from '../modal'

type Props = {
  modal: ModalControls
  schoolPersonToEdit?: SchoolPersonFragment
  onModalClose: () => void
}

const EditPersonModal = ({ modal: { props, close }, schoolPersonToEdit, onModalClose }: Props) => {
  const {
    state: {
      enabledUserTypes,
      school: {
        divisions: { nodes: divisions },
        id: schoolId,
      },
    },
  } = useSchoolContext()

  const schoolwideGroupId = divisions?.find(o => o.schoolwide)?.group?.id

  const { enqueueSnackbar } = useSnackbar()

  const { mutateAsync: updatePersonGroupMembershipAndUserTypes } = useHandleReactQueryMutation(
    useAdminUpdatePersonGroupMembershipAndUserTypesMutation({
      onSuccess: () => {
        enqueueSnackbar('Person successfully updated.', {
          variant: 'success',
        })
      },
    })
  )

  const person = schoolPersonToEdit?.person
  if (!person || !schoolPersonToEdit || !schoolwideGroupId) return null

  const initialValues = {
    groupIds: person.memberGroups.nodes.map(o => o.groupId),
    userTypes: schoolPersonToEdit.userTypes,
  }

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={async values => {
        await updatePersonGroupMembershipAndUserTypes({
          personId: person.id,
          schoolId,
          selectedGroupIds: [schoolwideGroupId, ...values.groupIds],
          userTypes: values.userTypes,
          adminMode: true,
        })
        close()
      }}
    >
      {({ submitForm, resetForm }) => (
        <Modal
          {...props}
          title="Manage App Access"
          size="sm"
          dismissible
          closeButton
          afterClose={() => {
            resetForm()
            onModalClose()
          }}
          actions={
            <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                type="button"
                style={{ minWidth: 100 }}
                variant="contained"
                color="primary"
                onClick={submitForm}
              >
                Save
              </Button>
            </div>
          }
        >
          <div>
            <Box mb={2}>
              <Typography variant="h6">
                {`${person.firstName} ${person.lastName} (${person.email})`}
              </Typography>
            </Box>

            <FormikGroupSelector
              divisions={divisions}
              fieldProps={{ name: 'groupIds', label: 'Group membership' }}
              excludeSchoolwide
              mode={GroupSelectorMode.flat}
            />
            <FormikUserTypeSelector
              field={{ name: 'userTypes', label: 'User type(s)' }}
              userTypes={enabledUserTypes}
              disableAll
              forSingleUser
            />
          </div>
        </Modal>
      )}
    </Formik>
  )
}

export default EditPersonModal
