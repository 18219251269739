import {
  Checkbox,
  createStyles,
  Divider,
  FormControlLabel,
  makeStyles,
  Typography,
  useTheme,
} from '@material-ui/core'
import { Lock } from '@material-ui/icons'

const useStyles = makeStyles(() =>
  createStyles({
    fullWidth: {
      flex: 1,
    },
  })
)

type Props = {
  checked: boolean
  onChange: (checked: boolean) => void
  label: string
  disabled?: boolean
  showPasscodeLock?: boolean
}

const SelectionCheckbox = ({ checked, onChange, label, disabled, showPasscodeLock }: Props) => {
  const theme = useTheme()
  const styles = useStyles()

  return (
    <>
      <Divider />
      <FormControlLabel
        classes={{
          label: styles.fullWidth,
        }}
        control={
          <Checkbox
            disabled={disabled}
            checked={checked}
            onChange={(_, _checked) => onChange(_checked)}
            color="primary"
            disableRipple
            style={{
              transform: 'scale(1.5)',
              margin: `${theme.spacing(2)}px ${theme.spacing(1)}px ${theme.spacing(2)}px 6px`,
            }}
          />
        }
        label={
          <Typography
            style={{
              fontSize: 18,
              flex: 1,
              color: checked ? theme.palette.primary.main : 'inherit',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            {label}

            {showPasscodeLock && (
              <div
                style={{
                  display: 'block',
                  color: theme.palette.grey[800],
                  padding: 1,
                  backgroundColor: 'white',
                }}
              >
                <Lock style={{ width: 15, height: 15 }} />
              </div>
            )}
          </Typography>
        }
      />
    </>
  )
}

export default SelectionCheckbox
