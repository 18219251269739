import { IconButton, useTheme } from '@material-ui/core'
import { ArrowBack } from '@material-ui/icons'
import { Dispatch, SetStateAction } from 'react'
import { useSchoolOnboardingContext } from '..'
import { SchoolDivisionsFragment } from '../../../graphql/autogenerate/operations'
import { State } from './types'
import usePreviousDivision from './use-previous-division'

type Props = {
  divisions: SchoolDivisionsFragment[]
  selectedDivisionsWithGroups?: SchoolDivisionsFragment[]
  currentDivision?: SchoolDivisionsFragment
  setState: Dispatch<SetStateAction<State>>
}

const BackButton = ({
  divisions,
  selectedDivisionsWithGroups,
  currentDivision,
  setState,
}: Props) => {
  const theme = useTheme()
  const { setSchoolOnboardingState } = useSchoolOnboardingContext()

  const previousDivisionToSelectGroupsFor = usePreviousDivision({
    divisions,
    currentDivision,
    selectedDivisionsWithGroups,
  })

  return (
    <div style={{ position: 'absolute', top: theme.spacing(1), left: 0 }}>
      <IconButton
        onClick={() => {
          if (previousDivisionToSelectGroupsFor) {
            setState(_state => ({
              ..._state,
              currentDivision: previousDivisionToSelectGroupsFor,
            }))
          } else if (currentDivision) {
            setState(_state => ({
              ..._state,
              currentDivision: undefined,
            }))
          } else {
            setSchoolOnboardingState(_state => ({
              ..._state,
              step: 'user-type',
            }))
          }
        }}
      >
        <ArrowBack />
      </IconButton>
    </div>
  )
}

export default BackButton
