import { FormControl, TextField, useTheme } from '@material-ui/core'
import { InfoOutlined } from '@material-ui/icons'
import clsx from 'clsx'
import { useFormStyles } from '../../styles/use-form-styles'
import { CommonProps, MUIInputVariants } from '../interfaces'
import { Tooltip } from '../tooltip'
import { MATERIAL_FIELD_VARIANT } from './constants'
import { IField } from './interfaces'

export interface ITextInputProps<ValueType> extends CommonProps {
  fieldProps: IField
  value: ValueType
  onChange: (value: string) => void
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
  error?: string
  touched?: boolean
  initialError?: string
  hideHelperTextWhenEmpty?: boolean
  size?: 'small' | 'medium'
  variant?: MUIInputVariants
  textFieldOnly?: boolean
  autoFocus?: boolean
  multiline?: boolean
  rows?: number
  type?: 'number' | 'email'
  noMargin?: boolean
  disabled?: boolean
  tooltip?: string
}

export const TextInput = <ValueType,>({
  style,
  type,
  fieldProps,
  value,
  onChange,
  onBlur,
  error,
  touched,
  initialError,
  hideHelperTextWhenEmpty,
  size,
  variant = MATERIAL_FIELD_VARIANT,
  textFieldOnly,
  autoFocus,
  multiline,
  rows,
  noMargin,
  disabled,
  tooltip,
}: ITextInputProps<ValueType>) => {
  const classes = useFormStyles()
  const theme = useTheme()

  const textField = textFieldOnly ? (
    <TextField
      disabled={disabled}
      type={type}
      style={style}
      variant={variant}
      name={fieldProps.name}
      value={value}
      error={(touched && !!error) || !!initialError}
      onChange={e => onChange(e.currentTarget.value)}
      onBlur={onBlur}
      InputProps={{
        style: {
          fontSize: 'inherit',
          padding: 0,
        },
      }}
      inputProps={{
        style: {
          padding: 0,
          height: 'inherit',
        },
      }}
      autoFocus
      multiline={multiline}
      rows={rows}
    />
  ) : (
    <FormControl
      disabled={disabled}
      className={clsx(!noMargin && classes.margin, classes.root)}
      variant={variant}
      style={style}
    >
      <TextField
        disabled={disabled}
        type={type}
        variant={variant}
        label={fieldProps.label}
        name={fieldProps.name}
        value={value}
        helperText={
          (touched && error) || fieldProps.helperText || (!hideHelperTextWhenEmpty && ' ')
        }
        error={(touched && !!error) || !!initialError}
        onChange={e => onChange(e.currentTarget.value)}
        onBlur={onBlur}
        size={size}
        autoFocus={autoFocus}
        multiline={multiline}
        rows={rows}
      />
    </FormControl>
  )

  if (tooltip) {
    return (
      <div>
        {textField}
        <Tooltip title={tooltip}>
          <InfoOutlined style={{ color: theme.palette.grey[500], cursor: 'pointer' }} />
        </Tooltip>
      </div>
    )
  } else {
    return textField
  }
}
