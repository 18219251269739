import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
  useTheme,
} from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { SetStateAction, useAtom, WritableAtom } from 'jotai'
import { RESET } from 'jotai/utils'
import React, { CSSProperties, FunctionComponent, ReactNode, useState } from 'react'
import { v4 } from 'uuid'
import { useFullScreenModalBreakpoint } from '../hooks'
import { Tooltip } from './tooltip'

export const useModalWithAtom = (
  atom: WritableAtom<boolean, typeof RESET | SetStateAction<boolean>>
) => {
  const [id] = useState(v4())

  const [isOpen, setIsOpen] = useAtom(atom)

  const open = () => setIsOpen(true)
  const close = () => setIsOpen(false)

  const props: Pick<IModalProps, 'id' | 'isOpen' | 'close'> = {
    id,
    isOpen,
    close,
  }

  return {
    props,
    open,
    close,
  }
}

export const useModal = (): ModalControls => {
  const [id] = useState(v4())

  const [isOpen, setIsOpen] = useState(false)

  const open = () => setIsOpen(true)
  const close = () => setIsOpen(false)

  const props: Pick<IModalProps, 'id' | 'isOpen' | 'close'> = {
    id,
    isOpen,
    close,
  }

  return {
    props,
    open,
    close,
  }
}

export type ModalControls = {
  open: () => void
  close: () => void
  props: Pick<IModalProps, 'id' | 'isOpen' | 'close'>
}

interface IModalProps {
  id: string

  // Content
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false
  fullscreen?: boolean
  title: ReactNode
  dividers?: boolean
  actions?: ReactNode
  closeButton?: boolean
  noPadding?: boolean
  style?: CSSProperties

  // Controls
  isOpen: boolean
  close: () => void
  afterClose?: () => Promise<void> | void
  dismissible?: boolean
  disableEscapeKeyDown?: boolean
  noHeader?: boolean
}

export const Modal: FunctionComponent<IModalProps> = ({
  id,
  size,
  fullscreen,
  title,
  children,
  dividers,
  actions,
  dismissible,
  isOpen,
  afterClose,
  close,
  disableEscapeKeyDown,
  closeButton,
  noPadding,
  style,
  noHeader,
}) => {
  const _fullScreen = useFullScreenModalBreakpoint() || fullscreen

  const theme = useTheme()

  return (
    <Dialog
      fullScreen={_fullScreen}
      scroll="paper"
      open={isOpen}
      onClose={dismissible ? close : undefined}
      // onExited={afterClose}
      // onExit={afterClose}
      TransitionProps={{
        onExited: () => {
          afterClose && afterClose()
        },
      }}
      aria-labelledby={`${id}-title`}
      maxWidth={size}
      fullWidth={!!size}
      disableEscapeKeyDown={disableEscapeKeyDown}
      PaperProps={{ style }}
      disableEnforceFocus
    >
      {!noHeader && (
        <DialogTitle
          id={`${id}-title`}
          disableTypography
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: 0,
            alignItems: 'center',
          }}
        >
          <Typography variant="h5" style={{ padding: theme.spacing(2) }}>
            {title}
          </Typography>
          {(closeButton || _fullScreen) && (
            <Tooltip title="Close" style={{ marginRight: theme.spacing(1) }}>
              <IconButton aria-label="Close modal" onClick={close}>
                <Close />
              </IconButton>
            </Tooltip>
          )}
        </DialogTitle>
      )}

      <DialogContent
        dividers={dividers}
        style={{
          ...(noPadding ? { padding: 0 } : {}),
          display: 'flex',
          flexDirection: 'column',
          minHeight: 0,
          // @ts-ignore
          overflowY: 'overlay',
        }}
      >
        {children}
      </DialogContent>

      {actions ? <DialogActions>{actions}</DialogActions> : null}
    </Dialog>
  )
}
