import { Button, Typography, useTheme } from '@material-ui/core'
import { useSnackbar } from 'notistack'
import { useState } from 'react'
import { useRemoveFacultyStaffFromSchoolMutation } from '../graphql/autogenerate/react-query'
import { Modal, useModal } from './modal'
import { useHandleReactQueryMutation } from '../hooks'
import { SchoolPersonFragment } from '../graphql/autogenerate/operations'
import { useSchoolContext } from '../stores/school'
import { useButtonStyles } from '../styles'

interface ISchoolPersonRemoveModalProps {
  modal: ReturnType<typeof useModal>
  afterClose: () => void
  schoolPersonToEdit?: SchoolPersonFragment
}

export const SchoolPersonRemoveModal = ({
  modal,
  afterClose,
  schoolPersonToEdit,
}: ISchoolPersonRemoveModalProps) => {
  const theme = useTheme()
  const buttonStyles = useButtonStyles()
  const {
    state: {
      school: { name: schoolName, id: schoolId },
    },
  } = useSchoolContext()
  const { enqueueSnackbar } = useSnackbar()
  const [state, setState] = useState({ removed: false })

  const { mutate: removePerson } = useHandleReactQueryMutation(
    useRemoveFacultyStaffFromSchoolMutation({
      onSuccess: () => {
        enqueueSnackbar('Person removed as Faculty/Staff.', { variant: 'warning' })
        setState({ removed: true })
      },
    })
  )

  return (
    <Modal
      {...modal.props}
      title="Remove Person"
      size="sm"
      dismissible
      closeButton
      afterClose={() => {
        afterClose()
        setState({ removed: false })
      }}
    >
      {state.removed ? (
        <div>
          <Typography gutterBottom variant="h6">
            {schoolPersonToEdit?.person?.firstName} has been removed from {schoolName}.
          </Typography>

          <Typography gutterBottom>
            All of their admin permissions have been revoked.{' '}
            {schoolPersonToEdit?.person?.firstName} will not be able to manage any school content.
            You can reinvite them at any time to restore their access.
          </Typography>

          <Button
            style={{ minWidth: 100, margin: `${theme.spacing(2)}px 0px` }}
            variant="outlined"
            color="primary"
            onClick={modal.close}
          >
            Done
          </Button>
        </div>
      ) : (
        <div>
          <Typography gutterBottom variant="h6">
            Are you sure you want to remove <b>{schoolPersonToEdit?.person?.firstName}</b> from{' '}
            {schoolName}?
          </Typography>

          <Typography>
            This will revoke all their admin access permissions. If{' '}
            {schoolPersonToEdit?.person?.firstName} is a parent, they will still be able to view
            publicly accessible content like other parents.
          </Typography>

          <Button
            variant="contained"
            className={buttonStyles.error}
            style={{ minWidth: 100, margin: `${theme.spacing(2)}px 0px` }}
            onClick={() => {
              if (!schoolPersonToEdit?.person) throw new Error('Missing school person to remove.')
              removePerson({ personId: schoolPersonToEdit.person?.id, schoolId })
            }}
          >
            Remove
          </Button>
        </div>
      )}
    </Modal>
  )
}
