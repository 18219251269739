import { Button } from '@material-ui/core'
import { ArrowForward } from '@material-ui/icons'
import { FormikTextInput } from '../../../forms'
import { StepProps } from './types'

const VerifyEmail = ({ formik }: StepProps) => (
  <>
    <div style={{ width: '100%', display: 'flex' }}>
      <FormikTextInput
        type="email"
        noMargin
        fieldProps={{
          name: 'email',
          label: 'Email',
        }}
      />
    </div>

    <Button
      disabled={!formik.isValid}
      style={{ width: '100%' }}
      size="large"
      type="submit"
      color="primary"
      variant="contained"
      endIcon={<ArrowForward />}
    >
      Continue
    </Button>
  </>
)

export default VerifyEmail
