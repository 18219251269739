import { Box, TextField, TextFieldProps } from '@material-ui/core'
import { Autocomplete, AutocompleteRenderInputParams } from '@material-ui/lab'
import clsx from 'clsx'
import React, { useCallback, useMemo, useState } from 'react'
import { SchoolDivisionsFragment } from '../../graphql/autogenerate/operations'
import { useAppState } from '../../hooks'
import { useSchoolContext } from '../../stores/school'
import { useFormStyles } from '../../styles'
import Callouts from './callouts'
import RecentSelections from './recent-selections'
import Sidebar from './sidebar'
import { GroupOption, GroupSelectorState, isInfoOption } from './types'
import useAvailableOptions from './use-available-options'
import useCalloutControls from './use-callout-controls'
import useGroupOptions from './use-group-options'
import useGroupSelectionHelpers from './use-group-selection-helpers'
import useHandleAutocompleteOnChange from './use-handle-autocomplete-on-change'
import useHandleFilterOptions from './use-handle-filter-options'
import useRenderOption from './use-render-option'
import useRenderTags from './use-render-tags'

export enum GroupSelectorMode {
  'tiered',
  'flat',
}

type Props = {
  label?: string
  searchPlaceholder?: string
  error?: string
  initialError?: string
  helperText?: string
  touched?: boolean
  divisions?: SchoolDivisionsFragment[]
  selectedGroupIds: string[]
  setSelectedGroupIds: (selectedGroupIds: string[]) => void
  /** 
    Control whether selecting a divistion or schoolwide will automatically select 
    all groups in that division or schoolwide or if each group can be selected individually.
  */
  mode?: GroupSelectorMode
  /** 
    Useful when using the group selector to control group membership vs group admin access.
    We never want to remove any user as a member of the schoolwide group.
  */
  excludeSchoolwide?: boolean
} & Pick<TextFieldProps, 'onBlur'>

const GroupSelector = React.memo(
  ({
    divisions,
    selectedGroupIds,
    setSelectedGroupIds,
    label,
    searchPlaceholder,
    initialError,
    error,
    helperText,
    touched,
    onBlur,
    mode = GroupSelectorMode.tiered,
    excludeSchoolwide,
  }: Props) => {
    const {
      state: { currentUser },
    } = useAppState()

    const {
      state: { currentUserPermissions },
    } = useSchoolContext()

    const [sidebarOpen, setSidebarOpen] = useState(false)

    const formStyles = useFormStyles()

    const [groupSelectorState, setGroupSelectorState] = useState<GroupSelectorState>({})

    const groupOptionsRaw = useGroupOptions({
      divisions,
      currentUser,
      currentUserPermissions,
      excludeSchoolwide,
    })
    const availableOptions = useAvailableOptions({ groupOptionsRaw, selectedGroupIds })
    const availableGroupOptions = useMemo<GroupOption[]>(() => {
      return availableOptions.filter(o => !isInfoOption(o)) as GroupOption[]
    }, [availableOptions])
    const selectedGroupOptions = useMemo(() => {
      return groupOptionsRaw
        .filter(o => selectedGroupIds.includes(o.id))
        .sort((a, b) => selectedGroupIds.indexOf(a.id) - selectedGroupIds.indexOf(b.id))
    }, [groupOptionsRaw, selectedGroupIds])

    const {
      removeGroupFromSelection,
      addGroupToSelection,
      toggleGroupSelected,
      handleSelectedGroupsChange,
    } = useGroupSelectionHelpers({
      selectedGroupIds,
      groupOptionsRaw,
      setGroupSelectorState,
      selectedGroupOptions,
      mode,
      setSelectedGroupIds,
    })

    const handleFilterOptions = useHandleFilterOptions({ selectedGroupIds })

    const calloutControls = useCalloutControls({ groupSelectorState })

    const handleAutocompleteOnChange = useHandleAutocompleteOnChange({
      closeSchoolwideCallout: calloutControls.closeSchoolwideCallout,
      closeDivisionCallout: calloutControls.closeDivisionCallout,
      handleSelectedGroupsChange,
      setSidebarOpen,
    })

    const handleRenderInput = useCallback(
      (params: AutocompleteRenderInputParams) => (
        <TextField
          {...params}
          label={label}
          error={(touched && !!error) || !!initialError}
          placeholder={searchPlaceholder || 'Search...'}
          helperText={(touched && error) || helperText}
          variant="outlined"
          onBlur={onBlur}
        />
      ),
      [label, touched, error, initialError, searchPlaceholder, helperText, onBlur]
    )

    const handleRenderOption = useRenderOption()
    const handleRenderTags = useRenderTags(removeGroupFromSelection)

    return (
      <Box mb={2}>
        <Callouts calloutControls={calloutControls} />

        <Box display="flex">
          <Autocomplete
            // Data
            filterOptions={handleFilterOptions}
            options={availableOptions}
            onChange={handleAutocompleteOnChange}
            value={selectedGroupOptions}
            // Config
            className={clsx(formStyles.margin)}
            multiple
            fullWidth
            // Display
            renderInput={handleRenderInput}
            renderOption={handleRenderOption}
            renderTags={handleRenderTags}
          />
        </Box>

        <Box mx={0.5}>
          <RecentSelections
            groupOptions={availableGroupOptions}
            onGroupOptionSelected={addGroupToSelection}
            selectedGroupIds={selectedGroupIds}
          />
        </Box>

        {divisions && (
          <Sidebar
            open={sidebarOpen}
            close={() => setSidebarOpen(false)}
            availableOptions={availableOptions}
            selectedGroupIds={selectedGroupIds}
            onGroupSelected={toggleGroupSelected}
            mode={mode}
            excludeSchoolwide={excludeSchoolwide}
          />
        )}
      </Box>
    )
  }
)

export default GroupSelector
