import { useTheme } from '@material-ui/core'
import { GroupListFieldsFragment } from '../../graphql/autogenerate/operations'
import { IconType } from '../../graphql/autogenerate/schemas'
import { useAppState } from '../../hooks'
import { useSchoolContext } from '../../stores/school'
import { MemoizedExpandableDropdown } from '../expandable-dropdown'
import { GroupSelectorMode } from '../group-selector'
import Division from './division'
import GroupRow from './group-row'
import useDisplayGroups from './use-display-groups'

type Props = {
  onGroupSelected: (group: GroupListFieldsFragment) => void
  selectedGroupIds?: string[]
  mode?: GroupSelectorMode
  excludeSchoolwide?: boolean
}

const GroupsTree = ({ onGroupSelected, selectedGroupIds, mode, excludeSchoolwide }: Props) => {
  const theme = useTheme()
  const {
    state: { currentUser },
  } = useAppState()
  const {
    state: {
      school: { divisions },
      currentUserPermissions,
    },
  } = useSchoolContext()

  const displayGroups = useDisplayGroups({
    divisions: divisions.nodes,
    currentUser,
    currentUserPermissions,
  })

  /* 
    If there are 10 or fewer groups to show, just render a pure list.

    If a Group's division is selected, disable it.
  */
  if (displayGroups.length <= 10) {
    return (
      <>
        {displayGroups.map(group => (
          <GroupRow
            key={group.id}
            title={group.groupName}
            iconType={group.icon as IconType}
            color={theme.palette.primary.main}
            onClick={() => onGroupSelected(group)}
            disabled={Boolean(
              !group.isDivisionGroup &&
                group.divisionId &&
                selectedGroupIds?.includes(group.divisionId)
            )}
          />
        ))}
      </>
    )
  }

  if (divisions.totalCount === 1) {
    return (
      <Division
        division={divisions.nodes[0]}
        onGroupSelected={onGroupSelected}
        displayGroups={displayGroups}
        selectedGroupIds={selectedGroupIds}
        mode={mode}
        excludeSchoolwide={excludeSchoolwide}
      />
    )
  }

  return (
    <>
      {divisions.nodes.map(division => (
        <MemoizedExpandableDropdown key={division.id} sectionTitle={division.name} startExpanded>
          <Division
            division={division}
            onGroupSelected={onGroupSelected}
            displayGroups={displayGroups}
            selectedGroupIds={selectedGroupIds}
            ml={3}
            mode={mode}
            excludeSchoolwide={excludeSchoolwide}
          />
        </MemoizedExpandableDropdown>
      ))}
    </>
  )
}

export default GroupsTree
