import { Divider, Typography, useTheme } from '@material-ui/core'
import { Fragment } from 'react'
import { SchoolDivisionsFragment } from '../../../graphql/autogenerate/operations'
import { IconTypeDisplay } from '../../icon-type-display'
import { GroupCell } from '../../group-cell'
import { useSchoolOnboardingContext } from '..'
import { RegistrationMode } from '../../../graphql/autogenerate/schemas'
import { usePasscodeCheckModal } from '../passcode-modal'
import useOnGroupPressed from './use-on-group-pressed'

type Props = {
  currentDivision: SchoolDivisionsFragment
}

const SelectDivisionGroups = ({ currentDivision }: Props) => {
  const { selectedGroups } = useSchoolOnboardingContext()

  const theme = useTheme()

  const { modal: checkPasscodeModal, checkPasscode } = usePasscodeCheckModal()
  const onGroupPressed = useOnGroupPressed({ checkPasscode })

  if (
    !currentDivision.groupCategories.nodes.some(category =>
      category.groups.nodes.some(
        group =>
          group.registrationMode === RegistrationMode.Open && !group.autoEnrollUserTypes?.length
      )
    )
  ) {
    return (
      <Typography color="textSecondary">This division does not have any group options.</Typography>
    )
  }

  return (
    <>
      {checkPasscodeModal}

      {currentDivision.groupCategories.nodes
        .filter(category =>
          category.groups.nodes.some(
            group =>
              group.registrationMode === RegistrationMode.Open && !group.autoEnrollUserTypes?.length
          )
        )
        .map(groupCategory => (
          <Fragment key={groupCategory.id}>
            <Divider />

            <Typography
              variant="h6"
              style={{
                display: 'flex',
                margin: `${theme.spacing(1)}px 0px`,
                alignItems: 'center',
                color: groupCategory.iconBackgroundColor || theme.palette.grey[700],
              }}
            >
              <IconTypeDisplay
                type={groupCategory.iconType}
                size={24}
                style={{ marginRight: theme.spacing(1) }}
              />{' '}
              {groupCategory.name}
            </Typography>

            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                marginBottom: theme.spacing(2),
              }}
            >
              {groupCategory.groups.nodes
                .filter(
                  group =>
                    group.registrationMode === RegistrationMode.Open &&
                    !group.autoEnrollUserTypes?.length
                )
                .map(group => (
                  <GroupCell
                    key={group.id}
                    group={group}
                    category={groupCategory}
                    sortingActive
                    onClick={onGroupPressed}
                    selected={Boolean(selectedGroups?.some(o => o.id === group.id))}
                    showPasscodeLock={Boolean(group.passcode)}
                  />
                ))}
            </div>
          </Fragment>
        ))}
    </>
  )
}

export default SelectDivisionGroups
