import { useCallback } from 'react'
import { Row } from 'react-table'
import { SchoolPersonFragment } from '../../graphql/autogenerate/operations'

const useNameSort = () =>
  useCallback((rowA: Row<SchoolPersonFragment>, rowB: Row<SchoolPersonFragment>) => {
    // Sort by first name
    if ((rowA.original.person?.firstName || '') > (rowB.original.person?.firstName || '')) return 1

    // If first names are the same, fall back to sorting by last name
    if (
      rowA.original.person?.firstName === rowB.original.person?.firstName &&
      (rowA.original.person?.lastName || '') > (rowB.original.person?.lastName || '')
    )
      return 1

    return -1
  }, [])

export default useNameSort
