import { ISelectOption } from '../forms'
import { SchoolDivisionsFragment } from '../../graphql/autogenerate/operations'
import { useMemo } from 'react'
import { IGroupCategoryOptionValue } from './types'

export const useGroupCategoryOptions = ({ divisions }: { divisions: SchoolDivisionsFragment[] }) =>
  useMemo(() => {
    const options: ISelectOption<IGroupCategoryOptionValue>[] = []
    return divisions.reduce((options, division) => {
      return [
        ...options,
        ...division.groupCategories.nodes.map(groupCategory => ({
          label: groupCategory.name,
          key: groupCategory.id,
          group: division.name,
          value: {
            id: groupCategory.id,
            color: groupCategory.iconBackgroundColor,
            divisionName: division.name,
            categoryName: groupCategory.name,
          },
        })),
      ]
    }, options)
  }, [divisions])
