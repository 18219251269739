import { Box, Typography, Button, useTheme } from '@material-ui/core'
import { Warning } from '@material-ui/icons'
import React, { Dispatch, SetStateAction, useEffect, useMemo } from 'react'
import { REFETCH_INTERVAL } from '../constants'
import { CurrentUserFragment, GcsStatFragment, SchoolDivisionsFragment } from '../graphql/autogenerate/operations'
import { useGoogleAccountsQuery, useGoogleAccountStatsQuery } from '../graphql/autogenerate/react-query'
import { useHandleReactQuery } from '../hooks'
import { GoogleCalendar } from '../images'
import { ISchoolState, useSchoolContext } from '../stores/school'
import { useButtonStyles } from '../styles'
import { Callout } from './callout'
import { GcsAddModal } from './gcs-add-modal'
import { GcsList } from './gcs-list'
import { GcsManageAccounts } from './gcs-manage-accounts'
import { Loading } from './loading'
import { ModalControls, Modal, useModal } from './modal'

export type GcsStatus =
    'auth_created' | // Success
    'auth_updated' | // Success
    'auth_access_denied' | // Access denied
    'error' // General error

interface IGcsProps {
    modalControls: ModalControls
    gcsStatus?: { status: GcsStatus, message?: string }
    setStatus: Dispatch<SetStateAction<{ status: GcsStatus, message?: string } | undefined>>
    currentUser: CurrentUserFragment
    divisions: SchoolDivisionsFragment[]
    groups: ISchoolState[ 'groups' ]
}

/** 
    The fabeled Google Calendar Sync (GCS).

    Have care all ye who enter here...make sure you understand what's going on.
*/
export const Gcs = React.memo(({ modalControls, gcsStatus, setStatus, currentUser, divisions, groups }: IGcsProps) => {
    const theme = useTheme()
    const { state: { school: { id: schoolId } } } = useSchoolContext()

    // Fetch a user's GoogleAccounts, associated Google calendars, and GoogleCalendarSyncs
    const googleAccountsQuery = useHandleReactQuery(useGoogleAccountsQuery({ schoolId }))
    useEffect(() => {
        // Refresh the list any time the modal is opened.
        if (modalControls.props.isOpen) googleAccountsQuery.refetch()
    }, [ modalControls.props.isOpen ])
    const googleAccounts = googleAccountsQuery.data?.currentPersonGoogleAccounts?.nodes
    const invalidGrantAccounts = googleAccounts?.filter(o => o.errorState === 'invalid_grant')
    const atLeastOneAccountHasInvalidGrant = Boolean(invalidGrantAccounts?.length)

    const buttonStyles = useButtonStyles()

    const confirmLinkAccountModal = useModal()
    const manageGoogleAccountsModal = useModal()
    const addSyncModal = useModal()

    const message = (() => {
        switch (gcsStatus?.status) {
            case 'auth_created':
                return <div>Successfully linked your Google Account: {gcsStatus.message}</div>
            case 'auth_updated':
                return <div>Successfully re-linked your Google Account: {gcsStatus.message}</div>
            case 'auth_access_denied':
                return (
                    <div>
                        <p>There was an error linking your Google account. It looks like you denied Legit Apps access on the Google Account consent screen.</p>
                        <p>If this was done in error, try linking your Google Account again and be sure to select "Allow".</p>
                    </div>
                )
            default:
                return (
                    <>
                        <p>There was an error linking your Google account: {gcsStatus?.status}.</p>
                        {gcsStatus?.message && <p>{gcsStatus?.message}</p>}
                    </>
                )
        }
    })()
    // Automatically open the sync calendar modal if a user just linked their first Google account
    useEffect(() => {
        if (gcsStatus?.status === 'auth_created' && googleAccounts?.length === 1) {
            addSyncModal.open()
        }
    }, [ gcsStatus, googleAccounts ])

    /* 
        We want to poll for the status/stats of the Google Calendar Syncs, but avoid refetching the user's Google calendars from the Google API on every poll.
        So we break out the stats polling to a separate query and then merge it together.
    */
    const googleAccountsStatsQuery = useHandleReactQuery(useGoogleAccountStatsQuery(undefined, { refetchInterval: REFETCH_INTERVAL }))

    const googleCalendarSyncs = useMemo(() => {
        const gcsStats = googleAccountsStatsQuery.data?.googleAccounts?.nodes.reduce<GcsStatFragment[]>((stats, stat) => {
            stats.push(...stat.googleCalendarSyncs.nodes)
            return stats
        }, [])

        return googleAccountsQuery.data?.googleCalendarSyncForSchool?.nodes?.map(gcs => {
            const stats = gcsStats?.find(o => o.id === gcs.id)
            return {
                ...gcs,
                ...stats ? stats : {},
            }
        })
    }, [ googleAccountsQuery, googleAccountsStatsQuery ])


    // Google supports passing some "state" with the OAuth URL. We send the userID and redirect url so the server can send us back to this page once the OAuth process is completed.
    const oauthLinkState = encodeURIComponent(JSON.stringify({ user_id: currentUser.userAccountId, redirect_url: encodeURI(window.location.href) }))
    const oauthLink = `${googleAccountsQuery.data?.googleOauthUrl}&state=${oauthLinkState}`

    return (
        <Modal
            {...modalControls.props}
            title={<div style={{ display: 'flex', alignItems: 'center' }}><GoogleCalendar style={{ height: 30, width: 30, marginRight: theme.spacing(1) }} /> Google Calendar Sync</div>}
            size='md'
            dismissible
            dividers
            closeButton
            afterClose={() => setStatus(undefined)}
            noPadding
            actions={
                googleAccounts && (googleAccounts.length > 0 || (googleCalendarSyncs && googleCalendarSyncs.length > 0)) ?
                    <>
                        {googleAccounts.length > 0 &&
                            <Button variant='outlined' color='primary' onClick={manageGoogleAccountsModal.open}>
                                Manage {googleAccounts.length} linked account{googleAccounts.length === 1 ? '' : 's'}
                            </Button>
                        }
                        {googleAccounts.length > 0 && googleCalendarSyncs && googleCalendarSyncs?.length > 0 &&
                            <Button disabled={atLeastOneAccountHasInvalidGrant} variant='contained' color='primary' onClick={addSyncModal.open} >
                                Sync Another Calendar
                            </Button>
                        }
                        {googleAccounts.length === 0 && googleCalendarSyncs?.length &&
                            <Button
                                type='button'
                                variant='outlined'
                                color='primary'
                                onClick={confirmLinkAccountModal.open}
                                disabled={atLeastOneAccountHasInvalidGrant}
                            >
                                Sync a calendar
                            </Button>
                        }
                    </>
                    :
                    undefined
            }
        >
            <>
                {atLeastOneAccountHasInvalidGrant &&
                    <div style={{ margin: `${theme.spacing(1)}px` }}>
                        <Callout type='error'>
                            <div>
                                <p>The authentication for one or more of your linked Google accounts has expired.</p>
                                <p>Until you re-link {invalidGrantAccounts?.length === 1 ? 'this account' : 'these accounts'}, all calendars synced from {invalidGrantAccounts?.length === 1 ? 'this account' : 'these accounts'} will not update and you will not be able to sync another calendar.</p>
                            </div>
                        </Callout>
                        {invalidGrantAccounts?.map(account =>
                            <div style={{ display: 'flex', alignItems: 'center', marginTop: theme.spacing(2) }}>
                                <Warning style={{ marginRight: theme.spacing(2), color: theme.palette.error.main }} />
                                <div style={{ marginRight: theme.spacing(2) }}>
                                    <div><b>{account.email}</b></div>
                                    <div><Typography variant='caption'>{account.googleCalendarSyncs.totalCount} synced calendar{account.googleCalendarSyncs.totalCount === 1 ? '' : 's'}</Typography> </div>
                                </div>
                                <Button href={`${oauthLink}&user_id=${account.email}`} style={{ minWidth: 100 }} variant='text' className={buttonStyles.errorOutlined}>
                                    Relink
                                </Button>
                            </div>
                        )}
                    </div>
                }
                {!googleAccounts || googleAccountsQuery.isLoading || !googleAccountsQuery.refetch ?
                    <div style={{ margin: `${theme.spacing(5)}px 0px`, display: 'flex', justifyContent: 'center', alignItems: 'center', color: theme.palette.primary.main }}>
                        <Loading />
                    </div>
                    :
                    <>
                        <Callout
                            children={message}
                            type={gcsStatus && (gcsStatus.status === 'auth_created' || gcsStatus.status === 'auth_updated') ? 'success' : 'error'}
                            closable={{
                                isOpen: Boolean(gcsStatus?.status && (googleAccounts.length > 1 || gcsStatus.status !== 'auth_created')),
                                close: () => setStatus(undefined)
                            }}
                            style={{ padding: `${theme.spacing(1)}px ${theme.spacing(1)}px 0px ${theme.spacing(1)}px` }}
                        />


                        {googleCalendarSyncs && googleCalendarSyncs?.length > 0 ?
                            <GcsList googleCalendarSyncs={googleCalendarSyncs} refetchGoogleAccounts={googleAccountsQuery.refetch} />
                            :
                            <Box my={5} display='flex' flexDirection='column' alignItems='center' justifyContent='center'>
                                <Typography variant='h5' color='textSecondary'>No Google calendars synced yet.</Typography>
                                <Box mt={3}>
                                    <Button
                                        type='button'
                                        variant='outlined'
                                        color='primary'
                                        onClick={() => {
                                            if (googleAccounts.length === 0) {
                                                confirmLinkAccountModal.open()
                                            } else {
                                                addSyncModal.open()
                                            }
                                        }}
                                        disabled={atLeastOneAccountHasInvalidGrant}
                                    >
                                        Sync a calendar
                                    </Button>
                                </Box>
                            </Box>
                        }

                        <GcsManageAccounts
                            modalControls={manageGoogleAccountsModal}
                            googleAccounts={googleAccounts}
                            oauthLink={oauthLink}
                            refetchGoogleAccounts={googleAccountsQuery.refetch}
                        />

                        <GcsAddModal
                            modalControls={addSyncModal}
                            googleAccounts={googleAccounts}
                            googleCalendarSyncs={googleCalendarSyncs || []}
                            divisions={divisions}
                            groups={groups}
                            refetchGoogleAccounts={googleAccountsQuery.refetch}
                            gcsStatus={gcsStatus}
                            setStatus={setStatus}
                            statusMessage={message}
                        />

                        <Modal
                            {...confirmLinkAccountModal.props}
                            title='Link a Google Account'
                            actions={
                                <>
                                    <Button variant='text' onClick={confirmLinkAccountModal.close}>Cancel</Button>
                                    <Button
                                        type='button'
                                        href={oauthLink}
                                        variant='outlined'
                                        color='primary'
                                    >
                                        Continue
                                    </Button>
                                </>
                            }
                        >
                            <Typography>To sync a Google calendar you need to link at least one Google account. Legit Apps supports linking unlimited Google accounts to your school.</Typography>
                            <br />
                            <Typography>Would you like to link your first account?</Typography>
                        </Modal>
                    </>
                }
            </>
        </Modal>
    )
})
