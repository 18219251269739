import * as yup from "yup";

const getValidationSchema = (step: string) =>
  yup.object({
    email: yup
      .string()
      .required("Required")
      .email("Please enter a valid email address."),
    password: yup.string().test("password", "Required", function (value) {
      if (step === "sign-in") return Boolean(value);
      return true;
    }),
  });

export default getValidationSchema;