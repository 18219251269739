import { IconButton, useTheme } from '@material-ui/core'
import { Refresh } from '@material-ui/icons'
import { useSchoolOnboardingContext } from '.'
import { UserType } from '../../graphql/autogenerate/schemas'
import { useSchoolContext } from '../../stores/school'

const RestartButton = () => {
  const theme = useTheme()
  const {
    state: {
      school: { schoolSetting },
    },
  } = useSchoolContext()

  const { setSchoolOnboardingState } = useSchoolOnboardingContext()

  return (
    <div style={{ position: 'absolute', top: theme.spacing(1), left: 0 }}>
      <IconButton
        onClick={() =>
          setSchoolOnboardingState(_state => ({
            ..._state,
            step: schoolSetting?.registrationMode ? 'welcome' : 'user-type',
            userTypes: _state.userTypes?.filter(o => o !== UserType.FacultyStaff),
          }))
        }
      >
        <Refresh />
      </IconButton>
    </div>
  )
}

export default RestartButton
