import { Box, useTheme } from '@material-ui/core'
import {
  GroupListFieldsFragment,
  SchoolDivisionsFragment,
} from '../../graphql/autogenerate/operations'
import { IconType } from '../../graphql/autogenerate/schemas'
import { GroupSelectorMode } from '../group-selector'
import GroupCategory from './group-category'
import GroupRow from './group-row'

type Props = {
  division: SchoolDivisionsFragment
  onGroupSelected: (group: GroupListFieldsFragment) => void
  displayGroups: GroupListFieldsFragment[]
  selectedGroupIds?: string[]
  ml?: number
  mode?: GroupSelectorMode
  excludeSchoolwide?: boolean
}

const Division = ({
  division,
  onGroupSelected,
  displayGroups,
  selectedGroupIds,
  ml,
  mode,
  excludeSchoolwide,
}: Props) => {
  const theme = useTheme()

  const showSchoolwideGroup = !excludeSchoolwide || !division.schoolwide

  return (
    <>
      {showSchoolwideGroup && (
        <Box ml={ml} my={0.5}>
          <GroupRow
            key={division.id}
            title={division.name}
            iconType={division.schoolwide ? IconType.IosFilledCityHall : IconType.IosFilledSchool}
            color={theme.palette.primary.main}
            onClick={() => division.group && onGroupSelected(division.group)}
            disabled={!displayGroups.some(o => o.id === division.group?.id)}
          />
        </Box>
      )}

      <Box ml={ml}>
        {division.groupCategories.nodes
          .filter(o => o.groups.totalCount)
          .map(groupCategory => (
            <GroupCategory
              key={groupCategory.id}
              category={groupCategory}
              groupSelected={group => onGroupSelected(group)}
              displayGroups={displayGroups}
              selectedGroupIds={selectedGroupIds}
              disabled={
                mode === GroupSelectorMode.tiered &&
                Boolean(division.group && selectedGroupIds?.includes(division.group?.id))
              }
            />
          ))}
      </Box>
    </>
  )
}

export default Division
