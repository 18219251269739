import { Button, Typography, FormControlLabel, Checkbox, useTheme, Tooltip } from '@material-ui/core'
import { Delete, Warning } from '@material-ui/icons'
import React, { useEffect } from 'react'
import { useCallback, useState } from 'react'
import { GoogleAccountFragment } from '../graphql/autogenerate/operations'
import { useDeleteGoogleAccountMutation, useGoogleAccountsQuery } from '../graphql/autogenerate/react-query'
import { useHandleReactQueryMutation } from '../hooks'
import { useCommonStyles, useButtonStyles } from '../styles'
import { AnimationIconWraper } from './animation-icon-wrapper'
import { LoadingButton } from './loading-button'
import { ModalControls, Modal } from './modal'
import { MemoizedSpringHeight } from './spring-height'

interface IGcsManageAccountsProps {
    modalControls: ModalControls
    googleAccounts: GoogleAccountFragment[]
    oauthLink: string
    refetchGoogleAccounts: ReturnType<typeof useGoogleAccountsQuery>[ 'refetch' ]
}

export const GcsManageAccounts = React.memo(({ modalControls, googleAccounts, oauthLink, refetchGoogleAccounts }: IGcsManageAccountsProps) => {
    const theme = useTheme()
    const commonStyles = useCommonStyles()
    const buttonStyles = useButtonStyles()

    const [ accountToDelete, setAccountToDelete ] = useState<{ accountId?: string, deleteEvents: boolean }>({ deleteEvents: true })
    const handleDeletePressed = useCallback((accountId: string) => setAccountToDelete({ accountId, deleteEvents: true }), [])
    const handleCancelDelete = useCallback(() => setAccountToDelete({ deleteEvents: true }), [])

    const { mutate: deleteGoogleAccount, isLoading: deleteGoogleAccountIsLoading } = useHandleReactQueryMutation(useDeleteGoogleAccountMutation())
    const handleDelete = useCallback(async () => {
        if (accountToDelete.accountId)
            deleteGoogleAccount(
                { googleAccountId: accountToDelete.accountId, deleteEvents: accountToDelete.deleteEvents },
                { onSettled: () => { refetchGoogleAccounts() } }
            )
    }, [ accountToDelete, refetchGoogleAccounts ])

    // Go ahead and close the modal if the only linked Google account was deleted.
    useEffect(() => {
        if (googleAccounts.length === 0) modalControls.close()
    }, [ googleAccounts ])

    return (
        <Modal
            {...modalControls.props}
            title='Linked Accounts'
            dismissible
            closeButton
            size='sm'
            afterClose={handleCancelDelete}
            dividers
            actions={
                <div>
                    <Button
                        type='button'
                        href={oauthLink}
                        variant='outlined'
                        color='primary'
                    >
                        Link another account
                    </Button>
                </div>
            }
        >
            {googleAccounts.map((account, index) => (
                <React.Fragment key={account.id}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: index > 0 ? theme.spacing(1) : 0 }}>
                        <div style={{ marginRight: theme.spacing(4), display: 'flex', alignItems: 'center' }}>
                            {account.errorState === 'invalid_grant' &&
                                <Tooltip
                                    title={
                                        <div>
                                            <p>The authentication for this linked Google account has expired.</p>
                                            <p>Until you re-link this account, all calendars synced from this account will not update and you will not be able to sync another calendar from this account.</p>
                                        </div>
                                    }
                                >
                                    <Warning style={{ marginRight: theme.spacing(2), color: theme.palette.error.main }} />
                                </Tooltip>
                            }
                            <div style={{ marginRight: theme.spacing(2) }}>
                                <div><b>{account.email}</b></div>
                                <div><Typography variant='caption'>{account.googleCalendarSyncs.totalCount} synced calendar{account.googleCalendarSyncs.totalCount === 1 ? '' : 's'}</Typography> </div>
                            </div>
                            {account.errorState === 'invalid_grant' &&
                                <Button href={oauthLink} style={{ minWidth: 100 }} variant='text' className={buttonStyles.errorOutlined}>
                                    Relink
                                </Button>
                            }
                        </div>
                        <AnimationIconWraper
                            children={<Delete style={{ height: 20, width: 20 }} />}
                            className={commonStyles.errorColorHover}
                            onClick={() => handleDeletePressed(account.id)}
                            tooltip={{ title: 'Remove account', placement: 'right' }}
                        />
                    </div>
                    <MemoizedSpringHeight isOpen={accountToDelete.accountId === account.id}>
                        <div style={{ padding: `${theme.spacing(2)}px 0`, }}>
                            <div
                                style={{
                                    border: `1px solid ${theme.palette.warning.main}`,
                                    borderRadius: theme.shape.borderRadius,
                                    padding: theme.spacing(2),
                                }}
                            >
                                <div><Typography>Are you sure you want to remove <b>{account.email}</b>?</Typography> </div>
                                {account.googleCalendarSyncs.totalCount > 0 &&
                                    <>
                                        <div style={{ marginTop: theme.spacing(1) }}><Typography><b>{account.googleCalendarSyncs.totalCount} calendars</b> from this account are synced. Removing this Google account will stop those syncs.</Typography></div>
                                        {account.googleCalendarSyncs.nodes.find(o => o.syncedEvents.totalCount > 0) &&
                                            <FormControlLabel
                                                control={<Checkbox checked={accountToDelete.deleteEvents} onChange={(ev) => setAccountToDelete({ ...accountToDelete, deleteEvents: ev.currentTarget.checked })} />}
                                                label={<span>Also delete the <b>{account.googleCalendarSyncs.nodes.reduce((totalEvents, gcs) => totalEvents += gcs.syncedEvents.totalCount, 0)} synced events</b> from these calendars?</span>}
                                            />
                                        }
                                    </>
                                }
                                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: theme.spacing(2) }}>
                                    <Button variant='text' onClick={handleCancelDelete}>Cancel</Button>
                                    <LoadingButton onClick={handleDelete} loading={deleteGoogleAccountIsLoading} variant='outlined' className={buttonStyles.errorOutlined}>REMOVE</LoadingButton>
                                </div>
                            </div>
                        </div>
                    </MemoizedSpringHeight>
                </React.Fragment>
            ))}
        </Modal>
    )
})