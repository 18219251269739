import { Box, useTheme } from '@material-ui/core'
import { animated, useSpring } from 'react-spring'
import { Callout } from '../callout'
import { CalloutControls } from './use-callout-controls'

type Props = {
  calloutControls: CalloutControls
}

const Callouts = ({
  calloutControls: {
    calloutsState: { schoolwideCallout, divisionCallout },
    closeSchoolwideCallout,
    closeDivisionCallout,
  },
}: Props) => {
  const theme = useTheme()

  const marginSpring = useSpring({
    marginTop: schoolwideCallout.isOpen || divisionCallout.isOpen ? theme.spacing(2) : 0,
  })

  return (
    <>
      <Box mx={0.5}>
        <Callout
          closable={{ isOpen: schoolwideCallout.isOpen, close: closeSchoolwideCallout }}
          why={{
            tooltip:
              'Content added to the Schoolwide group is visible to all users. There is no need to select additional groups.',
          }}
          children={'Schoolwide group selected. All other selections cleared.'}
        />
      </Box>

      <Box mx={0.5}>
        <Callout
          closable={{ isOpen: divisionCallout.isOpen, close: closeDivisionCallout }}
          why={{ tooltip: divisionCallout.tooltip }}
          children={divisionCallout.children}
        />
      </Box>

      <animated.div style={marginSpring} />
    </>
  )
}

export default Callouts
