import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Button,
  Divider,
  Typography,
  useTheme,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import { Form, Formik } from "formik";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useUpdateSponsorSchoolSettingsMutation } from "../../graphql/autogenerate/react-query";
import { useHandleReactQueryMutation } from "../../hooks";
import { useSchoolContext } from "../../stores/school";
import { FormikSwitchField, FormikTextInput } from "../forms";

export const SponsorSettings = () => {
  const {
    state: {
      school: { schoolSetting, id: schoolId },
    },
    actions: {
      refetch: { schoolDetail: refetchSchoolDetails },
    },
  } = useSchoolContext();

  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [isOpen, setIsOpen] = useState(false);

  const { mutate: updateSponsorSettings } = useHandleReactQueryMutation(
    useUpdateSponsorSchoolSettingsMutation({
      onSuccess: () => {
        enqueueSnackbar("Sponsor settings updated", { variant: "success" });
        refetchSchoolDetails();
      },
    })
  );

  if (!schoolSetting) return null;

  return (
    <Formik
      initialValues={{
        sponsored: Boolean(schoolSetting.sponsored),
        sponsorsIncludeSilversOnHome: Boolean(
          schoolSetting.sponsorsIncludeSilversOnHome
        ),
        goldSponsorLabel: schoolSetting.goldSponsorLabel || "",
        silverSponsorLabel: schoolSetting.silverSponsorLabel || "",
        bronzeSponsorLabel: schoolSetting.bronzeSponsorLabel || "",
      }}
      onSubmit={(values) => updateSponsorSettings({ ...values, schoolId })}
    >
      {(formikProps) => (
        <Accordion
          expanded={isOpen}
          onChange={(_, expanded) => setIsOpen(expanded)}
        >
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography variant="h5">💸 Sponsor Settings</Typography>
          </AccordionSummary>
          <AccordionDetails
            style={{ padding: `${theme.spacing(1)}px ${theme.spacing(2)}px` }}
          >
            <Form style={{ flex: 1 }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: theme.spacing(1),
                }}
              >
                <FormikSwitchField
                  style={{ marginRight: theme.spacing(2) }}
                  field={{ name: "sponsored", label: "Sponsored" }}
                />
                {formikProps.values.sponsored && (
                  <FormikSwitchField
                    field={{
                      name: "sponsorsIncludeSilversOnHome",
                      label: "Include silver sponsors on the home screen",
                    }}
                  />
                )}
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: theme.spacing(1),
                }}
              >
                <FormikTextInput
                  fieldProps={{
                    name: "goldSponsorLabel",
                    label: "Gold Label",
                  }}
                />
                <FormikTextInput
                  fieldProps={{
                    name: "silverSponsorLabel",
                    label: "Silver Label",
                  }}
                />
                <FormikTextInput
                  fieldProps={{
                    name: "bronzeSponsorLabel",
                    label: "Bronze Label",
                  }}
                />
              </div>
            </Form>
          </AccordionDetails>
          <Divider />
          <AccordionActions>
            <Button size="small" onClick={() => setIsOpen(false)}>
              Cancel
            </Button>
            <Button
              onClick={formikProps.submitForm}
              type="submit"
              size="small"
              color="primary"
            >
              Save
            </Button>
          </AccordionActions>
        </Accordion>
      )}
    </Formik>
  );
};
