import { Button, Typography, useTheme } from '@material-ui/core'
import { ArrowForward } from '@material-ui/icons'
import { useState } from 'react'
import { UserType } from '../../graphql/autogenerate/schemas'
import { useSchoolContext } from '../../stores/school'
import { usePasscodeCheckModal } from './passcode-modal'
import { useSchoolOnboardingContext, useSchoolOnboardingStyles } from '.'
import SelectionCheckbox from './checkbox'

const UserTypeSelection = () => {
  const theme = useTheme()
  const onboardingStyles = useSchoolOnboardingStyles()
  const {
    state: {
      currentUserPermissions,
      school: { schoolSetting },
    },
  } = useSchoolContext()

  const isFacultyStaff = currentUserPermissions.school?.userTypes.includes(UserType.FacultyStaff)

  const { userTypes, setSchoolOnboardingState } = useSchoolOnboardingContext()

  const { modal, checkPasscode } = usePasscodeCheckModal()

  const [state, setState] = useState({
    parentFamily: Boolean(userTypes?.includes(UserType.Parent)),
    student: Boolean(userTypes?.includes(UserType.Student)),
    communityMember: Boolean(userTypes?.includes(UserType.Guest)),
    facultyStaff: Boolean(userTypes?.includes(UserType.FacultyStaff)),
  })

  const onParentSelected = (checked: boolean) =>
    setState(_state => ({
      ..._state,
      parentFamily: checked,
      student: false,
      communityMember: false,
    }))
  const onStudentSelected = (checked: boolean) =>
    setState(_state => ({
      ..._state,
      student: checked,
      parentFamily: false,
      communityMember: false,
      facultyStaff: false,
    }))

  return (
    <>
      <div className={onboardingStyles.stepContainer} style={{ paddingTop: theme.spacing(2) }}>
        <div
          style={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            minHeight: 0,
            overflowY: 'auto',
          }}
        >
          <Typography gutterBottom variant="h4">
            Please tell us a little about yourself.
          </Typography>
          <Typography style={{ marginBottom: theme.spacing(4) }}>
            Select all that apply so that we can provide you with the most relevant information.
          </Typography>

          {schoolSetting?.enableParentFamilyAccess && (
            <SelectionCheckbox
              label="Parent & Family"
              checked={state.parentFamily}
              showPasscodeLock={Boolean(schoolSetting?.parentPasscode)}
              onChange={checked =>
                schoolSetting?.parentPasscode && checked
                  ? checkPasscode({
                      onCorrectPasscode: () => onParentSelected(checked),
                      passcode: schoolSetting?.parentPasscode,
                      passcodeHint: schoolSetting?.parentPasscodeHint,
                    })
                  : onParentSelected(checked)
              }
            />
          )}

          {schoolSetting?.enableStudentAccess && (
            <SelectionCheckbox
              label="Student"
              checked={state.student}
              showPasscodeLock={Boolean(schoolSetting?.studentPasscode)}
              onChange={checked =>
                schoolSetting?.studentPasscode && checked
                  ? checkPasscode({
                      onCorrectPasscode: () => onStudentSelected(checked),
                      passcode: schoolSetting.studentPasscode,
                      passcodeHint: schoolSetting.studentPasscodeHint,
                    })
                  : onStudentSelected(checked)
              }
            />
          )}

          {schoolSetting?.enableGuestAccess && (
            <SelectionCheckbox
              label="Community Member"
              checked={state.communityMember}
              onChange={checked =>
                setState(_state => ({
                  ..._state,
                  communityMember: checked,
                  parentFamily: false,
                  student: false,
                  facultyStaff: false,
                }))
              }
            />
          )}

          <SelectionCheckbox
            label="Faculty & Staff"
            checked={state.facultyStaff}
            onChange={checked =>
              setState(_state => ({
                ..._state,
                facultyStaff: checked,
                student: false,
                communityMember: false,
              }))
            }
          />
        </div>

        <div>
          <Button
            disabled={!Object.values(state).some(o => o)}
            style={{ width: '100%', color: theme.palette.primary.contrastText }}
            size="large"
            color="primary"
            variant="contained"
            endIcon={<ArrowForward />}
            onClick={() => {
              const _userTypes: UserType[] = []

              if (state.parentFamily) _userTypes.push(UserType.Parent)
              if (state.student) _userTypes.push(UserType.Student)
              if (state.communityMember) _userTypes.push(UserType.Guest)
              if (state.facultyStaff) _userTypes.push(UserType.FacultyStaff)

              setSchoolOnboardingState(_state => ({
                ..._state,
                step: state.facultyStaff && !isFacultyStaff ? 'faculty-staff-auth' : 'divisions',
                userTypes: _userTypes,
              }))
            }}
          >
            Continue
          </Button>
        </div>
      </div>
      {modal}
    </>
  )
}

export default UserTypeSelection
