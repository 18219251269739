import { useCallback } from 'react'
import { GroupOption, GroupOptionType } from './types'
import { Chip } from '@material-ui/core'
import { useTheme } from '@material-ui/core'
import { IconTypeDisplay } from '../icon-type-display'
import { useMuiClassOverrides } from '../../styles/use-mui-class-overrides'

const useRenderTags = (removeGroupFromSelection: (groupId: string) => void) => {
  const theme = useTheme()
  const muiOverrides = useMuiClassOverrides()

  return useCallback(
    (values: GroupOptionType[]) => {
      return (
        <>
          {(values as GroupOption[]).map(o => (
            <Chip
              style={{
                marginRight: theme.spacing(0.5),
                marginTop: theme.spacing(0.5),
                backgroundColor: o.color || theme.palette.grey[500],
              }}
              classes={{ label: muiOverrides.label }}
              key={o.id}
              variant="default"
              color="primary"
              onDelete={() => removeGroupFromSelection(o.id)}
              avatar={
                <IconTypeDisplay
                  style={{ color: 'white', marginLeft: theme.spacing(1) }}
                  size={15}
                  type={o.iconType}
                />
              }
              label={`${o.title}${o.divisionName && !o.isDivision ? ` (${o.divisionName})` : ''}`}
            />
          ))}
        </>
      )
    },
    /* 
        We need to include a dependency on selectedGroupIds or removeGroupFromSelection since we have a hidden dependency in here.
        The removeGroupFromSelection function depends on selectedGroupIds.
        But the current version of the removeGroupFromSelection callback is "captured" inside this useCallback for handleRenderTags. 
        Thus it wouldn't get updated when selectedGroupIds changes unelss we make the handleRenderTags useCallback also depend on removeGroupFromSelection.

        Tricky
    */
    [removeGroupFromSelection]
  )
}

export default useRenderTags
