import { Paper, Tab, Tabs, useTheme } from '@material-ui/core'
import { useState } from 'react'
import { UserType } from '../graphql/autogenerate/schemas'
import { AdminManagePeopleFacultyStaff } from './admin-manage-people-faculty-staff'
import ManagePeopleTable from './manage-people-table'
import { useSchoolContext } from '../stores/school'

export const AdminManagePeople = () => {
  const theme = useTheme()

  const {
    state: {
      school: { schoolSetting },
    },
  } = useSchoolContext()

  const [selectedTabIndex, setSelectedTabIndex] = useState(0)

  if (!schoolSetting) return null

  const { enableParentFamilyAccess, enableStudentAccess, enableGuestAccess, registrationMode } =
    schoolSetting

  return (
    <div
      style={{
        margin: theme.spacing(2),
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        minHeight: 0,
        overflow: 'hidden',
      }}
    >
      <Paper>
        <Tabs
          value={selectedTabIndex}
          onChange={(_, newValue) => setSelectedTabIndex(newValue)}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab label="Faculty & Staff" value={0} />

          {enableParentFamilyAccess && registrationMode && <Tab label="Parents" value={1} />}
          {enableStudentAccess && registrationMode && <Tab label="Students" value={2} />}
          {enableGuestAccess && registrationMode && <Tab label="Community Members" value={3} />}
        </Tabs>
      </Paper>

      <div style={{ margin: theme.spacing(0.5) }} />

      {selectedTabIndex === 0 && <AdminManagePeopleFacultyStaff />}
      {selectedTabIndex === 1 && <ManagePeopleTable userTypes={[UserType.Parent]} />}
      {selectedTabIndex === 2 && <ManagePeopleTable userTypes={[UserType.Student]} />}
      {selectedTabIndex === 3 && <ManagePeopleTable userTypes={[UserType.Guest]} />}
    </div>
  )
}
