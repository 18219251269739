import { useCallback } from 'react'
import { GroupListFieldsFragment } from '../../../graphql/autogenerate/operations'
import { IPasscodeModalProps } from '../passcode-modal'
import { useSchoolOnboardingContext } from '..'

type Options = {
  checkPasscode: (options: IPasscodeModalProps) => void
}

const useOnGroupPressed = ({ checkPasscode }: Options) => {
  const { setSchoolOnboardingState, selectedGroups } = useSchoolOnboardingContext()

  return useCallback(
    (group: GroupListFieldsFragment) => {
      const selected = selectedGroups?.some(o => o.id === group.id)

      const selectGroup = () => {
        if (selected) {
          setSchoolOnboardingState(_state => ({
            ..._state,
            selectedGroups: (_state.selectedGroups || []).filter(o => o.id !== group.id),
          }))
        } else {
          setSchoolOnboardingState(_state => ({
            ..._state,
            selectedGroups: (_state.selectedGroups || []).concat(group),
          }))
        }
      }

      if (group.passcode && !selected) {
        checkPasscode({
          onCorrectPasscode: () => selectGroup(),
          passcode: group.passcode,
          passcodeHint: group.passcodeHint,
        })
      } else {
        selectGroup()
      }
    },
    [selectedGroups]
  )
}

export default useOnGroupPressed