import { useTheme } from '@material-ui/core'
import { SchoolDivisionsFragment } from '../../../graphql/autogenerate/operations'

type Props = {
  selectedDivisionsWithGroups: SchoolDivisionsFragment[]
  currentDivision: SchoolDivisionsFragment
}

const ProgressBar = ({ selectedDivisionsWithGroups, currentDivision }: Props) => {
  const theme = useTheme()

  return (
    <div
      style={{
        display: 'flex',
        height: theme.spacing(1),
        marginTop: theme.spacing(1),
      }}
    >
      {selectedDivisionsWithGroups.map((division, index, array) => {
        const filled =
          index <= selectedDivisionsWithGroups.findIndex(o => o.id === currentDivision.id)
        return (
          <div
            key={division.id}
            style={{
              backgroundColor: filled ? theme.palette.primary.main : theme.palette.grey[500],
              flex: 1,
              height: '100%',
              ...(index === 0
                ? {
                    borderTopLeftRadius: 5,
                    borderBottomLeftRadius: 5,
                    borderRight: '1px solid white',
                  }
                : {}),
              ...(index === array.length - 1
                ? {
                    borderTopRightRadius: 5,
                    borderBottomRightRadius: 5,
                    borderLeft: '1px solid white',
                  }
                : {}),
              ...(index > 1 && index < array.length - 2
                ? {
                    borderLeft: '1px solid white',
                    borderRight: '1px solid white',
                  }
                : {}),
            }}
          />
        )
      })}
    </div>
  )
}

export default ProgressBar
