import { useMemo } from 'react'
import { SchoolDivisionsFragment } from '../../../graphql/autogenerate/operations'

const useNextDivision = ({
  selectedDivisionsWithGroups,
  currentDivision,
}: {
  selectedDivisionsWithGroups?: SchoolDivisionsFragment[]
  currentDivision?: SchoolDivisionsFragment
}) =>
  useMemo(() => {
    // No divisions selected...there is no next division
    if (!selectedDivisionsWithGroups || !selectedDivisionsWithGroups.length) return null

    // At least one division was selected from the list of divisions. Have we moved on to a division detail page?
    if (currentDivision) {
      // We have moved on to a division detail page! Let's get the next one.
      const currentDivisionIdx = selectedDivisionsWithGroups.findIndex(
        o => o.id === currentDivision.id
      )

      // This was the last one, no more divisions!
      if (
        currentDivisionIdx === -1 ||
        selectedDivisionsWithGroups.length === currentDivisionIdx + 1
      )
        return null

      // We have more divisions...return the next one.
      return selectedDivisionsWithGroups[currentDivisionIdx + 1]
    } else {
      // We have selected divisions but we've not moved on to a detail page.
      // The next division is the first division of the selected divisions.
      return selectedDivisionsWithGroups[0]
    }
  }, [currentDivision, selectedDivisionsWithGroups])

export default useNextDivision
