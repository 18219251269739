import { Button, Divider, Typography, useTheme } from "@material-ui/core";
import { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { useSchoolOnboardingContext, useSchoolOnboardingStyles } from ".";
import { getLabelForUserType } from "../../helpers";
import { useSchoolContext } from "../../stores/school";

const Summary = () => {
  const theme = useTheme();
  const onboardingStyles = useSchoolOnboardingStyles();
  const navigate = useNavigate();
  const { userTypes, selectedDivisions, selectedGroups, onClose } =
    useSchoolOnboardingContext();
  const {
    actions: {
      refetch: { schoolDetail: refetchSchool },
      setUserHasOnboarded,
    },
  } = useSchoolContext();

  return (
    <div
      className={onboardingStyles.stepContainer}
      style={{ paddingTop: theme.spacing(2) }}
    >
      <div>
        <Typography gutterBottom variant="h4">
          You're all set!
        </Typography>
        <Typography style={{ marginBottom: theme.spacing(2) }}>
          You can manage your settings any time by tapping the Manage button in
          the notifications tab.
        </Typography>
      </div>

      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          minHeight: 0,
          overflowY: "auto",
          paddingBottom: theme.spacing(2),
        }}
      >
        <Divider style={{ height: 3, backgroundColor: "black" }} />
        <Typography
          variant="h5"
          style={{ margin: `${theme.spacing(1)}px 0px` }}
        >
          General Settings
        </Typography>
        <div>
          <b
            style={{
              color: theme.palette.primary.main,
              marginRight: theme.spacing(1),
            }}
          >
            About me
          </b>
          {userTypes?.map((o) => getLabelForUserType(o, true)).join(", ")}
        </div>
        <Divider style={{ margin: `${theme.spacing(1)}px 0px` }} />
        <div>
          <b
            style={{
              color: theme.palette.primary.main,
              marginRight: theme.spacing(1),
            }}
          >
            Divisions
          </b>
          {selectedDivisions?.map((o) => o.name).join(", ")}
        </div>
        <Divider
          style={{
            height: 3,
            backgroundColor: "black",
            marginTop: theme.spacing(3),
          }}
        />
        {selectedDivisions
          ?.filter((o) =>
            o.groupCategories.nodes.some((category) =>
              category.groups.nodes.some((group) =>
                selectedGroups?.some(
                  (selectedGroup) => selectedGroup.id === group.id
                )
              )
            )
          )
          .map((division, divisionIdx, divisions) => (
            <Fragment key={division.id}>
              <Typography
                variant="h5"
                style={{ margin: `${theme.spacing(1)}px 0px` }}
              >
                {division.name}
              </Typography>
              {division.groupCategories.nodes
                .filter((category) =>
                  category.groups.nodes.some((group) =>
                    selectedGroups?.some(
                      (selectedGroup) => selectedGroup.id === group.id
                    )
                  )
                )
                .map((category, index, categories) => (
                  <Fragment key={category.id}>
                    <div>
                      <b
                        style={{
                          color:
                            category.iconBackgroundColor ||
                            theme.palette.grey[700],
                          marginRight: theme.spacing(1),
                        }}
                      >
                        {category.name}
                      </b>
                      {category.groups.nodes
                        .filter((group) =>
                          selectedGroups?.some(
                            (selectedGroup) => selectedGroup.id === group.id
                          )
                        )
                        .map((group) => group.groupName)
                        .join(", ")}
                    </div>

                    {index === categories.length - 1 ? (
                      divisionIdx !== divisions.length - 1 && (
                        <Divider
                          style={{
                            height: 3,
                            backgroundColor: "black",
                            marginTop: theme.spacing(3),
                          }}
                        />
                      )
                    ) : (
                      <Divider
                        style={{ margin: `${theme.spacing(1)}px 0px` }}
                      />
                    )}
                  </Fragment>
                ))}
            </Fragment>
          ))}
      </div>

      <div>
        <Button
          style={{ width: "100%" }}
          size="large"
          color="primary"
          variant="contained"
          onClick={async () => {
            await refetchSchool();

            setUserHasOnboarded(true);

            if (onClose) {
              onClose();
            } else {
              navigate("./home");
            }
          }}
        >
          Close
        </Button>
      </div>
    </div>
  );
};

export default Summary;