import { useField } from 'formik'
import { IField } from '.'
import { useFormStyles } from '../../styles'
import clsx from 'clsx'
import { MATERIAL_FIELD_VARIANT } from './constants'
import { FormControl, InputLabel, Select, FormHelperText, TextField } from '@material-ui/core'
import parse from 'autosuggest-highlight/parse'
import match from 'autosuggest-highlight/match'
import { useMobileDetect } from '../../hooks'
import { Autocomplete } from '@material-ui/lab'
import { CommonProps } from '../interfaces'

interface ISelectFieldProps<OptionValue> extends CommonProps {
  fieldProps: IField
  options: ISelectOption<OptionValue>[]
  getKeyFromOptionValue: (value?: OptionValue) => string | undefined
  onChange?: (value?: ISelectOption<OptionValue> | null) => void
  hideHelperTextWhenEmpty?: boolean
}

export interface ISelectOption<OptionValue> {
  group?: string
  key: string
  label: string
  value: OptionValue
}

export const FormikSelectField = <OptionValue,>({
  fieldProps,
  options,
  getKeyFromOptionValue,
  onChange,
  hideHelperTextWhenEmpty,
  ...rest
}: ISelectFieldProps<OptionValue>) => {
  const classes = useFormStyles()

  const [field, meta, helpers] = useField(fieldProps)

  const { isMobile } = useMobileDetect()

  const error = meta.error?.toString()
  const helperText = fieldProps.helperText?.toString()

  return (
    <FormControl
      className={clsx(classes.margin, classes.root)}
      variant={MATERIAL_FIELD_VARIANT}
      error={(meta.touched && !!meta.error) || !!meta.initialError}
      disabled={fieldProps.disabled}
      {...rest}
    >
      {isMobile() ? (
        <>
          <InputLabel>{fieldProps.label}</InputLabel>
          <Select
            variant={MATERIAL_FIELD_VARIANT}
            {...field}
            onChange={ev => {
              const option = options.find(o => o.key === ev.currentTarget.value)
              if (onChange) onChange(option)
              field.onChange(ev)
            }}
            label={fieldProps.label}
            native
          >
            <option></option>
            {options.map(option => (
              <option key={option.key} value={option.key}>
                {option.label}
                {option.group ? ` (${option.group})` : ''}
              </option>
            ))}
          </Select>
        </>
      ) : (
        <Autocomplete
          id={field.name}
          options={options}
          onBlur={field.onBlur}
          getOptionLabel={option => option.label || ''}
          value={options.find(o => o.key == getKeyFromOptionValue(field.value)) || null}
          getOptionSelected={option => option.key == getKeyFromOptionValue(field.value)}
          groupBy={option => option.group || ''}
          onChange={(_, option) => {
            if (onChange) onChange(option)
            if (!option) {
              helpers.setValue('')
            } else {
              if (Array.isArray(option)) {
                helpers.setValue(option.map(o => o.value))
              } else {
                helpers.setValue(option.value)
              }
            }
          }}
          renderInput={params => (
            <TextField
              {...params}
              label={fieldProps.label}
              variant={MATERIAL_FIELD_VARIANT}
              error={(meta.touched && !!meta.error) || !!meta.initialError}
              disabled={fieldProps.disabled}
            />
          )}
          renderOption={(option, { inputValue }) => {
            const matches = match(option.label, inputValue)
            const parts = parse(option.label, matches)

            return (
              <div>
                {parts.map((part, index) => (
                  <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                    {part.text}
                  </span>
                ))}
              </div>
            )
          }}
        />
      )}

      {!hideHelperTextWhenEmpty ||
        (((error && error.length > 0) || (helperText && helperText.length > 0)) && (
          <FormHelperText>{error || helperText || ' '}</FormHelperText>
        ))}
    </FormControl>
  )
}
