import { Button, Typography, useTheme } from '@material-ui/core'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { REFETCH_INTERVAL } from '../constants'
import {
	useCurrentPersonScholNotificationsQuery,
	useUpdateSchoolPersonInstallationMutation,
} from '../graphql/autogenerate/react-query'
import { UserType } from '../graphql/autogenerate/schemas'
import { locationHashToObject } from '../helpers'
import { useHandleReactQuery, useHandleReactQueryMutation } from '../hooks'
import {
	INativeBridgeContext,
	SourceVersionNumber,
	SourceVersionTest,
	TestDeviceSourceVersion,
	openAppSettings,
	pollIOSForNotDeterminedNotificationPermissionStatus,
	registerForPushNotifications,
	useNativeBridge,
} from '../hooks/use-native-bridge'
import { useSchoolContext } from '../stores/school'
import { useButtonStyles } from '../styles'
import { Callout } from './callout'
import { NotificationDetail } from './notification-detail'
import { NotificationMessage } from './notification-message'
import SchoolOnboarding from './school-onboarding'

export const SchoolNotifications = () => {
	const theme = useTheme()
	const buttonStyles = useButtonStyles()
	const {
		state: {
			currentUserPermissions,
			school: { id: schoolId, slug, name: schoolName },
		},
	} = useSchoolContext()

	const schoolPersonInstallation =
		currentUserPermissions.school?.schoolPersonInstallations.nodes[0]
	const notificationsEnabled = schoolPersonInstallation?.pushEnabled

	const [notificationToView, setNotificationToView] = useState<string>()

	const nativeBridge = useNativeBridge()

	const { data } = useHandleReactQuery(
		useCurrentPersonScholNotificationsQuery(
			{ schoolId },
			{ refetchInterval: REFETCH_INTERVAL }
		)
	)
	const { mutateAsync: updateSchoolPersonInstallation } =
		useHandleReactQueryMutation(useUpdateSchoolPersonInstallationMutation())
	const location = useLocation()
	useEffect(() => {
		const parsedHash = locationHashToObject(location.hash)
		if (parsedHash.view) setNotificationToView(parsedHash.view)
	}, [location.hash])

	const [showManage, setShowManage] = useState(false)

	async function nativelyChangePushSubStatus(
		nativeBridge: INativeBridgeContext,
		schoolPersonInstallationId?: string
	) {
		//ask the device to change its notificaiton sub status depending on the sub status.
		console.log(
			'sourceVersion is 10.0.2+ pollIOSForNotDeterminedNotificationPermissionStatus()'
		)
		if (nativeBridge.device.platform === 'ios' && schoolPersonInstallationId) {
			const isUndefinedPoll =
				await pollIOSForNotDeterminedNotificationPermissionStatus()
			if (isUndefinedPoll) {
				if (isUndefinedPoll.ispushStatusIsUndefined) {
					//Never been hard prompted
					//Prompt
					console.log('iOS push status undefined. Prompting for push notificaitons.')
					const registrationResults = await registerForPushNotifications()

					if (registrationResults) {
						await updateSchoolPersonInstallation({
							id: schoolPersonInstallationId,
							patch: {
								oneSignalToken: registrationResults.pushToken,
								oneSignalPlayerId: registrationResults.playerId,
								pushEnabled: registrationResults.pushEnabled,
							},
						})
					}
				} else {
					//Already've been hard prompted.
					//App Settings
					openAppSettings()
				}
			}
		} else {
			//is android
			//Go to app settings
			openAppSettings()
		}
		const completed = true
		return completed
	}

	const showManageGroups = () => {
		setShowManage(true)
	}

	return (
		<>
			<div
				style={{
					flex: 1,
					padding: `${theme.spacing(1)}px ${theme.spacing(1)}px ${
						theme.spacing(1) + 55
					}px ${theme.spacing(1)}px`,
					display: 'flex',
					flexDirection: 'column',
				}}
			>
				{nativeBridge ? (
					<Callout type='info'>
						{notificationsEnabled ? (
							<div>
								<Typography gutterBottom>
									You're all set to receive important updates. 👍
								</Typography>
								<div
									style={{ marginBottom: theme.spacing(1), marginTop: theme.spacing(2) }}
								>
									<Button
										onClick={showManageGroups}
										size='small'
										className={buttonStyles.white}
										variant='outlined'
									>
										Manage Groups
									</Button>
								</div>
							</div>
						) : (
							<div>
								<Typography gutterBottom>
									It looks like you did not allow push notifications. 🙁 You will not
									receive alerts for new messages.
								</Typography>
								<div
									style={{ marginBottom: theme.spacing(1), marginTop: theme.spacing(2) }}
								>
									<Button
										style={{ marginRight: theme.spacing(1) }}
										onClick={async () => {
											if (
												nativeBridge.device.sourceVersion !== undefined &&
												TestDeviceSourceVersion(
													SourceVersionNumber['10.0.2'],
													nativeBridge.device.sourceVersion,
													SourceVersionTest.GreaterThanOrEqualTo
												)
											) {
												//ask the device to change its notificaiton sub status depending on the sub status.
												console.log(
													'sourceVersion is 10.0.2+ nativelyChangePushSubStatus()'
												)
												const completed = await nativelyChangePushSubStatus(
													nativeBridge,
													schoolPersonInstallation?.id
												)
											} else {
												//otherwise, assume (potentially incorrectly), that going to the app settings is how to change the notificaiton sub.
												console.log('sourceVersion IS NOT 10.0.2+ openAppSettings()')
												openAppSettings()
											}
										}}
										size='small'
										className={buttonStyles.white}
										variant='outlined'
									>
										Enable Notifications
									</Button>
									<Button
										onClick={showManageGroups}
										size='small'
										className={buttonStyles.white}
										variant='outlined'
									>
										Manage Groups
									</Button>
								</div>
							</div>
						)}
					</Callout>
				) : (
					<Callout type='info'>
						<div>
							<Typography gutterBottom>
								You're all set to view important updates. 👍
							</Typography>
							<div
								style={{ marginBottom: theme.spacing(1), marginTop: theme.spacing(2) }}
							>
								<Button
									onClick={showManageGroups}
									size='small'
									className={buttonStyles.white}
									variant='outlined'
								>
									Manage Groups
								</Button>
							</div>
						</div>
					</Callout>
				)}

				{data?.schoolNotificationsForCurrentPerson?.totalCount ? (
					<div style={{ marginTop: theme.spacing(2) }}>
						{data.schoolNotificationsForCurrentPerson.nodes.map(notification => (
							<NotificationMessage
								key={notification.id}
								newsletterUrl={
									notification.notificationNewsletters.nodes[0]?.newsletter?.url
								}
								style={{ marginBottom: theme.spacing(1) }}
								message={notification.message}
								displayTime={dayjs(notification.sendDate || undefined)}
								groupIds={notification.notificationGroups.nodes.map(o => o.group!.id)}
								userTypes={(notification.userTypes as UserType[]) || []}
								sent
								onClick={() => {
									setNotificationToView(notification.id)

									gtag('event', 'notification_viewed', {
										event_category: slug,
										event_label: notification.message,
									})
								}}
							/>
						))}
					</div>
				) : (
					<div
						style={{
							flex: 1,
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							flexDirection: 'column',
							padding: theme.spacing(6),
						}}
					>
						<Typography gutterBottom color='textSecondary' align='center'>
							No notifications have been sent yet.
						</Typography>
						<Typography color='textSecondary' align='center'>
							Check back later!
						</Typography>
					</div>
				)}

				{notificationToView && (
					<NotificationDetail
						selectedNotificationId={notificationToView}
						onClosePressed={() => setNotificationToView(undefined)}
					/>
				)}
			</div>
			{showManage && (
				<SchoolOnboarding
					initialStep='user-type'
					onClose={() => setShowManage(false)}
				/>
			)}
		</>
	)
}
