import { Button } from '@material-ui/core'
import { ArrowForward } from '@material-ui/icons'
import { FormikTextInput, PasswordField } from '../../../forms'
import { StepProps } from './types'

const SignIn = ({ formik }: StepProps) => (
  <>
    <div style={{ width: '100%', display: 'flex' }}>
      <FormikTextInput
        type="email"
        noMargin
        fieldProps={{
          name: 'email',
          label: 'Email',
          disabled: true,
        }}
      />
    </div>

    <div style={{ width: '100%', display: 'flex' }}>
      <PasswordField
        style={{ marginBottom: 16 }}
        fieldProps={{
          name: 'password',
          label: 'Password',
          labelWidth: 70,
        }}
        noMargin
      />
    </div>

    <Button
      disabled={!formik.isValid}
      style={{ width: '100%' }}
      size="large"
      type="submit"
      color="primary"
      variant="contained"
      endIcon={<ArrowForward />}
    >
      Continue
    </Button>

    <Button
      href={`/forgot-password?email=${formik.values.email}&redirect=/return-to-app`}
      type="button"
      variant="text"
      color="primary"
      disableElevation
      style={{ width: '100%', marginTop: 8 }}
    >
      Forgot password
    </Button>
  </>
)

export default SignIn
