import { Button, useTheme } from "@material-ui/core";
import dayjs from "dayjs";
import { useSchoolAppQuery } from "../../graphql/autogenerate/react-query";
import { useAppState, useHandleReactQuery } from "../../hooks";
import { useSchoolContext } from "../../stores/school";
import { Tooltip } from "../tooltip";
import { CreativeSettings } from "./creative-settings";
import { GeneralSettings } from "./general-settings";
import { LaunchScreenSettings } from "./launch-screen-settings";
import { PushNotificationsSettings } from "./push-notifications-settings";
import { SponsorSettings } from "./sponsor-settings";
import UserTypeSettings from "./user-type-settings";

const AdminSchoolSettings = () => {
  const theme = useTheme();
  const {
    state: { currentUser },
  } = useAppState();
  const {
    state: {
      school: {
        id: schoolId,
        androidAppDownloadUrl,
        iosAppDownloadUrl,
        appleDeveloperAccountExpiry,
      },
    },
  } = useSchoolContext();
  const { data } = useHandleReactQuery(useSchoolAppQuery({ schoolId }));
  const app = data?.apps?.nodes[0];

  const appleDeveloperAccountIsExpired = Boolean(
    appleDeveloperAccountExpiry &&
      dayjs(appleDeveloperAccountExpiry).isBefore(dayjs(), "d")
  );
  const appStoreButton = (
    <Button
      href={iosAppDownloadUrl || ""}
      target="_blank"
      style={{ marginRight: theme.spacing(1) }}
      variant="contained"
    >
      View App Store Listing {appleDeveloperAccountIsExpired ? "(expired)" : ""}
    </Button>
  );

  return (
    <div
      style={{
        margin: theme.spacing(2),
        flex: 1,
        display: "flex",
        flexDirection: "column",
        minHeight: 0,
        overflowY: "auto",
      }}
    >
      <div style={{ marginBottom: theme.spacing(2) }}>
        {iosAppDownloadUrl &&
          (appleDeveloperAccountIsExpired ? (
            <Tooltip title="Apple developer account is expired">
              {appStoreButton}
            </Tooltip>
          ) : (
            appStoreButton
          ))}
        {androidAppDownloadUrl && (
          <Button
            href={androidAppDownloadUrl}
            target="_blank"
            variant="contained"
          >
            View Google Play Listing
          </Button>
        )}
      </div>

      {app && currentUser?.appAdministrator && <PushNotificationsSettings />}

      {currentUser?.appAdministrator && <GeneralSettings />}

      <SponsorSettings />

      <LaunchScreenSettings />

      <CreativeSettings />

      <UserTypeSettings />
    </div>
  );
};

export default AdminSchoolSettings;
