import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Button,
  Divider,
  Typography,
  useTheme,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import { Form, Formik } from "formik";
import { useSnackbar } from "notistack";
import { useState } from "react";
import * as yup from "yup";
import { useUpdateCreativeSchoolSettingsMutation } from "../../graphql/autogenerate/react-query";
import { useHandleReactQueryMutation } from "../../hooks";
import { useSchoolContext } from "../../stores/school";
import {
  FileFieldValue,
  FormikImageField,
  useImageFieldChangeHandler,
} from "../forms";
import { FormikNumberField } from "../forms/formik-number-field";

export const CreativeSettings = () => {
  const {
    state: {
      school: { schoolSetting, id: schoolId },
    },
    actions: {
      refetch: { schoolDetail: refetchSchoolDetails },
    },
  } = useSchoolContext();

  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [isOpen, setIsOpen] = useState(false);
  const fileChangeHandler = useImageFieldChangeHandler();

  const { mutate: updateCreativeSettings } = useHandleReactQueryMutation(
    useUpdateCreativeSchoolSettingsMutation({
      onSuccess: () => {
        enqueueSnackbar("Creative settings updated", { variant: "success" });
        refetchSchoolDetails();
      },
    })
  );

  if (!schoolSetting) return null;

  return (
    <Formik
      initialValues={{
        thumbnailImage:
          schoolSetting.thumbnailImage as unknown as FileFieldValue,
        resourceTileOpacity: schoolSetting.resourceTileOpacity || 0,
      }}
      validationSchema={yup.object({
        resourceTileOpacity: yup
          .number()
          .required("Required")
          .min(0, "Must be greater than or equal to 0.")
          .max(99, "Must be less than 100."),
      })}
      onSubmit={async (values) => {
        updateCreativeSettings({
          schoolId,
          ...(await fileChangeHandler({
            oldValue: schoolSetting.thumbnailImage,
            newValue: values.thumbnailImage,
            filePropertyName: "thumbnailImage",
          })),
          resourceTileOpacity: values.resourceTileOpacity,
        });
      }}
    >
      {(formikProps) => (
        <Accordion
          expanded={isOpen}
          onChange={(_, expanded) => setIsOpen(expanded)}
        >
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography variant="h5">📷 Creative</Typography>
          </AccordionSummary>
          <AccordionDetails
            style={{ padding: `${theme.spacing(1)}px ${theme.spacing(2)}px` }}
          >
            <Form style={{ flex: 1, minWidth: 0 }}>
              <div style={{ display: "flex" }}>
                <FormikImageField
                  style={{ flex: 1, marginRight: theme.spacing(1) }}
                  field={{ name: "thumbnailImage", label: "Icon" }}
                  aspectRatio={1}
                  aspectRatioHelper="1:1 aspect ratio (e.g. 500px by 500px)"
                />
              </div>

              <div style={{ marginTop: theme.spacing(1) }}>
                <FormikNumberField
                  field={{
                    name: "resourceTileOpacity",
                    label: "Resource Tile Opacity",
                  }}
                />
              </div>
            </Form>
          </AccordionDetails>
          <Divider />
          <AccordionActions>
            <Button size="small" onClick={() => setIsOpen(false)}>
              Cancel
            </Button>
            <Button
              onClick={formikProps.submitForm}
              type="submit"
              size="small"
              color="primary"
            >
              Save
            </Button>
          </AccordionActions>
        </Accordion>
      )}
    </Formik>
  );
};
