import { useCallback } from 'react'
import { GroupOptionType, isInfoOption } from './types'
import useGetContentForInfoOption from './use-get-content-for-info-option'
import { AutocompleteRenderOptionState } from '@material-ui/lab'
import { IconTypeDisplay } from '../icon-type-display'
import { useTheme } from '@material-ui/core'

const useRenderOption = () => {
  const getContentForInfoOption = useGetContentForInfoOption()

  const theme = useTheme()

  return useCallback((option: GroupOptionType, state: AutocompleteRenderOptionState) => {
    if (isInfoOption(option)) {
      return getContentForInfoOption(option)
    } else {
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <IconTypeDisplay
            style={{
              color: option.color || theme.palette.grey[500],
              marginRight: theme.spacing(1),
            }}
            size={15}
            type={option.iconType}
          />
          {option.title}
          {option.divisionName && !option.isDivision && ` (${option.divisionName})`}
        </div>
      )
    }
  }, [])
}

export default useRenderOption
