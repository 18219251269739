import { GroupOption } from './types'

import { ChangeEvent, useCallback } from 'react'
import { AutocompleteChangeReason } from '@material-ui/lab'
import { GroupOptionType, InfoOption, isInfoOption } from './types'

type Options = {
  closeSchoolwideCallout: () => void
  closeDivisionCallout: () => void
  handleSelectedGroupsChange: (selectedOptions: GroupOption[]) => void
  setSidebarOpen: (open: boolean) => void
}

const useHandleAutocompleteOnChange = ({
  closeSchoolwideCallout,
  closeDivisionCallout,
  handleSelectedGroupsChange,
  setSidebarOpen,
}: Options) =>
  useCallback(
    async (
      _: ChangeEvent<{}>,
      selectedOptions: GroupOptionType[],
      reason: AutocompleteChangeReason
    ) => {
      if (reason === 'clear') {
        closeSchoolwideCallout()
        closeDivisionCallout()
        handleSelectedGroupsChange([])
      } else if (selectedOptions.find(o => isInfoOption(o))) {
        const infoOption = selectedOptions.find(o => isInfoOption(o)) as InfoOption
        if (infoOption.openSidebarWhenSelected) setSidebarOpen(true)
        return
      } else {
        // Safe to type cast at this point since we filter out any info options in the check above this one. Otherwise, totally NOT safe to force handleSelectedGroupsChange to take the list of selectedOptions.
        handleSelectedGroupsChange(selectedOptions as GroupOption[])
      }
    },
    []
  )

export default useHandleAutocompleteOnChange
