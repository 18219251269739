import { useMemo } from 'react'
import { GroupOption } from './types'
import { CurrentUserFragment, SchoolDivisionsFragment } from '../../graphql/autogenerate/operations'
import { IconType } from '../../graphql/autogenerate/schemas'
import { useTheme } from '@material-ui/core'
import { ICurrentUserPermssions } from '../../stores/school'

type Options = {
  divisions?: SchoolDivisionsFragment[]
  currentUser?: CurrentUserFragment | null
  currentUserPermissions: ICurrentUserPermssions
  excludeSchoolwide?: boolean
}

/* 
    Calculate the raw options list, this should only need to be done once since the 
    divisions query is unlikely to be run again (but it's OK if it happens multiple times, 
    hence including Divisions in the deps list).
*/
const useGroupOptions = ({
  divisions,
  currentUser,
  currentUserPermissions,
  excludeSchoolwide,
}: Options) => {
  const theme = useTheme()

  return useMemo<GroupOption[]>(() => {
    if (!divisions) return []

    const multiDivisionSchool = divisions.length > 1

    // We check the group names to see if there are any duplicates. If there are, we add the group's division and category to help distinguish it.
    const groupNames: string[] = []
    divisions.forEach(division => {
      division.groupCategories.nodes.forEach(category => {
        groupNames.push(...category.groups.nodes.map(group => group.groupName.toLowerCase()))
      })
    })
    const nameCounts = groupNames.reduce<{ [key: string]: number }>(
      (nameCounts, name) => ({
        ...nameCounts,
        [name]: (nameCounts[name] || 0) + 1,
      }),
      {}
    )
    const duplicateNames = Object.keys(nameCounts).filter(name => nameCounts[name] > 1)

    let _options: GroupOption[] = []
    divisions.forEach(division => {
      if (division.group) {
        _options.push({
          id: division.group.id,
          title: division.name,
          iconType: division.schoolwide ? IconType.IosFilledCityHall : IconType.IosFilledSchool,
          color: theme.palette.primary.main,
          isSchoolwide: Boolean(division.schoolwide),
          isDivision: true,
          divisionName: division.name,
          divisionId: division.id,
          divisionGroupId: division.group.id,
        })
      }

      division.groupCategories.nodes.forEach(category => {
        _options.push(
          ...category.groups.nodes.map(group => ({
            id: group.id,
            title: group.groupName,
            divisionName:
              !multiDivisionSchool && duplicateNames.includes(group.groupName.toLowerCase())
                ? division.name
                : undefined,
            iconType: group.iconType || category.iconType,
            color: category.iconBackgroundColor || theme.palette.grey[500],
            divisionId: division.id,
            divisionGroupId: division.group?.id,
          }))
        )
      })
    })

    if (excludeSchoolwide) {
      _options = _options.filter(option => !option.isSchoolwide)
    }

    // App administrators and schoolwide admins can select all groups
    if (currentUser?.appAdministrator || currentUserPermissions.schoolwideAdmin) {
      return _options
    } else {
      return _options.filter(option =>
        // Allow access if the user is an admin on the group OR an admin on this group's division's group
        currentUserPermissions.groups.nodes.some(
          group =>
            (group.groupId === option.id || option.divisionGroupId === group.groupId) && group.admin
        )
      )
    }
  }, [divisions, excludeSchoolwide])
}

export default useGroupOptions
