import { Button } from '@material-ui/core'
import { ArrowForward, CheckCircleOutline } from '@material-ui/icons'
import { Dispatch, SetStateAction } from 'react'
import {
    GroupListFieldsFragment,
    SchoolDivisionsFragment,
} from '../../../graphql/autogenerate/operations'
import { UserType } from '../../../graphql/autogenerate/schemas'
import { State } from './types'
import useNextDivision from './use-next-division'
import useSubmitSelections from './use-submit-selections'

type Props = {
  currentDivision?: SchoolDivisionsFragment
  selectedDivisions?: SchoolDivisionsFragment[]
  selectedGroups?: GroupListFieldsFragment[]
  userTypes?: UserType[]
  setState: Dispatch<SetStateAction<State>>
  selectedDivisionsWithGroups?: SchoolDivisionsFragment[]
}

const ContinueButton = ({
  currentDivision,
  selectedDivisions,
  selectedGroups,
  userTypes,
  setState,
  selectedDivisionsWithGroups,
}: Props) => {
  const nextDivisionToSelectGroupsFor = useNextDivision({
    selectedDivisionsWithGroups,
    currentDivision,
  })

  const submitSelections = useSubmitSelections({
    setState,
    selectedGroups,
    selectedDivisions,
    userTypes,
    nextDivisionToSelectGroupsFor,
  })

  return (
    <div>
      <Button
        disabled={!Boolean(selectedDivisions?.length)}
        style={{ width: '100%' }}
        size="large"
        color="primary"
        variant="contained"
        endIcon={nextDivisionToSelectGroupsFor ? <ArrowForward /> : <CheckCircleOutline />}
        onClick={submitSelections}
      >
        {currentDivision
          ? nextDivisionToSelectGroupsFor
            ? `Continue to ${nextDivisionToSelectGroupsFor.name}`
            : 'Save'
          : 'Continue'}
      </Button>
    </div>
  )
}

export default ContinueButton
