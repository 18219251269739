import { useTheme } from '@material-ui/core'
import { useCallback, useMemo, useState } from 'react'
import { createContext, FunctionComponent, useContext } from 'react'
import {
  SchoolDetailFragment,
  CurrentUserSchoolPermissionsFragment,
  GroupListFieldsFragment,
} from '../../graphql/autogenerate/operations'
import { IconType, UserType } from '../../graphql/autogenerate/schemas'

interface ISchoolActions {
  refetch: {
    schoolDetail: () => Promise<void>
  }
  setUserHasOnboarded: (onboarded: boolean) => void
}

export interface ICurrentUserPermssions extends CurrentUserSchoolPermissionsFragment {
  schoolwideAdmin?: boolean
}

export interface ISchoolState {
  school: SchoolDetailFragment
  groups: IGroup[]
  enabledUserTypes: UserType[]
  currentUserPermissions: ICurrentUserPermssions
}

export interface ISchoolContext {
  state: ISchoolState
  actions: ISchoolActions
  userHasOnboarded?: boolean
}

export interface IGroup extends GroupListFieldsFragment {
  calendarIds: string[]
  iconType: IconType
  color: string
}

export const SchoolContext = createContext<ISchoolContext | undefined>(undefined)

export const useSchoolContext = () => {
  const context = useContext(SchoolContext)
  if (!context) throw new Error('Attempted to SchoolContext before it was provided.')
  return context
}

interface IShoolContextProviderProps {
  school: ISchoolState['school']
  refetchSchool: ISchoolActions['refetch']['schoolDetail']
  currentUserPermissions: ISchoolState['currentUserPermissions']
}

export const ShoolContextProvider: FunctionComponent<IShoolContextProviderProps> = ({
  children,
  school,
  refetchSchool,
  currentUserPermissions,
}) => {
  const theme = useTheme()

  const groups = useMemo(() => {
    const _groups: IGroup[] = []

    const divisions = school.divisions.nodes

    if (!divisions) return _groups

    divisions.forEach(division => {
      if (division.group) {
        _groups.push({
          ...division.group,
          calendarIds: division.group.calendars.nodes.map(o => o.id),
          iconType: division.schoolwide ? IconType.IosFilledCityHall : IconType.IosFilledSchool,
          color: theme.palette.primary.main,
        })
      }

      division.groupCategories.nodes.forEach(category => {
        _groups.push(
          ...category.groups.nodes.map(group => ({
            ...group,
            calendarIds: group.calendars.nodes.map(o => o.id),
            iconType: group.iconType || category.iconType,
            color: category.iconBackgroundColor || theme.palette.grey[500],
          }))
        )
      })
    })

    return _groups
  }, [school])

  const enabledUserTypes = useMemo(() => {
    const userTypes: UserType[] = []
    if (school.schoolSetting?.enableParentFamilyAccess) userTypes.push(UserType.Parent)
    userTypes.push(UserType.FacultyStaff)
    if (school.schoolSetting?.enableStudentAccess) userTypes.push(UserType.Student)
    if (school.schoolSetting?.enableGuestAccess) userTypes.push(UserType.Guest)
    return userTypes
  }, [school])

  const [userHasOnboarded, setUserHasOnboarded] = useState(
    currentUserPermissions.school?.completedOnboarding
  )

  const getContext = useCallback((): ISchoolContext => {
    return {
      state: {
        groups,
        school,
        currentUserPermissions,
        enabledUserTypes,
      },
      actions: {
        refetch: { schoolDetail: refetchSchool },
        setUserHasOnboarded,
      },
      userHasOnboarded,
    }
  }, [groups, school, currentUserPermissions, refetchSchool, userHasOnboarded, setUserHasOnboarded])

  return <SchoolContext.Provider value={getContext()} children={children} />
}
