export enum RegisterStep {
  Register = 'register',
  SignIn = 'sign-in',
}

export type State = {
  step?: RegisterStep
}

export const defaultState: State = {}

export type FormValues = {
  email: string
  password: string
  confirmPassword: string
  firstName: string
  lastName: string
}
