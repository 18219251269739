import { Dispatch, SetStateAction } from 'react'
import { useSchoolOnboardingContext } from '..'
import {
  GroupListFieldsFragment,
  SchoolDivisionsFragment,
} from '../../../graphql/autogenerate/operations'
import {
  useCreateSchoolPersonInstallationMutation,
  useSubmitOnboardingAnswersMutation,
} from '../../../graphql/autogenerate/react-query'
import { UserType } from '../../../graphql/autogenerate/schemas'
import { useHandleReactQueryMutation } from '../../../hooks'
import { setOneSignalUserId, useNativeBridge } from '../../../hooks/use-native-bridge'
import { useSchoolContext } from '../../../stores/school'
import { State } from './types'

type Options = {
  setState: Dispatch<SetStateAction<State>>
  selectedGroups?: GroupListFieldsFragment[]
  selectedDivisions?: SchoolDivisionsFragment[]
  userTypes?: UserType[]
  nextDivisionToSelectGroupsFor: SchoolDivisionsFragment | null
}

const useSubmitSelections = ({
  setState,
  selectedGroups,
  selectedDivisions,
  userTypes,
  nextDivisionToSelectGroupsFor,
}: Options) => {
  const {
    state: {
      school: { id: schoolId },
    },
    actions: { refetch },
  } = useSchoolContext()

  const { setSchoolOnboardingState } = useSchoolOnboardingContext()
  const bridgeContext = useNativeBridge()

  const { mutateAsync: createSchoolPersonInstallation } = useHandleReactQueryMutation(
    useCreateSchoolPersonInstallationMutation()
  )

  const { mutate: submitOnboardingSelections } = useHandleReactQueryMutation(
    useSubmitOnboardingAnswersMutation({
      onSuccess: async ({ submitCurrentPersonSchoolOnboarding }) => {
        if (submitCurrentPersonSchoolOnboarding?.schoolPerson) {
          if (
            !submitCurrentPersonSchoolOnboarding.schoolPerson.schoolPersonInstallations.nodes
              .length &&
            bridgeContext
          ) {
            // If we're on native and there isn't already an installation for this person, create a School_PersonInstallation for this School_Person
            await createSchoolPersonInstallation({
              ...bridgeContext.device,
              schoolPersonId: submitCurrentPersonSchoolOnboarding.schoolPerson.id,
            })

            // Register this School_Person's ID with OneSignal as the "user" for the device
            setOneSignalUserId({
              schoolPersonId: submitCurrentPersonSchoolOnboarding.schoolPerson.id,
            })
          }
        }

        await refetch.schoolDetail()

        setSchoolOnboardingState(_state => ({
          ..._state,
          step: bridgeContext ? 'enable-notifications' : 'summary',
        }))
      },
    })
  )

  return () => {
    if (nextDivisionToSelectGroupsFor) {
      setState(_state => ({
        ..._state,
        currentDivision: nextDivisionToSelectGroupsFor,
      }))
    } else {
      // We have all the user's onboarding selections now. Go ahead and complete the onboarding then take them to enable notifications request.
      submitOnboardingSelections({
        selectedGroupIds: (selectedGroups || [])
          .map(o => o.id)
          .concat((selectedDivisions || []).map(o => o.group!.id)),
        userTypes: userTypes || [],
        schoolId,
        installationFilter: bridgeContext
          ? {
              deviceName: {
                equalTo: bridgeContext.device.deviceName,
              },
              platform: { equalTo: bridgeContext.device.platform },
            }
          : undefined,
      })
    }
  }
}

export default useSubmitSelections
